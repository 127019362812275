import React from 'react';
import PropTypes from 'prop-types';
import '../../sam.scss';
import NOB from '../../../../constants/language-strings';
import Item from './Item';
var type = 'Modem info';
var ModemInfo = function (_a) {
    var devicePollingMac = _a.devicePollingMac;
    return (React.createElement("div", { className: "overview__section__items--value--modem" },
        React.createElement(Item, { value: devicePollingMac.Status, header: NOB.DOWNSTREAM_UPSTREAM.STATUS, statusColor: devicePollingMac.Online ? 'status--good' : 'status--error', type: type }),
        React.createElement(Item, { value: devicePollingMac.UpTimeDescription, header: NOB.DOWNSTREAM_UPSTREAM.UPTIME, type: type }),
        React.createElement(Item, { value: devicePollingMac.Serial, header: NOB.DOWNSTREAM_UPSTREAM.SERIAL, type: type }),
        React.createElement(Item, { value: devicePollingMac.MacAddress, header: NOB.DOWNSTREAM_UPSTREAM.MAC_1, type: type }),
        React.createElement(Item, { value: devicePollingMac.Ipv4, header: NOB.DOWNSTREAM_UPSTREAM.IP, type: type }),
        React.createElement(Item, { value: devicePollingMac.Cmts, header: NOB.DOWNSTREAM_UPSTREAM.CMTS, type: type }),
        React.createElement(Item, { value: devicePollingMac.ConfigFile, header: NOB.DOWNSTREAM_UPSTREAM.CONFIG, type: type })));
};
ModemInfo.propTypes = {
    devicePollingMac: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default ModemInfo;
