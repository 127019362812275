var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export default function filterServices(services, existingWorkOrderServiceIds, categories, isDiagnosticServices) {
    var filteredServices = services.filter(function (item) { return !existingWorkOrderServiceIds.includes(item.serviceId); });
    if (categories) {
        filteredServices = filteredServices.map(function (service) {
            var _a = __read(service.description.split(' > '), 3), main = _a[0], sub = _a[1], error = _a[2];
            var amount = service.servicePrices[0] ? service.servicePrices[0].amount : 0;
            var trimmedError = error ? error.trim() : error;
            return {
                main: isDiagnosticServices ? sub : main,
                sub: isDiagnosticServices ? trimmedError : sub,
                error: isDiagnosticServices ? null : error,
                id: service.serviceId,
                description: service.description,
                name: service.description,
                amount: amount,
                timeUnitPerService: service.timeUnitPerService,
                serviceId: service.serviceId,
                value: service.serviceId,
            };
        });
    }
    return filteredServices;
}
