var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CORRECTED, UNCORRECTABLE, UPSTREAM, POWER, MIN_POWER, MAX_POWER, SNR, MIN_SNR, MAX_SNR, DOWNSTREAM, } from '../constants/samSelector';
var STATUS_GOOD = 'status--good';
var STATUS_WARNING = 'status--warning';
var STATUS_ERROR = 'status--error';
/*
  percentHelper is a helper-function to the colorPicker function.
  It is based on calculations reverse engineered from the "cwPercent"
  function, found in healthCheck.js localized by inspecting the SAM - customer view
  page. (Developer tools > Sources > OneUi > js)

  Args:
    property: String, preferably from the ../constants/samSelector.js file
    pollData: Array of objects originating from the SAM-api.

  Returns:
    The percent of corrected or uncorrected values extracted from the array of objects.
*/
function percentHelper(property, pollData) {
    var totalSum = pollData.reduce(function (sum, ch) { return sum + ch.Good + ch.Corrected + ch.Uncorrectable; }, 0);
    if (property === CORRECTED) {
        return pollData.reduce(function (sum, ch) { return sum + ch.Corrected * 100; }, 0) / totalSum;
    }
    return pollData.reduce(function (sum, ch) { return sum + ch.Uncorrectable * 100; }, 0) / totalSum;
}
/*
  minMaxHelper is a helper-function to the colorPicker function.
  It returns the maxium, minimum or first element of the data array argument, based
  on the selector argument.

  Args:
    selector: string, preferably from the ../constants/samSelector.js file
    data: Array of objects originating from the SAM-api.

  Returns:
    returnVal: Max or min of data if arg selector includes "MAX" or "MIN", otherwise the
              first element of the array.
*/
function minMaxHelper(selector, data) {
    var returnVal = data[0];
    if (selector.includes('MAX')) {
        returnVal = Math.max.apply(Math, __spreadArray([], __read(data), false));
    }
    else if (selector.includes('MIN')) {
        returnVal = Math.min.apply(Math, __spreadArray([], __read(data), false));
    }
    return returnVal;
}
/*
  The colorPicker function returns one of three strings based on the attribute:
  selected, channel and the limits.

  The calculations are reverse-engineered from the SAM - customer view page by inspecting
  the healthCheck.js file. (Developer tools > Sources > OneUi > js) functions: cwPercent,
  renderPolledData might be of interest.

  Args:
    scoreVariables: Object, the scoreVariables from the SAM-api. Contains limits.
    selector:       String, the attribute to be tested, i.e. "power"
    channel:        String, "us" for upstream-, "ds" for downstream channel.
    pollData:       Object, the channel(s) from the SAM-api

  Returns:
    htmlClassName: String, one of the following:
                    STATUS_GOOD    =  "status--good"
                    STATUS_WARNING =  "status--warning"
                    STATUS_ERROR   =  "status--error"
*/
export default function colorPicker(scoreVariables, selector, channel, pollData) {
    var UsPwrLimit1Ch = scoreVariables.UsPwrLimit1Ch, UsPwrLimit2Ch = scoreVariables.UsPwrLimit2Ch, UsPwrLimitXCh = scoreVariables.UsPwrLimitXCh, DsPwrLimitHigh = scoreVariables.DsPwrLimitHigh, DsPwrLimitLow = scoreVariables.DsPwrLimitLow, UsSnrLimit2 = scoreVariables.UsSnrLimit2, UsSnrLimit1 = scoreVariables.UsSnrLimit1, DsSnrLimit = scoreVariables.DsSnrLimit, CwCorrLim2 = scoreVariables.CwCorrLim2, CwCorrLim1 = scoreVariables.CwCorrLim1, UsUncorrSeverity2 = scoreVariables.UsUncorrSeverity2, UsUncorrSeverity0 = scoreVariables.UsUncorrSeverity0, DsUncorrSeverity2 = scoreVariables.DsUncorrSeverity2, DsUncorrSeverity0 = scoreVariables.DsUncorrSeverity0;
    var data = Array.isArray(pollData) ? pollData : [pollData];
    var noOfUpstream = data.length;
    var power = minMaxHelper(selector, data.map(function (ch) { return (ch.Power === -1000 ? 0 : ch.Power); }));
    var snr = minMaxHelper(selector, data.map(function (ch) { return (ch.Snr === -1000 ? 0 : ch.Snr); }));
    var corrected = percentHelper(CORRECTED, data);
    var uncorrectable = percentHelper(UNCORRECTABLE, data);
    var htmlClassName = STATUS_GOOD;
    if (channel === UPSTREAM) {
        switch (selector) {
            case POWER:
            case MIN_POWER:
            case MAX_POWER:
                if ((noOfUpstream === 1 && power >= UsPwrLimit1Ch) ||
                    (noOfUpstream === 2 && power >= UsPwrLimit2Ch) ||
                    (noOfUpstream > 2 && power >= UsPwrLimitXCh)) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            case SNR:
            case MIN_SNR:
            case MAX_SNR:
                if (snr <= UsSnrLimit2) {
                    htmlClassName = STATUS_ERROR;
                }
                else if (snr <= UsSnrLimit1) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            case CORRECTED:
                if (corrected >= CwCorrLim2) {
                    htmlClassName = STATUS_ERROR;
                }
                else if (corrected >= CwCorrLim1) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            case UNCORRECTABLE:
                // NOTE: Skipping UsUncorrServerity1. SAM has > 1 in orange and > 0 in yellow.
                if (uncorrectable >= UsUncorrSeverity2) {
                    htmlClassName = STATUS_ERROR;
                }
                else if (uncorrectable >= UsUncorrSeverity0) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            default:
                break;
        }
    }
    else if (channel === DOWNSTREAM) {
        switch (selector) {
            case POWER:
            case MIN_POWER:
            case MAX_POWER:
                if (power >= DsPwrLimitHigh || power <= DsPwrLimitLow) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            case SNR:
            case MIN_SNR:
            case MAX_SNR:
                if (snr <= DsSnrLimit) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            case CORRECTED:
                if (corrected >= CwCorrLim2) {
                    htmlClassName = STATUS_ERROR;
                }
                else if (corrected >= CwCorrLim1) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            case UNCORRECTABLE:
                // NOTE: Skipping UsUncorrServerity1. SAM has > 1 in orange and > 0 in yellow.
                if (uncorrectable >= DsUncorrSeverity2) {
                    htmlClassName = STATUS_ERROR;
                }
                else if (uncorrectable >= DsUncorrSeverity0) {
                    htmlClassName = STATUS_WARNING;
                }
                break;
            default:
                break;
        }
    }
    return htmlClassName;
}
