import NOB from './constants/language-strings';
export default (function (state, action) {
    if (state === void 0) { state = NOB; }
    switch (action.type) {
        case 'NOB':
            return {
                NOB: action.NOB,
            };
        default:
            return state;
    }
});
