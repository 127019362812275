// Activate product
export var ACTIVATE_PRODUCT_FAILURE = 'ACTIVATE_PRODUCT_FAILURE';
export var ACTIVATE_PRODUCT_REMOVE_ERROR = 'ACTIVATE_PRODUCT_REMOVE_ERROR';
export var ACTIVATE_PRODUCT_REQUEST = 'ACTIVATE_PRODUCT_REQUEST';
export var ACTIVATE_PRODUCT_SUCCESS = 'ACTIVATE_PRODUCT_SUCCESS';
export var ACTIVATE_PRODUCT_CLEAR = 'ACTIVATE_PRODUCT_CLEAR';
export var DPAD_DASH = 'dpad-';
export var SMOOTH = 'smooth';
// Add product
export var ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';
export var ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export var ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
// Agreements
export var AGREEMENTS_FAILURE = 'AGREEMENTS_FAILURE';
export var AGREEMENTS_REQUEST = 'AGREEMENTS_REQUEST';
export var AGREEMENTS_SUCCESS = 'AGREEMENTS_SUCCESS';
// Agreement price
export var AGREEMENTS_PRICE_FAILURE = 'AGREEMENTS_PRICE_FAILURE';
export var AGREEMENTS_PRICE_REQUEST = 'AGREEMENTS_PRICE_REQUEST';
export var AGREEMENTS_PRICE_SUCCESS = 'AGREEMENTS_PRICE_SUCCESS';
// Available products
export var AVAILABLE_PRODUCTS_ERROR = 'AVAILABLE_PRODUCTS_ERROR';
export var AVAILABLE_PRODUCTS_REQUEST = 'AVAILABLE_PRODUCTS_REQUEST';
export var AVAILABLE_PRODUCTS_SUCCESS = 'AVAILABLE_PRODUCTS_SUCCESS';
export var AVAILABLE_PRODUCTS_CLEAR_ERROR = 'AVAILABLE_PRODUCTS_CLEAR_ERROR';
// Change product
export var CHANGE_PRODUCT_FAILURE = 'CHANGE_PRODUCT_FAILURE';
export var CHANGE_PRODUCT_REQUEST = 'CHANGE_PRODUCT_REQUEST';
export var CHANGE_PRODUCT_SUCCESS = 'CHANGE_PRODUCT_SUCCESS';
export var CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
// Customer product
export var CUSTOMER_PRODUCTS_REQUEST = 'CUSTOMER_PRODUCTS_REQUEST';
export var CUSTOMER_PRODUCTS_SUCCESS = 'CUSTOMER_PRODUCTS_SUCCESS';
export var CUSTOMER_PRODUCTS_FAILURE = 'CUSTOMER_PRODUCTS_FAILURE';
export var CUSTOMER_PRODUCTS_UPDATE = 'CUSTOMER_PRODUCTS_UPDATE';
export var CUSTOMER_PRODUCTS_REQUEST_BACKGROUND = 'CUSTOMER_PRODUCTS_REQUEST_BACKGROUND';
export var UPDATE_SINGLE_PRODUCT = 'UPDATE_SINGLE_PRODUCT';
// Link product
export var LINK_PRODUCT_REQUEST = 'LINK_PRODUCT_REQUEST';
export var LINK_PRODUCT_SUCCESS = 'LINK_PRODUCT_SUCCESS';
export var LINK_PRODUCT_FAILURE = 'LINK_PRODUCT_FAILURE';
export var LINK_SINGLE_PRODUCT_REQUEST = 'LINK_SINGLE_PRODUCT_REQUEST';
// Remove customer product
export var TO_BE_REMOVED = 'TO_BE_REMOVED';
export var REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST';
export var REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export var REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE';
export var CLEAR_REMOVE_ERROR_MESSAGE = 'CLEAR_REMOVE_ERROR_MESSAGE';
export var ENTER = 'Enter';
