var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import capitalize from '../../../../utils/capitalizeString';
import Icon from '../../../../icons';
import NOB from '../../../../constants/language-strings';
import Status, { COMPLETE, INCOMPLETE } from '../../../../constants/status-codes';
import { CAR, TOOL_OULINED } from '../../../../constants/iconNames';
import './workOrderDetails.scss';
import { hours, minutes } from '../../../../utils/timeCalculator';
import getFormattedDate from '../../../../utils/getFormattedDate';
import isPast from '../../../../utils/isPastDue';
var WorkOrderDetails = /** @class */ (function (_super) {
    __extends(WorkOrderDetails, _super);
    function WorkOrderDetails(props) {
        var _this = _super.call(this, props) || this;
        _this.formatTime = function (sec) {
            _this.hours = Math.floor(sec / (60 * 60));
            _this.formatMinutes = sec % (60 * 60);
            _this.minutes = Math.floor(_this.formatMinutes / 60);
            _this.formatSeconds = _this.formatMinutes % 60;
            _this.seconds = Math.ceil(_this.formatSeconds);
            _this.time = {
                hours: _this.hours.toString(),
                minutes: _this.minutes.toString(),
                seconds: _this.seconds.toString(),
            };
            return _this.time;
        };
        _this.startDriveTimer = function () {
            if (_this.driveTimer === 0) {
                _this.driveTimer = setInterval(_this.countDrive, 1000);
            }
        };
        _this.stopDriveTimer = function () {
            clearInterval(_this.driveTimer);
        };
        _this.countDrive = function () {
            var driveSeconds = _this.state.driveSeconds + 1;
            _this.setState({
                driveTime: _this.formatTime(driveSeconds),
                driveSeconds: driveSeconds,
            });
            if (driveSeconds === 0) {
                clearInterval(_this.driveTimer);
            }
        };
        _this.startWorkTimer = function () {
            if (_this.workTimer === 0) {
                _this.workTimer = setInterval(_this.countWork, 1000);
            }
        };
        _this.stopWorkTimer = function () {
            clearInterval(_this.workTimer);
        };
        _this.countWork = function () {
            var workSeconds = _this.state.workSeconds + 1;
            _this.setState({
                workTime: _this.formatTime(workSeconds),
                workSeconds: workSeconds,
            });
            if (workSeconds === 0) {
                clearInterval(_this.workTimer);
            }
        };
        var _a = _this.props, travelDuration = _a.travelDuration, workDuration = _a.workDuration;
        _this.state = {
            driveTime: _this.formatTime(travelDuration - workDuration),
            workTime: _this.formatTime(workDuration),
            driveSeconds: travelDuration,
            workSeconds: workDuration,
        };
        _this.driveTimer = 0;
        _this.workTimer = 0;
        if (Status.TRAVELING.includes(props.workOrder.status)) {
            _this.setState({
                driveSeconds: travelDuration,
                driveTime: _this.formatTime(travelDuration),
            });
            _this.startDriveTimer();
            _this.stopWorkTimer();
        }
        if (Status.ON_SITE.includes(props.workOrder.status)) {
            _this.setState({
                workSeconds: workDuration,
                driveSeconds: travelDuration,
                driveTime: _this.formatTime(travelDuration),
            });
            _this.startWorkTimer();
            _this.stopDriveTimer();
        }
        return _this;
    }
    WorkOrderDetails.getDerivedStateFromProps = function (props, state) {
        var travelDuration = props.travelDuration, workDuration = props.workDuration;
        var driveTime = state.driveSeconds;
        // In case the time from ICX (travelDuration) does not match the timed seconds in driveTime, use the ICX time, unless it is zero.
        var actualDriveTime = travelDuration !== 0 ? travelDuration : driveTime;
        var newState = null;
        if (Status.ON_SITE.includes(props.workOrder.status)) {
            newState = {
                workSeconds: workDuration,
                driveSeconds: actualDriveTime,
                workTime: this.formatTime(workDuration),
                driveTime: this.formatTime(actualDriveTime),
            };
            this.startWorkTimer();
            this.stopDriveTimer();
        }
        if (Status.TRAVELING.includes(props.workOrder.status)) {
            newState = {
                driveSeconds: actualDriveTime,
                driveTime: this.formatTime(actualDriveTime),
            };
            this.startDriveTimer();
            this.stopWorkTimer();
        }
        return newState;
    };
    WorkOrderDetails.prototype.componentWillUnmount = function () {
        this.startWorkTimer();
        this.startDriveTimer();
        this.stopWorkTimer();
        this.stopDriveTimer();
        this.countDrive();
        this.countWork();
    };
    WorkOrderDetails.prototype.render = function () {
        var _a = this.props, isFetching = _a.isFetching, done = _a.done, workOrder = _a.workOrder, totalTimeEstimate = _a.totalTimeEstimate, inWorkLog = _a.inWorkLog;
        var driveTimer = "\n      ".concat(this.state.driveTime.hours.padStart(2, 0), ":\n      ").concat(this.state.driveTime.minutes.padStart(2, 0), ":\n      ").concat(this.state.driveTime.seconds.padStart(2, 0));
        var workTimer = "\n      ".concat(this.state.workTime.hours.padStart(2, 0), ":\n      ").concat(this.state.workTime.minutes.padStart(2, 0), ":\n      ").concat(this.state.workTime.seconds.padStart(2, 0));
        var status = /\d/.test(workOrder.status)
            ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
            : workOrder.status;
        var header = function () { return "\n        ".concat(NOB.WORKORDER_DETAILS.ESTIMATED_TIME, " \n        ").concat(hours(totalTimeEstimate), " \n        ").concat(minutes(totalTimeEstimate)); };
        var isPastDue = isPast(workOrder.serviceDate);
        return (React.createElement("div", { className: "workOrder__details__outer__container" },
            React.createElement("div", { className: "workOrder__details__container" },
                React.createElement("span", { className: "item" },
                    React.createElement("span", { className: "item__description" },
                        NOB.WORKORDER_DETAILS.WORKORDER,
                        ":\u00A0"),
                    workOrder.workOrderId),
                !(Status.ON_SITE.includes(workOrder.status) || Status.TRAVELING.includes(workOrder.status)) ? (React.createElement("div", null, COMPLETE.includes(status) || INCOMPLETE.includes(status) ? (React.createElement("div", { className: "item" },
                    React.createElement("span", { className: "item--item" }, capitalize(status)))) : (React.createElement("div", { className: isPastDue ? 'item--red' : 'item' },
                    getFormattedDate(workOrder.serviceDate),
                    " kl.",
                    workOrder.timeSlot)))) : (React.createElement("div", { className: "item" },
                    React.createElement("span", null, Status.TRAVELING.includes(workOrder.status)
                        ? NOB.WORKORDER_LIST.DRIVESTARTED
                        : NOB.WORKORDER_LIST.WORKSTARTED)))),
            !done && !inWorkLog && (React.createElement("div", null, !(Status.ON_SITE.includes(workOrder.status) ||
                Status.TRAVELING.includes(workOrder.status)) ? (React.createElement("span", { className: "workOrder__details__container__items--estimatedTime" }, header())) : (React.createElement("div", { className: "workOrder__details__container--bottom" },
                Status.ON_SITE.includes(workOrder.status) && (React.createElement("div", { className: Status.ON_SITE.includes(workOrder.status)
                        ? 'workOrder__details__container__items--started'
                        : 'workOrder__details__container__items--bottom' },
                    React.createElement(Icon, { name: TOOL_OULINED }),
                    React.createElement("div", null,
                        " ",
                        NOB.WORKORDER_DETAILS.WORKING_TIME,
                        " "),
                    "\u00A0",
                    isFetching && (React.createElement("div", { className: "loader__wrapper" },
                        React.createElement("div", { className: "circle-loader-black" }))),
                    !isFetching && React.createElement("span", null, workTimer))),
                React.createElement("div", { className: Status.TRAVELING.includes(workOrder.status)
                        ? 'workOrder__details__container__items--started'
                        : 'workOrder__details__container__items--bottom' },
                    React.createElement(Icon, { name: CAR }),
                    NOB.WORKORDER_DETAILS.DRIVING_TIME,
                    "\u00A0",
                    isFetching && (React.createElement("div", { className: "loader__wrapper" },
                        React.createElement("div", { className: "circle-loader-black" }))),
                    !isFetching && React.createElement("span", null, driveTimer))))))));
    };
    return WorkOrderDetails;
}(Component));
WorkOrderDetails.propTypes = {
    isFetching: PropTypes.bool,
    done: PropTypes.bool.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    totalTimeEstimate: PropTypes.number.isRequired,
    travelDuration: PropTypes.number,
    workDuration: PropTypes.number,
    inWorkLog: PropTypes.bool.isRequired,
};
WorkOrderDetails.defaultProps = {
    isFetching: false,
    travelDuration: 0,
    workDuration: 0,
};
export default WorkOrderDetails;
