import React from 'react';
import SimpleButton from '../../components/Buttons/SimpleButton/SimpleButton';
import './ErrorPage.scss';
import ErrorImage from './ErrorImage.png';
import PropTypes from 'prop-types';
var TEXT = {
    BACK_TITLE: 'Tilbake',
    CONTENT_TITLE: 'Oi sann... Noe gikk galt',
    GENERIC_ERROR: 'Vi beklager, men tjenesten er for tiden ikke tilgjengelig. Vi jobber hardt for å få den opp og gå igjen.',
};
export var ErrorPage = function (props) {
    var username = localStorage.getItem('username');
    var isoDate = new Date().toISOString();
    return (React.createElement("div", { className: "error-page__container" },
        React.createElement("div", { className: "error-page__content-box" },
            React.createElement("h1", null, TEXT.CONTENT_TITLE),
            React.createElement("img", { src: ErrorImage, alt: "Error", className: "error-page__image" }),
            React.createElement("p", null, TEXT.GENERIC_ERROR),
            React.createElement("p", null,
                React.createElement("b", null, "Timestamp: "),
                " ",
                isoDate),
            username && (React.createElement("p", null,
                React.createElement("b", null, "Username: "),
                username)),
            React.createElement("p", null,
                React.createElement("b", null, "Event ID: "),
                props.eventId),
            React.createElement("p", null,
                React.createElement("b", null, "Message: "),
                props.error.message),
            React.createElement(SimpleButton, { onClick: function () { return window.location.replace(window.location.origin); }, title: TEXT.BACK_TITLE }))));
};
ErrorPage.propTypes = {
    eventId: PropTypes.string.isRequired,
    error: PropTypes.objectOf({
        message: PropTypes.string,
    }),
};
