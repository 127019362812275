import PropTypes from 'prop-types';
import React from 'react';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { INCOMPLETE_WITHOUT_CODE } from '../../../constants/status-codes';
import NOB from '../../../constants/language-strings';
import '../containers/addressHistory.scss';
export default function DropdownContent(_a) {
    var closeDropdown = _a.closeDropdown, spanLightText = _a.spanLightText, _b = _a.singleAddressHistory, actionTaken = _b.actionTaken, associateNames = _b.associateNames, customerId = _b.customerId, orderedItems = _b.orderedItems, status = _b.status, workDescription = _b.workDescription, workOrderId = _b.workOrderId;
    var incomplete = status.includes(INCOMPLETE_WITHOUT_CODE);
    return (React.createElement(SlideDown, { className: incomplete ? 'dropdown__content--red' : 'dropdown__content--blue', transitionOnAppear: false, closed: closeDropdown },
        React.createElement("div", { className: "dropdown__content", key: workOrderId },
            React.createElement("div", { className: "dropdown__content--table" },
                React.createElement("div", null,
                    React.createElement("span", null, NOB.ADDRESS_HISTORY.STATUS),
                    React.createElement("span", { className: "dropdown__content--table--status" }, status)),
                React.createElement("div", null,
                    React.createElement("span", null, NOB.ADDRESS_HISTORY.USER),
                    spanLightText(associateNames)),
                React.createElement("div", null,
                    React.createElement("span", null, NOB.ADDRESS_HISTORY.WORKORDER),
                    spanLightText(workOrderId)),
                React.createElement("div", null,
                    React.createElement("span", null, NOB.ADDRESS_HISTORY.CUSTOMER_ID),
                    spanLightText(customerId))),
            React.createElement("div", { className: "dropdown__content--item--left" },
                React.createElement("span", null, NOB.ADDRESS_HISTORY.WORK_ORDER_DESCRIPTION),
                spanLightText(workDescription)),
            React.createElement("div", { className: "dropdown__content--item--left" },
                React.createElement("span", null, NOB.ADDRESS_HISTORY.SERVICE_CODES),
                React.createElement("div", { className: "dropdown__content--item--left--service_codes" }, orderedItems.itemSummary.map(function (item) { return (React.createElement("span", { key: item.workOrderServiceId }, item.name)); }))),
            actionTaken && (React.createElement("div", { className: "dropdown__content--item--left" },
                React.createElement("span", null, NOB.ADDRESS_HISTORY.COMMENT),
                spanLightText(actionTaken))))));
}
DropdownContent.propTypes = {
    singleAddressHistory: PropTypes.objectOf(PropTypes.any).isRequired,
    closeDropdown: PropTypes.bool.isRequired,
    spanLightText: PropTypes.func.isRequired,
};
