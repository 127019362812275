import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { LOCATION_CHANGE } from 'connected-react-router';
import authenticationReducer from './modules/Authentication/reducers/index';
import healthCheckReducer from './modules/HealthCheck/reducers';
import workOrderListReducer from './modules/WorkorderList/reducers/workOrderList';
import productListReducer from './modules/WorkorderList/reducers/productList';
import changeProductReducer from './modules/CustomerProducts/reducers/changeProductReducer';
import workOrderReducer from './modules/Workorder/reducers/workOrder';
import customerProductsReducer from './modules/CustomerProducts/reducers/customerProducts';
import languageStringsReducer from './languageStringReducer';
import mapIdReducer from './modules/Map/reducers/mapId';
import mapTokenReducer from './modules/Map/reducers/mapTokenReducer';
import findServicesReducer from './modules/WorkLog/reducers/findServicesReducer';
import completeStatusReducer from './modules/WorkLog/reducers/completeStatusReducer';
import zendeskReducer from './modules/WorkLog/reducers/zendeskReducer';
import addressHistoryReducer from './modules/AddressHistory/reducers/addressHistoryReducer';
import addProductReducer from './modules/CustomerProducts/reducers/addProductReducer';
import devicePollingMacReducer from './modules/HealthCheck/reducers/devicePollingMacReducer';
import devicePollingCustomerReducer from './modules/HealthCheck/reducers/devicePollingReducer';
import updateDevicePollingMacReducer from './modules/HealthCheck/reducers/updateDevicePollingMacReducer';
import agreementsReducer from './modules/CustomerProducts/reducers/agreementsReducer';
import removeProductReducer from './modules/CustomerProducts/reducers/removeProductReducer';
import popupReducer from './components/Popup/reducers/popupReducer';
import dayStatusReducer from './modules/Authentication/reducers/dayStatusReducer';
import serviceTypesReducer from './modules/CreateNewWorkOrder/reducers/serviceTypesReducer';
import linkProductReducer from './modules/CustomerProducts/reducers/linkProductReducer';
import workOrderServicesReducer from './modules/WorkLog/reducers/workOrderServicesReducer';
import followUpFormsReducer from './modules/FollowUpForms/reducers/followUpReducer';
import prevWorkOrderList from './modules/WorkorderList/reducers/prevWorkOrderList';
import workOrderHistoryReducer from './modules/WorkOrderHistory/reducers/workOrderHistoryReducer';
var routeInitialState = {
    location: null,
};
/**
 * Merge route into the global application state
 */
function routeReducer(state, action) {
    if (state === void 0) { state = routeInitialState; }
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { location: action.payload });
        default:
            return state;
    }
}
var appReducer = combineReducers({
    authentication: authenticationReducer,
    router: routeReducer,
    workOrders: workOrderListReducer,
    previousWorkOrders: prevWorkOrderList,
    productList: productListReducer,
    workOrder: workOrderReducer,
    customerProducts: customerProductsReducer,
    changeProduct: changeProductReducer,
    NOB: languageStringsReducer,
    form: formReducer,
    map: mapIdReducer,
    mapToken: mapTokenReducer,
    healthCheck: healthCheckReducer,
    findServices: findServicesReducer,
    completeStatus: completeStatusReducer,
    zendesk: zendeskReducer,
    addressHistory: addressHistoryReducer,
    addProduct: addProductReducer,
    devicePollingMac: devicePollingMacReducer,
    devicePollingCustomer: devicePollingCustomerReducer,
    agreements: agreementsReducer,
    removeProduct: removeProductReducer,
    updateDevicePollingMac: updateDevicePollingMacReducer,
    popup: popupReducer,
    dayStatus: dayStatusReducer,
    serviceTypes: serviceTypesReducer,
    linkProduct: linkProductReducer,
    workOrderServices: workOrderServicesReducer,
    followUpForms: followUpFormsReducer,
    workOrderHistory: workOrderHistoryReducer,
});
var rootReducer = function (state, action) {
    if (action.type === 'USER_LOGOUT') {
        state = undefined; // eslint-disable-line no-param-reassign
    }
    return appReducer(state, action);
};
export default rootReducer;
