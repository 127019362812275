import { CATEGORY_MAIN, CATEGORY_SUB } from '../modules/AddServiceCode/addServiceCodeConstants';
import { MAIN, SUB } from '../constants/elements';
import setCategoriesValues from './setCategoriesValues';
export default function setCategoriesDropdown(category, allCategories, main, sub) {
    var values = [];
    switch (category) {
        case CATEGORY_MAIN: {
            // Categories containing a sub category
            var mainCategoriesMultiple = allCategories.filter(function (singleCategory) { return singleCategory.sub; });
            // Categories not containing a sub category
            var mainCategoriesSingle = allCategories.filter(function (singleCategory) { return !singleCategory.sub; });
            values = setCategoriesValues(mainCategoriesMultiple, mainCategoriesSingle, MAIN);
            return values;
        }
        case CATEGORY_SUB: {
            // Categories containing the selected main category and a sub category
            var subCategories = allCategories.filter(function (singleCategory) { return singleCategory.main === main && singleCategory.sub; });
            // Filtered categories containing a main error
            var subCategoriesMultiple = subCategories.filter(function (singleCategory) { return singleCategory.error; });
            // Filtered categories not containing a main error
            var subCategoriesSingle = subCategories.filter(function (singleCategory) { return !singleCategory.error; });
            values = setCategoriesValues(subCategoriesMultiple, subCategoriesSingle, SUB);
            return values;
        }
        default: {
            // Categories containing the selected main category and a sub category
            var errorCategories = allCategories.filter(function (singleCategory) {
                return singleCategory.main === main && singleCategory.sub === sub && singleCategory.error;
            });
            values = errorCategories.map(function (service) { return ({
                name: service.error,
                value: service.id,
            }); });
            return values;
        }
    }
}
