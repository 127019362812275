import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import './orderedProducts.scss';
import { hours, minutes } from '../../../../utils/timeCalculator';
var OrderedProducts = function (_a) {
    var done = _a.done, orderedItems = _a.orderedItems, technician = _a.technician;
    var showDiagnosis = technician && !done;
    var itemSummary = showDiagnosis
        ? orderedItems.itemSummary.filter(function (item) {
            return item.name.split(' > ').includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS);
        })
        : orderedItems.itemSummary.filter(function (item) { return !item.name.includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS); });
    var removeDiagnosis = function (diagnosisItemName) {
        return diagnosisItemName.replace("".concat(NOB.ORDERED_PRODUCTS.DIAGNOSIS, " >"), '');
    };
    var productsTable = itemSummary.map(function (product) { return (React.createElement("div", { className: "ordered__products--items", key: product.workOrderServiceId },
        React.createElement("div", null, product.quantity),
        React.createElement("div", null, showDiagnosis ? removeDiagnosis(product.name) : product.name),
        React.createElement("div", null, "".concat(hours(product.timeEstimate), " ").concat(minutes(product.timeEstimate))))); });
    return (itemSummary.length > 0 && (React.createElement("div", { className: "ordered__products" },
        React.createElement("div", { className: "ordered__products--headings" },
            React.createElement("div", null, NOB.ORDERED_PRODUCTS.QUANTITY),
            done || !technician
                ? NOB.ORDERED_PRODUCTS.SERVICE_CODES
                : NOB.ORDERED_PRODUCTS.DIAGNOSIS,
            React.createElement("div", null, NOB.ORDERED_PRODUCTS.TIME)),
        React.createElement("div", { className: "ordered__products--productlist" }, productsTable))));
};
OrderedProducts.propTypes = {
    done: PropTypes.bool.isRequired,
    orderedItems: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
    technician: PropTypes.bool.isRequired,
};
export default OrderedProducts;
