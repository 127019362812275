var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import SimpleDropdown from '../../../components/Drowpdowns/SimpleDropdown';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import { useQuery } from 'react-query';
import { http } from '../../../api/apiConfig';
import { GET_DEVICES_FOR_PROVISIONING, SEND_PROVISIONING_COMMAND, } from '../../../constants/endpoints';
import './HitDecoder.scss';
import Popup from '../../../components/Popup/containers/Popup';
function HitDecoderContainer(_a) {
    var _this = this;
    var customerId = _a.customerId, isVisible = _a.isVisible, onClose = _a.onClose;
    var dpads = useQuery({
        queryKey: ['dpads', customerId],
        queryFn: function () { return __awaiter(_this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, http.get(GET_DEVICES_FOR_PROVISIONING(customerId))];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); },
        enabled: true,
    }).data;
    var provisioningCommands = [
        { value: 'RESET_PIN', name: 'Nullstill PIN' },
        { value: 'REAUTH_SOFTWARE_ON_DEVICE', name: 'Oppdater programvare' },
        { value: 'REFRESH_DEVICE', name: 'Refresh device' },
        { value: 'RESEND_ALL_PACKETS', name: 'Resend All Packets' },
    ];
    var _b = __read(useState(null), 2), selectedDevice = _b[0], setSelectedDevice = _b[1];
    var _c = __read(useState(null), 2), selectedCommand = _c[0], setSelectedCommand = _c[1];
    var _d = __read(useState(false), 2), showSuccessModal = _d[0], setShowSuccessModal = _d[1];
    var _e = __read(useState(false), 2), showErrorModal = _e[0], setShowErrorModal = _e[1];
    var _f = __read(useState(''), 2), errorModalMessage = _f[0], setErrorModalMessage = _f[1];
    var handleFirstDropdownChange = function (selectedValue) {
        setSelectedDevice(selectedValue);
    };
    var handleSecondDropdownChange = function (selectedValue) {
        setSelectedCommand(selectedValue);
    };
    var handleButtonClick = function () {
        if (selectedDevice && selectedCommand) {
            var requestBody = {
                command: selectedCommand.value,
            };
            http
                .post(SEND_PROVISIONING_COMMAND(selectedDevice.value), requestBody)
                .then(function () {
                setShowSuccessModal(true);
            })
                .catch(function (error) {
                var _a, _b;
                var errorMessage = ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Failed to send provisioning command';
                setShowErrorModal(true);
                setErrorModalMessage(errorMessage);
            });
        }
    };
    return (React.createElement(SlideInModal, { display: isVisible, onClose: onClose, mainHeading: "Reprovisjoner boks", fetchingSpinnerColor: "black" },
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("label", { className: "dropdown-label" }, "Boks"),
            dpads ? (React.createElement(SimpleDropdown, { values: dpads.map(function (device) { return ({
                    value: device.deviceId,
                    name: device.modelName,
                }); }), onChange: handleFirstDropdownChange, title: selectedDevice ? selectedDevice.name : 'Select Boks' })) : (React.createElement("div", { className: "fetchInfo" }, "Loading..."))),
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("label", { className: "dropdown-label" }, "Kommando"),
            React.createElement(SimpleDropdown, { values: provisioningCommands, onChange: handleSecondDropdownChange, title: selectedCommand ? selectedCommand.name : 'Select kommando' })),
        React.createElement("div", { className: "button-container" },
            React.createElement(SimpleButton, { color: Color.BLACK, title: "Gjennomf\u00F8r", onClick: function () {
                    handleButtonClick();
                } })),
        showSuccessModal && (React.createElement(Popup, { firstLine: "", secondLine: 'Successfully send command ' +
                selectedCommand.name +
                ' to device ' +
                selectedDevice.name, closePopup: function () { return setShowSuccessModal(false); }, button: React.createElement("div", { className: "popup__remove-buttons" },
                React.createElement("button", { onClick: function () { return setShowSuccessModal(false); } }, "OK")) })),
        showErrorModal && (React.createElement(Popup, { firstLine: "Error", secondLine: errorModalMessage, closePopup: function () { return setShowErrorModal(false); }, button: React.createElement("div", { className: "popup__remove-buttons" },
                React.createElement("button", { onClick: function () { return setShowErrorModal(false); } }, "OK")) }))));
}
HitDecoderContainer.propTypes = {
    customerId: PropTypes.number,
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
var mapStateToProps = function () { return ({}); };
var mapDispatchToProps = function () { return ({}); };
export default connect(mapStateToProps, mapDispatchToProps)(HitDecoderContainer);
