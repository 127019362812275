var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { COMPLETE_STATUS_CHANGE_SUCCESS } from '../workLogConstants';
var updateStatusReducerDefaultState = {
    workOrder: {
        orderedItems: {
            itemSummary: [],
            totalTimeEstimate: 0,
        },
        customer: {
            customerId: 0,
            firstName: '',
            phoneNumber: '',
            surname: '',
        },
        address: {},
        status: '',
        workDescription: '',
        technicalDetails: {},
        capacity: {
            data: [],
        },
    },
    isFetching: false,
    statusChanged: false,
    errorMessage: '',
    status: '',
    checked: '',
};
export default (function (state, _a) {
    if (state === void 0) { state = updateStatusReducerDefaultState; }
    var status = _a.status, type = _a.type, workOrder = _a.workOrder;
    switch (type) {
        case COMPLETE_STATUS_CHANGE_SUCCESS:
            return __assign(__assign({}, state), { workOrder: workOrder, isFetching: false, statusChanged: true, status: status });
        default:
            return state;
    }
});
