import { NEW, SOLVED } from '../modules/WorkLog/workLogConstants';
export default function zendeskBody(workOrder, customerProducts, zendesk, status) {
    var workOrderId = workOrder.workOrderId, _a = workOrder.address, city = _a.city, flatOrApartment = _a.flatOrApartment, houseNumberAlpha = _a.houseNumberAlpha, houseNumberNumeric = _a.houseNumberNumeric, postalCode = _a.postalCode, street = _a.street, _b = workOrder.customer, customerId = _b.customerId, email = _b.email, firstName = _b.firstName, phoneNumber = _b.phoneNumber, surname = _b.surname, _c = workOrder.technicalDetails, Hub = _c.Hub, Node = _c.Node;
    var commentBody = zendesk.commentBody, files = zendesk.files, followUp = zendesk.followUp;
    var fileList = [];
    if (files) {
        files.forEach(function (value) {
            fileList.push({
                fileName: value.fileName,
                base64String: value.file.split('base64,').pop(),
            });
        });
    }
    var zendeskStatus = followUp || !status ? NEW : SOLVED;
    var createdCommentBody = zendesk.followUpFormValues
        ? "\n    \n\n    ".concat(Object.keys(zendesk.followUpFormValues).map(function (key) {
            var value;
            if (zendesk.followUpFormValues[key] === 'false')
                value = 'Ja';
            else if (zendesk.followUpFormValues[key] === 'true')
                value = 'Nei';
            else
                value = zendesk.followUpFormValues[key];
            return "\n **".concat(key, "** \n ").concat(value);
        }), "\n  ")
        : commentBody;
    var body = {
        workOrderId: workOrderId,
        commentBody: createdCommentBody,
        firstName: firstName,
        surName: surname,
        phoneNumber: phoneNumber,
        email: email,
        customerId: customerId,
        streetName: street,
        houseNumberNumeric: houseNumberNumeric,
        houseNumberAlpha: houseNumberAlpha,
        flatOrApartment: flatOrApartment,
        postalCode: postalCode,
        city: city,
        hub: Hub,
        node: Node,
        status: zendeskStatus,
        zendeskFileList: fileList,
        followUp: followUp,
        complete: status,
        customerProducts: customerProducts,
    };
    return body;
}
