var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { ACTION_TAKEN, POST_ZENDESK, STATUS } from '../../../../constants/endpoints';
import { postAPI, putAPI } from '../../../../api/apiConfig';
import { COMPLETE_WORK_ORDER_WITH_ZENDESK } from '../../workLogConstants';
import * as completeAction from '../../actions/completeStatusActions';
import { clearSavedFiles } from '../../actions/zendeskActions';
import { FRONT_PAGE_PATH } from '../../../../constants/paths';
import { updateDiagnosticServices, selectServiceType, } from '../../../CreateNewWorkOrder/actions/serviceTypesActions';
import { DEFAULT_SERVICE_TYPE } from '../../../CreateNewWorkOrder/createNewWorkOrderConstants';
export function callCompleteStatus(_a) {
    var actionTakenURL, updateStatusURL, requestBody, error_1;
    var body = _a.body, status = _a.status, azimuth = _a.azimuth, workOrderId = _a.workOrderId;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 9, , 11]);
                actionTakenURL = ACTION_TAKEN(workOrderId);
                updateStatusURL = STATUS(workOrderId);
                return [4 /*yield*/, call(postAPI, POST_ZENDESK, body)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(putAPI, actionTakenURL, { body: body.commentBody })];
            case 2:
                _b.sent();
                requestBody = { status: status, azimuthValue: azimuth };
                return [4 /*yield*/, call(putAPI, updateStatusURL, requestBody)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(completeAction.receiveCompleteStatusChange())];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(clearSavedFiles())];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(updateDiagnosticServices({ selectedDiagnosticServices: [] }))];
            case 6:
                _b.sent();
                return [4 /*yield*/, put(selectServiceType({ serviceType: DEFAULT_SERVICE_TYPE }))];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(push(FRONT_PAGE_PATH))];
            case 8:
                _b.sent();
                return [3 /*break*/, 11];
            case 9:
                error_1 = _b.sent();
                return [4 /*yield*/, put(completeAction.updateActionTakenFailure({
                        errorMessage: "Could not update action taken. ".concat(error_1 === null || error_1 === void 0 ? void 0 : error_1.message),
                    }))];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
export default [takeEvery(COMPLETE_WORK_ORDER_WITH_ZENDESK, callCompleteStatus)];
