import { MAP_ID_FAILURE, MAP_ID_REQUEST, MAP_ID_SUCCESS, MAP_TOKEN_FAILURE, MAP_TOKEN_REQUEST, MAP_TOKEN_SUCCESS, } from '../constants';
export var requestMapId = function () { return ({
    type: MAP_ID_REQUEST,
}); };
export var receiveMapId = function (_a) {
    var mapId = _a.mapId;
    return ({
        type: MAP_ID_SUCCESS,
        mapId: mapId,
    });
};
export var errorMapId = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: MAP_ID_FAILURE,
        errorMessage: errorMessage,
    });
};
export var requestMapToken = function () { return ({
    type: MAP_TOKEN_REQUEST,
}); };
export var receiveMapToken = function (_a) {
    var mapToken = _a.mapToken;
    return ({
        type: MAP_TOKEN_SUCCESS,
        mapToken: mapToken,
    });
};
export var errorMapToken = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: MAP_TOKEN_FAILURE,
        errorMessage: errorMessage,
    });
};
