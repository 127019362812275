// Dropdown
export var EXPANDING = 'EXPANDING';
export var EXPANDED = 'EXPANDED';
// End day
export var END_DAY = 'END_DAY';
// Installation serviceTypeCodes
export var INSTALLATION_SERVICE_TYPE_CODES = ['I', '0', 'F', '9', 'A', 'P'];
// Product list
export var PRODUCT_LIST_FAILURE = 'PRODUCT_LIST_FAILURE';
export var PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export var PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
// Work order list
export var EMPTY_WORKORDER_LIST = 'EMPTY_WORKORDER_LIST';
export var WORKORDER_LIST_FAILURE = 'WORKORDER_LIST_FAILURE';
export var WORKORDER_LIST_REQUEST = 'WORKORDER_LIST_REQUEST';
export var WORKORDER_LIST_SUCCESS = 'WORKORDER_LIST_SUCCESS';
export var WORKORDER_STATUS_UPDATE = 'WORKORDER_STATUS_UPDATE';
export var WORKORDER_LIST_CANCEL = 'WORKORDER_LIST_CANCEL';
// Previous work orders
export var PREVIOUS_WORKORDER_LIST_FAILURE = 'PREVIOUS_WORKORDER_LIST_FAILURE';
export var PREVIOUS_WORKORDER_LIST_REQUEST = 'PREVIOUS_WORKORDER_LIST_REQUEST';
export var PREVIOUS_WORKORDER_LIST_SUCCESS = 'PREVIOUS_WORKORDER_LIST_SUCCESS';
