import { PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAILURE, } from '../workOrderListConstants';
export var requestProductList = function () { return ({
    type: PRODUCT_LIST_REQUEST,
}); };
export var receiveProductList = function (productList) { return ({
    type: PRODUCT_LIST_SUCCESS,
    productList: productList,
}); };
export var errorProductList = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: PRODUCT_LIST_FAILURE,
        errorMessage: errorMessage,
    });
};
