var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DEVICE_POLLING_CUSTOMER_ERROR, DEVICE_POLLING_CUSTOMER_RECEIVE, DEVICE_POLLING_CUSTOMER_REQUEST, } from '../constants';
var devicePollingDefaultState = {
    isFetching: true,
    devicePollingCustomer: {
        checkPassed: false,
        platform: '',
        deviceStatus: [],
    },
    errorMessage: '',
};
export default (function (state, _a) {
    if (state === void 0) { state = devicePollingDefaultState; }
    var devicePollingCustomer = _a.devicePollingCustomer, errorMessage = _a.errorMessage, type = _a.type;
    switch (type) {
        case DEVICE_POLLING_CUSTOMER_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case DEVICE_POLLING_CUSTOMER_RECEIVE:
            return __assign(__assign({}, state), { devicePollingCustomer: devicePollingCustomer, isFetching: false });
        case DEVICE_POLLING_CUSTOMER_ERROR:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        default:
            return state;
    }
});
