import { SIGN_IN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT_REQUEST, SIGN_OUT_SUCCESS, START_DAY_FAILURE, END_DAY_REQUEST, END_DAY_FAILURE, DAY_STATUS_SUCCESS, DEV_SIGN_IN_REQUEST, } from '../constants';
export var requestSignIn = function (_a) {
    var credentials = _a.credentials;
    return ({
        type: SIGN_IN_REQUEST,
        credentials: credentials,
    });
};
export var requestDevSignIn = function (_a) {
    var credentials = _a.credentials;
    return ({
        type: DEV_SIGN_IN_REQUEST,
        credentials: credentials,
    });
};
export var receiveSignIn = function (_a) {
    var token = _a.token;
    return ({
        type: SIGN_IN_SUCCESS,
        token: token,
    });
};
export var errorSignIn = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: SIGN_IN_FAILURE,
        errorMessage: errorMessage,
    });
};
export var requestLogout = function () { return ({
    type: SIGN_OUT_REQUEST,
}); };
export var receiveLogout = function () { return ({
    type: SIGN_OUT_SUCCESS,
}); };
export var errorStartDay = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: START_DAY_FAILURE,
        errorMessage: errorMessage,
    });
};
export var requestEndDay = function () { return ({
    type: END_DAY_REQUEST,
}); };
export var errorEndDay = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: END_DAY_FAILURE,
        errorMessage: errorMessage,
    });
};
export var errorEndStartDay = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: END_DAY_FAILURE,
        errorMessage: errorMessage,
    });
};
export var receiveDayStatus = function (_a) {
    var dayActive = _a.dayActive;
    return ({
        type: DAY_STATUS_SUCCESS,
        dayActive: dayActive,
    });
};
