import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';
import { CROSS_LARGE, ARROW_DOWN } from '../../constants/iconNames';
import Icon from '../../icons';
import Variables from '../../styles/variables.scss';
import './imageViewer.scss';
export default function ExtendedImage(_a) {
    var expandedIndex = _a.expandedIndex, imagesFiles = _a.imagesFiles, toggleExpandImage = _a.toggleExpandImage;
    var icon = function (className) { return (React.createElement("span", { className: className },
        React.createElement(Icon, { name: ARROW_DOWN, stroke: Variables.whiteSecondary, width: "30px", height: "30px" }))); };
    var sortedImagesFiles = function () {
        if (!expandedIndex) {
            return imagesFiles;
        }
        var startValues = imagesFiles.slice(expandedIndex);
        var endValues = imagesFiles.slice(0, expandedIndex);
        return startValues.concat(endValues);
    };
    return (React.createElement("div", { className: "image_file__expanded" },
        React.createElement("button", { onClick: toggleExpandImage },
            React.createElement(Icon, { fill: Variables.whiteSecondary, name: CROSS_LARGE })),
        React.createElement(Swiper, { swiperOptions: {
                slidesPerView: 'auto',
                loop: sortedImagesFiles().length > 1,
                pagination: false,
            }, nextButton: function () { return icon('navigation-arrow--next'); }, prevButton: function () { return icon('navigation-arrow--prev'); } }, sortedImagesFiles().map(function (item, index) { return (React.createElement(Slide, { id: index, key: item },
            React.createElement("img", { alt: "added item", src: item.file, key: item.fileName }))); }))));
}
ExtendedImage.propTypes = {
    expandedIndex: PropTypes.number.isRequired,
    imagesFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
    toggleExpandImage: PropTypes.func.isRequired,
};
