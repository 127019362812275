var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from 'react';
var useForm = function (callback) {
    var _a = __read(useState({}), 2), values = _a[0], setValues = _a[1];
    var handleSubmit = function (event) {
        if (event)
            event.preventDefault();
        callback();
    };
    var handleChange = function (event) {
        var _a, _b;
        event.persist();
        if (event.target.type === 'checkbox')
            setValues(__assign(__assign({}, values), (_a = {}, _a[event.target.name] = event.target.checked, _a)));
        else if (!event.target.value) {
            delete values[event.target.name];
            setValues(__assign({}, values));
        }
        else
            setValues(__assign(__assign({}, values), (_b = {}, _b[event.target.name] = event.target.value, _b)));
    };
    var overrideValue = function (name, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[name] = value, _a)));
    };
    var overrideValues = function () {
        var namesWithValue = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            namesWithValue[_i] = arguments[_i];
        }
        var overridddenValues = __assign({}, values);
        namesWithValue.forEach(function (nameAndValue) {
            overridddenValues[nameAndValue.name] = nameAndValue.value;
        });
        setValues(overridddenValues);
    };
    return {
        handleChange: handleChange,
        handleSubmit: handleSubmit,
        values: values,
        overrideValue: overrideValue,
        overrideValues: overrideValues,
    };
};
export default useForm;
