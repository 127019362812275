var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { all } from 'redux-saga/effects';
import signInSaga from '../modules/Authentication/sagas/signInSaga';
import devSignInSaga from '../modules/Authentication/sagas/devSignInSaga';
import verifyTokenSaga from '../modules/Authentication/sagas/verifyTokenSaga';
import workOrders from '../modules/WorkorderList/sagas/workOrderList';
import productList from '../modules/WorkorderList/sagas/productList';
import workOrder from '../modules/Workorder/sagas/workOrder/workOrder';
import customerProducts from '../modules/CustomerProducts/sagas/customerProducts';
import changeProduct from '../modules/CustomerProducts/sagas/changeProductSaga';
import checkForStatusSkip from '../modules/Workorder/sagas/statusChange/checkForStatusSkip';
import mapIdSaga from '../modules/Map/sagas/mapIdSaga';
import mapTokenSaga from '../modules/Map/sagas/mapTokenSaga';
import getHealthCheckSaga from '../modules/HealthCheck/sagas/getHealthCheck';
import updateHealthCheckSaga from '../modules/HealthCheck/sagas/updateHealthCheck';
import removeServiceSaga from '../modules/WorkLog/sagas/services/removeServiceSaga';
import updateServiceSaga from '../modules/WorkLog/sagas/services/updateServiceSaga';
import findServicesSaga from '../modules/WorkLog/sagas/services/findServicesSaga';
import zendeskSaga from '../modules/WorkLog/sagas/complete/zendeskSaga';
import completeStatusSaga from '../modules/WorkLog/sagas/complete/completeStatusSaga';
import addressHistorySaga from '../modules/AddressHistory/sagas/addressHistorySaga';
import getProductsSaga from '../modules/CustomerProducts/sagas/getProductsSaga';
import addProductSaga from '../modules/CustomerProducts/sagas/addProductSaga';
import devicePollingMacSaga from '../modules/HealthCheck/sagas/devicePollingMacSaga';
import devicePollingCustomer from '../modules/HealthCheck/sagas/getDevicePollingCustomer';
import iccCheckSaga from '../modules/HealthCheck/sagas/getIccCheck';
import agreementsSaga from '../modules/CustomerProducts/sagas/agreementsSaga';
import removeProductSaga from '../modules/CustomerProducts/sagas/removeProductSaga';
import updateDevicePollingMacSaga from '../modules/HealthCheck/sagas/updateDevicePollingMacSaga';
import changeStatusSaga from '../rootSagas/changeStatusSaga';
import startDaySaga from '../modules/Authentication/sagas/startDaySaga';
import endDaySaga from '../modules/Authentication/sagas/endDaySaga';
import agreementPriceSaga from '../modules/CustomerProducts/sagas/agreementPriceSaga';
import findServiceTypesSaga from '../modules/CreateNewWorkOrder/sagas/findServiceTypesSaga';
import linkProductSaga from '../modules/CustomerProducts/sagas/linkProductSaga';
import workorderServicesSaga from '../modules/WorkLog/sagas/services/workorderServices';
import followUpFormsSaga from '../modules/FollowUpForms/sagas/followUpFormsSaga';
import workOrderHistorySaga from '../modules/WorkOrderHistory/sagas/workOrderHistorySaga';
export default function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(signInSaga), false), __read(devSignInSaga), false), __read(verifyTokenSaga), false), __read(workOrders), false), __read(customerProducts), false), __read(mapIdSaga), false), __read(mapTokenSaga), false), __read(workOrder), false), __read(productList), false), __read(getHealthCheckSaga), false), __read(updateHealthCheckSaga), false), __read(changeStatusSaga), false), __read(checkForStatusSkip), false), __read(changeProduct), false), __read(removeServiceSaga), false), __read(updateServiceSaga), false), __read(findServicesSaga), false), __read(zendeskSaga), false), __read(completeStatusSaga), false), __read(addressHistorySaga), false), __read(getProductsSaga), false), __read(addProductSaga), false), __read(devicePollingMacSaga), false), __read(iccCheckSaga), false), __read(devicePollingCustomer), false), __read(agreementsSaga), false), __read(agreementPriceSaga), false), __read(removeProductSaga), false), __read(updateDevicePollingMacSaga), false), __read(startDaySaga), false), __read(endDaySaga), false), __read(findServiceTypesSaga), false), __read(linkProductSaga), false), __read(workorderServicesSaga), false), __read(followUpFormsSaga), false), __read(workOrderHistorySaga), false))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
