import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var fill = _a.fill;
    return (React.createElement("svg", { width: "39px", height: "30px", viewBox: "0 0 39 30", version: "1.1" },
        React.createElement("title", null, "icon/Kamera_bl\u00E5"),
        React.createElement("desc", null, "Created with Sketch."),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "icon/Kamera_bl\u00E5" },
                React.createElement("g", { id: "Kamera_bl\u00E5-Copy" },
                    React.createElement("g", { id: "Combined-Shape" },
                        React.createElement("g", { id: "camera1", fill: fill },
                            React.createElement("path", { d: "M0.866666667,26.0726329 L0.866666667,7.98242214 C0.866666667,6.32556789 2.20981242,4.98242214 3.86666667,4.98242214 L6.970933,4.98242214 C7.47213766,4.98242214 7.95507395,4.7942369 8.32413575,4.45512113 L11.092108,1.91174487 C11.8302316,1.23351334 12.7961042,0.857142857 13.7985135,0.857142857 L24.5491576,0.857142857 C25.4903831,0.857142857 26.4014622,1.18905437 27.122124,1.79448983 L30.3589118,4.51374865 C30.7192427,4.81646638 31.1747822,4.98242214 31.645395,4.98242214 L34.8908035,4.98242214 C36.5476577,4.98242214 37.8908035,6.32556789 37.8908035,7.98242214 L37.8908035,26.0726329 C37.8908035,27.7294872 36.5476577,29.0726329 34.8908035,29.0726329 L3.86666667,29.0726329 C2.20981242,29.0726329 0.866666667,27.7294872 0.866666667,26.0726329 Z M19.0666667,24 C23.3744877,24 26.8666667,20.5461966 26.8666667,16.2857143 C26.8666667,12.0252319 23.3744877,8.57142857 19.0666667,8.57142857 C14.7588456,8.57142857 11.2666667,12.0252319 11.2666667,16.2857143 C11.2666667,20.5461966 14.7588456,24 19.0666667,24 Z" })),
                        React.createElement("g", { id: "cameraFilter", fill: "black" },
                            React.createElement("filter", { x: "-6.8%", y: "-8.9%", width: "113.5%", height: "117.7%", filterUnits: "objectBoundingBox", id: "filter-2" },
                                React.createElement("feGaussianBlur", { stdDeviation: "1.5", in: "SourceAlpha", result: "shadowBlurInner1" }),
                                React.createElement("feOffset", { dx: "0", dy: "1", in: "shadowBlurInner1", result: "shadowOffsetInner1" }),
                                React.createElement("feComposite", { in: "shadowOffsetInner1", in2: "SourceAlpha", operator: "arithmetic", k2: "-1", k3: "1", result: "shadowInnerInner1" }),
                                React.createElement("feColorMatrix", { values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0", type: "matrix", in: "shadowInnerInner1" }))),
                        React.createElement("g", { stroke: "#FFFFFF", strokeWidth: "1" },
                            React.createElement("path", { d: "M0.866666667,26.0726329 L0.866666667,7.98242214 C0.866666667,6.32556789 2.20981242,4.98242214 3.86666667,4.98242214 L6.970933,4.98242214 C7.47213766,4.98242214 7.95507395,4.7942369 8.32413575,4.45512113 L11.092108,1.91174487 C11.8302316,1.23351334 12.7961042,0.857142857 13.7985135,0.857142857 L24.5491576,0.857142857 C25.4903831,0.857142857 26.4014622,1.18905437 27.122124,1.79448983 L30.3589118,4.51374865 C30.7192427,4.81646638 31.1747822,4.98242214 31.645395,4.98242214 L34.8908035,4.98242214 C36.5476577,4.98242214 37.8908035,6.32556789 37.8908035,7.98242214 L37.8908035,26.0726329 C37.8908035,27.7294872 36.5476577,29.0726329 34.8908035,29.0726329 L3.86666667,29.0726329 C2.20981242,29.0726329 0.866666667,27.7294872 0.866666667,26.0726329 Z M19.0666667,24 C23.3744877,24 26.8666667,20.5461966 26.8666667,16.2857143 C26.8666667,12.0252319 23.3744877,8.57142857 19.0666667,8.57142857 C14.7588456,8.57142857 11.2666667,12.0252319 11.2666667,16.2857143 C11.2666667,20.5461966 14.7588456,24 19.0666667,24 Z" })))),
                React.createElement("ellipse", { id: "Oval-3", stroke: fill, fill: fill, fillRule: "nonzero", cx: "19.0666667", cy: "16.2857143", rx: "6.06666667", ry: "6" })))));
};
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.iconBlue,
};
export default SVG;
