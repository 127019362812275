var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from 'redux-saga/effects';
import { errorWorkOrder, receiveWorkOrder, checkForStatusSkip } from '../../actions/workOrder';
import { getAPI } from '../../../../api/apiConfig';
import { requestAgreements } from '../../../CustomerProducts/actions/agreementsActions';
import { requestCustomerProducts } from '../../../CustomerProducts/actions/customerProducts';
import { WORKORDER, WORKORDER_BY_CUSTOMER_ID } from '../../../../constants/endpoints';
import { WORKORDER_REQUEST, WORKORDER_REQUEST_BY_CUSTOMER_ID } from '../../workOrderConstants';
import { requestAddressHistoryByAddressId } from '../../../AddressHistory/actions/addressHistoryActions';
export function callWorkorder(request) {
    var url, result, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                url = WORKORDER({ workOrderId: request.workOrderId });
                return [4 /*yield*/, call(getAPI, url)];
            case 1:
                result = _a.sent();
                return [4 /*yield*/, put(receiveWorkOrder({ workOrder: result }))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(checkForStatusSkip(result.status))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(requestCustomerProducts({ customerId: result.customer.customerId }))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(requestAgreements({ customerId: result.customer.customerId }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                error_1 = _a.sent();
                return [4 /*yield*/, put(errorWorkOrder({
                        errorMessage: "Could not receive work order. ".concat(error_1.message),
                    }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function callCustomerInfo(request) {
    var url, result, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                url = WORKORDER_BY_CUSTOMER_ID(request.customerId);
                return [4 /*yield*/, call(getAPI, url)];
            case 1:
                result = _a.sent();
                return [4 /*yield*/, put(requestAddressHistoryByAddressId(result.address.validAddressId))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(receiveWorkOrder({ workOrder: result }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(requestCustomerProducts({ customerId: result.customer.customerId }))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(requestAgreements({ customerId: result.customer.customerId }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                error_2 = _a.sent();
                return [4 /*yield*/, put(errorWorkOrder({
                        errorMessage: "Could not receive customer info by customerId: ".concat(request.customerId, ". Error: ").concat(error_2.message),
                    }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export default [
    takeEvery(WORKORDER_REQUEST, callWorkorder),
    takeEvery(WORKORDER_REQUEST_BY_CUSTOMER_ID, callCustomerInfo),
];
