import { CHANGE_PRODUCT_REQUEST, CHANGE_PRODUCT_SUCCESS, CHANGE_PRODUCT_FAILURE, CLEAR_ERROR_MESSAGE, } from '../customerProductsConstants';
export var requestChangeProduct = function (productId, dpadId, serialNumber, reload) { return ({
    type: CHANGE_PRODUCT_REQUEST,
    productId: productId,
    dpadId: dpadId,
    serialNumber: serialNumber,
    reload: reload,
}); };
export var receiveChangeProduct = function (_a) {
    var changedProduct = _a.changedProduct, oldSerial = _a.oldSerial, productName = _a.productName;
    return ({
        type: CHANGE_PRODUCT_SUCCESS,
        changedProduct: changedProduct,
        oldSerial: oldSerial,
        productName: productName,
    });
};
export var errorChangeProduct = function (_a) {
    var errorMessage = _a.errorMessage, errorProducts = _a.errorProducts;
    return ({
        type: CHANGE_PRODUCT_FAILURE,
        errorMessage: errorMessage,
        errorProducts: errorProducts,
    });
};
export var clearErrorMessage = function (_a) {
    var errorProducts = _a.errorProducts;
    return ({
        type: CLEAR_ERROR_MESSAGE,
        errorProducts: errorProducts,
    });
};
