import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SEARCH } from '../../constants/iconNames';
import Icon from '../../icons';
import markSearch from '../../utils/markSearch';
import Variables from '../../styles/variables.scss';
import './searchProductBar.scss';
var SearchProductBar = function (_a) {
    var options = _a.options, placeholder = _a.placeholder, onChange = _a.onChange;
    return (React.createElement("div", { className: "search_product_bar-container" },
        React.createElement("span", { className: "search_product_bar-icon" },
            React.createElement(Icon, { color: Variables.blackPrimary, name: SEARCH })),
        React.createElement(SelectSearch, { autofocus: false, className: "search_product_bar", onChange: onChange, options: options, renderOption: markSearch, search: true, placeholder: placeholder })));
};
SearchProductBar.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    placeholder: PropTypes.string.isRequired,
};
export default SearchProductBar;
