import { DEVICE_POLLING_CUSTOMER_ERROR, DEVICE_POLLING_CUSTOMER_RECEIVE, DEVICE_POLLING_CUSTOMER_REQUEST, HEALTH_CHECK_ERROR, HEALTH_CHECK_RECEIVE, HEALTH_CHECK_REQUEST, ICC_CHECK_ERROR, ICC_CHECK_RECEIVE, ICC_CHECK_REQUEST, UPDATE_HEALTH_CHECK_REQUEST, } from '../constants';
export var requestHealthCheck = function (_a) {
    var customerId = _a.customerId;
    return ({
        type: HEALTH_CHECK_REQUEST,
        customerId: customerId,
    });
};
export var receiveHealthCheck = function (_a) {
    var provisioning = _a.provisioning;
    return ({
        type: HEALTH_CHECK_RECEIVE,
        provisioning: provisioning,
    });
};
export var errorHealthCheck = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: HEALTH_CHECK_ERROR,
        errorMessage: errorMessage,
    });
};
export var requestUpdateHealthCheck = function (_a) {
    var customerId = _a.customerId, healthCheckIds = _a.healthCheckIds;
    return ({
        type: UPDATE_HEALTH_CHECK_REQUEST,
        customerId: customerId,
        healthCheckIds: healthCheckIds,
    });
};
export var requestIccCheck = function (customerId) { return ({
    type: ICC_CHECK_REQUEST,
    customerId: customerId,
}); };
export var receiveIccCheck = function (_a) {
    var iccCheck = _a.iccCheck;
    return ({
        type: ICC_CHECK_RECEIVE,
        iccCheck: iccCheck,
    });
};
export var errorIccCheck = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: ICC_CHECK_ERROR,
        errorMessage: errorMessage,
    });
};
export var requestDevicePollingCustomer = function (_a) {
    var customerId = _a.customerId, mainHeading = _a.mainHeading, subHeading = _a.subHeading;
    return ({
        type: DEVICE_POLLING_CUSTOMER_REQUEST,
        customerId: customerId,
        mainHeading: mainHeading,
        subHeading: subHeading,
    });
};
export var receiveDevicePollingCustomer = function (_a) {
    var devicePollingCustomer = _a.devicePollingCustomer;
    return ({
        type: DEVICE_POLLING_CUSTOMER_RECEIVE,
        devicePollingCustomer: devicePollingCustomer,
    });
};
export var errorDevicePollingCustomer = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: DEVICE_POLLING_CUSTOMER_ERROR,
        errorMessage: errorMessage,
    });
};
