var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from '../../../../components/InfoBox/InfoBoxError';
import Feedback from '../../../../feedbacks';
import { FEEDBACK_NOT_FOUND } from '../../../../constants/feedbackNames';
import './deviceStatusList.scss';
import DeviceStatusListItem from './DeviceStatusListItem';
import NOB from '../../../../constants/language-strings';
import ListItem from '../../../../components/ListItem/ListItem';
var DeviceStatus = function (_a) {
    var devices = _a.devices, toggleDisplaySam = _a.toggleDisplaySam, error = _a.error;
    if (error || devices.length === 0) {
        return (React.createElement(InfoBox, { feedback: React.createElement(Feedback, { name: FEEDBACK_NOT_FOUND }), firstInfoBoxLine: NOB.HEALTH_CHECK.DEVICE_STATUS_ERROR, link: false }));
    }
    return (React.createElement("div", null, devices.map(function (device) { return (React.createElement(ListItem, { onClick: function () { return toggleDisplaySam(device); }, key: device.description, clickable: device.pollable },
        React.createElement(DeviceStatusListItem, __assign({}, device)))); })));
};
DeviceStatus.propTypes = {
    devices: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
    toggleDisplaySam: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
};
export default DeviceStatus;
