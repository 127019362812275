import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { FRONT_PAGE_PATH, SIGN_OUT_PATH, WORK_ORDER_ID_PATH, WORK_LOG_PATH, ADDRESS_SEARCH, CUSTOMER_INFO_PATH, } from '../constants/paths';
import InfoBox from '../components/InfoBox/InfoBox';
import SignOut from '../modules/Authentication/containers/SignOutContainer';
import WorkOrderContainer from '../modules/Workorder/containers/workOrder/WorkOrderContainer';
import WorkOrderListContainer from '../modules/WorkorderList/containers/WorkOrderListContainer';
import WorkLogContainer from '../modules/WorkLog/containers/WorkLogContainer';
import { AddressSearch } from '../features/address-search';
import CustomerPage from '../modules/Workorder/containers/customerPage/CustomerPage';
var Routes = function () { return (React.createElement(Switch, null,
    React.createElement(Route, { exact: true, path: FRONT_PAGE_PATH, component: WorkOrderListContainer }),
    React.createElement(Route, { exact: true, path: WORK_ORDER_ID_PATH, render: function (_a) {
            var match = _a.match;
            return React.createElement(WorkOrderContainer, { workOrderId: +match.params.id });
        } }),
    React.createElement(Route, { exact: true, path: CUSTOMER_INFO_PATH, render: function (_a) {
            var match = _a.match;
            return React.createElement(CustomerPage, { customerId: +match.params.customerId });
        } }),
    React.createElement(Route, { exact: true, path: SIGN_OUT_PATH, component: SignOut }),
    React.createElement(Route, { exact: true, path: WORK_LOG_PATH, component: WorkLogContainer }),
    React.createElement(Route, { exact: true, path: ADDRESS_SEARCH, render: function () { return React.createElement(AddressSearch, null); } }),
    React.createElement(Route, { path: "*", render: function () { return (React.createElement("div", { className: "no__know__route" },
            React.createElement(InfoBox, null))); } }))); };
export default Routes;
