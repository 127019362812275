import React from 'react';
import capitalize from './capitalizeString';
import getFormattedDate from './getFormattedDate';
export default function statusTimeDiv(status, time, bold, date, isPastDue) {
    var formattedDate = getFormattedDate(date);
    var statusTime = (React.createElement("div", { className: "status_time" },
        React.createElement("span", { className: "".concat(bold ? 'status_time--bold-status' : '', " ").concat(isPastDue ? 'warning' : '') },
            React.createElement("span", { className: "date" }, formattedDate),
            React.createElement("span", { className: "status" }, capitalize(status))),
        React.createElement("span", null, time)));
    return statusTime;
}
