var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var ResultListButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: inherit;\n  border: 0;\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  font-size: 16px;\n  line-height: 24px;\n  margin: 0;\n  padding: 2rem 24px;\n\n  &:hover,\n  &:focus {\n    background-color: rgb(251, 240, 255);\n    color: rgb(120, 0, 180);\n  }\n\n  &:active {\n    background-color: rgb(237, 200, 255);\n    color: rgb(120, 0, 180);\n  }\n"], ["\n  background: inherit;\n  border: 0;\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  flex-grow: 1;\n  font-size: 16px;\n  line-height: 24px;\n  margin: 0;\n  padding: 2rem 24px;\n\n  &:hover,\n  &:focus {\n    background-color: rgb(251, 240, 255);\n    color: rgb(120, 0, 180);\n  }\n\n  &:active {\n    background-color: rgb(237, 200, 255);\n    color: rgb(120, 0, 180);\n  }\n"])));
var CustomerInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-grow: 1;\n  text-align: left;\n"], ["\n  flex-grow: 1;\n  text-align: left;\n"])));
var CustomerId = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 0.2rem;\n  font-weight: normal;\n  letter-spacing: 2px;\n"], ["\n  padding-bottom: 0.2rem;\n  font-weight: normal;\n  letter-spacing: 2px;\n"])));
var FullName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.4rem;\n  font-weight: normal;\n"], ["\n  font-size: 1.4rem;\n  font-weight: normal;\n"])));
var Address = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: normal;\n  padding-top: 0.3rem;\n"], ["\n  font-weight: normal;\n  padding-top: 0.3rem;\n"])));
var CustomerStatus = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  height: 100%;\n"])));
var Platforms = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: normal;\n  padding-top: 0.3rem;\n"], ["\n  font-weight: normal;\n  padding-top: 0.3rem;\n"])));
export var SearchResultItem = function (_a) {
    var it = _a.customer, onClick = _a.onClick;
    return (React.createElement(ResultListButton, { type: "button", onClick: function () { return onClick(); } },
        React.createElement(CustomerInfo, null,
            React.createElement(CustomerId, null,
                "#",
                it.customerId),
            React.createElement(FullName, null, "".concat(it.fullName)),
            React.createElement(Address, null, "".concat(it.street, " ").concat(it.houseNumberNumeric).concat(it.houseNumberAlpha, ", ").concat(it.flatOrApartment, ", ").concat(it.postalCode, ", ").concat(it.bigCity)),
            React.createElement(Platforms, null, it.platforms.join(' · '))),
        React.createElement(CustomerStatus, null,
            React.createElement("div", null, "".concat(it.customerStatus)))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
