import React from 'react';
import PropTypes from 'prop-types';
import { LINK, TRASH } from '../../constants/iconNames';
import Icon from '../../icons';
import IconButton from '../Buttons/IconButton/IconButton';
import Variables from '../../styles/variables.scss';
function ProductItemContentActive(_a) {
    var hasLinkOptions = _a.hasLinkOptions, name = _a.name, showDeleteButton = _a.showDeleteButton, removeProduct = _a.removeProduct, leftText = _a.leftText, serialNumbers = _a.serialNumbers;
    return (React.createElement("div", { className: "product_item--active" },
        hasLinkOptions && (React.createElement("div", { className: "product_item-icon" },
            React.createElement(Icon, { name: LINK }))),
        React.createElement("div", { className: "product_item--active-left" },
            React.createElement("span", null, name),
            React.createElement("span", { className: "product_item--active-status" }, leftText)),
        React.createElement("div", { className: "serial-number-list" }, serialNumbers.map(function (serialNumber) { return (React.createElement("span", { key: serialNumber, className: "product_item--active-serial" }, serialNumber)); })),
        React.createElement("div", { className: "product--right" }, showDeleteButton && (React.createElement(IconButton, { icon: TRASH, onClick: removeProduct, fill: Variables.blackPrimary })))));
}
ProductItemContentActive.propTypes = {
    showDeleteButton: PropTypes.bool.isRequired,
    hasLinkOptions: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    removeProduct: PropTypes.func.isRequired,
    leftText: PropTypes.string.isRequired,
    serialNumbers: PropTypes.arrayOf(PropTypes.string),
};
ProductItemContentActive.defaultProps = {
    serialNumbers: [],
};
export default ProductItemContentActive;
