var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PREVIOUS_WORKORDER_LIST_REQUEST, PREVIOUS_WORKORDER_LIST_FAILURE, PREVIOUS_WORKORDER_LIST_SUCCESS, } from '../workOrderListConstants';
var prevWorkOrderListReducerDefaultState = {
    errorMessage: '',
    isFetching: false,
    workOrders: [],
    latestPreviousWorkOrderRequest: {},
};
export default (function (state, _a) {
    if (state === void 0) { state = prevWorkOrderListReducerDefaultState; }
    var errorMessage = _a.errorMessage, type = _a.type, workOrderRequest = _a.workOrderRequest;
    switch (type) {
        case PREVIOUS_WORKORDER_LIST_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case PREVIOUS_WORKORDER_LIST_SUCCESS: {
            var workOrders = !workOrderRequest.first
                ? __spreadArray(__spreadArray([], __read(state.workOrders), false), __read(workOrderRequest.content), false) : workOrderRequest.content;
            return __assign(__assign({}, state), { isFetching: false, errorMessage: '', workOrders: workOrders, latestPreviousWorkOrderRequest: workOrderRequest });
        }
        case PREVIOUS_WORKORDER_LIST_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        default:
            return state;
    }
});
