import axios from 'axios';
import { TOKEN } from '../constants/localStorage';
import { AUTHORIZATION } from '../constants/endpoints';
axios.defaults.withCredentials = true;
axios.defaults.headers.Accept = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (value) {
    var isAuthRequest = value.url === AUTHORIZATION;
    if (!isAuthRequest && localStorage.getItem(TOKEN)) {
        value.headers.Authorization = "Bearer ".concat(localStorage.getItem(TOKEN));
    }
    return value;
});
export var http = {
    delete: function (url, config) { return axios.delete(url, config); },
    get: function (url, config) { return axios.get(url, config); },
    head: function (url, config) { return axios.head(url, config); },
    patch: function (url, payload, config) { return axios.patch(url, payload, config); },
    post: function (url, payload, config) { return axios.post(url, payload, config); },
    put: function (url, payload, config) { return axios.put(url, payload, config); },
    isCancel: axios.isCancel,
    cancelToken: axios.CancelToken,
};
export var getAPI = function (url) { return axios.get(url).then(function (response) { return response.data; }); };
export var putAPI = function (url, body) {
    return axios
        .put(url, body)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        throw error.response;
    });
};
export var postAPI = function (url, body) {
    return axios
        .post(url, body)
        .then(function (response) { return response.data; })
        .catch(function (error) {
        throw error.response.data;
    });
};
export var deleteAPI = function (url) { return axios.delete(url).then(function (response) { return response.data; }); };
