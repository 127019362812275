import { REMOVE_SERVICE_ERROR, REMOVE_SERVICE_REQUEST, REMOVE_SERVICE_SUCCESS, } from '../workLogConstants';
export var requestRemoveService = function (action) { return ({
    type: REMOVE_SERVICE_REQUEST,
    workOrderId: action.workOrderId,
    workOrderServiceId: action.workOrderServiceId,
}); };
export var errorRemoveService = function (action) { return ({
    type: REMOVE_SERVICE_ERROR,
    errorMessage: action.errorMessage,
}); };
export var successRemoveService = function (action) { return ({
    type: REMOVE_SERVICE_SUCCESS,
    workOrder: action.workOrder,
}); };
