/* eslint-disable */
var baseurl = process.env.HOST + '/api';
// Adresses
export var ADDRESS_HISTORY = function (workOrderId) { return "".concat(baseurl, "/addresses/history?workOrderId=").concat(workOrderId); };
// Authentization
export var AUTHORIZATION = "".concat(baseurl, "/auth");
export var DEV_AUTHORIZATION = "".concat(baseurl, "/auth/technician");
// Customers
export var AGREEMENTS = function (customerId) { return "".concat(baseurl, "/customers/").concat(customerId, "/agreements"); };
export var LINK_PRODUCT = function (customerId, agreementDetailId) {
    return "".concat(baseurl, "/customers/").concat(customerId, "/products/").concat(agreementDetailId, "/link");
};
// Devices
export var CHANGE_PRODUCT = function (oldSerial, newSerial) { return "".concat(baseurl, "/devices/").concat(oldSerial, "/swap/").concat(newSerial); };
// Employee
export var START_DAY = "".concat(baseurl, "/employee/login");
export var END_DAY = "".concat(baseurl, "/employee/logout");
export var SHIFT_STATUS = "".concat(baseurl, "/employee/login-status");
// Health checks
export var GET_ICC_CHECK = function (customerId) { return "".concat(baseurl, "/health-check/icc-checks/").concat(customerId); };
export var HEALTH_CHECK = function (customerId) { return "".concat(baseurl, "/health-check/provisioning/").concat(customerId); };
export var GET_DEVICES_FOR_PROVISIONING = function (customerId) { return "".concat(baseurl, "/customers/").concat(customerId, "/devices"); };
export var SEND_PROVISIONING_COMMAND = function (deviceId) { return "".concat(baseurl, "/devices/").concat(deviceId, "/command"); };
export var DEVICE_POLLING_CUSTOMER = function (customerId) { return "".concat(baseurl, "/health-check/device-polling/all?customerId=").concat(customerId); };
export var DEVICE_POLLING_MAC = function (macAdddress) { return "".concat(baseurl, "/health-check/device-polling/").concat(macAdddress); };
export var DEVICE_POLL_URL = function (pollUrl) {
    return "".concat(baseurl).concat(pollUrl);
};
// Maps
export var MAP_ID = "".concat(baseurl, "/maps");
export var MAP_TOKEN = function (referer) { return "".concat(baseurl, "/maps/auth?referer=").concat(referer); };
// Products
export var AVAILABLE_PRODUCTS = function (agreementId) { return "".concat(baseurl, "/products/available?agreementId=").concat(agreementId); };
export var POST_PRODUCT = function (agreementId) { return "".concat(baseurl, "/products?agreementId=").concat(agreementId); };
export var PRODUCTS_INSTALLER = function (customerId) { return "".concat(baseurl, "/products/filtered-current?customerId=").concat(customerId); };
export var PRODUCTS_TECHNICIAN = function (customerId) { return "".concat(baseurl, "/products/current?customerId=").concat(customerId); };
export var REMOVE_PRODUCT = function (agreementDetailId) { return "".concat(baseurl, "/customer/product?agreementDetailId=").concat(agreementDetailId); };
export var ACTIVATE_AGREEMENT_DETAIL = function (agreementDetailId) { return "".concat(baseurl, "/products/activate?agreementDetailId=").concat(agreementDetailId); };
export var ACTIVATE_PRODUCT = function (agreementDetailId) { return "".concat(baseurl, "/products/").concat(agreementDetailId, "/devices/activate"); };
// Price
export var AGREEMENT_PRICE = function (customerId) { return "".concat(baseurl, "/price/agreements?customerId=").concat(customerId); };
// Workorders
export var WORKORDER = function (_a) {
    var workOrderId = _a.workOrderId;
    return "".concat(baseurl, "/work-orders/").concat(workOrderId);
};
export var WORKORDERS_EXTERNAL_INSTALLER = "".concat(baseurl, "/work-orders");
export var WORKORDERS_TECHNICIAN = "".concat(baseurl, "/work-orders/technician");
export var PRODUCT_LIST = "".concat(baseurl, "/work-orders/products");
export var ACTION_TAKEN = function (workOrderId) { return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/action-taken"); };
export var STATUS = function (workOrderId) { return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/status"); };
export var FIND_SERVICES = function (_a) {
    var workOrderId = _a.workOrderId;
    return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/services");
};
export var FIND_SERVICE_TYPES = function (validAddressId) { return "".concat(baseurl, "/work-orders/service-types?validAddressId=").concat(validAddressId); };
export var REMOVE_SERVICE = function (workOrderId, workOrderServiceId) { return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/services/").concat(workOrderServiceId); };
export var UPDATE_SERVICE = function (workOrderId) { return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/services"); };
export var WORKORDER_SERVICES = function (_a) {
    var workOrderId = _a.workOrderId;
    return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/services/active");
};
export var WORKORDER_PREVIOUS = "".concat(baseurl, "/work-orders/previous");
export var TRAVEL_AND_WORK_DURATION = function (workOrderId) { return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/travel-time"); };
//Work Orders History
export var WORK_ORDER_HISTORY = function (workOrderId) { return "".concat(baseurl, "/work-orders/").concat(workOrderId, "/history"); };
// Zendesk
export var POST_ZENDESK = "".concat(baseurl, "/zendesk");
export var FOLLOW_UP_FORMS = "".concat(baseurl, "/zendesk/forms");
// Customer Info
export var WORKORDER_BY_CUSTOMER_ID = function (customerId) { return "".concat(baseurl, "/customer/").concat(customerId); };
export var CUSTOMER_ADDRESS_HISTORY = function (validAddressId) { return "".concat(baseurl, "/address/").concat(validAddressId, "/history"); };
export var CUSTOMER_SEARCH_API_URL = "".concat(baseurl, "/customer/search");
export var CUSTOMER_AGREEMENT_PRODUCT_PRICES_URL = function (customerId) { return "".concat(baseurl, "/customer/").concat(customerId, "/agreements "); };
