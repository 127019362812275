import DeviceStatus from '../constants/deviceStatus';
function getDeviceStatusDescription(deviceStatus) {
    switch (deviceStatus) {
        case DeviceStatus.OK:
            return 'Online';
        case DeviceStatus.NEUTRAL:
            return 'Unknown';
        case DeviceStatus.WARN:
            return 'Unstable';
        case DeviceStatus.ERROR:
            return 'Offline';
        default:
            return 'Unknown';
    }
}
export default getDeviceStatusDescription;
