var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CUSTOMER_PRODUCTS_REQUEST, CUSTOMER_PRODUCTS_SUCCESS, CUSTOMER_PRODUCTS_FAILURE, CUSTOMER_PRODUCTS_UPDATE, CUSTOMER_PRODUCTS_REQUEST_BACKGROUND, UPDATE_SINGLE_PRODUCT, REMOVE_PRODUCT_SUCCESS, } from '../customerProductsConstants';
var customerProductsReducerDefaultState = {
    customerProducts: [],
    errorMessage: '',
    isFetching: true,
    isFetchingInBackground: false,
    selectedProductId: 0,
};
export default (function (state, _a) {
    if (state === void 0) { state = customerProductsReducerDefaultState; }
    var customerProducts = _a.customerProducts, errorMessage = _a.errorMessage, type = _a.type, newProduct = _a.newProduct, removedProductId = _a.removedProductId;
    switch (type) {
        case CUSTOMER_PRODUCTS_REQUEST:
            return __assign(__assign({}, state), { customerProducts: [], isFetching: true, errorMessage: '' });
        case CUSTOMER_PRODUCTS_REQUEST_BACKGROUND:
            return __assign(__assign({}, state), { isFetchingInBackground: true });
        case CUSTOMER_PRODUCTS_SUCCESS:
            return __assign(__assign({}, state), { customerProducts: customerProducts, isFetching: false, isFetchingInBackground: false });
        case CUSTOMER_PRODUCTS_FAILURE:
            return __assign(__assign({}, state), { errorMessage: errorMessage, isFetching: false, isFetchingInBackground: false });
        case CUSTOMER_PRODUCTS_UPDATE:
            return __assign(__assign({}, state), { customerProducts: customerProducts });
        case UPDATE_SINGLE_PRODUCT:
            return __assign(__assign({}, state), { customerProducts: state.customerProducts.map(function (oldProduct) {
                    return oldProduct.productId === newProduct.productId ? newProduct : oldProduct;
                }) });
        case REMOVE_PRODUCT_SUCCESS: {
            return __assign(__assign({}, state), { customerProducts: state.customerProducts.filter(function (product) { return product.productId !== removedProductId; }) });
        }
        default:
            return state;
    }
});
