import { TOGGLE_POPUP } from '../popupConstants';
export var togglePopup = function (_a) {
    var close = _a.close, popupType = _a.popupType;
    return ({
        type: TOGGLE_POPUP,
        close: close,
        popupType: popupType,
    });
};
export default togglePopup;
