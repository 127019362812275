import moment from 'moment/moment';
import weekdays from '../constants/weekdays';
import { DASH_YEAR_MONTH_DAY } from '../constants/dateFormats';
export default function dateFromToday(daysFromToday) {
    if (daysFromToday === void 0) { daysFromToday = 0; }
    var today = new Date();
    today.setDate(today.getDate() + daysFromToday);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; // January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = "0".concat(dd);
    }
    if (mm < 10) {
        mm = "0".concat(mm);
    }
    var weekday = weekdays[today.getDay()];
    var date = moment("".concat(yyyy, "-").concat(mm, "-").concat(dd)).format(DASH_YEAR_MONTH_DAY);
    return {
        date: date,
        weekday: weekday,
    };
}
