import React from 'react';
import PropTypes from 'prop-types';
import './deviceStatusList.scss';
import DeviceStatus from '../../../../constants/deviceStatus';
import getDeviceStatusDescription from '../../../../utils/getDeviceStatusDescription';
function DeviceStatusListItem(_a) {
    var statusDescription = _a.statusDescription, description = _a.description, deviceStatus = _a.deviceStatus;
    var className = function () {
        switch (deviceStatus) {
            case DeviceStatus.OK:
                return 'device__status__container__button--status--good';
            case DeviceStatus.NEUTRAL:
                return 'device__status__container__button--status--neutral';
            case DeviceStatus.WARN:
                return 'device__status__container__button--status--warning';
            case DeviceStatus.ERROR:
                return 'device__status__container__button--status--error';
            default:
                return '';
        }
    };
    return (React.createElement("div", { className: "device_status_item" },
        React.createElement("div", { className: "device_status_item-left" },
            description,
            React.createElement("span", { className: "device_status_item-description" }, statusDescription)),
        React.createElement("div", { className: "health_check_device_status-right" },
            React.createElement("div", null,
                React.createElement("span", { className: className() }),
                getDeviceStatusDescription(deviceStatus)))));
}
DeviceStatusListItem.propTypes = {
    statusDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    deviceStatus: PropTypes.string.isRequired,
};
export default DeviceStatusListItem;
