import { FOLLOW_UP_FORM_FAILURE, FOLLOW_UP_FORM_REQUEST, FOLLOW_UP_FORM_SUCCESS, } from '../FollowUpConstans';
export var followUpFormsRequest = function () { return ({
    type: FOLLOW_UP_FORM_REQUEST,
}); };
export var followUpFormsSuccess = function (forms) { return ({
    type: FOLLOW_UP_FORM_SUCCESS,
    forms: forms,
}); };
export var followUpFormsError = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: FOLLOW_UP_FORM_FAILURE,
        errorMessage: errorMessage,
    });
};
