var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILURE, AVAILABLE_PRODUCTS_REQUEST, AVAILABLE_PRODUCTS_SUCCESS, AVAILABLE_PRODUCTS_ERROR, AVAILABLE_PRODUCTS_CLEAR_ERROR, } from '../customerProductsConstants';
var addProductReducerDefaultState = {
    addProductRequest: 0,
    availableProducts: [],
    errorMessage: '',
    isAdding: false,
    isFetching: false,
    availableProductsError: '',
};
export default (function (state, action) {
    if (state === void 0) { state = addProductReducerDefaultState; }
    var agreementId = action.agreementId, availableProducts = action.availableProducts, errorMessage = action.errorMessage, type = action.type;
    switch (type) {
        case ADD_PRODUCT_REQUEST:
            return __assign(__assign({}, state), { addProductRequest: agreementId, errorMessage: '', isAdding: true });
        case ADD_PRODUCT_SUCCESS:
            return __assign(__assign({}, state), { addProductRequest: 0, errorMessage: '', isAdding: false });
        case ADD_PRODUCT_FAILURE:
            return __assign(__assign({}, state), { isAdding: false, addProductRequest: 0, errorMessage: errorMessage });
        case AVAILABLE_PRODUCTS_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case AVAILABLE_PRODUCTS_SUCCESS:
            return __assign(__assign({}, state), { availableProducts: availableProducts, isFetching: false });
        case AVAILABLE_PRODUCTS_ERROR:
            return __assign(__assign({}, state), { isFetching: false, availableProductsError: errorMessage });
        case AVAILABLE_PRODUCTS_CLEAR_ERROR:
            return __assign(__assign({}, state), { availableProductsError: '' });
        default:
            return state;
    }
});
