var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeEvery, race, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as actions from '../actions/workOrderList';
import { getAPI } from '../../../api/apiConfig';
import { TECHNICIAN } from '../../Authentication/constants';
import { WORKORDER_LIST_REQUEST, PREVIOUS_WORKORDER_LIST_REQUEST, WORKORDER_LIST_CANCEL, } from '../workOrderListConstants';
import { WORKORDERS_EXTERNAL_INSTALLER, WORKORDERS_TECHNICIAN, WORKORDER_PREVIOUS, } from '../../../constants/endpoints';
import NOB from '../../../constants/language-strings';
function callWorkOrderList() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(actions.workOrderListCancel())];
            case 1:
                _a.sent();
                return [4 /*yield*/, race({
                        task: call(callWorkOrderListWorker),
                        cancel: take(WORKORDER_LIST_CANCEL),
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function callWorkOrderListWorker() {
    var role, URL_1, workOrders, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 9, , 14]);
                _a.label = 1;
            case 1:
                if (!true) return [3 /*break*/, 8];
                return [4 /*yield*/, select(function (state) { return state.authentication.role; })];
            case 2:
                role = _a.sent();
                URL_1 = role === TECHNICIAN ? WORKORDERS_TECHNICIAN : WORKORDERS_EXTERNAL_INSTALLER;
                return [4 /*yield*/, call(getAPI, URL_1)];
            case 3:
                workOrders = _a.sent();
                return [4 /*yield*/, put(actions.receiveWorkOrderList({ workOrders: workOrders }))];
            case 4:
                _a.sent();
                if (!workOrders.length) return [3 /*break*/, 6];
                return [4 /*yield*/, put(actions.workOrderListCancel())];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [4 /*yield*/, call(delay, 8000)];
            case 7:
                _a.sent();
                return [3 /*break*/, 1];
            case 8: return [3 /*break*/, 14];
            case 9:
                error_1 = _a.sent();
                if (!(error_1.response.status === 401)) return [3 /*break*/, 11];
                return [4 /*yield*/, put(actions.errorWorkOrderList(NOB.NO_WORK_ORDERS.NO_ACCESS))];
            case 10:
                _a.sent();
                return [3 /*break*/, 13];
            case 11: return [4 /*yield*/, put(actions.errorWorkOrderList(NOB.NO_WORK_ORDERS.FAILED))];
            case 12:
                _a.sent();
                _a.label = 13;
            case 13: return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
function callPreviousWorkOrders(_a) {
    var pageSelector, url, previousWorkOrders, e_1;
    var pageNumber = _a.pageNumber;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 5]);
                pageSelector = pageNumber ? "?page=".concat(pageNumber) : '';
                url = WORKORDER_PREVIOUS + pageSelector;
                return [4 /*yield*/, call(getAPI, url)];
            case 1:
                previousWorkOrders = _b.sent();
                return [4 /*yield*/, put(actions.receivePrevWorkOrderList(previousWorkOrders))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                e_1 = _b.sent();
                return [4 /*yield*/, put(actions.errorPrevWorkOrderList(e_1.message))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export default [
    takeEvery(WORKORDER_LIST_REQUEST, callWorkOrderList),
    takeEvery(PREVIOUS_WORKORDER_LIST_REQUEST, callPreviousWorkOrders),
];
