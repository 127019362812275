var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import Icon from '../../icons';
import { SEARCH, STATUS_ERROR } from '../../constants/iconNames';
import { http } from '../../api/apiConfig';
import { useQuery } from 'react-query';
import { PebbleSpinner } from './pebble-spinner';
import { CUSTOMER_INFO_URL } from '../../constants/paths';
import withRouter from 'react-router/withRouter';
import { SearchResultItem } from './SearchResultItem';
import { CUSTOMER_SEARCH_API_URL } from '../../constants/endpoints';
import { useSearchStore } from './useSearchStore';
var AddressSearchFormWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  margin-top: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  margin-top: 24px;\n"])));
var AddressHeading = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 18px;\n  font-weight: 500;\n"], ["\n  margin-bottom: 18px;\n  font-weight: 500;\n"])));
var AddressAndFormWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 370px;\n  @media (max-width: 768px) {\n    width: 100%;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 370px;\n  @media (max-width: 768px) {\n    width: 100%;\n    flex-direction: column;\n  }\n"])));
var StyledForm = styled.form(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: space-between;\n  margin: 8px 0 16px;\n  padding: 0 0.5rem;\n  box-sizing: border-box;\n  gap: 0.5rem;\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: space-between;\n  margin: 8px 0 16px;\n  padding: 0 0.5rem;\n  box-sizing: border-box;\n  gap: 0.5rem;\n"])));
var InputContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
var StyledTextInputField = styled.input.attrs(function (props) { return ({
    style: {
        border: '1px solid black',
    },
}); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 18px;\n  margin-right: 8px;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 0 0.5rem;\n\n  :focus {\n    border: 2px solid rgb(240, 127, 165);\n    outline: none;\n  }\n"], ["\n  font-size: 18px;\n  margin-right: 8px;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 0 0.5rem;\n\n  :focus {\n    border: 2px solid rgb(240, 127, 165);\n    outline: none;\n  }\n"])));
var StyledButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 4rem;\n  height: 2.7rem;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  justify-content: center;\n  color: black;\n  border-radius: 0.4rem;\n  border: 1px solid black;\n"], ["\n  width: 4rem;\n  height: 2.7rem;\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  justify-content: center;\n  color: black;\n  border-radius: 0.4rem;\n  border: 1px solid black;\n"])));
var CheckboxWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  font-weight: 500;\n  font-size: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n  font-weight: 500;\n  font-size: 16px;\n"])));
var StyledCheckbox = styled.input(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  position: relative;\n  cursor: pointer;\n  background: black;\n  margin-top: 6px;\n\n  &:checked:before {\n    content: '';\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    background: #ffe500;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  position: relative;\n  cursor: pointer;\n  background: black;\n  margin-top: 6px;\n\n  &:checked:before {\n    content: '';\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    background: #ffe500;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n"])));
var ButtonAndCheckboxWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
var AddressSearchResultsWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 24px;\n"], ["\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 24px;\n"])));
var ResultsWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: rgb(232, 232, 237);\n  border-radius: 0.333333rem;\n  display: flex;\n  -webkit-box-flex: 1;\n  flex-grow: 1;\n  overflow: hidden;\n"], ["\n  background-color: rgb(232, 232, 237);\n  border-radius: 0.333333rem;\n  display: flex;\n  -webkit-box-flex: 1;\n  flex-grow: 1;\n  overflow: hidden;\n"])));
var StyledScrollWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  padding: 0;\n  margin: 0;\n  overflow-y: auto;\n  position: relative;\n\n  ::-webkit-scrollbar {\n    background: transparent;\n    width: 5px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: rgb(220, 220, 225);\n    border-radius: 5px;\n    outline: none;\n    width: 5px;\n  }\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  padding: 0;\n  margin: 0;\n  overflow-y: auto;\n  position: relative;\n\n  ::-webkit-scrollbar {\n    background: transparent;\n    width: 5px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: rgb(220, 220, 225);\n    border-radius: 5px;\n    outline: none;\n    width: 5px;\n  }\n"])));
var Error = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  text-align: center;\n  font-size: 18px;\n  justify-content: center;\n  border-radius: 0.133333rem;\n  border-width: 0.4rem 0.133333rem 0.133333rem;\n  border-style: solid;\n  border-color: rgb(240, 127, 165);\n  margin-top: 32px;\n  margin-bottom: 0;\n  margin-left: 12px;\n  margin-right: 12px;\n  padding: 16px 36px;\n"], ["\n  display: flex;\n  align-items: center;\n  text-align: center;\n  font-size: 18px;\n  justify-content: center;\n  border-radius: 0.133333rem;\n  border-width: 0.4rem 0.133333rem 0.133333rem;\n  border-style: solid;\n  border-color: rgb(240, 127, 165);\n  margin-top: 32px;\n  margin-bottom: 0;\n  margin-left: 12px;\n  margin-right: 12px;\n  padding: 16px 36px;\n"])));
var ErrorText = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-left: 8px;\n"], ["\n  margin-left: 8px;\n"])));
var ResultListItem = styled.li(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  &:nth-child(odd) {\n    background-color: rgb(220, 220, 225);\n  }\n"], ["\n  display: flex;\n  &:nth-child(odd) {\n    background-color: rgb(220, 220, 225);\n  }\n"])));
var LoaderContainer = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  position: relative;\n  margin: 0 auto 0;\n  align-items: center;\n  flex-grow: 1;\n  justify-content: center;\n  display: flex;\n  text-align: center;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 12px;\n"], ["\n  position: relative;\n  margin: 0 auto 0;\n  align-items: center;\n  flex-grow: 1;\n  justify-content: center;\n  display: flex;\n  text-align: center;\n  flex-direction: column;\n  gap: 12px;\n  margin-top: 12px;\n"])));
var LoadingText = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: 500;\n  margin-top: 12px;\n"], ["\n  font-size: 24px;\n  font-weight: 500;\n  margin-top: 12px;\n"])));
export var AddressSearchForm = withRouter(function (_a) {
    var history = _a.history;
    var _b = useSearchStore(), searchInput = _b.searchInput, setSearchInput = _b.setSearchInput, searchQuery = _b.searchQuery, setSearchQuery = _b.setSearchQuery, onlyActive = _b.onlyActive, setSearchOnlyActive = _b.setSearchOnlyActive, cache = _b.cache, setCache = _b.setCache, cacheKey = _b.cacheKey, setCacheKey = _b.setCacheKey;
    var _c = useQuery({
        queryKey: ['search', searchQuery, onlyActive],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (cacheKey === searchQuery) {
                            return [2 /*return*/, cache];
                        }
                        return [4 /*yield*/, http.get(CUSTOMER_SEARCH_API_URL, {
                                params: {
                                    q: searchQuery,
                                    status: onlyActive ? 'Active' : '',
                                },
                                timeout: 40000,
                                keepPreviousData: true,
                                refetchOnWindowFocus: false,
                                refetchOnMount: false,
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        setCacheKey(searchQuery);
                        setCache(data);
                        return [2 /*return*/, data];
                }
            });
        }); },
        enabled: searchQuery !== '',
    }), data = _c.data, refetch = _c.refetch, isFetching = _c.isFetching, isError = _c.isError;
    function onSearchClicked() {
        if (searchInput !== searchQuery) {
            setSearchQuery(searchInput);
        }
        else {
            setCacheKey('');
            setCache(null);
            window.setTimeout(function () {
                void refetch();
            }, 1);
        }
    }
    return (React.createElement(AddressSearchFormWrapper, null,
        React.createElement(AddressHeading, null, "KUNDES\u00D8K"),
        React.createElement(AddressAndFormWrapper, null,
            React.createElement(StyledForm, { autoComplete: "off", handleSubmit: function () { return false; } },
                React.createElement(InputContainer, null,
                    React.createElement(StyledTextInputField, { name: "query", value: searchInput, onChange: function (e) { return setSearchInput(e.target.value); } })),
                React.createElement(ButtonAndCheckboxWrapper, null,
                    React.createElement(StyledButton, { type: "submit", onClick: function (e) {
                            e.preventDefault();
                            onSearchClicked();
                        } },
                        React.createElement(Icon, { color: '#1e1c1e', name: SEARCH })),
                    data && (React.createElement(CheckboxWrapper, null,
                        "Active",
                        React.createElement(StyledCheckbox, { type: "checkbox", checked: onlyActive, onChange: function () { return setSearchOnlyActive(!onlyActive); } }))))),
            isFetching ? (React.createElement(LoaderContainer, null,
                React.createElement(PebbleSpinner, null),
                React.createElement(LoadingText, null, "S\u00F8ker etter kunder..."))) : (React.createElement(AddressSearchResultsWrapper, null,
                data && !!data.customers.length && (React.createElement(ResultsWrapper, null,
                    React.createElement(StyledScrollWrapper, { as: "ul" }, data.customers.map(function (it) { return (React.createElement(ResultListItem, { key: it.customerId },
                        React.createElement(SearchResultItem, { customer: it, onClick: function () { return history.push(CUSTOMER_INFO_URL(it.customerId)); } }))); })))),
                data && data.customers.length === 0 && (React.createElement(Error, null,
                    React.createElement(Icon, { fill: '#F07FA5FF', name: STATUS_ERROR }),
                    React.createElement(ErrorText, null, "Ingen kunder funnet etter et gitt kriterium."))),
                isError && (React.createElement(Error, null,
                    React.createElement(Icon, { fill: '#F07FA5FF', name: STATUS_ERROR }),
                    React.createElement(ErrorText, null, "Uventet feil i svar"))))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
