var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import FetchingSpinner from '../utils/FetchingSpinner';
import { QA } from '../constants/paths';
import { requestTokenVerification } from '../modules/Authentication/actions/verifyTokenActions';
import Routes from './routes';
import SignIn from '../modules/Authentication/containers/SignInContainer';
import { TOKEN, URL } from '../constants/localStorage';
import '../modules/WorkorderList/components/workOrderListBottom/workOrderListBottom.scss';
import './offline.scss';
import Header from './Header/Header';
import SlideInPagePortal from '../components/SlideInPagePortal/SlideInPagePortal';
import ScrollToTop from '../components/ScrollTopTop/ScrollToTop';
import { ErrorPage } from '../modules/ErrorPage';
import AuthenticationDev from '../modules/authentication-dev/authentication-dev-container';
import RedirectionModal from '../modules/RedirectionModal/redirection-modal';
var PROD_ENV = 'getservice.get.no';
var PROD_ENV_AWS = 'homeservice.telia.no';
var DEV_ENV_AWS = 'homeservice-test.telia.io';
var hostname = window.location.hostname;
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 12px;\n  left: 16px;\n  z-index: 10;\n"], ["\n  position: fixed;\n  top: 12px;\n  left: 16px;\n  z-index: 10;\n"])));
var oldToken = '';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClickButton = function () {
            _this.setState(function (prev) { return ({ devLogin: !prev.devLogin }); });
        };
        _this.handleCloseModal = function () {
            _this.setState(function (prev) { return ({ showRedirectionModal: !prev.showRedirectionModal }); });
        };
        _this.componentDidMount = function () {
            var requestTokenVerificationFunc = _this.props.requestTokenVerificationFunc;
            var token = localStorage.getItem(TOKEN);
            if (token) {
                if (token !== oldToken) {
                    oldToken = token;
                    requestTokenVerificationFunc({ token: token });
                }
            }
        };
        _this.state = {
            devLogin: false,
            showRedirectionModal: true,
        };
        return _this;
    }
    app.prototype.render = function () {
        var _this = this;
        var _a = this.props, location = _a.location, isAuthenticated = _a.isAuthenticated, isAuthenticatedFetching = _a.isAuthenticatedFetching;
        if (window.location.host.includes(QA)) {
            window.onload = function () {
                document.body.id = 'qa';
            };
        }
        else {
            window.onload = function () {
                document.body.id = 'notQa';
            };
        }
        localStorage.setItem(URL, location.pathname + location.hash);
        var showDevLoginButton = function () {
            return !isAuthenticated && hostname !== PROD_ENV && hostname !== PROD_ENV_AWS;
        };
        var showDevLoginForm = function () {
            return !isAuthenticated &&
                !isAuthenticatedFetching &&
                _this.state.devLogin &&
                hostname !== PROD_ENV &&
                hostname !== PROD_ENV_AWS;
        };
        var showRedirectModal = function () {
            return _this.state.showRedirectionModal &&
                hostname !== PROD_ENV_AWS &&
                hostname !== DEV_ENV_AWS &&
                hostname !== 'localhost';
        };
        return (React.createElement(Sentry.ErrorBoundary, { fallback: ErrorPage },
            React.createElement("div", { className: "app__main" },
                localStorage.getItem(URL) && localStorage.getItem(URL) !== location.pathname,
                React.createElement(Header, null),
                showDevLoginButton() && (React.createElement(StyledButton, { onClick: function () { return _this.handleClickButton(); } }, this.state.devLogin ? 'Regular Login' : 'Dev Login')),
                React.createElement("div", { className: "main__main-container" },
                    showDevLoginForm() && React.createElement(AuthenticationDev, null),
                    !isAuthenticated && !isAuthenticatedFetching && !this.state.devLogin && React.createElement(SignIn, null),
                    isAuthenticatedFetching && React.createElement(FetchingSpinner, null),
                    isAuthenticated && !isAuthenticatedFetching && (React.createElement(ScrollToTop, null,
                        React.createElement(Routes, { location: location }))),
                    React.createElement(SlideInPagePortal, { hash: location.hash })),
                showRedirectModal() && (React.createElement(RedirectionModal, { onCloseModal: this.handleCloseModal, hostname: hostname === PROD_ENV ? PROD_ENV_AWS : DEV_ENV_AWS })))));
    };
    return app;
}(Component));
app.propTypes = {
    close: PropTypes.bool,
    requestTokenVerificationFunc: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthenticatedFetching: PropTypes.bool.isRequired,
    isFetchingWorkOrder: PropTypes.bool.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    popupType: PropTypes.string,
};
app.defaultProps = {
    close: true,
    popupType: '',
};
var mapStateToProps = function (state) { return ({
    close: state.popup.close,
    isAuthenticated: state.authentication.isAuthenticated,
    isAuthenticatedFetching: state.authentication.isFetching,
    popupType: state.popup.popupType,
    isFetchingWorkOrder: state.workOrder.isFetching,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestTokenVerificationFunc: function (token) { return dispatch(requestTokenVerification(token)); },
}); };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(app));
var templateObject_1;
