export default function setCategoriesValues(multiples, singles, type) {
    var values = [];
    var singleNames = singles.map(function (single) { return single[type]; });
    var multipleArray = multiples.map(function (category) { return ({
        name: category[type],
        value: category.id,
    }); });
    singles.map(function (category) {
        values.push({
            name: category[type],
            value: category.id,
        });
        return values;
    });
    // Only show duplicate categories once
    var names = multipleArray
        .map(function (category) { return category.name; })
        .filter(function (value, id, array) { return array.indexOf(value) === id && !singleNames.includes(value); });
    if (names.length) {
        for (var i = 0; i <= names.length; i++) {
            values.push({
                name: names[i],
                value: i + 1,
            });
        }
    }
    return values;
}
