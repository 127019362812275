var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/Table/Table';
function HealthCheckTabContent(_a) {
    var categoryValues = _a.categoryValues;
    return categoryValues.map(function (categoyValue) {
        // Table columns are based on the element with the longest list of values(propertyValues)
        var columnValueTypes = categoyValue.subcategoryValues
            .reduce(function (a, b) { return (a.propertyValues.length > b.propertyValues.length ? a : b); })
            .propertyValues.map(function (value) { return value.type; });
        // Construct table columns. Based on the category tag as the first column and values from all tags above
        var columns = __spreadArray([], __read(columnValueTypes), false);
        columns.unshift(categoyValue.description);
        return (React.createElement("div", { className: "table-wrapper", key: categoyValue.name },
            categoyValue.name && React.createElement("span", { className: "title" }, categoyValue.name),
            React.createElement(Table, { columns: columns, rows: categoyValue.subcategoryValues, columnValueTypes: columnValueTypes })));
    });
}
HealthCheckTabContent.propTypes = {
    categoryValues: PropTypes.arrayOf(PropTypes.shape({
        subcategoryValues: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            propertyValues: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string.isRequired,
                value: PropTypes.objectOf(PropTypes.shape({
                    first: PropTypes.objectOf(PropTypes.shape({
                        value: PropTypes.string.isRequired,
                        valueStatus: PropTypes.string.isRequired,
                    })),
                    second: PropTypes.objectOf(PropTypes.shape({
                        value: PropTypes.string.isRequired,
                        valueStatus: PropTypes.string.isRequired,
                    })),
                })).isRequired,
            })).isRequired,
        })),
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    })).isRequired,
};
export default HealthCheckTabContent;
