var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-dynamic-swiper/lib/styles.css';
import SlideContent from '../components/SlideContent';
import workOrderDays from '../../../utils/workOrderDays';
import WorkOrderListTop from '../components/workOrderListTop/WorkOrderListTop';
import './workOrderList.scss';
import PreviousWorkOrderListContainer from './PreviousWorkOrderListContainer';
import PageSwiper from '../../../components/Swiper/PageSwiper';
import getDayAsString from '../../../utils/getDayAsString';
function WorkOrderListSlideContainer(_a) {
    var productList = _a.productList, workOrders = _a.workOrders, numberOfPrevWorkOrders = _a.numberOfPrevWorkOrders, languageStrings = _a.languageStrings;
    var _b = __read(useState(null), 2), swiper = _b[0], updateSwiper = _b[1];
    var findCorrectProductList = function (date) {
        return productList.find(function (listElement) { return listElement.serviceDate === date; })
            ? productList.find(function (listElement) { return listElement.serviceDate === date; }).products
            : {};
    };
    var allWorkOrders = workOrderDays(workOrders).allWorkOrders;
    var goNext = function () {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };
    var goPrev = function () {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    return (React.createElement(PageSwiper, { getSwiper: updateSwiper, containerClass: "workOrder__list", initialSlide: 1 },
        React.createElement("div", null,
            React.createElement(WorkOrderListTop, { hideProductList: true, workOrders: workOrders, title: languageStrings.TITLE, onNextClick: goNext, hasNext: true }),
            React.createElement(PreviousWorkOrderListContainer, null)),
        allWorkOrders.map(function (item, index) { return (React.createElement("div", { id: index, key: item.date },
            React.createElement(WorkOrderListTop, { hideProductList: !item.workOrdersNotDone.length, title: getDayAsString(item.date), productList: findCorrectProductList(item.date), onNextClick: index + 1 < allWorkOrders.length ? goNext : null, onPrevClick: goPrev, numberOfElementsPrevPage: index === 0 ? numberOfPrevWorkOrders : null }),
            React.createElement(SlideContent, { isWorkOrderListEmpty: item.isWorkOrderListEmpty, areAllWorkOrdersDone: item.areAllWorkOrdersDone, isExternalInstaller: true, workOrdersCancelled: item.workOrdersCancelled, workOrdersDone: item.workOrdersDone, workOrdersNotDone: item.workOrdersNotDone }))); })));
}
WorkOrderListSlideContainer.propTypes = {
    productList: PropTypes.arrayOf(PropTypes.any).isRequired,
    workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
    numberOfPrevWorkOrders: PropTypes.number,
    languageStrings: PropTypes.objectOf(PropTypes.string).isRequired,
};
WorkOrderListSlideContainer.defaultProps = {
    numberOfPrevWorkOrders: null,
};
var mapStateToProps = function (state) { return ({
    productList: state.productList.productList,
    workOrders: state.workOrders.workOrders,
    numberOfPrevWorkOrders: state.previousWorkOrders.workOrders.length
        ? state.previousWorkOrders.latestPreviousWorkOrderRequest.totalElements
        : 0,
    isFetchingPrevWorkOrders: state.previousWorkOrders.isFetching,
    languageStrings: state.NOB.PREV_WORKORDERS,
}); };
export default connect(mapStateToProps)(WorkOrderListSlideContainer);
