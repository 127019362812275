var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WORKORDER_LIST_REQUEST, WORKORDER_LIST_FAILURE, WORKORDER_LIST_SUCCESS, EMPTY_WORKORDER_LIST, WORKORDER_STATUS_UPDATE, END_DAY, } from '../workOrderListConstants';
import Status from '../../../constants/status-codes';
import dateFromToday from '../../../utils/dateFromToday';
var today = dateFromToday();
var workOrderListReducerDefaultState = {
    endDay: false,
    errorMessage: '',
    isFetching: false,
    workOrders: [],
    startedWorkOrder: [],
};
export default (function (state, _a) {
    if (state === void 0) { state = workOrderListReducerDefaultState; }
    var endDay = _a.endDay, errorMessage = _a.errorMessage, newStatus = _a.newStatus, type = _a.type, workOrderId = _a.workOrderId, workOrders = _a.workOrders;
    switch (type) {
        case WORKORDER_LIST_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case WORKORDER_LIST_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: '', workOrders: workOrders, selectedDay: today.weekday, selectedDate: today.date, startedWorkOrder: workOrders
                    .filter(function (workOrder) {
                    return Status.TRAVELING.includes(workOrder.status) ||
                        Status.ON_SITE.includes(workOrder.status);
                })
                    .map(function (workOrder) { return workOrder.workOrderId; }) });
        case WORKORDER_LIST_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        case WORKORDER_STATUS_UPDATE:
            // Update to new status for selected workorder.
            return __assign(__assign({}, state), { workOrders: state.workOrders.map(function (item) {
                    if (item.workOrderId === workOrderId) {
                        return __assign(__assign({}, item), { status: newStatus });
                    }
                    return item;
                }) });
        case EMPTY_WORKORDER_LIST:
            return __assign(__assign({}, state), { isFetching: false, workOrders: [] });
        case END_DAY:
            return __assign(__assign({}, state), { endDay: endDay });
        default:
            return state;
    }
});
