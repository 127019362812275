import removeFirstValueFromBracketString from './removeFirstValueFromBracketString';
export default function hideSelectedDiagnosticServices(values, selectedDiagnosticServices, removeFirstValue) {
    var filteredValues = values.filter(function (value) {
        return !selectedDiagnosticServices
            .map(function (service) {
            return removeFirstValue
                ? removeFirstValueFromBracketString(service.description)
                : service.description;
        })
            .includes(value.name);
    });
    return filteredValues;
}
