var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FadeLoader } from 'react-spinners';
import QuantityDropdown from './QuantityDropdown';
import ServicecodeContent from './ServicecodeContent';
import Icon from '../../icons';
import { CHECK } from '../../constants/iconNames';
var Service = /** @class */ (function (_super) {
    __extends(Service, _super);
    function Service() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            amount: _this.props.service.quantity,
            showDropdown: false,
            value: '',
        };
        _this.setDropDownValues = function (max) {
            var values = [];
            for (var i = 1; i <= max; i++) {
                var selected = i === _this.state.amount;
                values.push({
                    name: (React.createElement("span", { className: selected ? 'selected' : '', key: i },
                        i,
                        selected && React.createElement(Icon, { name: CHECK }))),
                    value: i,
                });
            }
            return values;
        };
        _this.toggleDropdown = function () {
            _this.setState({ showDropdown: !_this.state.showDropdown });
        };
        _this.selected = function (event) {
            var _a = _this.props, requestUpdateService = _a.requestUpdateService, _b = _a.service, workOrderServiceId = _b.workOrderServiceId, id = _b.id, workOrderId = _a.workOrderId;
            _this.setState({
                showDropdown: false,
                amount: event.value,
            });
            requestUpdateService(workOrderId, workOrderServiceId, id, _this.state.amount, false);
        };
        _this.removeClick = function () {
            var _a = _this.props, requestRemoveService = _a.requestRemoveService, workOrderServiceId = _a.service.workOrderServiceId, workOrderId = _a.workOrderId;
            requestRemoveService(workOrderId, workOrderServiceId);
        };
        return _this;
    }
    Service.prototype.render = function () {
        var _a = this.props, max = _a.max, workOrderServiceIdToBeChanged = _a.workOrderServiceIdToBeChanged, service = _a.service, technician = _a.technician;
        var _b = this.state, amount = _b.amount, showDropdown = _b.showDropdown, value = _b.value;
        return (React.createElement("div", { className: "service" },
            service.workOrderServiceId === workOrderServiceIdToBeChanged && (React.createElement("div", { className: "service__spinner" },
                React.createElement(FadeLoader, null))),
            max > 1 && (React.createElement(QuantityDropdown, { dropDownValues: this.setDropDownValues(max), showDropdown: showDropdown, toggleDropdown: this.toggleDropdown, selected: this.selected, value: value, amount: amount })),
            React.createElement(ServicecodeContent, { service: service, amount: amount, noDropdown: max === 1, removeClick: this.removeClick, workOrderServiceIdToBeChanged: workOrderServiceIdToBeChanged, technician: technician })));
    };
    return Service;
}(Component));
Service.propTypes = {
    max: PropTypes.number.isRequired,
    workOrderServiceIdToBeChanged: PropTypes.number,
    requestRemoveService: PropTypes.func.isRequired,
    requestUpdateService: PropTypes.func.isRequired,
    service: PropTypes.objectOf(PropTypes.any).isRequired,
    technician: PropTypes.bool.isRequired,
    workOrderId: PropTypes.number.isRequired,
};
Service.defaultProps = {
    workOrderServiceIdToBeChanged: null,
};
export default Service;
