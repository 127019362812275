var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HEALTH_CHECK_REQUEST, HEALTH_CHECK_RECEIVE, HEALTH_CHECK_ERROR, UPDATE_HEALTH_CHECK_REQUEST, ICC_CHECK_ERROR, ICC_CHECK_RECEIVE, ICC_CHECK_REQUEST, } from '../constants';
var healthCheckDefaultState = {
    provisioning: {
        healthCheckResponses: [],
    },
    iccCheck: {},
    isFetching: true,
    isIccFetching: true,
    errorMessage: '',
};
export default (function (state, action) {
    if (state === void 0) { state = healthCheckDefaultState; }
    switch (action.type) {
        case HEALTH_CHECK_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case HEALTH_CHECK_RECEIVE:
            return __assign(__assign({}, state), { provisioning: action.provisioning, isFetching: false });
        case HEALTH_CHECK_ERROR:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: action.errorMessage });
        case UPDATE_HEALTH_CHECK_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case ICC_CHECK_REQUEST:
            return __assign(__assign({}, state), { isIccFetching: true });
        case ICC_CHECK_RECEIVE:
            return __assign(__assign({}, state), { iccCheck: action.iccCheck, isIccFetching: false });
        case ICC_CHECK_ERROR:
            return __assign(__assign({}, state), { isIccFetching: false, errorMessage: action.errorMessage });
        default:
            return state;
    }
});
