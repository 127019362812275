var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Archive from "./Archive";
import ArrowDown from "./ArrowDown";
import ArrowLeft from "./ArrowLeft";
import Calendar from "./Calendar";
import Camera from "./Camera";
import Car from "./Car";
import Check from "./Check";
import Clock from "./Clock";
import Cross from "./Cross";
import CrossLarge from "./CrossLarge";
import Delete from "./Delete";
import Edit from "./Edit";
import Expand from "./Expand";
import GetBox from "./GetBox";
import Heart from "./Heart";
import HealthCheck from "./HealthCheck";
import History from "./History";
import House from "./House";
import Link from "./Link";
import Map from "./Map";
import Minimize from "./Minimize";
import Plus from "./Plus";
import Refresh from "./Refresh";
import Save from "./Save";
import Scan from "./Scan";
import Search from "./Search";
import StatusError from "./StatusError";
import StatusOk from "./StatusOk";
import Switch from "./Switch";
import Telephone from "./Telephone";
import Tool from "./Tool";
import ToolOutlined from "./ToolOutlined";
import Trash from "./Trash";
import User from "./User";
import Switch2 from "./Switch2";
import * as ICON from "../constants/iconNames";
import Pdf from "./Pdf";
var Icon = function (props) {
    switch (props.name) {
        case ICON.ARCHIVE:
            return React.createElement(Archive, __assign({}, props));
        case ICON.ARROW_DOWN:
            return React.createElement(ArrowDown, __assign({}, props));
        case ICON.ARROW_LEFT:
            return React.createElement(ArrowLeft, __assign({}, props));
        case ICON.CALENDAR:
            return React.createElement(Calendar, __assign({}, props));
        case ICON.CAMERA:
            return React.createElement(Camera, __assign({}, props));
        case ICON.CAR:
            return React.createElement(Car, __assign({}, props));
        case ICON.CHECK:
            return React.createElement(Check, __assign({}, props));
        case ICON.CLOCK:
            return React.createElement(Clock, __assign({}, props));
        case ICON.CROSS:
            return React.createElement(Cross, __assign({}, props));
        case ICON.CROSS_LARGE:
            return React.createElement(CrossLarge, __assign({}, props));
        case ICON.DELETE:
            return React.createElement(Delete, __assign({}, props));
        case ICON.EDIT:
            return React.createElement(Edit, __assign({}, props));
        case ICON.EXPAND:
            return React.createElement(Expand, __assign({}, props));
        case ICON.GET_BOX:
            return React.createElement(GetBox, __assign({}, props));
        case ICON.HEART:
            return React.createElement(Heart, __assign({}, props));
        case ICON.HISTORY:
            return React.createElement(History, __assign({}, props));
        case ICON.HOUSE:
            return React.createElement(House, __assign({}, props));
        case ICON.HEALTH_CHECK:
            return React.createElement(HealthCheck, __assign({}, props));
        case ICON.LINK:
            return React.createElement(Link, __assign({}, props));
        case ICON.MAP:
            return React.createElement(Map, __assign({}, props));
        case ICON.MINIMIZE:
            return React.createElement(Minimize, __assign({}, props));
        case ICON.SAVE:
            return React.createElement(Save, __assign({}, props));
        case ICON.SCAN:
            return React.createElement(Scan, __assign({}, props));
        case ICON.SEARCH:
            return React.createElement(Search, __assign({}, props));
        case ICON.STATUS_ERROR:
            return React.createElement(StatusError, __assign({}, props));
        case ICON.STATUS_OK:
            return React.createElement(StatusOk, __assign({}, props));
        case ICON.SWITCH:
            return React.createElement(Switch, __assign({}, props));
        case ICON.PLUS:
            return React.createElement(Plus, __assign({}, props));
        case ICON.REFRESH:
            return React.createElement(Refresh, __assign({}, props));
        case ICON.TOOL:
            return React.createElement(Tool, __assign({}, props));
        case ICON.TOOL_OULINED:
            return React.createElement(ToolOutlined, __assign({}, props));
        case ICON.TRASH:
            return React.createElement(Trash, __assign({}, props));
        case ICON.USER:
            return React.createElement(User, __assign({}, props));
        case ICON.TELEPHONE:
            return React.createElement(Telephone, __assign({}, props));
        case ICON.PDF:
            return React.createElement(Pdf, __assign({}, props));
        case ICON.SWITCH2:
            return React.createElement(Switch2, null);
        default:
            return React.createElement(Clock, __assign({}, props));
    }
};
export default Icon;
