var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './changeProduct.scss';
import ProductListElement from '../../../../../components/ProductListElement/ProductListElement';
import Scanner from '../../../../../components/Scanner/Scanner';
function DeviceElement(_a) {
    var device = _a.device, onReset = _a.onReset, hasChanged = _a.hasChanged, serialNumberInput = _a.serialNumberInput, onScannerDetect = _a.onScannerDetect, isFetching = _a.isFetching, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, status = _a.status;
    var _b = __read(useState(false), 2), showScanner = _b[0], setShowScanner = _b[1];
    var onDetected = function (result) {
        onScannerDetect(result);
        setShowScanner(false);
    };
    return (React.createElement("div", null,
        React.createElement(ProductListElement, { isFetching: isFetching, onScannerClick: function () { return setShowScanner(true); }, title: device.name, serialNumberInputs: !hasChanged ? [serialNumberInput] : [], handleSubmit: handleSubmit, handleChange: handleChange, serialNumbers: [device.serialNumber], status: status, onReset: onReset }),
        showScanner && (React.createElement(Scanner, { onHeaderClick: function () { return setShowScanner(false); }, onDetected: onDetected }))));
}
DeviceElement.propTypes = {
    device: PropTypes.shape({
        name: PropTypes.string.isRequired,
        serialNumber: PropTypes.string,
    }).isRequired,
    onReset: PropTypes.func,
    hasChanged: PropTypes.bool,
    serialNumberInput: PropTypes.shape({
        dpadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        errorMessage: PropTypes.string,
    }),
    onScannerDetect: PropTypes.func,
    isFetching: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
};
DeviceElement.defaultProps = {
    onReset: function () { },
    hasChanged: false,
    serialNumberInput: null,
    onScannerDetect: function () { },
    isFetching: false,
};
export default DeviceElement;
