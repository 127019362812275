import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ARROW_DOWN } from '../../../constants/iconNames';
import { INCOMPLETE_WITHOUT_CODE } from '../../../constants/status-codes';
import { DOT_DAY_MONTH_YEAR } from '../../../constants/dateFormats';
import Icon from '../../../icons';
import Variables from '../../../styles/variables.scss';
import '../containers/addressHistory.scss';
export default function DropdownButton(_a) {
    var closeDropdown = _a.closeDropdown, open = _a.open, _b = _a.singleAddressHistory, registeredDate = _b.registeredDate, status = _b.status, workOrderId = _b.workOrderId, serviceTypeName = _b.serviceTypeName, toggleDropdown = _a.toggleDropdown;
    return (React.createElement("button", { onClick: function () { return toggleDropdown(open === workOrderId ? 1 : workOrderId); }, className: status.includes(INCOMPLETE_WITHOUT_CODE)
            ? 'address_history__dropdown--red'
            : 'address_history__dropdown--blue' },
        React.createElement("div", null,
            React.createElement("span", null,
                serviceTypeName,
                " "),
            React.createElement("div", { className: "address_history__dropdown--right" },
                React.createElement("span", null, moment(registeredDate.slice(0, 10)).format(DOT_DAY_MONTH_YEAR)),
                React.createElement("span", { className: closeDropdown ? 'rotate-down' : 'rotate-up' },
                    React.createElement(Icon, { stroke: Variables.blackPrimary, name: ARROW_DOWN }))))));
}
DropdownButton.propTypes = {
    singleAddressHistory: PropTypes.objectOf(PropTypes.any).isRequired,
    closeDropdown: PropTypes.bool.isRequired,
    open: PropTypes.number.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
};
