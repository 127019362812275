import { CATEGORY_MAIN, CATEGORY_SUB } from '../modules/AddServiceCode/addServiceCodeConstants';
export default function setCategoriesDropdown(category, allCategories, main, sub) {
    var values = [];
    var mainCategories = allCategories.map(function (singleCategory) { return singleCategory.mainCategories; })[0];
    switch (category) {
        case CATEGORY_MAIN: {
            // Assigning main categories
            mainCategories.map(function (mainCategory) {
                values.push({
                    name: mainCategory.description,
                    value: mainCategory.serviceId,
                });
                return values;
            });
            return values;
        }
        case CATEGORY_SUB: {
            // Categories containing the selected main category
            var subCategories = mainCategories
                .filter(function (filterSub) { return filterSub.description === main; })
                .map(function (singleSub) { return singleSub.subcategories; })[0];
            // Assigning sub categories
            subCategories.map(function (subCategory) {
                values.push({
                    name: subCategory.description,
                    value: subCategory.serviceId,
                });
                return values;
            });
            return values;
        }
        default: {
            // Categories containing the selected main category and the selected sub category
            var errorCategories = allCategories.filter(function (mainCategory) {
                return mainCategory.main === main && mainCategory.sub === sub && mainCategory.error;
            });
            values = errorCategories.map(function (service) { return ({
                name: service.error,
                value: service.id,
            }); });
            return values;
        }
    }
}
