import { ADDRESS_HISTORY_REQUEST, ADDRESS_HISTORY_SUCCESS, ADDRESS_HISTORY_FAILURE, ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST, } from '../addressHistoryConstants';
export var requestAddressHistory = function (action) { return ({
    type: ADDRESS_HISTORY_REQUEST,
    workOrderId: action.workOrderId,
}); };
export var requestAddressHistoryByAddressId = function (validAddressId) { return ({
    type: ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST,
    validAddressId: validAddressId,
}); };
export var receiveAddressHistory = function (action) { return ({
    type: ADDRESS_HISTORY_SUCCESS,
    addressHistory: action.addressHistory,
}); };
export var errorAddressHistory = function (action) { return ({
    type: ADDRESS_HISTORY_FAILURE,
    errorMessage: action.errorMessage,
}); };
