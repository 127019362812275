import React from 'react';
import capitalize from './capitalizeString';
import NOB from '../constants/language-strings';
import Status, { COMPLETE_WITHOUT_CODE, CANCELLED } from '../constants/status-codes';
import setStatusTimeDiv from './setStatusTimeDiv';
import { hours, minutes } from './timeCalculator';
export default function setStatusTimeValue(workOrder, filteredWorkOrderStatus, isPastDue) {
    var workOrderStatus = "".concat(NOB.WORKORDER_LIST.TIME_ESTIMATE, ": \n  ").concat(hours(workOrder.totalTimeEstimate), " ").concat(minutes(workOrder.totalTimeEstimate));
    if (filteredWorkOrderStatus.includes(COMPLETE_WITHOUT_CODE)) {
        workOrderStatus = (React.createElement("span", { className: "status_time--bold-status" }, capitalize(filteredWorkOrderStatus)));
    }
    else if (filteredWorkOrderStatus === CANCELLED) {
        workOrderStatus = setStatusTimeDiv(filteredWorkOrderStatus, workOrderStatus, true, workOrder.serviceDate, isPastDue);
    }
    else if (Status.ALLOCATED.includes(workOrder.status)) {
        var stringToShow = "kl.".concat(workOrder.timeSlot);
        workOrderStatus = setStatusTimeDiv(stringToShow, workOrderStatus, false, workOrder.serviceDate, isPastDue);
    }
    else if (Status.TRAVELING.includes(workOrder.status)) {
        workOrderStatus = setStatusTimeDiv(NOB.WORKORDER_LIST.DRIVESTARTED, workOrderStatus, true, workOrder.serviceDate, isPastDue);
    }
    else if (Status.ON_SITE.includes(workOrder.status)) {
        workOrderStatus = setStatusTimeDiv(NOB.WORKORDER_LIST.WORKSTARTED, workOrderStatus, true, workOrder.serviceDate, isPastDue);
    }
    return workOrderStatus;
}
