import { STATUS_CHANGE_REQUEST, STATUS_CHANGE_SUCCESS, STATUS_CHANGE_FAILURE, STATUS_CHANGE_CHECK_SKIP, WORKORDER_REQUEST, WORKORDER_SUCCESS, WORKORDER_FAILURE, WORKORDER_REQUEST_BY_CUSTOMER_ID, } from '../workOrderConstants';
export var requestStatusChange = function (currentStatus) { return ({
    type: STATUS_CHANGE_REQUEST,
    currentStatus: currentStatus,
}); };
export var receiveStatusChange = function (status) { return ({
    type: STATUS_CHANGE_SUCCESS,
    status: status,
}); };
export var checkForStatusSkip = function (currentStatus, workOrderId) { return ({
    type: STATUS_CHANGE_CHECK_SKIP,
    currentStatus: currentStatus,
    workOrderId: workOrderId,
}); };
export var errorStatusChange = function (action) { return ({
    type: STATUS_CHANGE_FAILURE,
    workOrder: action.workOrder,
    errorMessage: action.errorMessage,
}); };
export var requestWorkOrderByCustomerInfo = function (customerId) { return ({
    type: WORKORDER_REQUEST_BY_CUSTOMER_ID,
    customerId: customerId,
}); };
export var requestWorkOrder = function (action) { return ({
    type: WORKORDER_REQUEST,
    workOrderId: action.workOrderId,
}); };
export var receiveWorkOrder = function (action) { return ({
    type: WORKORDER_SUCCESS,
    workOrder: action.workOrder,
}); };
export var errorWorkOrder = function (action) { return ({
    type: WORKORDER_FAILURE,
    errorMessage: action.errorMessage,
}); };
