export var POWER = 'POWER';
export var MIN_POWER = 'MIN_POWER';
export var MAX_POWER = 'MAX_POWER';
export var SNR = 'SNR';
export var MIN_SNR = 'MIN_SNR';
export var MAX_SNR = 'MAX_SNR';
export var CORRECTED = 'CORRECTED';
export var UNCORRECTABLE = 'UNCORRECTABLE';
export var DOWNSTREAM = 'DOWNSTREAM';
export var UPSTREAM = 'UPSTREAM';
