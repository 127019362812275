import { WORK_ORDER_HISTORY_REQUEST, WORK_ORDER_HISTORY_FAILURE, WORK_ORDER_HISTORY_SUCCESS, } from '../workOrderHistoryConstants';
export var requestWorkOrderHistory = function (action) { return ({
    type: WORK_ORDER_HISTORY_REQUEST,
    workOrderId: action.workOrderId,
}); };
export var receiveWorkOrderHistory = function (action) { return ({
    type: WORK_ORDER_HISTORY_SUCCESS,
    workOrderHistory: action.workOrderHistory,
}); };
export var errorWorkOrderHistory = function (action) { return ({
    type: WORK_ORDER_HISTORY_FAILURE,
    errorMessage: action.errorMessage,
}); };
