// Complete work order
export var COMPLETE_WORK_ORDER_WITH_ZENDESK = 'COMPLETE_WORK_ORDER_WITH_ZENDESK';
// Complete status change
export var COMPLETE_STATUS_CHANGE_REQUEST = 'COMPLETE_STATUS_CHANGE_REQUEST';
export var COMPLETE_STATUS_CHANGE_SUCCESS = 'COMPLETE_STATUS_CHANGE_SUCCESS';
export var COMPLETE_STATUS_CHANGE_FAILURE = 'COMPLETE_STATUS_CHANGE_FAILURE';
// Description
export var NO_ONE_HOME = 'Ingen Hjemme';
export var SERVICE_CALL = 'Serviceutrykning';
// Find services
export var FIND_SERVICES_REQUEST = 'FIND_SERVICES_REQUEST';
export var FIND_SERVICES_SUCCESS = 'FIND_SERVICES_SUCCESS';
export var FIND_SERVICES_ERROR = 'FIND_SERVICES_ERROR';
// Remove service
export var REMOVE_SERVICE_REQUEST = 'REMOVE_SERVICE_REQUEST';
export var REMOVE_SERVICE_ERROR = 'REMOVE_SERVICE_ERROR';
export var REMOVE_SERVICE_SUCCESS = 'REMOVE_SERVICE_SUCCESS';
// Update service
export var UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export var UPDATE_SERVICE_ERROR = 'UPDATE_SERVICE_ERROR';
export var UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
// Zendesk
export var ZENDESK_POST_REQUEST = 'ZENDESK_POST_REQUEST';
export var ZENDESK_POST_SUCCESS = 'ZENDESK_POST_SUCCESS';
export var ZENDESK_POST_ERROR = 'ZENDESK_POST_ERROR';
// Zendesk follow up
export var ZENDESK_FOLLOW_UP_POST_REQUEST = 'ZENDESK_FOLLOW_UP_POST_REQUEST';
export var ZENDESK_FOLLOW_UP_POST_SUCCESS = 'ZENDESK_FOLLOW_UP_POST_SUCCESS';
export var ZENDESK_FOLLOW_UP_POST_ERROR = 'ZENDESK_FOLLOW_UP_POST_ERROR';
export var SAVE_COMMENT = 'SAVE_COMMENT';
export var SAVE_FILE = 'SAVE_FILE';
export var CLEAR_SAVED_FILES = 'CLEAR_SAVED_FILES';
export var SAVE_FOLLOW_UP_FILE = 'SAVE_FOLLOW_UP_FILE';
export var SET_FOLLOW_UP = 'SET_FOLLOW_UP';
// Zendesk statuses
export var SOLVED = 'solved';
export var NEW = 'ny';
export var UPDATE_ACTION_TAKEN_FAILURE = 'UPDATE_ACTION_TAKEN_FAILURE';
// Follow up
export var CALLED_CUSTOMER = 'calledCustomer';
export var CONFIRMED_RIGHT_ADDRESS = 'confirmedRightAddress';
export var CUSTOMER_NOT_HOME = 'customerNotHome';
export var FOLLOW_UP = 'followUp';
export var RUNG_KNOCKED_DOOR = 'rungKnockedDoor';
export var COMMENT = 'comment';
export var AZIMUTH = 'azimuth';
// Checked categories
export var SET_CHECKED_CATEGORY = 'SET_CHECKED_CATEGORY';
export var CREATE_NEW_WORK_ORDER = 'CREATE_NEW_WORK_ORDER';
// WorkOrder servics
export var WORKORDER_SERVICES_REQUEST = 'WORKORDER_SERVICES_REQUEST';
export var WORKORDER_SERVICES_SUCCESS = 'WORKORDER_SERVICES_SUCCESS';
export var WORKORDER_SERVICES_ERROR = 'WORKORDER_SERVICES_ERROR';
