import React from 'react';
import PropTypes from 'prop-types';
import ClickOutHandler from 'react-onclickout';
import SelectSearch from 'react-select-search';
import { SlideDown } from 'react-slidedown';
import { ARROW_DOWN } from '../../constants/iconNames';
import Icon from '../../icons';
import { iconBlue } from '../../styles/variables.scss';
export default function QuantityDropdown(_a) {
    var amount = _a.amount, dropDownValues = _a.dropDownValues, selected = _a.selected, showDropdown = _a.showDropdown, toggleDropdown = _a.toggleDropdown, value = _a.value;
    return (React.createElement("div", { className: "quantity__dropdown" },
        React.createElement(ClickOutHandler, { onClickOut: showDropdown ? function () { return toggleDropdown(); } : function () { return function () { }; } },
            React.createElement("button", { onClick: toggleDropdown },
                amount,
                React.createElement("span", { className: showDropdown ? 'rotate-up' : 'rotate-down' },
                    React.createElement(Icon, { stroke: iconBlue, name: ARROW_DOWN }))),
            React.createElement(SlideDown, { className: "slidedown", transitionOnAppear: false, closed: !showDropdown },
                React.createElement(SelectSearch, { autofocus: true, onChange: selected, options: dropDownValues, value: value, search: false })))));
}
QuantityDropdown.propTypes = {
    toggleDropdown: PropTypes.func.isRequired,
    selected: PropTypes.func.isRequired,
    dropDownValues: PropTypes.arrayOf(PropTypes.any).isRequired,
    showDropdown: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
};
