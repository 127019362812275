import { COMPLETE_WITHOUT_CODE, CANCELLED, INCOMPLETE_WITHOUT_CODE, } from '../constants/status-codes';
export default function setStatusClassName(startedStatuses, filteredWorkOrderStatus, workOrder) {
    var className = 'workOrder__container__';
    if (filteredWorkOrderStatus.includes(INCOMPLETE_WITHOUT_CODE)) {
        className += 'incomplete';
    }
    else if (filteredWorkOrderStatus.includes(COMPLETE_WITHOUT_CODE)) {
        className += 'completed';
    }
    else if (filteredWorkOrderStatus === CANCELLED) {
        className += 'cancelled';
    }
    else if (startedStatuses.includes(workOrder.status)) {
        className += 'started';
    }
    else {
        className += 'normal';
    }
    return "".concat(className, " workOrder__container");
}
