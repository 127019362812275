export var NUMBERS_IN_LETTERS_NOB = [
    'ingen',
    'én',
    'to',
    'tre',
    'fire',
    'fem',
    'seks',
    'sju',
    'åtte',
    'ni',
    'ti',
    'elleve',
    'tolv',
    'tretten',
    'fjorten',
    'femten',
    'seksten',
    'sytten',
    'atten',
    'nitten',
    'tjue',
];
export var NUMBERS_IN_LETTERS_ENG = [
    'no',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
];
