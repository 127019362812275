import React from 'react';
import PropTypes from 'prop-types';
function InfoText(_a) {
    var text = _a.text;
    return React.createElement("div", { className: "info-text" }, text);
}
InfoText.propTypes = {
    text: PropTypes.string.isRequired,
};
export default InfoText;
