var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WorkOrderDetails from '../WorkOrderDetails';
import { TRAVEL_AND_WORK_DURATION } from '../../../../../constants/endpoints';
import { getAPI } from '../../../../../api/apiConfig';
export default function WorkOrderDetailsContainer(_a) {
    var done = _a.done, workOrder = _a.workOrder, totalTimeEstimate = _a.totalTimeEstimate, inWorkLog = _a.inWorkLog;
    var _b = __read(useState(0), 2), travelDuration = _b[0], setTravelDuration = _b[1];
    var _c = __read(useState(0), 2), workDuration = _c[0], setWorkDuration = _c[1];
    var _d = __read(useState(true), 2), isFetching = _d[0], setIsFetching = _d[1];
    var _e = __read(useState(false), 2), startTimeError = _e[0], setStartTimeError = _e[1];
    var requestTravelAndWorkDuration = function (workOrderId) {
        var url = TRAVEL_AND_WORK_DURATION(workOrderId);
        getAPI(url)
            .then(function (result) {
            setTravelDuration(result.travelDuration);
            setWorkDuration(result.workDuration);
            setIsFetching(false);
            return result;
        })
            .catch(function () {
            setStartTimeError(true);
            setIsFetching(false);
        });
    };
    useEffect(function () {
        requestTravelAndWorkDuration(workOrder.workOrderId);
    }, []);
    return (React.createElement("div", { className: "workOrder__details__wrapper" }, !startTimeError && (React.createElement(WorkOrderDetails, { isFetching: isFetching, done: done, workOrder: workOrder, totalTimeEstimate: totalTimeEstimate, travelDuration: travelDuration, workDuration: workDuration, inWorkLog: inWorkLog }))));
}
WorkOrderDetailsContainer.propTypes = {
    done: PropTypes.bool.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    totalTimeEstimate: PropTypes.number.isRequired,
    inWorkLog: PropTypes.bool.isRequired,
};
