import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var bodyFill = _a.bodyFill, coverColor = _a.coverColor, darkStroke = _a.darkStroke, darkestStroke = _a.darkestStroke, eyeFill = _a.eyeFill, handFill = _a.handFill, sweatFill = _a.sweatFill;
    return (React.createElement("svg", { width: "123px", height: "63px", viewBox: "0 0 123 63", version: "1.1" },
        React.createElement("defs", null,
            React.createElement("polygon", { id: "path-1", points: "0 0.0706 79 0.0706 79 10.0706 0 10.0706" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "icon/tilbakemelding_finner-ikke", transform: "translate(0.000000, -3.000000)" },
                React.createElement("g", { id: "Group-40", transform: "translate(20.000000, 5.000000)" },
                    React.createElement("g", { id: "Group-39", transform: "translate(0.000000, 0.929400)" },
                        React.createElement("path", { id: "Fill-6", fill: bodyFill, d: "M56.2496,62.7674 L21.4836,57.4754 C18.2216,56.9784 15.9586,53.9034 16.4546,50.6404 L22.1486,13.2384 C22.6456,9.9754 25.7216,7.7124 28.9836,8.2084 L63.7496,13.5014 C67.0126,13.9984 69.2746,17.0744 68.7786,20.3364 L63.0846,57.7394 C62.5876,61.0014 59.5126,63.2644 56.2496,62.7674" }),
                        React.createElement("path", { id: "Stroke-8", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M56.2496,62.7674 L21.4836,57.4754 C18.2216,56.9784 15.9586,53.9034 16.4546,50.6404 L22.1486,13.2384 C22.6456,9.9754 25.7216,7.7124 28.9836,8.2084 L63.7496,13.5014 C67.0126,13.9984 69.2746,17.0744 68.7786,20.3364 L63.0846,57.7394 C62.5876,61.0014 59.5126,63.2644 56.2496,62.7674 Z" }),
                        React.createElement("path", { id: "Fill-10", fill: eyeFill, d: "M56.5201,28.9901 C56.3681,29.9891 55.4351,30.6761 54.4361,30.5241 C53.4371,30.3721 52.7501,29.4381 52.9031,28.4401 C53.0551,27.4411 53.9881,26.7551 54.9871,26.9061 C55.9861,27.0581 56.6721,27.9921 56.5201,28.9901" }),
                        React.createElement("path", { id: "Fill-12", fill: eyeFill, d: "M34.9439,25.4764 C34.7839,26.4734 33.8449,27.1534 32.8469,26.9934 C31.8499,26.8324 31.1709,25.8944 31.3309,24.8974 C31.4909,23.8994 32.4289,23.2204 33.4269,23.3794 C34.4239,23.5394 35.1029,24.4784 34.9439,25.4764" }),
                        React.createElement("path", { id: "Fill-14", fill: sweatFill, d: "M66.484,19.9168 C66.367,20.6488 65.678,21.1468 64.946,21.0298 C64.213,20.9128 63.714,20.2238 63.832,19.4908 C63.95,18.7588 65.603,16.9278 65.603,16.9278 C65.603,16.9278 66.602,19.1848 66.484,19.9168" }),
                        React.createElement("path", { id: "Fill-16", fill: sweatFill, d: "M61.7486,21.8856 C61.6316,22.6176 60.9416,23.1156 60.2096,22.9986 C59.4776,22.8816 58.9786,22.1926 59.0966,21.4596 C59.2136,20.7276 60.8676,18.8966 60.8676,18.8966 C60.8676,18.8966 61.8656,21.1536 61.7486,21.8856" }),
                        React.createElement("path", { id: "Stroke-18", stroke: darkStroke, strokeWidth: "2", strokeLinecap: "round", d: "M34.3258,37.1951 C39.6948,33.3101 47.1968,34.5131 51.0828,39.8831" }),
                        React.createElement("g", { id: "Group-22", transform: "translate(3.000000, 57.000000)" },
                            React.createElement("mask", { id: "mask-2", fill: "white" }),
                            React.createElement("g", { id: "Clip-21" }),
                            React.createElement("path", { id: "Fill-20", fill: coverColor, d: "M79,0.0706 L79,9.26440845 C79,9.70942619 78.1095456,10.0706 77.0123786,10.0706 L1.9876214,10.0706 C0.889460575,10.0706 0,9.70942619 0,9.26440845 L0,0.0706" })),
                        React.createElement("g", { id: "Group-26", transform: "translate(2.000000, 56.000000)" },
                            React.createElement("path", { id: "Fill-23", fill: bodyFill, d: "M0.8707,1.1922 L80.3627,1.1922" }),
                            React.createElement("path", { id: "Stroke-25", d: "M0.8707,1.1922 L80.3627,1.1922", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })),
                        React.createElement("path", { id: "Fill-27", fill: handFill, d: "M21.1451,8.3211 C21.1451,8.3211 21.8421,2.5651 26.4761,0.6841 C31.1101,-1.1969 31.1021,1.6361 29.8371,3.9221 C28.6151,6.1301 24.5881,7.9601 21.1451,8.3211" }),
                        React.createElement("path", { id: "Stroke-29", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M21.1451,8.3211 C21.1451,8.3211 21.8421,2.5651 26.4761,0.6841 C31.1101,-1.1969 31.1021,1.6361 29.8371,3.9221 C28.6151,6.1301 24.5881,7.9601 21.1451,8.3211 Z" }),
                        React.createElement("path", { id: "Stroke-31", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M19.7066,25.6541 C19.7066,25.6541 -2.8414,28.3751 1.5586,18.4021 C2.8706,15.4271 21.2336,8.3211 21.2336,8.3211" }),
                        React.createElement("path", { id: "Fill-33", fill: handFill, d: "M71.5523,17.951 C71.5523,17.951 72.5993,12.249 68.7353,9.074 C64.8703,5.899 64.0353,8.607 64.5633,11.165 C65.0733,13.637 68.3723,16.582 71.5523,17.951" }),
                        React.createElement("path", { id: "Stroke-35", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M71.5523,17.951 C71.5523,17.951 72.5993,12.249 68.7353,9.074 C64.8703,5.899 64.0353,8.607 64.5633,11.165 C65.0733,13.637 68.3723,16.582 71.5523,17.951 Z" }),
                        React.createElement("path", { id: "Stroke-37", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M67.7681,34.9276 C67.7681,34.9276 88.4851,44.2346 87.2511,33.4046 C86.8841,30.1736 71.4681,17.9246 71.4681,17.9246" })))))));
};
SVG.propTypes = {
    bodyFill: PropTypes.string,
    coverColor: PropTypes.string,
    darkStroke: PropTypes.string,
    darkestStroke: PropTypes.string,
    eyeFill: PropTypes.string,
    handFill: PropTypes.string,
    sweatFill: PropTypes.string,
};
SVG.defaultProps = {
    bodyFill: Variables.whiteSecondary,
    coverColor: Variables.grayPrimary,
    darkStroke: Variables.feedbackDarkGray,
    darkestStroke: Variables.feedbackDarkestGray,
    eyeFill: Variables.blackSecondary,
    handFill: Variables.yellowOnBlack,
    sweatFill: Variables.blackSecondary,
};
export default SVG;
