var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { followUpFormsRequest } from './actions/followUpFormActions';
import SimpleDropdown from '../../components/Drowpdowns/SimpleDropdown';
import './follow-up.scss';
import FollowUpForm from './components/FollowUpForm';
import validateZendeskForm from '../../utils/validateZendeskForm';
import NOB from '../../constants/language-strings';
import { postZendeskFollowUpRequest, saveFollowUpFile } from '../WorkLog/actions/zendeskActions';
import zendeskBody from '../../utils/zendeskBody';
import { INPUT_TYPES } from './FollowUpConstans';
import { WORK_LOG_URL } from '../../constants/paths';
import SlideInPage from '../../components/SlideInPage/SlideInPage';
var FollowUpFormsContainer = /** @class */ (function (_super) {
    __extends(FollowUpFormsContainer, _super);
    function FollowUpFormsContainer() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            chosenForm: NOB.FOLLOW_UP.DEFAULT_DROPDOWN_VALUE,
            touched: false,
        };
        _this.getChosenForm = function () { return _this.props.forms.find(function (form) { return form.name === _this.state.chosenForm; }); };
        _this.handleFormChange = function (event) {
            _this.setState({ chosenForm: event.value, touched: false });
        };
        _this.handleSubmit = function (values) {
            var zendesk = {
                followUpFormValues: values,
                files: _this.props.followUp.files,
            };
            var body = zendeskBody(_this.props.workOrder, zendesk, false);
            _this.setState({ touched: true });
            if (validateZendeskForm(values, _this.getChosenForm())) {
                _this.props.postZendeskRequest(body);
                _this.props.push(WORK_LOG_URL(_this.props.workOrder.workOrderId));
            }
        };
        return _this;
    }
    FollowUpFormsContainer.prototype.componentDidMount = function () {
        this.props.requestForms();
    };
    FollowUpFormsContainer.prototype.render = function () {
        var _a = this.props.followUp, files = _a.files, isFetching = _a.isFetching;
        var formList = this.props.forms.map(function (form) { return ({ name: form.name, value: form.name }); });
        var chosenForm = this.getChosenForm();
        return (React.createElement(SlideInPage, { showLeftIcon: true, mainHeading: NOB.FOLLOW_UP.FOLLOW_UP, showRightIcon: false, isFetching: isFetching },
            React.createElement("div", { className: "followup-content-heading-label" },
                INPUT_TYPES.HEADING,
                " "),
            React.createElement(SimpleDropdown, { isWhite: true, values: formList, onChange: this.handleFormChange, title: this.state.chosenForm }),
            chosenForm && (React.createElement(FollowUpForm, { form: chosenForm, files: files, onSubmit: this.handleSubmit, isSubmitted: this.state.touched, saveFiles: this.props.saveFiles }))));
    };
    return FollowUpFormsContainer;
}(React.Component));
FollowUpFormsContainer.propTypes = {
    requestForms: PropTypes.func.isRequired,
    forms: PropTypes.arrayOf(PropTypes.any).isRequired,
    saveFiles: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    postZendeskRequest: PropTypes.func.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    followUp: PropTypes.objectOf(PropTypes.any).isRequired,
};
var mapStateToProps = function (state) { return ({
    followUp: state.zendesk.followUpForm,
    forms: state.followUpForms.forms,
    workOrder: state.workOrder.workOrder,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestForms: function () { return dispatch(followUpFormsRequest()); },
    postZendeskRequest: function (body) { return dispatch(postZendeskFollowUpRequest(body)); },
    saveFiles: function (files) { return dispatch(saveFollowUpFile(files)); },
    push: function (path) { return dispatch(push(path)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(FollowUpFormsContainer);
