import { NDS_MIKRO_SMARTCARD } from '../constants/elements';
function deviceSerialNumbersToString(devices) {
    var subHeading = null;
    var deviceIndex;
    if (devices.length > 0) {
        deviceIndex = devices.findIndex(function (device) { return !device.name.includes(NDS_MIKRO_SMARTCARD); });
        subHeading =
            devices.length < 3
                ? devices[deviceIndex].serialNumber
                : "".concat(devices[0].serialNumber, ", \n        ").concat(devices[1].serialNumber, ", \n        ").concat(devices[2].serialNumber);
    }
    return subHeading;
}
export default deviceSerialNumbersToString;
