import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';
import DeviceStatus from '../../constants/deviceStatus';
function Table(_a) {
    var columns = _a.columns, rows = _a.rows, columnValueTypes = _a.columnValueTypes;
    function colorPicker(status) {
        switch (status) {
            case DeviceStatus.OK:
                return 'td--green';
            case DeviceStatus.NEUTRAL:
                return '';
            case DeviceStatus.WARN:
                return 'td--warn';
            case DeviceStatus.ERROR:
                return 'td--error';
            default:
                return '';
        }
    }
    var columnNames = columns.filter(function (column) { return column !== ''; });
    return (React.createElement("div", { className: "table" },
        React.createElement("div", { className: "tr" }, columnNames.map(function (column) { return (React.createElement("div", { className: "th", key: column }, column)); })),
        rows.map(function (row) { return (React.createElement("div", { className: "tr", key: row.name },
            React.createElement("span", { className: "td" }, row.name),
            columnValueTypes.map(function (type) {
                var propertyValue = row.propertyValues.find(function (value) { return value.type === type; });
                if (propertyValue) {
                    return (React.createElement("div", { className: "td", key: propertyValue },
                        React.createElement("div", null,
                            React.createElement("span", { className: colorPicker(propertyValue.value.first.valueStatus) }, propertyValue.value.first.value),
                            propertyValue.value.second.value && (React.createElement("span", null,
                                React.createElement("span", null, " / "),
                                React.createElement("span", { className: colorPicker(propertyValue.value.second.valueStatus) }, propertyValue.value.second.value))))));
                }
                return React.createElement("div", { className: "td", key: propertyValue });
            }))); })));
}
Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        propertyValues: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            value: PropTypes.objectOf(PropTypes.shape({
                first: PropTypes.objectOf(PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    valueStatus: PropTypes.string.isRequired,
                })),
                second: PropTypes.objectOf(PropTypes.shape({
                    value: PropTypes.string.isRequired,
                    valueStatus: PropTypes.string.isRequired,
                })),
            })).isRequired,
        })).isRequired,
    })).isRequired,
    columnValueTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default Table;
