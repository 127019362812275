import { WORKORDER_LIST_REQUEST, WORKORDER_LIST_SUCCESS, WORKORDER_LIST_FAILURE, END_DAY, EMPTY_WORKORDER_LIST, WORKORDER_STATUS_UPDATE, PREVIOUS_WORKORDER_LIST_SUCCESS, PREVIOUS_WORKORDER_LIST_REQUEST, PREVIOUS_WORKORDER_LIST_FAILURE, WORKORDER_LIST_CANCEL, } from '../workOrderListConstants';
export var requestWorkOrderList = function () { return ({
    type: WORKORDER_LIST_REQUEST,
}); };
export var receiveWorkOrderList = function (_a) {
    var workOrders = _a.workOrders;
    return ({
        type: WORKORDER_LIST_SUCCESS,
        workOrders: workOrders,
    });
};
export var errorWorkOrderList = function (errorMessage) { return ({
    type: WORKORDER_LIST_FAILURE,
    errorMessage: errorMessage,
}); };
export var requestPrevWorkOrderList = function (pageNumber) { return ({
    type: PREVIOUS_WORKORDER_LIST_REQUEST,
    pageNumber: pageNumber,
}); };
export var receivePrevWorkOrderList = function (workOrderRequest) { return ({
    type: PREVIOUS_WORKORDER_LIST_SUCCESS,
    workOrderRequest: workOrderRequest,
}); };
export var errorPrevWorkOrderList = function (errorMessage) { return ({
    type: PREVIOUS_WORKORDER_LIST_FAILURE,
    errorMessage: errorMessage,
}); };
export var endDayFunction = function (_a) {
    var endDay = _a.endDay;
    return ({
        type: END_DAY,
        endDay: endDay,
    });
};
export var emptyWorkOrderList = function () { return ({
    type: EMPTY_WORKORDER_LIST,
}); };
export var updateSingleWorkOrderStatus = function (newStatus, workOrderId) { return ({
    type: WORKORDER_STATUS_UPDATE,
    newStatus: newStatus,
    workOrderId: workOrderId,
}); };
export var workOrderListCancel = function () { return ({
    type: WORKORDER_LIST_CANCEL,
}); };
