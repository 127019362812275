var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background: #000000b3;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 99999;\n"], ["\n  align-items: center;\n  background: #000000b3;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  z-index: 99999;\n"])));
var ModalBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 520px;\n  padding: 32px;\n  margin: 12px;\n  background: #fff;\n  border-radius: 5px;\n  font-size: 18px;\n  line-height: 1.5;\n"], ["\n  width: 100%;\n  max-width: 520px;\n  padding: 32px;\n  margin: 12px;\n  background: #fff;\n  border-radius: 5px;\n  font-size: 18px;\n  line-height: 1.5;\n"])));
var StyledLink = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #5e0092;\n  text-decoration: underline;\n\n  &:hover,\n  &:active {\n    background-color: #fbf0ff;\n  }\n"], ["\n  color: #5e0092;\n  text-decoration: underline;\n\n  &:hover,\n  &:active {\n    background-color: #fbf0ff;\n  }\n"])));
var ModalActions = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
var baseButtonStyle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: auto;\n  padding: 16px 24px;\n  margin: 20px 0 0 8px;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 16px;\n  border-radius: 99px;\n  outline: 0;\n  transition: all 150ms ease;\n  border-style: solid;\n  border-width: 1px;\n  color: #fff;\n"], ["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: auto;\n  padding: 16px 24px;\n  margin: 20px 0 0 8px;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 16px;\n  border-radius: 99px;\n  outline: 0;\n  transition: all 150ms ease;\n  border-style: solid;\n  border-width: 1px;\n  color: #fff;\n"])));
var ButtonLink = styled.a(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: #29003e;\n  border-color: #29003e;\n  ", "\n\n  &:hover {\n    background: #5e0092;\n    color: #fff;\n  }\n"], ["\n  background-color: #29003e;\n  border-color: #29003e;\n  ", "\n\n  &:hover {\n    background: #5e0092;\n    color: #fff;\n  }\n"])), baseButtonStyle);
var RedirectionModal = function (_a) {
    var hostname = _a.hostname;
    var AWS_URL = "https://".concat(hostname);
    return (React.createElement(Container, null,
        React.createElement(ModalBody, null,
            React.createElement("div", null,
                React.createElement("h2", null, "Viktig informasjon"),
                "HomeService vil bytte til ny URL: ",
                React.createElement(StyledLink, { href: AWS_URL }, AWS_URL),
                ". Den gamle adressen vil bli deaktivert. Ved sp\u00F8rsm\u00E5l, ta kontakt med Field Service Manager: Sara Johnsen -",
                ' ',
                React.createElement(StyledLink, { mailto: "sara.johnsen@telia.no" }, "sara.johnsen@telia.no"),
                "."),
            React.createElement(ModalActions, null,
                React.createElement(ButtonLink, { href: AWS_URL }, "Go to new URL address")))));
};
RedirectionModal.propTypes = {
    hostname: PropTypes.string.isRequired,
};
export default RedirectionModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
