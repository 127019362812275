import { AGREEMENTS_FAILURE, AGREEMENTS_REQUEST, AGREEMENTS_SUCCESS, } from '../customerProductsConstants';
export var requestAgreements = function (_a) {
    var customerId = _a.customerId;
    return ({
        type: AGREEMENTS_REQUEST,
        customerId: customerId,
    });
};
export var receiveAgreements = function (_a) {
    var agreements = _a.agreements;
    return ({
        type: AGREEMENTS_SUCCESS,
        agreements: agreements,
    });
};
export var errorAgreements = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: AGREEMENTS_FAILURE,
        errorMessage: errorMessage,
    });
};
