import { connect } from 'react-redux';
import { requestDevSignIn } from '../Authentication/actions';
import { AuthenticationDev } from './authentication-dev';
var mapStateToProps = function (state) { return ({
    signInError: state.authentication.signInError,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestDevSignIn: function (credentials) { return dispatch(requestDevSignIn(credentials)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationDev);
