export var FRONT_PAGE_PATH = '/';
export var QA = 'qa';
export var SIGN_OUT_PATH = '/signout';
export var WORK_ORDER_ID_PATH = '/workOrder/:id';
export var CUSTOMER_INFO_PATH = '/customerInfo/:customerId';
export var ADDRESS_SEARCH = '/address-search';
export var hashes = {
    customerProducts: '#customerProducts',
    addressHistory: '#addressHistory',
    workOrderHistory: '#workOrderHistory',
    healthCheck: '#healthCheck',
    followUp: '#followUp',
    notHome: '#nothome',
};
export var WORK_LOG_PATH = "".concat(WORK_ORDER_ID_PATH, "/workLog");
export var ADDRESS_HISTORY_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "#addressHistory"); };
export var WORK_ORDER_HISTORY_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "#workOrderHistory"); };
export var CUSTOMER_PRODUCTS_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "#customerProducts"); };
export var HEALTH_CHECK_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "#healthCheck"); };
export var WORK_LOG_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "/workLog"); };
export var FOLLOW_UP_CUSTOMER_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "/workLog#followUp"); };
export var NEW_WORK_ORDER_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId, "/workLog#newWorkOrder"); };
export var NOT_HOME_URL = function (workOrderId) { return "/workOrder/".concat(workOrderId).concat(hashes.notHome); };
export var CUSTOMER_INFO_URL = function (customerId) { return "/customerInfo/".concat(customerId); };
export var CUSTOMER_INFO_ADDRESS_HISTORY_URL = function (customerId) {
    return "/customerInfo/".concat(customerId, "#addressHistory");
};
export var CUSTOMER_INFO_CUSTOMER_PRODUCTS_URL = function (customerId) {
    return "/customerInfo/".concat(customerId, "#customerProducts");
};
export var CUSTOMER_INFO_HEALTH_CHECK_URL = function (customerId) {
    return "/customerInfo/".concat(customerId, "#healthCheck");
};
