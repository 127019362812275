var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LINK_PRODUCT_REQUEST, LINK_PRODUCT_SUCCESS, LINK_PRODUCT_FAILURE, LINK_SINGLE_PRODUCT_REQUEST, } from '../customerProductsConstants';
var linkProductReducerDefaultState = {
    errorMessage: '',
    isFetching: {
        isFetchingSingleDpad: false,
        dpadId: 0,
    },
};
export default (function (state, _a) {
    if (state === void 0) { state = linkProductReducerDefaultState; }
    var error = _a.error, type = _a.type, dpadId = _a.dpadId, product = _a.product;
    switch (type) {
        case LINK_PRODUCT_REQUEST:
            return {
                errorMessage: linkProductReducerDefaultState.errorMessage,
                isFetching: {
                    isFetchingSingleDpad: true,
                    dpadId: dpadId,
                },
            };
        case LINK_SINGLE_PRODUCT_REQUEST:
            return {
                errorMessage: linkProductReducerDefaultState.errorMessage,
                isFetching: {
                    isFetchingSingleDpad: true,
                    dpadId: dpadId,
                },
            };
        case LINK_PRODUCT_SUCCESS:
            return __assign(__assign({}, state), { customerProducts: __assign(__assign({}, state.customerProducts), { customerProducts: __assign(__assign({}, state.customerProducts), { product: product }) }), isFetching: {
                    isFetchingSingleDpad: false,
                    dpadId: 0,
                } });
        case LINK_PRODUCT_FAILURE:
            return __assign(__assign({}, state), { errorMessage: error, isFetching: {
                    isFetchingSingleDpad: false,
                    dpadId: 0,
                } });
        default:
            return state;
    }
});
