import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NOB from '../../../../constants/language-strings';
import './notHome.scss';
import WorkOrderCompletionFormContainer from '../../../WorkOrderCompletionForm/containers/WorkOrderCompletionFormContainer';
import SlideInPage from '../../../../components/SlideInPage/SlideInPage';
import { requestStatusChange } from '../../actions/workOrder';
import { TECHNICIAN } from '../../../Authentication/constants';
import { TRAVELING_STATUS } from '../../../../constants/status-codes';
var NotHome = function (_a) {
    var statusChangeRequest = _a.statusChangeRequest, role = _a.role, workOrder = _a.workOrder.workOrder;
    useEffect(function () {
        if (role === TECHNICIAN && workOrder.status === TRAVELING_STATUS) {
            statusChangeRequest(workOrder.status);
        }
    }, [workOrder.status]);
    return (React.createElement(SlideInPage, { mainHeading: NOB.CUSTOMER_NOT_HOME.CUSTOMER_NOT_HOME, showLeftIcon: true },
        React.createElement("div", { className: "not_home" },
            React.createElement(WorkOrderCompletionFormContainer, { customerNotHome: true }))));
};
NotHome.propTypes = {
    statusChangeRequest: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};
var mapStateToProps = function (state) { return ({
    role: state.authentication.role,
    workOrder: state.workOrder,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    statusChangeRequest: function (currentStatus) { return dispatch(requestStatusChange(currentStatus)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(NotHome);
