var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ACTIVE } from '../../../../constants/productStatuses';
import useForm from '../../../../utils/customHooks/useForm';
import { ACTIVATE_PRODUCT, ACTIVATE_AGREEMENT_DETAIL, REMOVE_PRODUCT, } from '../../../../constants/endpoints';
import { removeProductSuccess } from '../../actions/activateProduct';
import { putAPI, deleteAPI } from '../../../../api/apiConfig';
import DeviceScannerContainer from '../../../DeviceScanner/DeviceActivationScannerContainer';
import { updateSingleProduct, requestCustomerProductsBackground, } from '../../actions/customerProducts';
import SingleProductContainer from '../SingleProduct/SingleProductContainer';
import ProductListElement from '../../../../components/ProductListElement/ProductListElement';
import Popup from '../../../../components/Popup/containers/Popup';
import { requestAgreementsPrice } from '../../actions/agreementPriceActions';
function ProductListElementContainer(_a) {
    var _this = this;
    var product = _a.product, updateProduct = _a.updateProduct, deleteProduct = _a.deleteProduct, languageStrings = _a.languageStrings, updateAllProducts = _a.updateAllProducts, requestAgreementPrice = _a.requestAgreementPrice, customerId = _a.customerId;
    var canBeRemoved = product.canBeRemoved, devices = product.devices, linkOptions = product.linkOptions, name = product.name, status = product.status, productId = product.productId;
    var _b = __read(useState(false), 2), isFetching = _b[0], setIsFetching = _b[1];
    var _c = __read(useState([]), 2), errorMessages = _c[0], setErrorMessages = _c[1];
    var _d = __read(useState(false), 2), showScanner = _d[0], setShowScanner = _d[1];
    var _e = __read(useState(''), 2), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = __read(useState(false), 2), showProductFullscreen = _f[0], setShowProductFullscreen = _f[1];
    var _g = __read(useState(false), 2), activatedAnimation = _g[0], setActivatedAnimation = _g[1];
    var _h = __read(useState(false), 2), showDeleteModal = _h[0], setShowDeleteModal = _h[1];
    var activateProduct = function (requestBody) { return __awaiter(_this, void 0, void 0, function () {
        var result, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsFetching(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, putAPI(ACTIVATE_PRODUCT(productId), requestBody)];
                case 2:
                    result = _b.sent();
                    updateAllProducts(customerId);
                    updateProduct(result);
                    setActivatedAnimation(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    setIsFetching(false);
                    setErrorMessages((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.data) !== null && _a !== void 0 ? _a : []);
                    return [3 /*break*/, 4];
                case 4:
                    setIsFetching(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsFetching(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, deleteAPI(REMOVE_PRODUCT(productId))];
                case 2:
                    _a.sent();
                    updateAllProducts(customerId);
                    requestAgreementPrice();
                    deleteProduct(productId);
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    setIsFetching(false);
                    setErrorMessage(error_2.data.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onActivate = function () { return __awaiter(_this, void 0, void 0, function () {
        var result, error_3;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsFetching(true);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, putAPI(ACTIVATE_AGREEMENT_DETAIL(productId))];
                case 2:
                    result = _d.sent();
                    updateAllProducts(customerId);
                    updateProduct(result);
                    setActivatedAnimation(true);
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _d.sent();
                    setIsFetching(false);
                    setErrorMessage((_c = (_b = (_a = error_3.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : error_3 === null || error_3 === void 0 ? void 0 : error_3.message) !== null && _c !== void 0 ? _c : 'Unexpected error. Check network connection and try again.');
                    return [3 /*break*/, 4];
                case 4:
                    setIsFetching(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onSubmit = function (formValues) {
        // Only try to activate when all serialNumbers are present
        if (devices.filter(function (_a) {
            var serialNumber = _a.serialNumber;
            return !serialNumber;
        }).length !==
            Object.values(formValues).filter(function (value) { return value; }).length)
            return;
        var requestBody = Object.entries(formValues).map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return ({
                dpadId: key,
                serialNumbers: [value],
            });
        });
        activateProduct(requestBody);
    };
    var _j = useForm(function () {
        onSubmit(values);
    }), values = _j.values, handleChange = _j.handleChange, handleSubmit = _j.handleSubmit, overrideValue = _j.overrideValue;
    var handleInputChange = function (e) {
        e.persist();
        setErrorMessages(errorMessages.filter(function (message) { return message.dpadId !== parseInt(e.target.name, 10); }));
        handleChange(e);
    };
    var onReset = function (dpadId) {
        overrideValue("".concat(dpadId), '');
        setErrorMessages(errorMessages.filter(function (message) { return message.dpadId !== dpadId; }));
    };
    var onSerialNumberFound = function (serialNumbers, errors) {
        serialNumbers.forEach(function (serialNumber, index) {
            return overrideValue(devices[index].dpadId, serialNumber.serialNumber);
        });
        setErrorMessages(errors);
        if (errors.data && errors.data.message)
            setErrorMessage(errors.data.message);
    };
    var missingSerialNumbers = devices
        .filter(function (_a) {
        var serialNumber = _a.serialNumber;
        return !serialNumber;
    })
        .map(function (_a) {
        var dpadId = _a.dpadId;
        return ({
            dpadId: dpadId,
            value: values[dpadId],
            errorMessage: errorMessages.find(function (dpad) { return dpad.dpadId === dpadId; })
                ? errorMessages.find(function (dpad) { return dpad.dpadId === dpadId; }).errorMessage
                : '',
        });
    });
    var clickable = status === ACTIVE && (devices.length > 0 || linkOptions.length > 0);
    var canBeActivated = status !== ACTIVE && !missingSerialNumbers.length;
    return (React.createElement("div", null,
        React.createElement(ProductListElement, { clickable: clickable, canBeActivated: canBeActivated, serialNumberInputs: missingSerialNumbers, onClick: function () { return setShowProductFullscreen(true); }, handleChange: handleInputChange, handleSubmit: handleSubmit, isFetching: isFetching, shouldActivateActiveAnimation: activatedAnimation, title: name, status: status, onActivate: onActivate, onDelete: function () { return setShowDeleteModal(true); }, errorMessage: errorMessage, showDeleteButton: canBeRemoved && !clickable, onScannerClick: function () { return setShowScanner(true); }, serialNumbers: devices.map(function (device) { return device.serialNumber; }), onReset: onReset, showLinkIcon: linkOptions.length > 0 }),
        showScanner && (React.createElement(DeviceScannerContainer, { onClose: function () { return setShowScanner(false); }, dpadIdsToActivate: missingSerialNumbers.map(function (device) { return device.dpadId; }), productId: productId, closeScanner: function () { return setShowScanner(false); }, onActivation: function () { return setActivatedAnimation(true); }, onSerialNumberFound: onSerialNumberFound })),
        React.createElement(SingleProductContainer, { onDelete: onDelete, product: product, close: function () { return setShowProductFullscreen(false); }, display: showProductFullscreen }),
        showDeleteModal && (React.createElement(Popup, { firstLine: languageStrings.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT, secondLine: languageStrings.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT_QUESTION, closePopup: function () { return setShowDeleteModal(false); }, 
            // TODO: dette er copy-pastet ved refaktorering, Popup komponenten bør refaktorers slik at knapper ikke lages her.
            // Eventuelt skifte navn på propsene slik at de gir mer mening
            button: React.createElement("div", { className: "popup__remove-buttons" },
                React.createElement("button", { onClick: function () { return setShowDeleteModal(false); } }, languageStrings.REMOVE_PRODUCT_POPUP.CANCEL),
                React.createElement("button", { onClick: function () {
                        onDelete();
                        setShowDeleteModal(false);
                    } }, languageStrings.REMOVE_PRODUCT_POPUP.REMOVE)) }))));
}
ProductListElementContainer.propTypes = {
    product: PropTypes.shape({
        canBeRemoved: PropTypes.bool.isRequired,
        devices: PropTypes.arrayOf(PropTypes.any).isRequired,
        linkOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        productId: PropTypes.number.isRequired,
    }).isRequired,
    updateProduct: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    languageStrings: PropTypes.objectOf(PropTypes.any).isRequired,
    updateAllProducts: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    requestAgreementPrice: PropTypes.func.isRequired,
};
var mapStateToProps = function (state) { return ({
    languageStrings: state.NOB,
    customerId: state.workOrder.workOrder.customer.customerId,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    updateProduct: function (newProduct) { return dispatch(updateSingleProduct(newProduct)); },
    deleteProduct: function (deletedAgreementDetail) { return dispatch(removeProductSuccess(deletedAgreementDetail)); },
    updateAllProducts: function (customerId) { return dispatch(requestCustomerProductsBackground(customerId)); },
    requestAgreementPrice: function () { return dispatch(requestAgreementsPrice()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ProductListElementContainer);
