import { DEVICE_POLLING_MAC_REQUEST, DEVICE_POLLING_MAC_RECEIVE, DEVICE_POLLING_MAC_ERROR, UPDATE_DEVICE_POLLING_MAC_REQUEST, UPDATE_DEVICE_POLLING_MAC_RECEIVE, UPDATE_DEVICE_POLLING_MAC_ERROR, } from '../constants';
export var requestDevicePollingMac = function (pollUrl) { return ({
    type: DEVICE_POLLING_MAC_REQUEST,
    pollUrl: pollUrl,
}); };
export var receiveDevicePollingMac = function (_a) {
    var devicePollingMac = _a.devicePollingMac;
    return ({
        type: DEVICE_POLLING_MAC_RECEIVE,
        devicePollingMac: devicePollingMac,
    });
};
export var errorDevicePollingMac = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: DEVICE_POLLING_MAC_ERROR,
        errorMessage: errorMessage,
    });
};
export var requestUpdateDevicePollingMac = function (_a) {
    var macAddress = _a.macAddress;
    return ({
        type: UPDATE_DEVICE_POLLING_MAC_REQUEST,
        macAddress: macAddress,
    });
};
export var receiveUpdateDevicePollingMac = function (_a) {
    var devicePollingMac = _a.devicePollingMac;
    return ({
        type: UPDATE_DEVICE_POLLING_MAC_RECEIVE,
        devicePollingMac: devicePollingMac,
    });
};
export var errorUpdateDevicePollingMac = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: UPDATE_DEVICE_POLLING_MAC_ERROR,
        errorMessage: errorMessage,
    });
};
