var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AGREEMENTS_FAILURE, AGREEMENTS_REQUEST, AGREEMENTS_SUCCESS, AGREEMENTS_PRICE_FAILURE, AGREEMENTS_PRICE_SUCCESS, AGREEMENTS_PRICE_REQUEST, } from '../customerProductsConstants';
var customerProductsReducerDefaultState = {
    agreements: [],
    customerProducts: [],
    errorMessage: '',
    isFetching: true,
    selectedAgreementId: 0,
    isFetchingPrices: false,
};
export default (function (state, action) {
    if (state === void 0) { state = customerProductsReducerDefaultState; }
    var agreements = action.agreements, errorMessage = action.errorMessage, type = action.type;
    switch (type) {
        case AGREEMENTS_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case AGREEMENTS_SUCCESS:
            return __assign(__assign({}, state), { agreements: agreements, isFetching: false });
        case AGREEMENTS_FAILURE:
            return __assign(__assign({}, state), { errorMessage: errorMessage, isFetching: false });
        case AGREEMENTS_PRICE_REQUEST:
            return __assign(__assign({}, state), { isFetchingPrices: true, isFetching: false });
        case AGREEMENTS_PRICE_SUCCESS:
            return __assign(__assign({}, state), { agreements: agreements, isFetchingPrices: false });
        case AGREEMENTS_PRICE_FAILURE:
            return __assign(__assign({}, state), { errorMessage: errorMessage, isFetchingPrices: false });
        default:
            return state;
    }
});
