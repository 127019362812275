import { WORKORDER_SERVICES_REQUEST, WORKORDER_SERVICES_SUCCESS, WORKORDER_SERVICES_ERROR, } from '../workLogConstants';
export var workorderServicesRequest = function (workOrderId) { return ({
    type: WORKORDER_SERVICES_REQUEST,
    workOrderId: workOrderId,
}); };
export var workorderServicesSuccess = function (_a) {
    var services = _a.services;
    return ({
        type: WORKORDER_SERVICES_SUCCESS,
        services: services,
    });
};
export var workorderServicesError = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: WORKORDER_SERVICES_ERROR,
        errorMessage: errorMessage,
    });
};
