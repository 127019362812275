// Paths
export var CANCEL = '/cancel';
export var STARTDRIVE = '/startdrive';
export var STARTWORK = '/startwork';
// Status
export var STATUS_CHANGE_CHECK_SKIP = 'STATUS_CHANGE_CHECK_SKIP';
export var STATUS_CHANGE_REQUEST = 'STATUS_CHANGE_REQUEST';
export var STATUS_CHANGE_SUCCESS = 'STATUS_CHANGE_SUCCESS';
export var STATUS_CHANGE_FAILURE = 'STATUS_CHANGE_FAILURE';
// Work order
export var WORKORDER_REQUEST = 'WORKORDER_REQUEST';
export var WORKORDER_REQUEST_BY_CUSTOMER_ID = 'WORKORDER_REQUEST_BY_CUSTOMER_ID';
export var WORKORDER_SUCCESS = 'WORKORDER_SUCCESS';
export var WORKORDER_FAILURE = 'WORKORDER_FAILURE';
