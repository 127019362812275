var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AddFiles from '../../../components/addFiles/addFiles';
import { TECHNICIAN } from '../../Authentication/constants';
import NOB from '../../../constants/language-strings';
import useForm from '../../../utils/customHooks/useForm';
import { FOLLOW_UP, CUSTOMER_NOT_HOME, COMMENT, CONFIRMED_RIGHT_ADDRESS, RUNG_KNOCKED_DOOR, CALLED_CUSTOMER, AZIMUTH, } from '../../WorkLog/workLogConstants';
import '../workOrderCompletionForm.scss';
import zendeskBody from '../../../utils/zendeskBody';
import completeWorkOrderWithZendesk from '../../WorkLog/actions/completeWorkOrderActions';
import { COMPLETE_WITHOUT_CODE, INCOMPLETE_WITHOUT_CODE, INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE, } from '../../../constants/status-codes';
import { whitePrimary } from '../../../styles/variables.scss';
import FetchingSpinnerWithOverlay from '../../../components/FetchingSpinnerWithOverlay/FetchingSpinnerWithOverlay';
import WorkOrderCompletionFields from '../components/WorkOrderCompletionFields';
import WorkOrderCompletionButtons from '../components/WorkOrderCompletionButtons';
import WorkOrderCompletionNavigationButtons from './WorkOrderCompletionNavigationButtons';
import formValidator from '../formValidator';
import Platform from '../../../constants/platform';
import FetchingSpinner from '../../../utils/FetchingSpinner';
function WorkOrderCompletionFormContainer(_a) {
    var _b, _c;
    var customerNotHome = _a.customerNotHome, isCompletingWorkOrder = _a.isCompletingWorkOrder, role = _a.role, requestCompleteWorkOrder = _a.requestCompleteWorkOrder, workOrder = _a.workOrder, workOrderServices = _a.workOrderServices, customerProducts = _a.customerProducts;
    var _d = __read(useState([]), 2), files = _d[0], setFiles = _d[1];
    var _e = __read(useState(''), 2), azimuthErrorMessage = _e[0], setAzimuthErrorMessage = _e[1];
    var technician = role === TECHNICIAN;
    var addedServiceCodes = workOrderServices.filter(function (item) { return !item.name.includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS); });
    var _f = useForm(), values = _f.values, handleChange = _f.handleChange, overrideValue = _f.overrideValue, overrideValues = _f.overrideValues;
    var handleChangeValidated = function (event) {
        handleChange(event);
        formValidator(event.target.name, event.target.value, setAzimuthErrorMessage);
    };
    var onSubmit = function (isComplete) {
        var zendeskBodyParams = zendeskBody(workOrder, customerProducts.customerProducts, {
            commentBody: values[COMMENT],
            files: files,
            followUp: values[FOLLOW_UP],
        }, isComplete);
        var completeStatus;
        if (values[CUSTOMER_NOT_HOME])
            completeStatus = INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE;
        else if (isComplete)
            completeStatus = COMPLETE_WITHOUT_CODE;
        else
            completeStatus = INCOMPLETE_WITHOUT_CODE;
        requestCompleteWorkOrder(zendeskBodyParams, completeStatus, workOrder.workOrderId, values[AZIMUTH]);
    };
    useEffect(function () {
        overrideValue(CUSTOMER_NOT_HOME, customerNotHome);
    }, []);
    var nameAndValue = function (name, value) { return ({ name: name, value: value }); };
    var overriddenHandleChange = function (event) {
        if (!values[CUSTOMER_NOT_HOME] &&
            (values[CONFIRMED_RIGHT_ADDRESS] || values[RUNG_KNOCKED_DOOR] || values[CALLED_CUSTOMER])) {
            overrideValues(nameAndValue(CALLED_CUSTOMER, false), nameAndValue(CONFIRMED_RIGHT_ADDRESS, false), nameAndValue(RUNG_KNOCKED_DOOR, false), nameAndValue(CUSTOMER_NOT_HOME, true));
        }
        else
            handleChangeValidated(event);
    };
    var disableCompleteButtons = customerNotHome || values[CUSTOMER_NOT_HOME]
        ? !values[COMMENT] ||
            !values[CONFIRMED_RIGHT_ADDRESS] ||
            !values[RUNG_KNOCKED_DOOR] ||
            !values[CALLED_CUSTOMER] ||
            !files.length ||
            !values[CUSTOMER_NOT_HOME]
        : !values[COMMENT] || !addedServiceCodes.length;
    var disableNotCompleteButton = values[FOLLOW_UP] ||
        (values[CUSTOMER_NOT_HOME] &&
            !(values[CONFIRMED_RIGHT_ADDRESS] && values[RUNG_KNOCKED_DOOR] && values[CALLED_CUSTOMER]));
    var disableCompleteButton = workOrderServices.length === 0 ||
        customerNotHome ||
        values[CUSTOMER_NOT_HOME] ||
        azimuthErrorMessage !== '';
    // TODO: hot fix: due network components we cannot known if azimuth is required
    // removed condition:   || (!values[AZIMUTH] && workOrder.address.platform === Platform.FWA);
    var isWorkOrderLoading = workOrder === undefined || workOrder === null || (workOrder === null || workOrder === void 0 ? void 0 : workOrder.workOrderId) === 0;
    if (isWorkOrderLoading) {
        return (React.createElement("div", { className: "workorder-completion-wrapper" },
            React.createElement(FetchingSpinner, { color: whitePrimary, size: 60 })));
    }
    return (React.createElement("div", { className: "workorder-completion-wrapper" },
        isCompletingWorkOrder && React.createElement(FetchingSpinnerWithOverlay, { color: whitePrimary, size: 60 }),
        React.createElement(WorkOrderCompletionFields, { handleChange: overriddenHandleChange, values: values, technician: technician, azimuthErrorMessage: azimuthErrorMessage, azimuthProposed: ((_b = workOrder.technicalDetails) === null || _b === void 0 ? void 0 : _b.Azimuth_proposed) || '', isFWA: ((_c = workOrder.address) === null || _c === void 0 ? void 0 : _c.platform) === Platform.FWA }),
        React.createElement(AddFiles, { saveFiles: setFiles, files: files }),
        technician && !customerNotHome && (React.createElement(WorkOrderCompletionNavigationButtons, { technician: technician, customerNotHome: customerNotHome })),
        React.createElement(WorkOrderCompletionButtons, { disabled: technician ? disableCompleteButtons : !values[COMMENT], handleSubmit: onSubmit, technician: technician, values: values, disableCompleteButton: disableCompleteButton, disableNotCompleteButton: disableNotCompleteButton })));
}
WorkOrderCompletionFormContainer.propTypes = {
    customerNotHome: PropTypes.bool,
    isCompletingWorkOrder: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    requestCompleteWorkOrder: PropTypes.func.isRequired,
    workOrderServices: PropTypes.arrayOf(PropTypes.any).isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    customerProducts: PropTypes.objectOf(PropTypes.any),
};
WorkOrderCompletionFormContainer.defaultProps = {
    customerNotHome: false,
};
var mapStateToProps = function (state) { return ({
    isCompletingWorkOrder: state.workOrderServices.isCompletingWorkOrder,
    role: state.authentication.role,
    workOrder: state.workOrder.workOrder,
    workOrderServices: state.workOrderServices.orderedItems.itemSummary,
    customerProducts: state.customerProducts,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestCompleteWorkOrder: function (body, status, workOrderId, azimuth) {
        return dispatch(completeWorkOrderWithZendesk(body, status, workOrderId, azimuth));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderCompletionFormContainer);
