import { ZENDESK_POST_REQUEST, ZENDESK_POST_SUCCESS, ZENDESK_POST_ERROR, SAVE_COMMENT, SET_FOLLOW_UP, ZENDESK_FOLLOW_UP_POST_REQUEST, ZENDESK_FOLLOW_UP_POST_SUCCESS, SAVE_FOLLOW_UP_FILE, SAVE_FILE, CLEAR_SAVED_FILES, } from '../workLogConstants';
export var postZendeskRequest = function (_a) {
    var body = _a.body, status = _a.status, workOrder = _a.workOrder;
    return ({
        type: ZENDESK_POST_REQUEST,
        body: body,
        status: status,
        workOrder: workOrder,
    });
};
export var postZendeskSuccess = function (_a) {
    var zendesk = _a.zendesk;
    return ({
        type: ZENDESK_POST_SUCCESS,
        zendesk: zendesk,
    });
};
export var postZendeskError = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: ZENDESK_POST_ERROR,
        errorMessage: errorMessage,
    });
};
export var saveComment = function (commentBody) { return ({
    type: SAVE_COMMENT,
    commentBody: commentBody,
}); };
export var saveFile = function (files) { return ({
    type: SAVE_FILE,
    files: files,
}); };
export var saveFollowUpFile = function (files) { return ({
    type: SAVE_FOLLOW_UP_FILE,
    files: files,
}); };
export var clearSavedFiles = function () { return ({
    type: CLEAR_SAVED_FILES,
}); };
export var setFollowUp = function (_a) {
    var allChecked = _a.allChecked, customerNotHome = _a.customerNotHome, followUp = _a.followUp;
    return ({
        type: SET_FOLLOW_UP,
        allChecked: allChecked,
        customerNotHome: customerNotHome,
        followUp: followUp,
    });
};
export var postZendeskFollowUpRequest = function (body) { return ({
    type: ZENDESK_FOLLOW_UP_POST_REQUEST,
    body: body,
}); };
export var postZendeskFollowUpSuccess = function (workOrderId) { return ({
    type: ZENDESK_FOLLOW_UP_POST_SUCCESS,
    workOrderId: workOrderId,
}); };
export var postZendeskFollowUpError = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: ZENDESK_POST_ERROR,
        errorMessage: errorMessage,
    });
};
