import { COMPLETE_STATUS_CHANGE_SUCCESS, UPDATE_ACTION_TAKEN_FAILURE } from '../workLogConstants';
export var receiveCompleteStatusChange = function () { return ({
    type: COMPLETE_STATUS_CHANGE_SUCCESS,
}); };
export var updateActionTakenFailure = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: UPDATE_ACTION_TAKEN_FAILURE,
        errorMessage: errorMessage,
    });
};
