import React from 'react';
import PropTypes from 'prop-types';
import SelectCategories from './content/SelectCategories';
export default function AddServiceCategoryPicker(_a) {
    var categories = _a.categories, mainCategory = _a.mainCategory, mainError = _a.mainError, resetCategories = _a.resetCategories, selectedCategory = _a.selectedCategory, showMainCategories = _a.showMainCategories, showMainErrors = _a.showMainErrors, showSubCategories = _a.showSubCategories, subCategory = _a.subCategory, technician = _a.technician, toggleCategoryDropdown = _a.toggleCategoryDropdown;
    return (React.createElement("div", null, technician && (React.createElement(SelectCategories, { categories: categories, mainCategory: mainCategory, mainError: mainError, resetCategories: resetCategories, toggleCategoryDropdown: toggleCategoryDropdown, selectedCategory: selectedCategory, showMainCategories: showMainCategories, showMainErrors: showMainErrors, showSubCategories: showSubCategories, subCategory: subCategory }))));
}
AddServiceCategoryPicker.propTypes = {
    categories: PropTypes.func.isRequired,
    mainCategory: PropTypes.string.isRequired,
    mainError: PropTypes.string.isRequired,
    resetCategories: PropTypes.func.isRequired,
    selectedCategory: PropTypes.func.isRequired,
    showMainCategories: PropTypes.bool.isRequired,
    showMainErrors: PropTypes.bool.isRequired,
    showSubCategories: PropTypes.bool.isRequired,
    subCategory: PropTypes.string.isRequired,
    technician: PropTypes.bool.isRequired,
    toggleCategoryDropdown: PropTypes.func.isRequired,
};
