import { ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILURE, AVAILABLE_PRODUCTS_REQUEST, AVAILABLE_PRODUCTS_SUCCESS, AVAILABLE_PRODUCTS_ERROR, AVAILABLE_PRODUCTS_CLEAR_ERROR, } from '../customerProductsConstants';
export var requestAvailableProducts = function (agreementId) { return ({
    type: AVAILABLE_PRODUCTS_REQUEST,
    agreementId: agreementId,
}); };
export var receiveAvailableProducts = function (availableProducts) { return ({
    type: AVAILABLE_PRODUCTS_SUCCESS,
    availableProducts: availableProducts,
}); };
export var errorAvailableProducts = function (errorMessage) { return ({
    type: AVAILABLE_PRODUCTS_ERROR,
    errorMessage: errorMessage,
}); };
export var requestAddProduct = function (_a) {
    var agreementId = _a.agreementId, customerId = _a.customerId, product = _a.product;
    return ({
        type: ADD_PRODUCT_REQUEST,
        agreementId: agreementId,
        customerId: customerId,
        product: product,
    });
};
export var receiveAddProduct = function () { return ({
    type: ADD_PRODUCT_SUCCESS,
}); };
export var errorAddProduct = function (errorMessage) { return ({
    type: ADD_PRODUCT_FAILURE,
    errorMessage: errorMessage,
}); };
export var clearAvailableProductsError = function () { return ({
    type: AVAILABLE_PRODUCTS_CLEAR_ERROR,
}); };
