var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FIND_SERVICE_TYPES_REQUEST, FIND_SERVICE_TYPES_SUCCESS, FIND_SERVICE_TYPES_ERROR, SELECT_SERVICE_TYPE, UPDATE_DIAGNOSTIC_SERVICE, DEFAULT_SERVICE_TYPE, } from '../createNewWorkOrderConstants';
var serviceTypesReducerDefaultState = {
    isFetching: true,
    errorMessage: '',
    selectedDiagnosticServices: [],
    serviceType: DEFAULT_SERVICE_TYPE,
    serviceTypes: [],
};
export default (function (state, _a) {
    if (state === void 0) { state = serviceTypesReducerDefaultState; }
    var errorMessage = _a.errorMessage, selectedDiagnosticServices = _a.selectedDiagnosticServices, serviceType = _a.serviceType, serviceTypes = _a.serviceTypes, type = _a.type;
    switch (type) {
        case FIND_SERVICE_TYPES_REQUEST:
            return __assign({}, state);
        case FIND_SERVICE_TYPES_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, serviceTypes: serviceTypes });
        case FIND_SERVICE_TYPES_ERROR:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        case SELECT_SERVICE_TYPE:
            return __assign(__assign({}, state), { serviceType: serviceType });
        case UPDATE_DIAGNOSTIC_SERVICE:
            return __assign(__assign({}, state), { selectedDiagnosticServices: selectedDiagnosticServices });
        default:
            return state;
    }
});
