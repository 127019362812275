var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ERROR, MAIN, SUB, SERVICE_IDS } from '../../../constants/elements';
import filterServices from '../../../utils/filterServices';
import NOB from '../../../constants/language-strings';
import remove360FromServiceType from '../../../utils/remove360FromServiceType';
import setCategoryDropdown from '../../../utils/setCategoryDropdown';
import subMainErrorCategoryValues from '../../../utils/subMainErrorCategoryValues';
import selectDiagnosticServiceUtil from '../../../utils/selectDiagnosticService';
import { CATEGORY_SUB } from '../addServiceCodeConstants';
import hideSelectedDiagnosticServices from '../../../utils/hideSelectedDiagnosticServices';
import setDiagnosisCategoryDropdown from '../../../utils/setDiagnosisCategoryDropdown';
import AddServiceCategoryPicker from '../components/AddServiceCategoryPicker';
import '../addService.scss';
import * as updateServiceAction from '../../WorkLog/actions/updateServiceActions';
import { TECHNICIAN } from '../../Authentication/constants';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import AddServiceListSearch from '../components/AddServiceListSearch';
import renderService from '../components/content/RenderService';
import objectNameComparator from '../../../utils/objectNameComparator';
var AddServiceCodeContainer = /** @class */ (function (_super) {
    __extends(AddServiceCodeContainer, _super);
    function AddServiceCodeContainer() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            showMainCategories: false,
            showSubCategories: false,
            showMainErrors: false,
            mainCategory: '',
            subCategory: '',
            mainError: '',
            isModalOpen: false,
        };
        _this.toggleIsModalOpen = function () {
            var isModalOpen = _this.state.isModalOpen;
            _this.resetCategories();
            _this.setState({
                isModalOpen: !isModalOpen,
            });
        };
        _this.toggleCategoryDropdown = function (type) {
            var _a = _this.state, showMainCategories = _a.showMainCategories, showMainErrors = _a.showMainErrors, showSubCategories = _a.showSubCategories;
            _this.setState({
                showMainCategories: type === MAIN ? !showMainCategories : false,
                showSubCategories: type === SUB ? !showSubCategories : false,
                showMainErrors: type === ERROR ? !showMainErrors : false,
            });
        };
        _this.openCategoryDropdown = function () {
            _this.setState({
                showMainCategories: false,
            });
        };
        _this.openServiceModal = function () {
            _this.toggleIsModalOpen();
            _this.openCategoryDropdown();
        };
        _this.resetCategories = function () {
            _this.setState({
                showMainCategories: false,
                showSubCategories: false,
                showMainErrors: false,
                mainCategory: '',
                subCategory: '',
                mainError: '',
            });
        };
        _this.selectedCategory = function (_a) {
            var name = _a.name;
            var _b = _this.state, showMainCategories = _b.showMainCategories, showSubCategories = _b.showSubCategories, showMainErrors = _b.showMainErrors, mainCategory = _b.mainCategory, subCategory = _b.subCategory, mainError = _b.mainError;
            var _c = subMainErrorCategoryValues(showSubCategories, showMainErrors, name, subCategory, mainError), subCategoryValue = _c.subCategoryValue, mainErrorValue = _c.mainErrorValue;
            _this.setState({
                showMainCategories: false,
                showSubCategories: showMainCategories,
                showMainErrors: showSubCategories,
                mainCategory: showMainCategories ? name : mainCategory,
                subCategory: subCategoryValue,
                mainError: mainErrorValue,
            });
        };
        _this.filteredServiceTypes = function (list) {
            var name = _this.props.serviceType.name;
            return list.filter(function (service) { return remove360FromServiceType(service.serviceType) === name; });
        };
        _this.handleSelectedDiagnosticService = function (event) {
            var _a = _this.props, diagnosticServices = _a.diagnosticServices, loadTimeSlots = _a.loadTimeSlots, selectedDiagnosticServices = _a.selectedDiagnosticServices, updateDiagnosticServices = _a.updateDiagnosticServices;
            var selectedServiceType = _this.filteredServiceTypes(diagnosticServices)[0].services.filter(function (service) { return service.serviceId === event.value; });
            var newSelectedDiagnosticServices = selectedDiagnosticServices.concat(selectDiagnosticServiceUtil(selectedServiceType));
            updateDiagnosticServices(newSelectedDiagnosticServices);
            loadTimeSlots(SERVICE_IDS, newSelectedDiagnosticServices.map(function (service) { return service.serviceId; }));
            _this.toggleIsModalOpen();
        };
        _this.addServiceCode = function (service) {
            var workOrderId = _this.props.workOrderId;
            var serviceId = service.id;
            var quantity = 1;
            var shouldAdd = true;
            _this.props.requestUpdateService(workOrderId, serviceId, quantity, shouldAdd);
            _this.toggleIsModalOpen();
        };
        _this.categories = function (category) {
            var _a = _this.state, mainCategory = _a.mainCategory, subCategory = _a.subCategory;
            var _b = _this.props, services = _b.findServices.services, workOrderServices = _b.workOrderServices;
            var allCategories = filterServices(services, workOrderServices, true, !_this.props.addServiceCode);
            var values = setCategoryDropdown(category, allCategories, mainCategory, subCategory);
            return values.filter(function (value) { return value.name; });
        };
        _this.diagnosisCategories = function (category) {
            var _a = _this.state, mainCategory = _a.mainCategory, subCategory = _a.subCategory;
            var _b = _this.props, selectedDiagnosticServices = _b.selectedDiagnosticServices, serviceTypes = _b.serviceTypes;
            var values = setDiagnosisCategoryDropdown(category, _this.filteredServiceTypes(serviceTypes), mainCategory, subCategory);
            if (category === CATEGORY_SUB) {
                values = hideSelectedDiagnosticServices(values, selectedDiagnosticServices, true);
            }
            return values.filter(function (value) { return value.name; });
        };
        return _this;
    }
    AddServiceCodeContainer.prototype.render = function () {
        var _this = this;
        var _a = this.state, mainCategory = _a.mainCategory, mainError = _a.mainError, showMainCategories = _a.showMainCategories, showMainErrors = _a.showMainErrors, showSubCategories = _a.showSubCategories, subCategory = _a.subCategory;
        var _b = this.props, addServiceCode = _b.addServiceCode, diagnosticServices = _b.diagnosticServices, role = _b.role, workOrderServices = _b.workOrderServices, services = _b.findServices.services;
        var technician = role === TECHNICIAN;
        var diagnosticServiceList = function () {
            var values = [];
            _this.filteredServiceTypes(diagnosticServices).map(function (diagnosticService) {
                return diagnosticService.services.map(function (service) { return values.push(service); });
            });
            return hideSelectedDiagnosticServices(values, _this.props.selectedDiagnosticServices, false);
        };
        var serviceList = filterServices(services, workOrderServices, false, !this.props.addServiceCode);
        var addServiceProps = addServiceCode
            ? {
                categories: this.categories,
                selectService: this.addServiceCode,
                serviceList: serviceList,
            }
            : {
                categories: this.diagnosisCategories,
                selectService: this.handleSelectedDiagnosticService,
                serviceList: diagnosticServiceList(),
            };
        var filteredServicesBasedOnSelectedCategory = filterServices(addServiceProps.serviceList, workOrderServices, true, !this.props.addServiceCode)
            .filter(function (service) {
            return (mainCategory === '' || service.main === mainCategory) &&
                (subCategory === '' || service.sub === subCategory) &&
                (mainError === '' || service.error === mainError);
        })
            .sort(objectNameComparator);
        var modalAndButtonText = addServiceCode
            ? NOB.ADD_SERVICE.ADD_NEW_SERVICE
            : NOB.CREATE_NEW_WORK_ORDER.ADD_DIAGNOSIS;
        return (React.createElement("div", { className: "add__service" },
            React.createElement("div", { className: !addServiceCode ? 'add_diagnosis_button' : 'add_service_button', id: "openModal" },
                React.createElement(SimpleButton, { onClick: this.openServiceModal, title: modalAndButtonText, color: Color.BLACK })),
            React.createElement(SlideInModal, { display: this.state.isModalOpen, onClose: this.toggleIsModalOpen, mainHeading: modalAndButtonText, cleanUp: function () { } },
                React.createElement("div", { className: "transparent_background_container \n          ".concat(showMainCategories ||
                        showSubCategories ||
                        (showMainErrors && filteredServicesBasedOnSelectedCategory.length > 1)
                        ? 'open_transparent_background'
                        : '') },
                    React.createElement("div", { className: "add__service" },
                        React.createElement(AddServiceCategoryPicker, { categories: addServiceProps.categories, mainCategory: mainCategory, mainError: mainError, resetCategories: this.resetCategories, technician: technician, selectedCategory: this.selectedCategory, showMainCategories: showMainCategories, showMainErrors: showMainErrors, showSubCategories: showSubCategories, subCategory: subCategory, toggleCategoryDropdown: this.toggleCategoryDropdown })),
                    React.createElement("div", { className: "hide_overflow" },
                        React.createElement(AddServiceListSearch, { placeholder: NOB.ADD_SERVICE.SEARCH, onChange: addServiceProps.selectService, options: filteredServicesBasedOnSelectedCategory, renderOption: renderService }))))));
    };
    return AddServiceCodeContainer;
}(Component));
AddServiceCodeContainer.propTypes = {
    addServiceCode: PropTypes.bool,
    diagnosticServices: PropTypes.arrayOf(PropTypes.any),
    findServices: PropTypes.objectOf(PropTypes.any).isRequired,
    loadTimeSlots: PropTypes.func,
    requestUpdateService: PropTypes.func,
    role: PropTypes.string.isRequired,
    selectedDiagnosticServices: PropTypes.arrayOf(PropTypes.any),
    serviceType: PropTypes.objectOf(PropTypes.any),
    serviceTypes: PropTypes.arrayOf(PropTypes.any),
    workOrderId: PropTypes.number,
    updateDiagnosticServices: PropTypes.func,
    workOrderServices: PropTypes.arrayOf(PropTypes.any).isRequired,
};
AddServiceCodeContainer.defaultProps = {
    addServiceCode: false,
    diagnosticServices: [],
    loadTimeSlots: null,
    requestUpdateService: null,
    selectedDiagnosticServices: [],
    serviceType: {},
    serviceTypes: [],
    updateDiagnosticServices: null,
    workOrderId: null,
};
var mapStateToProps = function (state) { return ({
    findServices: state.findServices,
    role: state.authentication.role,
    workOrderId: state.workOrder.workOrder.workOrderId,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestUpdateService: function (workOrderId, serviceId, quantity, added) {
        return dispatch(updateServiceAction.requestUpdateService({
            workOrderId: workOrderId,
            serviceId: serviceId,
            quantity: quantity,
            added: added,
        }));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceCodeContainer);
