import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var fill = _a.fill;
    return (React.createElement("svg", { width: "24px", height: "9px", viewBox: "0 0 24 9", version: "1.1" },
        React.createElement("title", null, "icon/getbox_r\u00F8d"),
        React.createElement("desc", null, "Created with Sketch."),
        React.createElement("defs", null),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "icon/getbox_r\u00F8d", fill: fill },
                React.createElement("g", { id: "Group-7" },
                    React.createElement("path", { d: "M23.2328767,7 L0.767123288,7 C0.343452055,7 0,6.61846957 0,6.14782609 L0,1.76521739 C0,0.790330435 0.711452055,0 1.5890411,0 L22.4109589,0 C23.2885479,0 24,0.790330435 24,1.76521739 L24,6.14782609 C24,6.61846957 23.6565479,7 23.2328767,7 Z", id: "Stroke-1" })),
                React.createElement("g", { id: "Group-7-Copy", transform: "translate(5.500000, 7.750000) scale(1, -1) translate(-5.500000, -7.750000) translate(4.000000, 7.000000)" },
                    React.createElement("path", { d: "M2.90410959,1.5 L0.095890411,1.5 C0.0429315068,1.5 0,1.41824348 0,1.3173913 L0,0.37826087 C0,0.169356522 0.0889315068,0 0.198630137,0 L2.80136986,0 C2.91106849,0 3,0.169356522 3,0.37826087 L3,1.3173913 C3,1.41824348 2.95706849,1.5 2.90410959,1.5 Z", id: "Stroke-1" })),
                React.createElement("g", { id: "Group-7-Copy-2", transform: "translate(18.500000, 7.750000) scale(1, -1) translate(-18.500000, -7.750000) translate(17.000000, 7.000000)" },
                    React.createElement("path", { d: "M2.90410959,1.5 L0.095890411,1.5 C0.0429315068,1.5 0,1.41824348 0,1.3173913 L0,0.37826087 C0,0.169356522 0.0889315068,0 0.198630137,0 L2.80136986,0 C2.91106849,0 3,0.169356522 3,0.37826087 L3,1.3173913 C3,1.41824348 2.95706849,1.5 2.90410959,1.5 Z", id: "Stroke-1" }))))));
};
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.buttonRed,
};
export default SVG;
