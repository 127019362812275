export default function subMainErrorCategoryValues(showSubCategories, showMainErrors, name, subCategory, mainError) {
    var subCategoryValue = '';
    var mainErrorValue = '';
    if (showSubCategories) {
        subCategoryValue = name;
    }
    else if (showMainErrors) {
        subCategoryValue = subCategory;
        mainErrorValue = name;
    }
    else {
        subCategoryValue = subCategory;
        mainErrorValue = mainError;
    }
    return { subCategoryValue: subCategoryValue, mainErrorValue: mainErrorValue };
}
