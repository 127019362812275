import React from "react";
import Variables from "../styles/variables.scss";
var SVG = function () { return (React.createElement("svg", { width: "34px", height: "28px", viewBox: "0 0 34 28", version: "1.1" },
    React.createElement("title", null, "AEF50AA1-13E6-4155-8CAD-92E3709354B2"),
    React.createElement("desc", null, "Created with sketchtool."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: Variables.blackPrimary, strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/scan", stroke: "Variables.blackPrimary" },
            React.createElement("rect", { id: "Rectangle-8", fill: "Variables.blackPrimary", x: "4.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy", fill: "Variables.blackPrimary", x: "7.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-2", fill: "Variables.blackPrimary", x: "9.5", y: "5.5", width: "2", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-3", fill: "Variables.blackPrimary", x: "20.5", y: "5.5", width: "2", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-7", fill: "Variables.blackPrimary", x: "28.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-4", fill: "Variables.blackPrimary", x: "16.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-8", fill: "Variables.blackPrimary", x: "24.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-5", fill: "Variables.blackPrimary", x: "13.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-6", fill: "Variables.blackPrimary", x: "18.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("rect", { id: "Rectangle-8-Copy-9", fill: "Variables.blackPrimary", x: "26.5", y: "5.5", width: "1", height: "17" }),
            React.createElement("g", { id: "Group", transform: "translate(0.000000, 1.000000)", fillRule: "nonzero", strokeLinecap: "square" },
                React.createElement("path", { d: "M1.5,4.5 L1.5,1.5", id: "Line-4" }),
                React.createElement("path", { d: "M1.5,1.5 L4.5,1.5", id: "Line-5" })),
            React.createElement("g", { id: "Group-Copy-2", transform: "translate(31.500000, 3.500000) scale(-1, 1) translate(-31.500000, -3.500000) translate(29.000000, 1.000000)", fillRule: "nonzero", strokeLinecap: "square" },
                React.createElement("path", { d: "M1.5,4.5 L1.5,1.5", id: "Line-4" }),
                React.createElement("path", { d: "M1.5,1.5 L4.5,1.5", id: "Line-5" })),
            React.createElement("g", { id: "Group-Copy", transform: "translate(2.500000, 24.500000) rotate(-90.000000) translate(-2.500000, -24.500000) translate(0.000000, 22.000000)", fillRule: "nonzero", strokeLinecap: "square" },
                React.createElement("path", { d: "M1.5,4.5 L1.5,1.5", id: "Line-4" }),
                React.createElement("path", { d: "M1.5,1.5 L4.5,1.5", id: "Line-5" })),
            React.createElement("g", { id: "Group-Copy-3", transform: "translate(31.500000, 24.500000) scale(-1, 1) rotate(-90.000000) translate(-31.500000, -24.500000) translate(29.000000, 22.000000)", fillRule: "nonzero", strokeLinecap: "square" },
                React.createElement("path", { d: "M1.5,4.5 L1.5,1.5", id: "Line-4" }),
                React.createElement("path", { d: "M1.5,1.5 L4.5,1.5", id: "Line-5" })))))); };
export default SVG;
