var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CHANGE_PRODUCT_REQUEST, CHANGE_PRODUCT_SUCCESS, CHANGE_PRODUCT_FAILURE, CLEAR_ERROR_MESSAGE, } from '../customerProductsConstants';
var changeProductReducerDefaultState = {
    changedProduct: [],
    errorMessage: '',
    errorProducts: [],
    isFetching: false,
    dpadId: 0,
};
export default (function (state, _a) {
    if (state === void 0) { state = changeProductReducerDefaultState; }
    var changedProduct = _a.changedProduct, errorMessage = _a.errorMessage, errorProducts = _a.errorProducts, oldSerial = _a.oldSerial, dpadId = _a.dpadId, productName = _a.productName, type = _a.type;
    switch (type) {
        case CHANGE_PRODUCT_REQUEST:
            return __assign(__assign({}, state), { isFetchingChangeProduct: true, errorMessage: '', oldSerial: oldSerial, dpadId: dpadId });
        case CHANGE_PRODUCT_SUCCESS:
            return __assign(__assign({}, state), { changedProduct: __spreadArray(__spreadArray([], __read(state.changedProduct), false), [changedProduct], false), productName: productName, isFetchingChangeProduct: false, errorMessage: '', errorProducts: [] });
        case CHANGE_PRODUCT_FAILURE:
            return __assign(__assign({}, state), { errorMessage: errorMessage, isFetching: false, isFetchingChangeProduct: false, errorProducts: state.errorProducts.concat(errorProducts) });
        case CLEAR_ERROR_MESSAGE:
            return __assign(__assign({}, state), { errorMessage: '', errorProducts: __spreadArray([], __read(state.errorProducts.filter(function (products) { return products !== errorProducts; })), false) });
        default:
            return state;
    }
});
