var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EsriLoader from '../components/EsriLoader';
import { requestMapId, requestMapToken } from '../actions/mapIdActions';
var MapContainer = /** @class */ (function (_super) {
    __extends(MapContainer, _super);
    function MapContainer() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = {
            shouldExpand: false,
        };
        _this.handleExpand = function () {
            _this.setState({ shouldExpand: !_this.state.shouldExpand });
        };
        return _this;
    }
    MapContainer.prototype.componentDidMount = function () {
        var _a = this.props, id = _a.id, newWorkOrderEntered = _a.newWorkOrderEntered, requestMapIdFunc = _a.requestMapIdFunc, token = _a.token, requestMapTokenFunc = _a.requestMapTokenFunc;
        if (newWorkOrderEntered || !id) {
            requestMapIdFunc();
        }
        if (newWorkOrderEntered || !token) {
            requestMapTokenFunc();
        }
    };
    MapContainer.prototype.render = function () {
        var _a = this.props, _b = _a.customerAddress, city = _b.city, houseNumberAlpha = _b.houseNumberAlpha, houseNumberNumeric = _b.houseNumberNumeric, postalCode = _b.postalCode, street = _b.street, id = _a.id, mapIsFetching = _a.mapIsFetching, mapTokenIsFetching = _a.mapTokenIsFetching, technicalDetails = _a.technicalDetails, token = _a.token;
        var address = "".concat(street, " ").concat(houseNumberNumeric).concat(houseNumberAlpha, ", ").concat(postalCode, " ").concat(city);
        return (React.createElement("div", { className: "map__container" },
            React.createElement(EsriLoader, { address: address, handleExpand: this.handleExpand, id: id, isFetching: mapIsFetching || mapTokenIsFetching, mapTokenIsFetching: mapTokenIsFetching, position: {
                    latitude: parseFloat(technicalDetails.Latitude),
                    longitude: parseFloat(technicalDetails.Longitude),
                }, shouldExpand: this.state.shouldExpand, token: token })));
    };
    return MapContainer;
}(Component));
MapContainer.propTypes = {
    customerAddress: PropTypes.objectOf(PropTypes.any).isRequired,
    id: PropTypes.string.isRequired,
    mapIsFetching: PropTypes.bool.isRequired,
    mapTokenIsFetching: PropTypes.bool.isRequired,
    newWorkOrderEntered: PropTypes.bool.isRequired,
    requestMapIdFunc: PropTypes.func.isRequired,
    requestMapTokenFunc: PropTypes.func.isRequired,
    technicalDetails: PropTypes.objectOf(PropTypes.any).isRequired,
    token: PropTypes.string.isRequired,
};
var mapStateToProps = function (state) { return ({
    customerAddress: state.workOrder.workOrder.address,
    id: state.map.mapId,
    mapIsFetching: state.map.isFetching,
    mapTokenIsFetching: state.mapToken.isFetching,
    technicalDetails: state.workOrder.workOrder.technicalDetails,
    token: state.mapToken.mapToken,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestMapIdFunc: function () { return dispatch(requestMapId()); },
    requestMapTokenFunc: function () { return dispatch(requestMapToken()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
