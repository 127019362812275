import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';
var Checkbox = function (_a) {
    var checked = _a.checked, isFetching = _a.isFetching, onClick = _a.onClick, label = _a.label, name = _a.name;
    return (React.createElement("label", { htmlFor: name, className: "checkbox" },
        React.createElement("span", { className: checked ? 'label--checked' : 'label' }, label),
        React.createElement("input", { id: name, checked: checked, value: checked, type: "checkbox", name: name, onClick: onClick, readOnly: true }),
        React.createElement("span", { className: "checkmark" }, isFetching && (React.createElement("span", { className: "".concat(checked ? 'circle-loader-green' : 'circle-loader-white') })))));
};
Checkbox.propTypes = {
    checked: PropTypes.bool,
    isFetching: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
Checkbox.defaultProps = {
    checked: false,
    isFetching: false,
};
export default Checkbox;
