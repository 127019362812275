var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FIND_SERVICES_REQUEST, FIND_SERVICES_SUCCESS, FIND_SERVICES_ERROR, } from '../workLogConstants';
var findServicesReducerDefaultState = {
    isFetchingServices: false,
    errorMessage: '',
    services: [],
};
export default (function (state, _a) {
    if (state === void 0) { state = findServicesReducerDefaultState; }
    var errorMessage = _a.errorMessage, services = _a.services, type = _a.type;
    switch (type) {
        case FIND_SERVICES_REQUEST:
            return __assign(__assign({}, state), { isFetchingServices: true });
        case FIND_SERVICES_SUCCESS:
            return __assign(__assign({}, state), { isFetchingServices: false, services: services });
        case FIND_SERVICES_ERROR:
            return __assign(__assign({}, state), { isFetchingServices: false, errorMessage: errorMessage });
        default:
            return state;
    }
});
