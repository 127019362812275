var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SlideDown } from 'react-slidedown';
import { whiteSecondary, blackPrimary } from '../../styles/variables.scss';
import { ARROW_DOWN } from '../../constants/iconNames';
import Icon from '../../icons';
import './simple-dropdown.scss';
function SimpleDropdown(_a) {
    var isWhite = _a.isWhite, values = _a.values, title = _a.title, onChange = _a.onChange, customClass = _a.customClass;
    var _b = __read(useState(false), 2), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement("div", { className: "".concat(isWhite ? 'simple-dropdown--white ' : 'simple-dropdown ').concat(customClass) },
        React.createElement("button", { onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement("span", null, title),
            React.createElement("span", { className: isOpen ? 'rotate-up' : 'rotate-down' },
                React.createElement(Icon, { stroke: isWhite ? blackPrimary : whiteSecondary, name: ARROW_DOWN }))),
        React.createElement(SlideDown, { className: "slidedown", closed: !isOpen, transitionOnAppear: true },
            React.createElement(SelectSearch, { autofocus: true, handleClickOutside: function () { }, onChange: function (e) {
                    onChange(e);
                    setIsOpen(!isOpen);
                }, options: values, search: false }))));
}
SimpleDropdown.propTypes = {
    isWhite: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.any,
        value: PropTypes.any,
    })).isRequired,
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customClass: PropTypes.string,
};
SimpleDropdown.defaultProps = {
    isWhite: false,
    customClass: '',
};
export default SimpleDropdown;
