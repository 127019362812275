import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (props) { return (React.createElement("svg", { width: "17px", height: "17px", viewBox: "0 0 17 17", version: "1.1" },
    React.createElement("title", null, "icon/klokke_sort"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/klokke_sort", transform: "translate(1.000000, 1.000000)", stroke: props.color },
            React.createElement("g", { id: "Group-3" },
                React.createElement("circle", { id: "Oval-115", cx: "7.5", cy: "7.5", r: "7.5" }),
                React.createElement("path", { d: "M7.5,3 L7.5,8", id: "Line-38", strokeLinecap: "round", strokeLinejoin: "round" }),
                React.createElement("path", { d: "M7.5,8 L10,11", id: "Line", strokeLinecap: "round", strokeLinejoin: "round" })))))); };
SVG.propTypes = {
    color: PropTypes.string,
};
SVG.defaultProps = {
    color: Variables.blackSecondary,
};
export default SVG;
