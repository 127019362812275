import { CUSTOMER_PRODUCTS_REQUEST, CUSTOMER_PRODUCTS_SUCCESS, CUSTOMER_PRODUCTS_FAILURE, CUSTOMER_PRODUCTS_UPDATE, CUSTOMER_PRODUCTS_REQUEST_BACKGROUND, UPDATE_SINGLE_PRODUCT, } from '../customerProductsConstants';
export var requestCustomerProducts = function (action) { return ({
    type: CUSTOMER_PRODUCTS_REQUEST,
    customerId: action.customerId,
}); };
export var requestCustomerProductsBackground = function (customerId) { return ({
    type: CUSTOMER_PRODUCTS_REQUEST_BACKGROUND,
    customerId: customerId,
}); };
export var receiveCustomerProducts = function (action) { return ({
    type: CUSTOMER_PRODUCTS_SUCCESS,
    customerProducts: action.customerProducts,
}); };
export var errorCustomerProducts = function (action) { return ({
    type: CUSTOMER_PRODUCTS_FAILURE,
    errorMessage: action.errorMessage,
}); };
export var updateCustomerProducts = function (action) { return ({
    type: CUSTOMER_PRODUCTS_UPDATE,
    customerProducts: action.customerProducts,
}); };
export var updateSingleProduct = function (newProduct) { return ({
    type: UPDATE_SINGLE_PRODUCT,
    newProduct: newProduct,
}); };
