import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import NOB from '../../../../../constants/language-strings';
import { SIGN_OUT_PATH } from '../../../../../constants/paths';
var EndDay = function (_a) {
    var endDayFunction = _a.endDayFunction, history = _a.history;
    var endDayAndSignOut = function () {
        endDayFunction(true);
        history.push(SIGN_OUT_PATH);
    };
    return (React.createElement("button", { className: "workOrder__list__bottom__buttons--end-day", onClick: endDayAndSignOut }, NOB.END_DAY.END_DAY));
};
EndDay.propTypes = {
    endDayFunction: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(EndDay);
