import React from 'react';
import PropTypes from 'prop-types';
import './numberInput.scss';
function NumberInput(_a) {
    var onChange = _a.onChange, label = _a.label, name = _a.name, errorMessage = _a.errorMessage, value = _a.value, placeholder = _a.placeholder, icon = _a.icon;
    return (React.createElement("div", { className: errorMessage ? 'number-input--error' : 'number-input' },
        React.createElement("label", { htmlFor: name },
            React.createElement("span", { className: "label" }, label),
            React.createElement("input", { name: name, value: value, onChange: onChange, placeholder: placeholder, type: "text" }),
            icon && React.createElement("span", { className: "icon" }, icon)),
        errorMessage && React.createElement("div", { className: "error-message" }, errorMessage)));
}
NumberInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    errorMessage: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
};
NumberInput.defaultProps = {
    name: null,
    errorMessage: '',
    value: '',
    placeholder: '',
    icon: null,
};
export default NumberInput;
