import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var stroke = _a.stroke, width = _a.width, height = _a.height;
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 18 10", version: "1.1" },
        React.createElement("g", { id: "icon/pilned", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
            React.createElement("polyline", { id: "Path-2", stroke: stroke, strokeWidth: "2", points: "1 1 9 9 17 1" }))));
};
SVG.propTypes = {
    stroke: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};
SVG.defaultProps = {
    stroke: Variables.blackSecondary,
    width: "18px",
    height: "10px",
};
export default SVG;
