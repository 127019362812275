// Day
export var END_DAY_REQUEST = 'END_DAY_REQUEST';
export var END_DAY_FAILURE = 'END_DAY_FAILURE';
export var START_DAY_REQUEST = 'START_DAY_REQUEST';
export var START_DAY_FAILURE = 'START_DAY_FAILURE';
export var DAY_STATUS_SUCCESS = 'DAY_STATUS_SUCCESS';
// Roles
export var EXTERNAL_INSTALLER = 'ROLE_INSTALLER';
export var TECHNICIAN = 'ROLE_TECHNICIAN';
// Sign in
export var SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export var SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export var DEV_SIGN_IN_REQUEST = 'DEV_SIGN_IN_REQUEST';
export var SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
// Sign out
export var SIGN_OUT_REQUEST = 'USER_LOGOUT';
export var SIGN_OUT_SUCCESS = 'SIGNOUT_SUCCESS';
export var SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
// Verify token
export var VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE';
export var VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST';
export var VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
