import { connect } from 'react-redux';
import SignInForm from '../components/SignInForm';
import { requestSignIn } from '../actions';
import '../signIn.scss';
var mapStateToProps = function (state) { return ({
    signInError: state.authentication.signInError,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestSignIn: function (credentials) { return dispatch(requestSignIn(credentials)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
