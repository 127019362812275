export var DATES = 'dates';
export var DIAGNOSIS = 'Diagnose >';
export var DPAD_ID = 'dpadid';
export var DURATION = 'Varighet >';
export var ERROR = 'error';
export var HOME_SERVICE = 'Hjemlevering';
export var MAIN = 'main';
export var NDS_MIKRO_SMARTCARD = 'NDS mikro Smartcard';
export var NO_DEVICES = 'Found no devices to poll';
export var NOT_PLANNED = 'Ikke avtalt >';
export var PLANNED = 'Avtalt >';
export var SERVICE_DATE = 'serviceDate';
export var SERVICE_IDS = 'serviceIds';
export var SERVICE_TYPE_ID = 'serviceTypeId';
export var SUB = 'sub';
