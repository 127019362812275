var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import FeedbackHmm from "./FeedbackHmm";
import FeedbackJuggle from "./FeedbackJuggle";
import FeedbackNotFound from "./FeedbackNotFound";
import FeedbackPopcorn from "./FeedbackPopcorn";
import FeedbackThinking from "./FeedbackThinking";
import * as FEEDBACK from "../constants/feedbackNames";
var Feedback = function (props) {
    switch (props.name) {
        case FEEDBACK.FEEDBACK_JUGGLE:
            return React.createElement(FeedbackJuggle, __assign({}, props));
        case FEEDBACK.FEEDBACK_NOT_FOUND:
            return React.createElement(FeedbackNotFound, __assign({}, props));
        case FEEDBACK.FEEDBACK_POPCORN:
            return React.createElement(FeedbackPopcorn, __assign({}, props));
        case FEEDBACK.FEEDBACK_THINKING:
            return React.createElement(FeedbackThinking, __assign({}, props));
        default:
            return React.createElement(FeedbackHmm, __assign({}, props));
    }
};
export default Feedback;
