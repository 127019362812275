// Filtered customer products
export default function filterCustomerProducts(customerProducts) {
    return customerProducts
        .sort(function (a, b) {
        if (a.productId < b.productId) {
            return 1;
        }
        if (a.productId > b.productId) {
            return -1;
        }
        return 0;
    })
        .sort(function (a, b) {
        if (a.status > b.status) {
            return 1;
        }
        if (a.status < b.status) {
            return -1;
        }
        return 0;
    })
        .sort(function (a, b) {
        if (a.devices.filter(function (device) { return device.serialNumber === ''; }).length >
            b.devices.filter(function (device) { return device.serialNumber === ''; }).length) {
            return 1;
        }
        if (a.devices.filter(function (device) { return device.serialNumber === ''; }).length <
            b.devices.filter(function (device) { return device.serialNumber === ''; }).length) {
            return -1;
        }
        return 0;
    });
}
