var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FOLLOW_UP_FORM_FAILURE, FOLLOW_UP_FORM_REQUEST, FOLLOW_UP_FORM_SUCCESS, } from '../FollowUpConstans';
var followUpFormsReducerDefaultState = {
    isFetching: false,
    errorMessage: '',
    forms: [],
};
export default (function (state, _a) {
    if (state === void 0) { state = followUpFormsReducerDefaultState; }
    var errorMessage = _a.errorMessage, forms = _a.forms, type = _a.type;
    switch (type) {
        case FOLLOW_UP_FORM_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case FOLLOW_UP_FORM_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, forms: forms });
        case FOLLOW_UP_FORM_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        default:
            return state;
    }
});
