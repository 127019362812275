import { UPDATE_SERVICE_ERROR, UPDATE_SERVICE_REQUEST, UPDATE_SERVICE_SUCCESS, } from '../workLogConstants';
export var requestUpdateService = function (_a) {
    var added = _a.added, quantity = _a.quantity, serviceId = _a.serviceId, workOrderId = _a.workOrderId, workOrderServiceId = _a.workOrderServiceId;
    return ({
        type: UPDATE_SERVICE_REQUEST,
        workOrderId: workOrderId,
        serviceId: serviceId,
        quantity: quantity,
        added: added,
        workOrderServiceId: workOrderServiceId,
    });
};
export var errorUpdateService = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: UPDATE_SERVICE_ERROR,
        errorMessage: errorMessage,
    });
};
export var successUpdateService = function (_a) {
    var workOrder = _a.workOrder;
    return ({
        type: UPDATE_SERVICE_SUCCESS,
        workOrder: workOrder,
    });
};
