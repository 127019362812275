export default function capitalize(string) {
    try {
        var returnString = string.toLowerCase();
        returnString = returnString.replace(/(^|\s)\S/g, function (letter) { return letter.toUpperCase(); });
        return returnString;
    }
    catch (TypeError) {
        return string;
    }
}
