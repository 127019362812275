var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Quagga from 'quagga';
import Scanner from '../../components/Scanner/Scanner';
import { putAPI } from '../../api/apiConfig';
import { ACTIVATE_PRODUCT } from '../../constants/endpoints';
import { updateSingleProduct, requestCustomerProductsBackground, } from '../CustomerProducts/actions/customerProducts';
function DeviceScannerContainer(_a) {
    var _this = this;
    var dpadIdsToActivate = _a.dpadIdsToActivate, productId = _a.productId, updateProduct = _a.updateProduct, onClose = _a.onClose, onActivation = _a.onActivation, onSerialNumberFound = _a.onSerialNumberFound, customerId = _a.customerId, updateAllProducts = _a.updateAllProducts;
    // List over completed activation requests
    var _b = __read(useState([]), 2), results = _b[0], setResults = _b[1];
    // List over scanned serialNumbers
    var _c = __read(useState([]), 2), scannerResults = _c[0], setScannerResults = _c[1];
    // This effect is used to coompare the scannedResults againts the results from the activation request
    useEffect(function () {
        var newResults = scannerResults.map(function (result) {
            if (results.find(function (prevResult) { return prevResult.serialNumber === result.serialNumber; })) {
                var isActivated = result.isActivated
                    ? result.isActivated
                    : results.find(function (prevResult) { return prevResult.serialNumber === result.serialNumber; }).isActivated;
                return __assign(__assign({}, result), { isActivated: isActivated, isFetching: false });
            }
            return result;
        });
        setScannerResults(newResults);
    }, [results]);
    var activateProduct = function (requestBody, newScannerResultList) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, putAPI(ACTIVATE_PRODUCT(productId), requestBody)
                        .then(function (result) {
                        onClose();
                        onActivation();
                        updateAllProducts(customerId);
                        return updateProduct(result);
                    })
                        .catch(function (error) {
                        var activatedSerialNumbers = error.data.length
                            ? error.data.filter(function (data) { return !data.isValid; }).map(function (data) { return data.serialNumber; })
                            : [];
                        var activationResultList = newScannerResultList.map(function (result) { return (__assign(__assign({}, result), { isActivated: activatedSerialNumbers.includes(result.serialNumber), isFetching: false })); });
                        onSerialNumberFound(activationResultList.filter(function (result) { return result.isActivated; }), error.data);
                        setResults(activationResultList);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // Adds a scanned result to the result list and performs a product activation request
    var onDetected = function (result) {
        var resultShouldBeAdded = !scannerResults
            .map(function (_a) {
            var serialNumber = _a.serialNumber;
            return serialNumber;
        })
            .includes(result.codeResult.code.toUpperCase());
        if (resultShouldBeAdded) {
            var regex = new RegExp('^[A-Z0-9]*$');
            if (regex.test(result.codeResult.code)) {
                Quagga.offDetected(onDetected);
                var newScannerResult = {
                    serialNumber: result.codeResult.code.toUpperCase(),
                    isActivated: false,
                    isFetching: true,
                };
                var newScannerResultList_1 = __spreadArray(__spreadArray([], __read(scannerResults), false), [newScannerResult], false);
                setScannerResults(newScannerResultList_1);
                var activationRequest = dpadIdsToActivate.map(function (id) { return ({
                    dpadId: id,
                    serialNumbers: newScannerResultList_1.map(function (_a) {
                        var serialNumber = _a.serialNumber;
                        return serialNumber;
                    }),
                }); });
                activateProduct(activationRequest, newScannerResultList_1);
            }
        }
    };
    useEffect(function () {
        Quagga.onDetected(onDetected);
        return function () { return Quagga.offDetected(onDetected); };
    }, [scannerResults]);
    return (React.createElement(Scanner, { results: scannerResults, onDetected: onDetected, onHeaderClick: onClose, totalDevices: dpadIdsToActivate.length }));
}
DeviceScannerContainer.propTypes = {
    dpadIdsToActivate: PropTypes.arrayOf(PropTypes.number).isRequired,
    productId: PropTypes.number.isRequired,
    updateProduct: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onActivation: PropTypes.func.isRequired,
    onSerialNumberFound: PropTypes.func,
    updateAllProducts: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
};
DeviceScannerContainer.defaultProps = {
    onSerialNumberFound: function () { },
};
var mapStateToProps = function (state) {
    var customerId = state.workOrder.workOrder.customer.customerId;
    return { customerId: customerId };
};
var mapDispatchToProps = function (dispatch) { return ({
    updateProduct: function (newProduct) { return dispatch(updateSingleProduct(newProduct)); },
    updateAllProducts: function (customerId) { return dispatch(requestCustomerProductsBackground(customerId)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(DeviceScannerContainer);
