import { VERIFY_TOKEN_FAILURE, VERIFY_TOKEN_REQUEST, VERIFY_TOKEN_SUCCESS } from '../constants';
export var requestTokenVerification = function (_a) {
    var token = _a.token;
    return ({
        type: VERIFY_TOKEN_REQUEST,
        token: token,
    });
};
export var successTokenVerification = function (_a) {
    var token = _a.token, role = _a.role;
    return ({
        type: VERIFY_TOKEN_SUCCESS,
        token: token,
        role: role,
    });
};
export var failureTokenVerification = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: VERIFY_TOKEN_FAILURE,
        errorMessage: errorMessage,
    });
};
