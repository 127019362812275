import React from 'react';
import PropTypes from 'prop-types';
function PriceRow(_a) {
    var title = _a.title, amount = _a.amount;
    return (React.createElement("div", { className: "price-row-wrapper" },
        React.createElement("div", { className: "row" },
            React.createElement("span", { className: "title" }, title),
            React.createElement("span", { className: "amount" },
                amount,
                ",-"))));
}
PriceRow.propTypes = {
    title: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
};
export default PriceRow;
