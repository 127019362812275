import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FRONT_PAGE_PATH } from '../../../constants/paths';
import * as action from '../actions';
import * as verifyAction from '../actions/verifyTokenActions';
import * as workOrderListAction from '../../WorkorderList/actions/workOrderList';
var SignOut = function (_a) {
    var emptyWorkOrderList = _a.emptyWorkOrderList, endDay = _a.endDay, failureTokenVerification = _a.failureTokenVerification, history = _a.history, requestEndDay = _a.requestEndDay, requestLogout = _a.requestLogout;
    requestLogout();
    emptyWorkOrderList();
    failureTokenVerification('Unauthorized');
    if (endDay) {
        requestEndDay();
    }
    history.push(FRONT_PAGE_PATH);
    return null;
};
SignOut.propTypes = {
    endDay: PropTypes.bool.isRequired,
};
SignOut.defaultProps = {
    endDay: false,
};
var mapStateToProps = function (state) { return ({
    endDay: state.workOrders.endDay,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    emptyWorkOrderList: function () { return dispatch(workOrderListAction.emptyWorkOrderList()); },
    requestEndDay: function () { return dispatch(action.requestEndDay()); },
    requestLogout: function () { return dispatch(action.requestLogout()); },
    failureTokenVerification: function (errorMessage) {
        return dispatch(verifyAction.failureTokenVerification({ errorMessage: errorMessage }));
    },
}); };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignOut));
