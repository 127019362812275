var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TOGGLE_POPUP } from '../popupConstants';
var popupReducerDefaultState = {
    close: true,
    popupType: '',
};
export default (function (state, _a) {
    if (state === void 0) { state = popupReducerDefaultState; }
    var close = _a.close, popupType = _a.popupType, type = _a.type;
    switch (type) {
        case TOGGLE_POPUP:
            return __assign(__assign({}, state), { close: close, popupType: popupType });
        default:
            return state;
    }
});
