import PropTypes from 'prop-types';
import React from 'react';
import capitalize from '../../../../utils/capitalizeString';
import { GOOGLE_MAPS } from '../../../../constants/popupTypes';
import { HOUSE, TELEPHONE, USER } from '../../../../constants/iconNames';
import Icon from '../../../../icons';
import './customerInfo.scss';
import Variables from '../../../../styles/variables.scss';
var CustomerInfo = function (_a) {
    var _b = _a.address, city = _b.city, flatOrApartment = _b.flatOrApartment, houseNumberAlpha = _b.houseNumberAlpha, houseNumberNumeric = _b.houseNumberNumeric, postalCode = _b.postalCode, street = _b.street, _c = _a.customer, firstName = _c.firstName, phoneNumber = _c.phoneNumber, email = _c.email, surname = _c.surname, birthDate = _c.birthDate, togglePopup = _a.togglePopup, isPastDue = _a.isPastDue;
    var color = isPastDue ? Variables.redRemove : undefined;
    var birthDateExtracted = birthDate.split('T')[0].split('-');
    var formatedBirthDate = "".concat(birthDateExtracted[2], "-").concat(birthDateExtracted[1], "-").concat(birthDateExtracted[0]); // dd-mm-yyyy
    return (React.createElement("div", { className: "customer__info__container__items" },
        React.createElement("button", { className: isPastDue ? 'address--red' : 'address', onClick: function () { return togglePopup(false, GOOGLE_MAPS); } },
            React.createElement(Icon, { name: HOUSE, houseColor: color }),
            React.createElement("div", { className: "customer__info__container__items__item--address" },
                React.createElement("span", null, "".concat(capitalize(street), " ").concat(houseNumberNumeric).concat(houseNumberAlpha, ", ")),
                React.createElement("span", null, "".concat(postalCode, " ").concat(capitalize(city), " ")),
                flatOrApartment && React.createElement("span", null, flatOrApartment))),
        React.createElement("div", { className: "customer__info__container__items__item" },
            React.createElement("div", { className: "customer__info__container__items__item--name" },
                React.createElement("span", null,
                    React.createElement(Icon, { name: USER, color: color }), "".concat(capitalize(firstName), " ").concat(capitalize(surname))),
                React.createElement("div", { className: "phoneAndDobContainer" },
                    React.createElement("div", null, formatedBirthDate),
                    React.createElement("div", null,
                        React.createElement("a", { href: "mailto:".concat(email) }, email)),
                    React.createElement("div", { className: isPastDue ? 'telephone--red' : 'telephone' },
                        React.createElement("span", { className: "customer__telephone__icon" },
                            React.createElement(Icon, { name: TELEPHONE, color: color })),
                        React.createElement("a", { href: "tel:".concat(phoneNumber) }, phoneNumber)))))));
};
CustomerInfo.propTypes = {
    address: PropTypes.objectOf(PropTypes.any).isRequired,
    customer: PropTypes.objectOf(PropTypes.any).isRequired,
    togglePopup: PropTypes.func.isRequired,
    isPastDue: PropTypes.bool.isRequired,
};
export default CustomerInfo;
