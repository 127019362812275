import { REMOVE_PRODUCT_FAILURE, REMOVE_PRODUCT_REQUEST, REMOVE_PRODUCT_SUCCESS, TO_BE_REMOVED, CLEAR_REMOVE_ERROR_MESSAGE, } from '../customerProductsConstants';
export var toBeRemoved = function (_a) {
    var agreementDetailId = _a.agreementDetailId;
    return ({
        type: TO_BE_REMOVED,
        agreementDetailId: agreementDetailId,
    });
};
export var requestRemoveProduct = function (_a) {
    var agreementDetailId = _a.agreementDetailId, customerId = _a.customerId;
    return ({
        type: REMOVE_PRODUCT_REQUEST,
        agreementDetailId: agreementDetailId,
        customerId: customerId,
    });
};
export var receiveRemoveProduct = function (removedProductId) { return ({
    type: REMOVE_PRODUCT_SUCCESS,
    removedProductId: removedProductId,
}); };
export var errorRemoveProduct = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: REMOVE_PRODUCT_FAILURE,
        errorMessage: errorMessage,
    });
};
export var clearRemoveErrorMessage = function () { return ({
    type: CLEAR_REMOVE_ERROR_MESSAGE,
}); };
