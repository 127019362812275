import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var fill = _a.fill;
    return (React.createElement("svg", { width: "48px", height: "48px", viewBox: "0 0 48 48" },
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("g", { id: "icon/kryss_hvit_liten", fill: fill },
                React.createElement("path", { d: "M24,23.22177 L30.0356859,17.1658979 C30.249678,16.9511901 30.6041701,16.9439378 30.8274662,17.1496994 C31.0507623,17.355461 31.0583047,17.6963188 30.8443126,17.9110266 L24.7756359,24 L30.8443126,30.0889734 C31.0583047,30.3036812 31.0507623,30.644539 30.8274662,30.8503006 C30.6041701,31.0560622 30.249678,31.0488099 30.0356859,30.8341021 L24,24.77823 L17.9643141,30.8341021 C17.750322,31.0488099 17.3958299,31.0560622 17.1725338,30.8503006 C16.9492377,30.644539 16.9416953,30.3036812 17.1556874,30.0889734 L23.2243641,24 L17.1556874,17.9110266 C16.9416953,17.6963188 16.9492377,17.355461 17.1725338,17.1496994 C17.3958299,16.9439378 17.750322,16.9511901 17.9643141,17.1658979 L24,23.22177 Z", id: "Combined-Shape-Copy-2" })))));
};
SVG.propTypes = {
    fillColor: PropTypes.string,
};
SVG.defaultProps = {
    fillColor: Variables.whitePrimary,
};
export default SVG;
