import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var fill = _a.fill;
    return (React.createElement("svg", { width: "16px", height: "15px", viewBox: "0 0 16 15", version: "1.1" },
        React.createElement("title", null, "icon/Hjerte_gul Copy 2"),
        React.createElement("desc", null, "Created with Sketch."),
        React.createElement("defs", null,
            React.createElement("path", { d: "M8.16157518,15 C0.63670542,8.89483171 -1.58130804,4.3819648 1.0803593,1.61198366 C2.85480419,-0.234670438 5.21520949,0.0397205815 8.16157518,2.43515671 C11.0585477,-0.0106424504 13.3680105,-0.28503347 15.0899637,1.61198366 C17.6728934,4.45750935 14.6107917,10.1074087 8.16157518,15 Z", id: "path-1" })),
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: fill, fillRule: "evenodd" },
            React.createElement("g", { id: "icon/Hjerte_gul-Copy-2" },
                React.createElement("g", { id: "Hjerte_bl\u00E5-Copy" },
                    React.createElement("mask", { id: "mask-2", fill: "white" },
                        React.createElement("use", { xlinkHref: "#path-1" })),
                    React.createElement("use", { id: "Combined-Shape", fill: fill, xlinkHref: "#path-1" }))))));
};
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.blackSecondary,
};
export default SVG;
