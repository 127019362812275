import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import colorPicker from '../../../../utils/colorPicker';
import { POWER, SNR, UPSTREAM } from '../../../../constants/samSelector';
var StreamChannel = function (_a) {
    var channels = _a.channels, type = _a.type, scoreVariables = _a.scoreVariables;
    var renderChannels = channels.map(function (response) {
        return response.Snr !== -1000 && response.Frequency !== 0 ? (React.createElement("div", { className: "stream__channel__items", key: type + response.Channel },
            React.createElement("div", null, response.Channel),
            React.createElement("div", null, response.Frequency),
            React.createElement("div", { className: colorPicker(scoreVariables, POWER, type, response) }, response.Power),
            React.createElement("div", { className: colorPicker(scoreVariables, SNR, type, response) }, response.Snr))) : (React.createElement("div", null));
    });
    return (React.createElement("div", { className: "stream__channel" },
        React.createElement("div", { className: "stream__channel--headings" },
            React.createElement("div", null, NOB.DOWNSTREAM_UPSTREAM.CH),
            React.createElement("div", null,
                NOB.DOWNSTREAM_UPSTREAM.FREQ,
                React.createElement("br", null),
                NOB.DOWNSTREAM_UPSTREAM.MHZ),
            React.createElement("div", null,
                NOB.DOWNSTREAM_UPSTREAM.SIGNAL_POWER,
                React.createElement("br", null),
                NOB.DOWNSTREAM_UPSTREAM.DBMV),
            React.createElement("div", null,
                NOB.DOWNSTREAM_UPSTREAM.SNR,
                React.createElement("br", null),
                NOB.DOWNSTREAM_UPSTREAM.DB)),
        renderChannels));
};
StreamChannel.propTypes = {
    channels: PropTypes.arrayOf(PropTypes.any).isRequired,
    scoreVariables: PropTypes.objectOf(PropTypes.any).isRequired,
    type: PropTypes.string,
};
StreamChannel.defaultProps = {
    type: UPSTREAM,
};
export default StreamChannel;
