var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment/moment';
import dateFromToday from './dateFromToday';
import { CANCELLED, COMPLETE_WITHOUT_CODE } from '../constants/status-codes';
import { DASH_YEAR_MONTH_DAY, DAYS } from '../constants/dateFormats';
import { SERVICE_DATE } from '../constants/elements';
import sortMinToMaxObject from './sortMinToMaxObject';
export default function workOrderDays(workOrders) {
    // Sorted list of serviceDates
    var serviceDates = sortMinToMaxObject(workOrders, SERVICE_DATE).map(function (workOrder) { return workOrder.serviceDate; });
    // Last day with work orders
    var maxDaysAhead = moment(serviceDates[serviceDates.length - 1]).diff(moment(dateFromToday().date, DASH_YEAR_MONTH_DAY), DAYS);
    // List of days ahead
    var days = __spreadArray([], __read(Array(maxDaysAhead + 1).keys()), false);
    var allWorkOrders = [];
    var dates = [];
    // Goes through all days and pushes days with values to array for all work orders
    days.map(function (day) {
        // List of work orders for a specific day in array
        var workOrderDay = workOrders.filter(function (workOrder) { return workOrder.serviceDate === dateFromToday(day).date; });
        // List of cancelled work orders
        var workOrdersCancelled = workOrderDay.filter(function (workOrder) {
            return workOrder.status.includes(CANCELLED);
        });
        // List of not done work orders
        var workOrdersNotDone = workOrderDay.filter(function (workOrder) {
            return !workOrder.status.includes(CANCELLED) && !workOrder.status.includes(COMPLETE_WITHOUT_CODE);
        });
        // List of done work orders
        var workOrdersDone = workOrderDay.filter(function (workOrder) {
            return workOrder.status.includes(COMPLETE_WITHOUT_CODE);
        });
        // check if there are no work orders for the day
        var isWorkOrderListEmpty = !workOrderDay.length;
        // check if all work orders for the day are done
        var areAllWorkOrdersDone = workOrdersNotDone.length === 0 && (workOrdersDone.length > 0 || workOrdersCancelled > 0);
        // Only push day to array if the day has any work orders
        if (!isWorkOrderListEmpty || day === 0) {
            allWorkOrders = __spreadArray(__spreadArray([], __read(allWorkOrders), false), [
                {
                    date: dateFromToday(day).date,
                    workOrdersNotDone: workOrdersNotDone,
                    workOrdersDone: workOrdersDone,
                    workOrdersCancelled: workOrdersCancelled,
                    isWorkOrderListEmpty: isWorkOrderListEmpty,
                    areAllWorkOrdersDone: areAllWorkOrdersDone,
                },
            ], false);
        }
        dates = __spreadArray(__spreadArray([], __read(dates), false), [dateFromToday(day).date], false);
        return allWorkOrders;
    });
    /*
      allWorkOrders: list of work orders sorted by date
      dates: list of dates from today to max day ahead
    */
    return { allWorkOrders: allWorkOrders, dates: dates };
}
