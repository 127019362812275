// Map
export var ATTRIBUTION = 'attribution';
export var GEOCODE_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer';
export var LATITUDE = 59.9544862;
export var LONGITUDE = 10.760517;
export var NO = 'NO';
export var SCALE = 200;
export var UTM = '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
export var WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
export var WKID = 25833;
// Map id
export var MAP_ID_FAILURE = 'MAP_ID_FAILURE';
export var MAP_ID_REQUEST = 'MAP_ID_REQUEST';
export var MAP_ID_SUCCESS = 'MAP_ID_SUCCESS';
// Map token
export var MAP_TOKEN_FAILURE = 'MAP_TOKEN_FAILURE';
export var MAP_TOKEN_REQUEST = 'MAP_TOKEN_REQUEST';
export var MAP_TOKEN_SUCCESS = 'MAP_TOKEN_SUCCESS';
export var DEVELOP = 'develop';
export var ORIGIN = 'http://localhost:8080';
