import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import * as action from '../actions/popupActions';
import NOB from '../../../constants/language-strings';
var GoogleMapsButton = function (_a) {
    var address = _a.address, togglePopup = _a.togglePopup;
    var city = address.city, houseNumberAlpha = address.houseNumberAlpha, houseNumberNumeric = address.houseNumberNumeric, postalCode = address.postalCode, street = address.street;
    var fullAddress = "".concat(street, "+").concat(houseNumberNumeric, "+").concat(houseNumberAlpha, "%2C").concat(postalCode, "+").concat(city);
    return (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.google.com/maps/dir/?api=1&destination=".concat(fullAddress, "&travelmode=driving"), className: "popup__button", onClick: function () { return togglePopup(true); } }, NOB.GOOGLE_MAPS_POPUP.OPEN_MAP));
};
GoogleMapsButton.propTypes = {
    address: PropTypes.objectOf(PropTypes.any).isRequired,
    togglePopup: PropTypes.func.isRequired,
};
var mapStateToProps = function (state) { return ({
    address: state.workOrder.workOrder.address,
    close: state.popup.close,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    togglePopup: function (close) { return dispatch(action.togglePopup({ close: close })); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapsButton);
