var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import Item from './Item';
import colorPicker from '../../../../utils/colorPicker';
import { MIN_POWER, MAX_POWER, DOWNSTREAM, MIN_SNR, MAX_SNR, CORRECTED, UNCORRECTABLE, UPSTREAM, } from '../../../../constants/samSelector';
var Streams = function (_a) {
    var channels = _a.channels, type = _a.type, noOfConfigured = _a.noOfConfigured, scoreVariables = _a.scoreVariables;
    var minPower;
    var maxPower;
    var minSNR;
    var maxSNR;
    var correctables;
    var uncorrectables;
    if (channels.length > 0) {
        var powers = channels.map(function (transmitPower) { return transmitPower.Power; });
        minPower = Math.min.apply(Math, __spreadArray([], __read(powers), false)).toFixed(1);
        maxPower = Math.max.apply(Math, __spreadArray([], __read(powers), false)).toFixed(1);
        var SNRs = channels.map(function (SNR) { return SNR.Snr; });
        minSNR = Math.min.apply(Math, __spreadArray([], __read(SNRs), false)).toFixed(1);
        maxSNR = Math.max.apply(Math, __spreadArray([], __read(SNRs), false)).toFixed(1);
        var correctablesSum = channels.map(function (correctable) { return correctable.Corrected; });
        var uncorrectablesSum = channels.map(function (correctable) { return correctable.Uncorrectable; });
        var goodSum = channels.map(function (correctable) { return correctable.Good; });
        var sum = correctablesSum.reduce(function (a, b) { return a + b; }) +
            uncorrectablesSum.reduce(function (a, b) { return a + b; }) +
            goodSum.reduce(function (a, b) { return a + b; });
        correctables = ((correctablesSum.reduce(function (a, b) { return a + b; }) * 100) / sum).toFixed(2);
        uncorrectables = ((uncorrectablesSum.reduce(function (a, b) { return a + b; }) * 100) / sum).toFixed(2);
    }
    return (React.createElement("div", null,
        React.createElement(Item, { value: "".concat(channels.length, "/").concat(noOfConfigured), header: NOB.DOWNSTREAM_UPSTREAM.CHANNELS }),
        React.createElement(Item, { value: minPower, secondValue: maxPower, statusColor: colorPicker(scoreVariables, MIN_POWER, type, channels), secondColor: colorPicker(scoreVariables, MAX_POWER, type, channels), header: type === DOWNSTREAM
                ? NOB.DOWNSTREAM_UPSTREAM.RECEIVED_POWER
                : NOB.DOWNSTREAM_UPSTREAM.TRANSMIT_POWER, unit: "dBmV" }),
        React.createElement(Item, { value: minSNR, secondValue: maxSNR, statusColor: colorPicker(scoreVariables, MIN_SNR, type, channels), secondColor: colorPicker(scoreVariables, MAX_SNR, type, channels), header: NOB.DOWNSTREAM_UPSTREAM.SNR_MIN, unit: "dB" }),
        React.createElement(Item, { value: correctables, header: NOB.DOWNSTREAM_UPSTREAM.CORRECTABLE, statusColor: colorPicker(scoreVariables, CORRECTED, type, channels), unit: "%" }),
        React.createElement(Item, { value: uncorrectables, header: NOB.DOWNSTREAM_UPSTREAM.UNCORRECTABLE, statusColor: colorPicker(scoreVariables, UNCORRECTABLE, type, channels), unit: "%" })));
};
Streams.propTypes = {
    channels: PropTypes.arrayOf(PropTypes.any),
    type: PropTypes.string,
    noOfConfigured: PropTypes.number,
    scoreVariables: PropTypes.objectOf(PropTypes.any),
};
Streams.defaultProps = {
    channels: [],
    type: UPSTREAM,
    noOfConfigured: 0,
    scoreVariables: {},
};
export default Streams;
