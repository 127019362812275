var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import NOB from '../../constants/language-strings';
import PropTypes from 'prop-types';
import styled from 'styled-components';
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  max-width: 498px;\n  margin: 48px 16px;\n  padding: 64px 80px;\n  background: white;\n  border-radius: 0.33em;\n"], ["\n  display: flex;\n  flex-direction: column;\n  max-width: 498px;\n  margin: 48px 16px;\n  padding: 64px 80px;\n  background: white;\n  border-radius: 0.33em;\n"])));
var StyledWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var StyledInput = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: block;\n  width: 100%;\n  height: 46px;\n  margin: 4px 0 10px;\n  padding: 10px 12px;\n  font-size: 16px;\n  line-height: 1.5;\n  color: rgba(0, 0, 0, 0.85) !important;\n  background-color: #ffffff;\n  border: 1px solid rgba(0, 0, 0, 0.3) !important;\n  border-radius: 2px;\n  -webkit-appearance: none;\n  appearance: none;\n"], ["\n  box-sizing: border-box;\n  display: block;\n  width: 100%;\n  height: 46px;\n  margin: 4px 0 10px;\n  padding: 10px 12px;\n  font-size: 16px;\n  line-height: 1.5;\n  color: rgba(0, 0, 0, 0.85) !important;\n  background-color: #ffffff;\n  border: 1px solid rgba(0, 0, 0, 0.3) !important;\n  border-radius: 2px;\n  -webkit-appearance: none;\n  appearance: none;\n"])));
var StyledError = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 8px;\n  margin-bottom: 10px;\n  color: rgb(190, 0, 64);\n"], ["\n  display: flex;\n  margin-top: 8px;\n  margin-bottom: 10px;\n  color: rgb(190, 0, 64);\n"])));
var StyledButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: auto;\n  padding: 16px 24px;\n  margin-top: 20px;\n  background-color: #29003e;\n  color: white;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 16px;\n  border-radius: 99px;\n  border: 1px solid #29003e !important;\n  outline: 0;\n  transition: all 150ms ease;\n  &:hover {\n    background: #5e0092;\n    color: white;\n  }\n"], ["\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  width: auto;\n  padding: 16px 24px;\n  margin-top: 20px;\n  background-color: #29003e;\n  color: white;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 16px;\n  border-radius: 99px;\n  border: 1px solid #29003e !important;\n  outline: 0;\n  transition: all 150ms ease;\n  &:hover {\n    background: #5e0092;\n    color: white;\n  }\n"])));
export var AuthenticationDev = function (_a) {
    var requestDevSignIn = _a.requestDevSignIn, signInError = _a.signInError;
    var _b = __read(useState({
        username: '',
        password: '',
        technician: '',
    }), 2), credentials = _b[0], setCredentials = _b[1];
    var username = credentials.username, password = credentials.password, technician = credentials.technician;
    var handleChange = function (event) {
        var _a;
        setCredentials(__assign(__assign({}, credentials), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        requestDevSignIn({
            credentials: {
                username: username,
                password: password,
                technician: technician,
            },
        });
    };
    return (React.createElement(StyledWrapper, null,
        React.createElement(StyledForm, { onSubmit: function (e) { return handleSubmit(e); } },
            React.createElement("h1", null, NOB.SIGN_IN.HEADER),
            React.createElement("div", null,
                React.createElement("label", { htmlFor: "username" }, NOB.SIGN_IN.INPUT_USERNAME),
                React.createElement(StyledInput, { name: "username", type: "text", autoComplete: "on", value: username, onChange: function (e) { return handleChange(e); } })),
            React.createElement("div", null,
                React.createElement("label", { htmlFor: "password" }, NOB.SIGN_IN.INPUT_PASSWORD),
                React.createElement(StyledInput, { name: "password", type: "password", autoComplete: "on", value: password, onChange: function (e) { return handleChange(e); } })),
            React.createElement("div", null,
                React.createElement("label", { htmlFor: "technician" }, NOB.SIGN_IN.TECHNICIAN),
                React.createElement(StyledInput, { name: "technician", type: "text", autoComplete: "on", value: technician, onChange: function (e) { return handleChange(e); } })),
            signInError && React.createElement(StyledError, null, NOB.SIGN_IN.ERROR_TEXT),
            React.createElement(StyledButton, { type: "submit" },
                NOB.SIGN_IN.SIGN_IN_BUTTON,
                " "))));
};
AuthenticationDev.propTypes = {
    signInError: PropTypes.bool.isRequired,
    requestDevSignIn: PropTypes.func.isRequired,
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
