var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import DeviceStatusList from '../components/DeviceStatusList/DeviceStatusList';
import HealthCheckHeader from '../components/healthCheck/HealthCheckHeader';
import HealthCheckList from '../components/healthCheck/HealthCheckList';
import NOB from '../../../constants/language-strings';
import * as action from '../actions';
import '../healthCheck.scss';
import InfoBoxError from '../../../components/InfoBox/InfoBoxError';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import IconButton from '../../../components/Buttons/IconButton/IconButton';
import { REFRESH } from '../../../constants/iconNames';
import { requestUpdateDevicePollingMac, requestDevicePollingMac, } from '../actions/devicePollingMacActions';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';
import Feedback from '../../../feedbacks';
import { FEEDBACK_NOT_FOUND } from '../../../constants/feedbackNames';
import DeviceHealthCheckContainer from '../../DeviceHealthCheck/DeviceHealthCheckContainer';
import Sam from './Sam';
import Platform from '../../../constants/platform';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import HitDecoderContainer from '../../HealthCheck/provisioning/HitDecoderContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            cacheTime: 0,
        },
    },
});
function HealthCheckContainer(_a) {
    var customerId = _a.customerId, requestHealthCheck = _a.requestHealthCheck, requestDevicePollingAllDevices = _a.requestDevicePollingAllDevices, requestIccCheck = _a.requestIccCheck, healthCheck = _a.healthCheck, requestUpdateHealthCheck = _a.requestUpdateHealthCheck, devicePollingAllDevices = _a.devicePollingAllDevices, isFetching = _a.isFetching, isIccFetching = _a.isIccFetching, isFetchingProvisioning = _a.isFetchingProvisioning, devicePollingError = _a.devicePollingError, isUpdateFetching = _a.isUpdateFetching, devicePollingSingleDeviceError = _a.devicePollingSingleDeviceError, requestUpdateDevicePollingSingleDevice = _a.requestUpdateDevicePollingSingleDevice, requestDevicePollingSingleDevice = _a.requestDevicePollingSingleDevice, languageStrings = _a.languageStrings;
    var _b = __read(useState(false), 2), displaySam = _b[0], setDisplaySam = _b[1];
    var _c = __read(useState({}), 2), displaySamDevice = _c[0], setDisplaySamDevice = _c[1];
    var _d = __read(useState(false), 2), IsVisibleHitDecoderModal = _d[0], setIsVisibleHitDecoderModal = _d[1];
    useEffect(function () {
        if (customerId !== 0) {
            requestHealthCheck(customerId);
            requestDevicePollingAllDevices(customerId);
            requestIccCheck(customerId);
        }
    }, [customerId]);
    var updateHealthCheck = function () {
        var healthCheckIds = healthCheck.provisioning.healthCheckResponses
            .filter(function (filterResponse) { return filterResponse.canUpdate; })
            .map(function (mapResponse) { return mapResponse.id; });
        requestUpdateHealthCheck(customerId, healthCheckIds);
    };
    var toggleDisplaySam = function (selectedProduct) {
        setDisplaySam(!displaySam);
        if (selectedProduct) {
            setDisplaySamDevice(selectedProduct);
        }
        else {
            setDisplaySamDevice('');
        }
    };
    var closeHealthCheckModal = function (event) {
        event.persist();
        setDisplaySam(false);
        setDisplaySamDevice('');
    };
    var refresh = function () {
        return new Promise(function () {
            if (!isUpdateFetching && !devicePollingSingleDeviceError && displaySamDevice.pollIdentifier) {
                requestUpdateDevicePollingSingleDevice(displaySamDevice.pollIdentifier);
            }
            else {
                requestDevicePollingSingleDevice(displaySamDevice.pollUrl);
            }
        });
    };
    return (React.createElement(SlideInPage, { mainHeading: NOB.HEALTH_CHECK.HEALTH_CHECK, showLeftIcon: true, showRightIcon: false },
        React.createElement("div", { className: "healthCheck__workOrder__details" },
            React.createElement(HealthCheckHeader, { header: NOB.HEALTH_CHECK.PROVISIONING_HEADING, healthCheck: healthCheck, updateHealthCheck: updateHealthCheck, deviceCheck: devicePollingAllDevices.checkPassedStatus, isFetching: isFetching, isIccFetching: isIccFetching, isFetchingProvisioning: isFetchingProvisioning }),
            React.createElement(HealthCheckList, { updateHealthCheck: updateHealthCheck, healthCheck: healthCheck, devicePollingCheckPassed: devicePollingAllDevices.checkPassed, isIccFetching: isIccFetching, isFetchingProvisioning: isFetchingProvisioning }),
            React.createElement("div", { className: "device__status__container" },
                React.createElement("div", { className: "device__status__container__heading" }, NOB.HEALTH_CHECK.DEVICE_STATUS),
                devicePollingAllDevices.deviceStatus.length > 0 && !isFetching && (React.createElement(DeviceStatusList, { devices: devicePollingAllDevices.deviceStatus, toggleDisplaySam: toggleDisplaySam, error: devicePollingError })),
                !devicePollingAllDevices.deviceStatus.length && !isFetching && (React.createElement(InfoBoxError, { feedback: React.createElement(Feedback, { name: FEEDBACK_NOT_FOUND }), firstInfoBoxLine: devicePollingError || languageStrings.NO_DEVICES_FOUND, link: false }))),
            React.createElement("div", { className: "device__status__container" },
                React.createElement(SimpleButton, { color: Color.BLACK, title: "Reprovisjoner boks", onClick: function () {
                        setIsVisibleHitDecoderModal(function (state) { return !state; });
                    } }))),
        // TODO: Refactor HFC endpoint and remove this conditional render
        devicePollingAllDevices.platform.toUpperCase() === Platform.HFC ? (React.createElement(SlideInModal, { display: displaySam, onClose: closeHealthCheckModal, mainHeading: displaySamDevice.description, leftIcon: isUpdateFetching ? (React.createElement(ClipLoader, { size: 20 })) : (React.createElement(IconButton, { onClick: refresh, icon: REFRESH, className: "sam_refresh-button" })) },
            React.createElement(Sam, { pollIdentifier: displaySamDevice.pollIdentifier, pollUrl: displaySamDevice.pollUrl }))) : (React.createElement(DeviceHealthCheckContainer, __assign({}, displaySamDevice, { display: displaySam, onClose: closeHealthCheckModal }))),
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(HitDecoderContainer, { isVisible: IsVisibleHitDecoderModal, customerId: customerId, onClose: function () { return setIsVisibleHitDecoderModal(function (state) { return !state; }); } }))));
}
HealthCheckContainer.propTypes = {
    customerId: PropTypes.number,
    devicePollingAllDevices: PropTypes.objectOf(PropTypes.any),
    devicePollingError: PropTypes.string.isRequired,
    healthCheck: PropTypes.objectOf(PropTypes.any),
    isFetching: PropTypes.bool.isRequired,
    isFetchingProvisioning: PropTypes.bool.isRequired,
    isUpdateFetching: PropTypes.bool.isRequired,
    isIccFetching: PropTypes.bool.isRequired,
    requestDevicePollingAllDevices: PropTypes.func.isRequired,
    requestHealthCheck: PropTypes.func.isRequired,
    requestIccCheck: PropTypes.func.isRequired,
    requestUpdateDevicePollingSingleDevice: PropTypes.func.isRequired,
    requestDevicePollingSingleDevice: PropTypes.func.isRequired,
    requestUpdateHealthCheck: PropTypes.func.isRequired,
    devicePollingSingleDeviceError: PropTypes.string.isRequired,
    languageStrings: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
        .isRequired,
};
HealthCheckContainer.defaultProps = {
    customerId: null,
    devicePollingAllDevices: {},
    healthCheck: {},
};
var mapStateToProps = function (state) {
    var _a, _b, _c;
    return ({
        customerId: ((_c = (_b = (_a = state.workOrder) === null || _a === void 0 ? void 0 : _a.workOrder) === null || _b === void 0 ? void 0 : _b.customer) === null || _c === void 0 ? void 0 : _c.customerId) || 0,
        devicePollingSingleDeviceError: state.devicePollingMac.errorMessage,
        workOrder: state.workOrder,
        customerProducts: state.customerProducts.customerProducts,
        devicePollingAllDevices: state.devicePollingCustomer.devicePollingCustomer,
        healthCheck: state.healthCheck,
        isIccFetching: state.healthCheck.isIccFetching,
        isFetching: state.devicePollingCustomer.isFetching,
        devicePollingError: state.devicePollingCustomer.errorMessage,
        isFetchingProvisioning: state.healthCheck.isFetching,
        isUpdateFetching: state.updateDevicePollingMac.isFetching,
        languageStrings: state.NOB.HEALTH_CHECK,
    });
};
var mapDispatchToProps = function (dispatch) { return ({
    requestHealthCheck: function (customerId) { return dispatch(action.requestHealthCheck({ customerId: customerId })); },
    requestUpdateHealthCheck: function (customerId, healthCheckIds) {
        return dispatch(action.requestUpdateHealthCheck({ customerId: customerId, healthCheckIds: healthCheckIds }));
    },
    requestDevicePollingAllDevices: function (customerId) {
        return dispatch(action.requestDevicePollingCustomer({ customerId: customerId }));
    },
    requestIccCheck: function (customerId) { return dispatch(action.requestIccCheck(customerId)); },
    requestUpdateDevicePollingSingleDevice: function (macAddress) {
        return dispatch(requestUpdateDevicePollingMac({ macAddress: macAddress }));
    },
    requestDevicePollingSingleDevice: function (pollUrl) { return dispatch(requestDevicePollingMac(pollUrl)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(HealthCheckContainer);
