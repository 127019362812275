var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExtendedImage from '../../../../components/ImageViewer/ImageViewer';
import './textBox.scss';
import Icon from '../../../../icons';
import { PDF } from '../../../../constants/iconNames';
import isImage from '../../../../utils/isImage';
function TextBox(_a) {
    var heading = _a.heading, text = _a.text, attachments = _a.attachments, languageStrings = _a.languageStrings;
    var _b = __read(useState(false), 2), expanded = _b[0], setIsExpanded = _b[1];
    var _c = __read(useState(0), 2), chosenImage = _c[0], setChosenImage = _c[1];
    var attachmentList = attachments.attachmentList.map(function (attachment, index) {
        if (!isImage(attachment.href)) {
            var fileEnding = attachment.href
                .substring(attachment.href.lastIndexOf('.') + 1)
                .toUpperCase();
            var title = fileEnding.length > 4 ? languageStrings.DEFAULT_FILE_NAME : fileEnding;
            return (React.createElement("a", { href: attachment.href, rel: "noopener noreferrer", target: "_blank", key: attachment.href },
                React.createElement(Icon, { name: PDF, title: title })));
        }
        return (React.createElement("button", { onClick: function () {
                setIsExpanded(true);
                setChosenImage(index);
            }, key: attachment.href },
            React.createElement("img", { src: attachment.href, alt: "Vedlegg" })));
    });
    var imageFiles = attachments.attachmentList
        .map(function (image) { return image.href; })
        .filter(function (link) { return isImage(link); });
    return (React.createElement("div", { className: "comment__container" },
        React.createElement("div", { className: "comment-header" }, heading),
        React.createElement("div", { className: "comment__content" },
            text,
            attachments.hasError && React.createElement("div", { className: "error" }, languageStrings.ATTACHMENT_ERROR),
            attachmentList.length > 0 && !attachments.hasError && (React.createElement("div", { className: "attachment-list" }, attachmentList)),
            expanded && (React.createElement(ExtendedImage, { expandedIndex: chosenImage, imagesFiles: imageFiles, toggleExpandImage: function () { return setIsExpanded(!expanded); } })))));
}
TextBox.propTypes = {
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    attachments: PropTypes.objectOf(PropTypes.any),
    languageStrings: PropTypes.objectOf(PropTypes.string).isRequired,
};
TextBox.defaultProps = {
    attachments: {
        hasError: false,
        attachmentList: [],
    },
};
var mapStateToProps = function (state) { return ({
    languageStrings: state.NOB.COMMENT,
}); };
export default connect(mapStateToProps)(TextBox);
