var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNextStatus, NOT_FOUND } from '../constants/status-codes';
import { putAPI } from '../api/apiConfig';
import { STATUS } from '../constants/endpoints';
import { STATUS_CHANGE_REQUEST } from '../modules/Workorder/workOrderConstants';
import * as workOrderAction from '../modules/Workorder/actions/workOrder';
import { workorderServicesRequest } from '../modules/WorkLog/actions/workOrderServiceActions';
import NOB from '../constants/language-strings';
function callChangeStatus(_a) {
    var currentWorkOrder, _b, nextStatus, url, requestBody, newStatus, error_1, errorMessage;
    var currentStatus = _a.currentStatus;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _b = [{}];
                return [4 /*yield*/, select(function (state) { return state.workOrder.workOrder; })];
            case 1:
                currentWorkOrder = __assign.apply(void 0, _b.concat([(_c.sent())]));
                nextStatus = getNextStatus(currentStatus);
                _c.label = 2;
            case 2:
                _c.trys.push([2, 6, , 8]);
                if (nextStatus === NOT_FOUND) {
                    throw new Error('No next status code found');
                }
                url = STATUS(currentWorkOrder.workOrderId);
                requestBody = { status: nextStatus };
                return [4 /*yield*/, call(putAPI, url, requestBody)];
            case 3:
                newStatus = _c.sent();
                return [4 /*yield*/, put(workOrderAction.checkForStatusSkip(newStatus, currentWorkOrder.workOrderId))];
            case 4:
                _c.sent();
                // Update services after workorder has changed status
                return [4 /*yield*/, put(workorderServicesRequest(currentWorkOrder.workOrderId))];
            case 5:
                // Update services after workorder has changed status
                _c.sent();
                return [3 /*break*/, 8];
            case 6:
                error_1 = _c.sent();
                errorMessage = error_1.message
                    ? error_1.message
                    : "".concat(NOB.WORKORDER_BUTTON.ERROR, " ").concat(error_1.response.data.message, " ").concat(NOB.WORKORDER_BUTTON.CONTACT_KETIL);
                return [4 /*yield*/, put(workOrderAction.errorStatusChange({
                        errorMessage: errorMessage,
                        workOrder: currentWorkOrder,
                    }))];
            case 7:
                _c.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export default [takeLatest(STATUS_CHANGE_REQUEST, callChangeStatus)];
