import React from 'react';
import PropTypes from 'prop-types';
import { ARROW_DOWN } from '../../../../../constants/iconNames';
import { EXPANDING, EXPANDED } from '../../../workOrderListConstants';
import Icon from '../../../../../icons';
import NOB from '../../../../../constants/language-strings';
import Variables from '../../../../../styles/variables.scss';
export default function DropdownButton(_a) {
    var onToggle = _a.onToggle, toggleState = _a.toggleState;
    return (React.createElement("button", { className: "product__list__dropdown", onClick: onToggle },
        React.createElement("span", null, NOB.PRODUCT_LIST.TODAYS_PRODUCT_LIST),
        React.createElement("span", { className: toggleState === EXPANDING || toggleState === EXPANDED ? 'rotate-up' : 'rotate-down' },
            React.createElement(Icon, { stroke: Variables.blackPrimary, name: ARROW_DOWN }))));
}
DropdownButton.propTypes = {
    onToggle: PropTypes.func.isRequired,
    toggleState: PropTypes.string.isRequired,
};
