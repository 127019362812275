import React from 'react';
import PropTypes from 'prop-types';
import { MetroSpinner } from 'react-spinners-kit';
import { iconBlue } from '../styles/variables.scss';
var FetchingSpinner = function (_a) {
    var color = _a.color, size = _a.size;
    return (React.createElement("div", { className: "loading" },
        React.createElement(MetroSpinner, { color: color, size: size })));
};
FetchingSpinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};
FetchingSpinner.defaultProps = {
    color: iconBlue,
    size: 40,
};
export default FetchingSpinner;
