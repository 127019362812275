export var ARCHIVE = 'archive';
export var ARROW_DOWN = 'arrowDown';
export var ARROW_LEFT = 'arrowLeft';
export var CALENDAR = 'calendar';
export var CAMERA = 'camera';
export var CAR = 'car';
export var CHECK = 'check';
export var CLOCK = 'clock';
export var CROSS = 'cross';
export var CROSS_LARGE = 'crossLarge';
export var DELETE = 'delete';
export var EDIT = 'edit';
export var EXPAND = 'expand';
export var GET_BOX = 'getBox';
export var HEALTH_CHECK = 'healthCheck';
export var HEART = 'heart';
export var HISTORY = 'history';
export var HOUSE = 'house';
export var LINK = 'link';
export var MAP = 'map';
export var MINIMIZE = 'minimize';
export var PLUS = 'plus';
export var REFRESH = 'refresh';
export var SAVE = 'save';
export var SCAN = 'scan';
export var SEARCH = 'search';
export var STATUS_ERROR = 'statusError';
export var STATUS_OK = 'statusOk';
export var SWITCH = 'switch';
export var TELEPHONE = 'telephone';
export var TOOL = 'tool';
export var TOOL_OULINED = 'toolOutlined';
export var TRASH = 'trash';
export var USER = 'user';
export var PDF = 'pdf';
export var SWITCH2 = 'switch2';
