var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading';
import Icon from '../../../../../icons';
import NOB from '../../../../../constants/language-strings';
import { SWITCH } from '../../../../../constants/iconNames';
import './changeProduct.scss';
import useForm from '../../../../../utils/customHooks/useForm';
import DeviceElement from './DeviceElement';
export default function ChangeProduct(_a) {
    var _b = _a.changeProductState, changedProduct = _b.changedProduct, dpadId = _b.dpadId, errorProducts = _b.errorProducts, isFetchingChangeProduct = _b.isFetchingChangeProduct, clearErrorMessage = _a.clearErrorMessage, product = _a.product, requestChangeProduct = _a.requestChangeProduct;
    var onSubmit = function (values) {
        var reload = !(product.devices.length > 1);
        Object.entries(values)
            .filter(function (_a) {
            var _b = __read(_a, 2), value = _b[1];
            return value;
        })
            .forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            requestChangeProduct(product.productId, key, value, reload);
        });
    };
    var _c = useForm(function () {
        onSubmit(values);
    }), values = _c.values, handleChange = _c.handleChange, handleSubmit = _c.handleSubmit, overrideValue = _c.overrideValue;
    var clearErrorMessages = function (dpadIdToRemove) {
        errorProducts.forEach(function (error) {
            if (parseInt(error.dpadId, 10) === dpadIdToRemove)
                clearErrorMessage(error);
        });
    };
    var onReset = function (dpad) {
        overrideValue("".concat(dpad), '');
        clearErrorMessages(dpad);
    };
    var handleInputChange = function (e) {
        e.persist();
        clearErrorMessages(parseInt(e.target.name, 10));
        handleChange(e);
    };
    // Nedenfor er copy-pastet etter refaktorering. Bør definitivt gjøres om til en egen komponent/container
    var mapDevices = function (devices) {
        return devices.map(function (device) {
            var hasChanged = changedProduct.includes(device.serialNumber);
            var errorMessage = errorProducts.find(function (errorProduct) { return parseInt(errorProduct.dpadId, 10) === device.dpadId; })
                ? errorProducts
                    .reverse()
                    .find(function (errorProduct) { return parseInt(errorProduct.dpadId, 10) === device.dpadId; }).errorMessage
                : '';
            var serialNumberInput = {
                dpadId: device.dpadId,
                value: values[device.dpadId],
                errorMessage: errorMessage,
            };
            var onScannerDetect = function (result) {
                var regex = new RegExp('^[A-Z0-9]*$');
                if (regex.test(result.codeResult.code)) {
                    overrideValue("".concat(device.dpadId), result.codeResult.code);
                    clearErrorMessages(device.dpadId);
                    var reload = !(product.devices.length > 1);
                    requestChangeProduct(product.productId, device.dpadId, result.codeResult.code, reload);
                }
            };
            return (React.createElement(DeviceElement, { device: device, onReset: onReset, hasChanged: hasChanged, serialNumberInput: serialNumberInput, onScannerDetect: onScannerDetect, isFetching: isFetchingChangeProduct && parseInt(dpadId, 10) === device.dpadId, handleChange: handleInputChange, handleSubmit: handleSubmit, status: product.status, key: device.dpadId }));
        });
    };
    return (React.createElement("div", { className: "change-product" },
        React.createElement(Heading, { icon: React.createElement(Icon, { name: SWITCH }), mainHeading: NOB.CHANGE_PRODUCT.SWAP_UNIT, subHeading: NOB.CHANGE_PRODUCT.ACTIVATE_UNIT }),
        React.createElement("div", { className: "change-product__input" }, mapDevices(product.devices))));
}
ChangeProduct.propTypes = {
    changeProductState: PropTypes.objectOf(PropTypes.any).isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    product: PropTypes.objectOf(PropTypes.any).isRequired,
    requestChangeProduct: PropTypes.func.isRequired,
};
