import { FIND_SERVICES_REQUEST, FIND_SERVICES_SUCCESS, FIND_SERVICES_ERROR, } from '../workLogConstants';
export var findServicesRequest = function (_a) {
    var workOrderId = _a.workOrderId;
    return ({
        type: FIND_SERVICES_REQUEST,
        workOrderId: workOrderId,
    });
};
export var findServicesSuccess = function (_a) {
    var services = _a.services;
    return ({
        type: FIND_SERVICES_SUCCESS,
        services: services,
    });
};
export var findServicesError = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: FIND_SERVICES_ERROR,
        errorMessage: errorMessage,
    });
};
