var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { withRouter } from 'react-router';
import CustomerInfoContainer from '../../components/customerInfo/CustomerInfoContainer';
import Feedback from '../../../../feedbacks';
import FetchingSpinner from '../../../../utils/FetchingSpinner';
import { GOOGLE_MAPS } from '../../../../constants/popupTypes';
import GoogleMapsButton from '../../../../components/Popup/components/GoogleMapsButton';
import Popup from '../../../../components/Popup/containers/Popup';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import MapContainer from '../../../Map/containers/MapContainer';
import NOB from '../../../../constants/language-strings';
import TechnicalDetails from '../../components/technicalDetails/TechnicalDetails';
import { EXTERNAL_INSTALLER } from '../../../Authentication/constants';
import { SCHEDULED_ACTIVATION, WAITING_ACTIVATION } from '../../../../constants/productStatuses';
import UNAUTHORIZED from '../../../../constants/errorStatusCodes';
import * as toggleAction from '../../../../components/Popup/actions/popupActions';
import '../../../../components/Popup/popup.scss';
import './customerPage.scss';
import NavigationButton from '../../../../components/Buttons/NavigationButton/NavigationButton';
import { CUSTOMER_INFO_ADDRESS_HISTORY_URL, CUSTOMER_INFO_CUSTOMER_PRODUCTS_URL, CUSTOMER_INFO_HEALTH_CHECK_URL, } from '../../../../constants/paths';
import Icon from '../../../../icons';
import Variables from '../../../../styles/variables.scss';
import { GET_BOX, HEALTH_CHECK, HISTORY } from '../../../../constants/iconNames';
import { requestWorkOrderByCustomerInfo } from '../../actions/workOrder';
var CustomerPage = /** @class */ (function (_super) {
    __extends(CustomerPage, _super);
    function CustomerPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            completed: false,
        };
        return _this;
    }
    CustomerPage.prototype.componentDidMount = function () {
        var _a = this.props, customerId = _a.customerId, dispatchAll = _a.dispatchAll;
        dispatchAll(customerId);
    };
    CustomerPage.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, dispatchAll = _a.dispatchAll, customerId = _a.customerId;
        if (customerId !== prevProps.customerId) {
            dispatchAll(customerId);
        }
    };
    CustomerPage.prototype.render = function () {
        var _a;
        var _b = this.props, addressHistory = _b.addressHistory, close = _b.close, customerProducts = _b.customerProducts, match = _b.match, popupType = _b.popupType, togglePopupFunc = _b.togglePopupFunc, _c = _b.workOrder, workOrder = _c.workOrder, errorMessage = _c.errorMessage, isFetching = _c.isFetching, customerId = _b.customerId, areProductsFetching = _b.areProductsFetching;
        var waitingActivationsLength = customerProducts.filter(function (waitingActivation) {
            return waitingActivation.status === WAITING_ACTIVATION ||
                waitingActivation.status === SCHEDULED_ACTIVATION;
        }).length;
        var newWorkOrderEntered = !customerId || match.params.id !== "".concat(customerId);
        if (isFetching) {
            return (React.createElement("div", { className: "work__order__container" },
                React.createElement(FetchingSpinner, null)));
        }
        var noAccess = errorMessage.includes(UNAUTHORIZED);
        if (errorMessage) {
            return (React.createElement("div", { className: "work__order__container" },
                React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), link: noAccess, secondInfoBoxLine: noAccess ? NOB.NO_WORK_ORDERS.NO_ACCESS : errorMessage })));
        }
        if (workOrder === undefined || workOrder.workOrderId === 0) {
            return (React.createElement("div", { className: "work__order__container" },
                React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), link: true, secondInfoBoxLine: "Unable to retrieve work order information. Try to refresh browser or login again." })));
        }
        return (React.createElement("div", { className: "work__order__container" },
            React.createElement("div", null,
                React.createElement("div", { className: "work__order" },
                    React.createElement("div", { className: "work__order__content" },
                        React.createElement("div", { className: "work__order__content--top" },
                            React.createElement("div", { className: "work__order__content--customer-info-container" },
                                React.createElement(CustomerInfoContainer, { address: workOrder.address, addressDetails: workOrder.addressDetails, customer: workOrder.customer, togglePopup: togglePopupFunc, workOrder: workOrder })),
                            addressHistory.length > 0 && (React.createElement(NavigationButton, { URL: CUSTOMER_INFO_ADDRESS_HISTORY_URL(customerId), leftIcon: React.createElement(Icon, { name: HISTORY }), title: NOB.WORKORDER_TABS.ADDRESS_HISTORY, number: addressHistory.length })),
                            React.createElement(NavigationButton, { URL: CUSTOMER_INFO_CUSTOMER_PRODUCTS_URL(customerId), title: NOB.CUSTOMER_PRODUCTS.CUSTOMER_PRODUCTS, leftIcon: areProductsFetching ? (React.createElement(ClipLoader, { size: 16, color: Variables.grayButton })) : (React.createElement(Icon, { fill: waitingActivationsLength === 0 ? Variables.iconBlue : Variables.buttonRed, name: GET_BOX })), number: waitingActivationsLength > 0 ? waitingActivationsLength : null, numberColor: waitingActivationsLength > 0 ? NOB.COMPONENT.RED : null }),
                            React.createElement(NavigationButton, { URL: CUSTOMER_INFO_HEALTH_CHECK_URL(customerId), leftIcon: React.createElement(Icon, { name: HEALTH_CHECK }), title: NOB.HEALTH_CHECK.HEALTH_CHECK }),
                            React.createElement(TechnicalDetails, { technicalDetails: workOrder.technicalDetails, capacity: (_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.capacity) === null || _a === void 0 ? void 0 : _a.data })),
                        !close && popupType === GOOGLE_MAPS && (React.createElement(Popup, { firstLine: NOB.GOOGLE_MAPS_POPUP.GOOGLE_MAPS, secondLine: NOB.GOOGLE_MAPS_POPUP.QUESTION, button: React.createElement(GoogleMapsButton, null) })),
                        React.createElement(MapContainer, { newWorkOrderEntered: newWorkOrderEntered }))))));
    };
    return CustomerPage;
}(Component));
CustomerPage.propTypes = {
    addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
    close: PropTypes.bool,
    customerProducts: PropTypes.arrayOf(PropTypes.any),
    isFetching: PropTypes.bool,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatchAll: PropTypes.func.isRequired,
    popupType: PropTypes.string,
    role: PropTypes.string,
    togglePopupFunc: PropTypes.func.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any),
    customerId: PropTypes.number,
    areProductsFetching: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
CustomerPage.defaultProps = {
    close: true,
    customerProducts: [],
    popupType: '',
    workOrder: {
        workOrder: {},
        errorMessage: '',
    },
    role: EXTERNAL_INSTALLER,
    customerId: 0,
    isFetching: false,
};
var mapStateToProps = function (state) { return ({
    addressHistory: state.addressHistory.addressHistory,
    close: state.popup.close,
    customerProducts: state.customerProducts.customerProducts,
    isPrimaryFetching: state.workOrder.isPrimaryFetching,
    areProductsFetching: state.customerProducts.isFetching,
    popupType: state.popup.popupType,
    role: state.authentication.role,
    workOrder: state.workOrder,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    dispatchAll: function (customerId) {
        dispatch(requestWorkOrderByCustomerInfo(customerId));
    },
    togglePopupFunc: function (close, popupType) { return dispatch(toggleAction.togglePopup({ close: close, popupType: popupType })); },
}); };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerPage));
