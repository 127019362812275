var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import PropTypes from 'prop-types';
export default function Products(_a) {
    var productList = _a.productList;
    var products = Object.entries(productList).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return (React.createElement("div", { className: "product__list__container--items--item", key: key },
            React.createElement("span", null, value),
            React.createElement("span", null, key)));
    });
    return products;
}
Products.propTypes = {
    productList: PropTypes.objectOf(PropTypes.any).isRequired,
};
