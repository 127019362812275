import React from 'react';
import PropTypes from 'prop-types';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import { FOLLOW_UP } from '../../WorkLog/workLogConstants';
import NOB from '../../../constants/language-strings';
import Color from '../../../components/Buttons/Color';
function WorkOrderCompletionButtons(_a) {
    var disabled = _a.disabled, handleSubmit = _a.handleSubmit, technician = _a.technician, values = _a.values, disableCompleteButton = _a.disableCompleteButton, disableNotCompleteButton = _a.disableNotCompleteButton;
    return (React.createElement("div", null, technician ? (React.createElement(SimpleButton, { onClick: function () {
            handleSubmit(!values[FOLLOW_UP]);
        }, disabled: disabled, title: NOB.CUSTOMER_NOT_HOME.REPORT })) : (React.createElement("div", { className: "complete__workorder__container--buttons" },
        React.createElement(SimpleButton, { onClick: function () {
                handleSubmit(false);
            }, disabled: disabled || disableNotCompleteButton, color: Color.RED, title: NOB.WORK_LOG.NOT_COMPLETE_BUTTON }),
        React.createElement(SimpleButton, { onClick: function () {
                handleSubmit(true);
            }, disabled: disabled || disableCompleteButton, color: Color.YELLOW, title: NOB.WORK_LOG.COMPLETE_BUTTON })))));
}
WorkOrderCompletionButtons.propTypes = {
    disabled: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    technician: PropTypes.bool.isRequired,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    disableCompleteButton: PropTypes.bool,
    disableNotCompleteButton: PropTypes.bool,
};
WorkOrderCompletionButtons.defaultProps = {
    disableCompleteButton: false,
    disableNotCompleteButton: false,
};
export default WorkOrderCompletionButtons;
