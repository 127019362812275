var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WORKORDER_SERVICES_REQUEST, WORKORDER_SERVICES_SUCCESS, WORKORDER_SERVICES_ERROR, UPDATE_SERVICE_REQUEST, REMOVE_SERVICE_REQUEST, COMPLETE_WORK_ORDER_WITH_ZENDESK, COMPLETE_STATUS_CHANGE_SUCCESS, COMPLETE_STATUS_CHANGE_FAILURE, } from '../workLogConstants';
var workorderServicesReducerDefaultState = {
    isFetchingServices: false,
    isCompletingWorkOrder: false,
    errorMessage: '',
    orderedItems: {
        itemSummary: [],
        totalTimeEstimate: 0,
        totalPrice: 0,
    },
    workOrderServiceIdToBeChanged: null,
};
export default (function (state, _a) {
    if (state === void 0) { state = workorderServicesReducerDefaultState; }
    var errorMessage = _a.errorMessage, services = _a.services, type = _a.type, workOrderServiceId = _a.workOrderServiceId;
    switch (type) {
        case WORKORDER_SERVICES_REQUEST:
            return __assign(__assign({}, state), { isFetchingServices: true });
        case WORKORDER_SERVICES_SUCCESS:
            return __assign(__assign({}, state), { isFetchingServices: false, orderedItems: services, workOrderServiceIdToBeChanged: null });
        case WORKORDER_SERVICES_ERROR:
            return __assign(__assign({}, state), { isFetchingServices: false, errorMessage: errorMessage });
        case UPDATE_SERVICE_REQUEST:
            return __assign(__assign({}, state), { workOrderServiceIdToBeChanged: workOrderServiceId, isFetchingServices: !workOrderServiceId });
        case REMOVE_SERVICE_REQUEST:
            return __assign(__assign({}, state), { workOrderServiceIdToBeChanged: workOrderServiceId });
        case COMPLETE_WORK_ORDER_WITH_ZENDESK:
            return __assign(__assign({}, state), { isCompletingWorkOrder: true });
        case COMPLETE_STATUS_CHANGE_SUCCESS:
            return __assign(__assign({}, state), { isCompletingWorkOrder: false });
        case COMPLETE_STATUS_CHANGE_FAILURE:
            return __assign(__assign({}, state), { isCompletingWorkOrder: false });
        default:
            return state;
    }
});
