var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { STATUS_CHANGE_REQUEST, STATUS_CHANGE_SUCCESS, STATUS_CHANGE_FAILURE, STATUS_CHANGE_CHECK_SKIP, WORKORDER_REQUEST, WORKORDER_SUCCESS, WORKORDER_FAILURE, WORKORDER_REQUEST_BY_CUSTOMER_ID, } from '../workOrderConstants';
import { REMOVE_SERVICE_SUCCESS, REMOVE_SERVICE_ERROR, UPDATE_SERVICE_SUCCESS, UPDATE_SERVICE_ERROR, } from '../../WorkLog/workLogConstants';
function emptyWorkOrder() {
    return {
        workOrderId: 0,
        customer: {
            customerId: 0,
            firstName: '',
            phoneNumber: '',
            surname: '',
        },
        address: {},
        status: '',
        workDescription: '',
        technicalDetails: {},
        associateId: '',
        actionTaken: '',
        attachments: {
            attachmentList: [],
            hasError: false,
        },
        capacity: {
            data: [],
        },
    };
}
var workOrderReducerDefaultState = {
    workOrder: emptyWorkOrder(),
    isFetching: false,
    isPrimaryFetching: false,
    errorMessage: '',
    statusChangeError: '',
};
export default (function (state, _a) {
    if (state === void 0) { state = workOrderReducerDefaultState; }
    var close = _a.close, errorMessage = _a.errorMessage, token = _a.token, type = _a.type, workOrder = _a.workOrder, status = _a.status;
    switch (type) {
        case STATUS_CHANGE_REQUEST:
            return __assign(__assign({}, state), { isFetching: false, isPrimaryFetching: true, statusChangeError: '' });
        case STATUS_CHANGE_SUCCESS:
            return __assign(__assign({}, state), { workOrder: __assign(__assign({}, state.workOrder), { status: status }), isFetching: false, isPrimaryFetching: false });
        case STATUS_CHANGE_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, isPrimaryFetching: false, statusChangeError: errorMessage });
        case STATUS_CHANGE_CHECK_SKIP:
            return __assign(__assign({}, state), { isFetching: false, isPrimaryFetching: false, close: close });
        case WORKORDER_REQUEST_BY_CUSTOMER_ID:
        case WORKORDER_REQUEST:
            return __assign(__assign({}, state), { token: token, isFetching: true, isPrimaryFetching: false });
        case WORKORDER_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, isPrimaryFetching: false, workOrder: workOrder, errorMessage: '' });
        case WORKORDER_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, isPrimaryFetching: false, errorMessage: errorMessage, workOrder: emptyWorkOrder() });
        case REMOVE_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { workOrder: workOrder });
        case REMOVE_SERVICE_ERROR:
            return __assign(__assign({}, state), { errorMessage: errorMessage });
        case UPDATE_SERVICE_SUCCESS:
            return __assign(__assign({}, state), { workOrder: workOrder });
        case UPDATE_SERVICE_ERROR:
            return __assign(__assign({}, state), { errorMessage: errorMessage });
        default:
            return state;
    }
});
