import React from 'react';
export default function markSearch(option, input) {
    if (input && input.search) {
        var regex = new RegExp(input.search.trim(), 'i');
        var segments = option.name.split(regex);
        var match = option.name.match(regex);
        // Make the searched word(s) bold
        if (match) {
            return (React.createElement("div", null,
                segments[0],
                React.createElement("span", { className: "searched" }, match),
                segments[1]));
        }
    }
    return option.name;
}
