import React from 'react';
import PropTypes from 'prop-types';
import EndDayButton from './endDay/EndDayButton';
import { TECHNICIAN } from '../../../Authentication/constants';
import './workOrderListBottom.scss';
export default function WorkOrderListBottom(_a) {
    var endDayFunction = _a.endDayFunction, role = _a.role;
    return (React.createElement("div", { className: "workOrder__list__bottom" }, role === TECHNICIAN && (React.createElement("div", { className: "workOrder__list__bottom__buttons" },
        React.createElement(EndDayButton, { endDayFunction: endDayFunction })))));
}
WorkOrderListBottom.propTypes = {
    endDayFunction: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    togglePopup: PropTypes.func.isRequired,
};
