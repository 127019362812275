import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import './listItem.scss';
import { iconBlue } from '../../styles/variables.scss';
function ListItem(_a) {
    var activated = _a.activated, activeBorder = _a.activeBorder, children = _a.children, isFetching = _a.isFetching, clickable = _a.clickable, onClick = _a.onClick, errorMessage = _a.errorMessage;
    var className = activated
        ? "list-item list_item--activated\n    ".concat(activeBorder ? 'list_item--active--border' : 'list_item--inactive--border')
        : "list-item\n    ".concat(activeBorder ? 'list_item--active--border' : 'list_item--inactive--border');
    var listItem = (React.createElement("div", { className: className },
        React.createElement("div", { className: "list_item-content" },
            isFetching && (React.createElement("div", { className: "list_item--fetching" },
                React.createElement(FadeLoader, { color: iconBlue }))),
            children),
        React.createElement("div", { className: "error" }, errorMessage)));
    if (clickable) {
        return (React.createElement("button", { onClick: onClick, className: "list_item-button" }, listItem));
    }
    return listItem;
}
ListItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.any),
        PropTypes.arrayOf(PropTypes.any),
    ]),
    activated: PropTypes.bool,
    activeBorder: PropTypes.bool,
    isFetching: PropTypes.bool,
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
    errorMessage: PropTypes.string,
};
ListItem.defaultProps = {
    activated: false,
    activeBorder: false,
    children: null,
    isFetching: false,
    onClick: function () { },
    clickable: false,
    errorMessage: '',
};
export default withRouter(ListItem);
