import { AGREEMENTS_PRICE_FAILURE, AGREEMENTS_PRICE_REQUEST, AGREEMENTS_PRICE_SUCCESS, } from '../customerProductsConstants';
export var requestAgreementsPrice = function () { return ({
    type: AGREEMENTS_PRICE_REQUEST,
}); };
export var receiveAgreementsPrice = function (_a) {
    var agreements = _a.agreements;
    return ({
        type: AGREEMENTS_PRICE_SUCCESS,
        agreements: agreements,
    });
};
export var errorAgreementsPrice = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: AGREEMENTS_PRICE_FAILURE,
        errorMessage: errorMessage,
    });
};
