import moment from 'moment/moment';
import { DATE_LETTERS } from '../constants/dateFormats';
import weekdays from '../constants/weekdays';
// Set moment to norwegian time
moment.locale('nb');
export default function getDayAsString(day) {
    var date = moment(day).format(DATE_LETTERS).slice(0, -4);
    var today = new Date(day).getDay();
    var weekday = weekdays[today];
    return "".concat(weekday, " ").concat(date);
}
