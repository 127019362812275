import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../icons/index';
import './iconButton.scss';
var IconButton = function (_a) {
    var className = _a.className, onClick = _a.onClick, icon = _a.icon, text = _a.text, fill = _a.fill;
    return (React.createElement("button", { className: "icon__button ".concat(className), onClick: onClick },
        React.createElement(Icon, { name: icon, fill: fill }),
        React.createElement("span", null, text)));
};
IconButton.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string,
};
IconButton.defaultProps = {
    className: '',
    text: '',
    fill: undefined,
};
export default IconButton;
