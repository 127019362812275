import { START_DAY_REQUEST, START_DAY_FAILURE, END_DAY_REQUEST, END_DAY_FAILURE, DAY_STATUS_SUCCESS, } from '../constants';
var dayStatusReducerDefaultState = {
    dayActive: false,
    errorMessage: '',
    isFetching: false,
};
export default (function (state, action) {
    if (state === void 0) { state = dayStatusReducerDefaultState; }
    var dayActive = action.dayActive, errorMessage = action.errorMessage, type = action.type;
    switch (type) {
        case START_DAY_REQUEST:
            return {
                isFetching: true,
            };
        case START_DAY_FAILURE:
            return {
                isFetching: false,
                errorMessage: errorMessage,
            };
        case END_DAY_REQUEST:
            return {
                isFetching: true,
            };
        case END_DAY_FAILURE:
            return {
                isFetching: false,
                errorMessage: errorMessage,
            };
        case DAY_STATUS_SUCCESS:
            return {
                isFetching: false,
                dayActive: dayActive,
            };
        default:
            return state;
    }
});
