var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NOB from '../../../constants/language-strings';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';
import PropTypes from 'prop-types';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Feedback from '../../../feedbacks';
import WorkOrderHistoryItem from '../components/WorkOrderHistoryItem';
import styled from 'styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin: 0 15px;\n  }\n"], ["\n    margin: 0 15px;\n  }\n"])));
var StyledHeading = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 10px;\n  margin: 0 15px;\n  color: #1e1c1e;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 20px;\n"], ["\n  padding-top: 10px;\n  margin: 0 15px;\n  color: #1e1c1e;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 20px;\n"])));
var HistoryListWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 10px;\n  margin-top: 5px;\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px #0000001a;\n"], ["\n  padding: 10px;\n  margin-top: 5px;\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px #0000001a;\n"])));
var HistoryList = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  max-height: 400px;\n  overflow-y: auto;\n  box-sizing: border-box;\n\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #888;\n    border-radius: 10px;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n"], ["\n  width: 100%;\n  max-height: 400px;\n  overflow-y: auto;\n  box-sizing: border-box;\n\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #888;\n    border-radius: 10px;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n"])));
function WorkOrderHistoryContainer(_a) {
    var _b = _a.workOrderHistory, workOrderHistory = _b.workOrderHistory, isFetching = _b.isFetching, errorMessage = _b.errorMessage, workOrderId = _a.workOrderId;
    return (React.createElement(SlideInPage, { mainHeading: NOB.WORKORDER_HISTORY.WORKORDER_HISTORY, showLeftIcon: true, isFetching: isFetching }, !workOrderHistory || workOrderHistory.length === 0 ? (React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, secondInfoBoxLine: NOB.WORKORDER_HISTORY.NO_WORKORDER_HISTORY })) : (React.createElement(Container, null,
        React.createElement(StyledHeading, null,
            NOB.WORKORDER_HISTORY.WORKORDER_ID,
            " ",
            workOrderId),
        React.createElement(HistoryListWrapper, null,
            React.createElement(HistoryList, null, workOrderHistory.map(function (item, index) { return (React.createElement(WorkOrderHistoryItem, { key: index, workOrderHistoryItem: item })); })))))));
}
WorkOrderHistoryContainer.propTypes = {
    workOrderHistory: PropTypes.objectOf(PropTypes.any),
    workOrderId: PropTypes.number.isRequired,
};
WorkOrderHistoryContainer.defaultProps = {
    workOrderHistory: [],
};
var mapStateToProps = function (state) { return ({
    workOrderHistory: state.workOrderHistory,
    workOrderId: state.workOrder.workOrder.workOrderId,
}); };
export default withRouter(connect(mapStateToProps)(WorkOrderHistoryContainer));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
