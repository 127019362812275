var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { hashes } from '../../constants/paths';
import CustomerProductsContainer from '../../modules/CustomerProducts/containers/CustomerProductsContainer';
import './slideInPagePortal.scss';
import HealthCheckContainer from '../../modules/HealthCheck/containers/HealthCheckContainer';
import AddressHistoryContainer from '../../modules/AddressHistory/containers/AddressHistoryContainer';
import FollowUpFormsContainer from '../../modules/FollowUpForms/FollowUpFormsContainer';
import UnknownHash from './UnknownHash';
import NotHome from '../../modules/Workorder/components/notHome/NotHome';
import WorkOrderHistoryContainer from '../../modules/WorkOrderHistory/containers/WorkOrderHistoryContainer';
/* children container needs to be added to array for slide in pages in routes to work properly */
function SlideInPagePortal(_a) {
    var hash = _a.hash;
    // keep selected hash i state so content does not disappear on slide out.
    var _b = __read(useState(hash), 2), selectedHash = _b[0], setSelectedHash = _b[1];
    useEffect(function () {
        // keep previous hash so it does not disappear on slide out
        if (hash !== selectedHash && hash) {
            setSelectedHash(hash);
        }
    }, [hash]);
    // To display containers correctly wrap container in SlideInPage component
    var containers = [
        { hash: hashes.customerProducts, container: React.createElement(CustomerProductsContainer, null) },
        { hash: hashes.addressHistory, container: React.createElement(AddressHistoryContainer, null) },
        { hash: hashes.workOrderHistory, container: React.createElement(WorkOrderHistoryContainer, null) },
        { hash: hashes.healthCheck, container: React.createElement(HealthCheckContainer, null) },
        { hash: hashes.followUp, container: React.createElement(FollowUpFormsContainer, null) },
        { hash: hashes.notHome, container: React.createElement(NotHome, null) },
    ];
    var selectedContainer = containers.find(function (container) { return container.hash === selectedHash; });
    return ReactDOM.createPortal(React.createElement(CSSTransition, { in: !!hash, timeout: 450, classNames: "slide", className: "slide_in_portal", mountOnEnter: true, unmountOnExit: true },
        React.createElement("div", null, selectedContainer ? selectedContainer.container : React.createElement(UnknownHash, null))), document.getElementById('slide-in-page'));
}
export default SlideInPagePortal;
