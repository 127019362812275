var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WORK_ORDER_HISTORY_REQUEST, WORK_ORDER_HISTORY_FAILURE, WORK_ORDER_HISTORY_SUCCESS, } from '../workOrderHistoryConstants';
var workOrderHistoryReducerDefaultState = {
    workOrderHistory: [],
    errorMessage: '',
    isFetching: false,
};
export default (function (state, action) {
    if (state === void 0) { state = workOrderHistoryReducerDefaultState; }
    var workOrderHistory = action.workOrderHistory, errorMessage = action.errorMessage, type = action.type, workOrderId = action.workOrderId;
    switch (type) {
        case WORK_ORDER_HISTORY_REQUEST:
            return {
                workOrderHistory: [],
                workOrderId: workOrderId,
                isFetching: true,
            };
        case WORK_ORDER_HISTORY_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, workOrderHistory: workOrderHistory });
        case WORK_ORDER_HISTORY_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        default:
            return state;
    }
});
