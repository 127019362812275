var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../constants/language-strings';
var SignInForm = /** @class */ (function (_super) {
    __extends(SignInForm, _super);
    function SignInForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            username: '',
            password: '',
        };
        _this.handleChange = _this.handleChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    SignInForm.prototype.handleChange = function (event) {
        var _a;
        this.setState((_a = {}, _a[event.target.name] = event.target.value, _a));
    };
    SignInForm.prototype.handleSubmit = function (event) {
        var _a = this.state, username = _a.username, password = _a.password;
        this.props.requestSignIn({ credentials: { username: username, password: password } });
        event.preventDefault();
    };
    SignInForm.prototype.render = function () {
        var signInError = this.props.signInError;
        return (React.createElement("div", { className: "login__container" },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("h1", null, NOB.SIGN_IN.HEADER),
                React.createElement("div", { className: "login__container__input" },
                    React.createElement("label", { htmlFor: "username", className: "login__container__input--labels" }, NOB.SIGN_IN.INPUT_USERNAME),
                    React.createElement("input", { name: "username", type: "text", autoComplete: "on", value: this.state.username, onChange: this.handleChange, className: signInError ? 'login__container__input--error' : '' })),
                React.createElement("div", { className: "login__container__input" },
                    React.createElement("label", { htmlFor: "password", className: "login__container__input--labels" }, NOB.SIGN_IN.INPUT_PASSWORD),
                    React.createElement("input", { name: "password", type: "password", autoComplete: "on", value: this.state.password, onChange: this.handleChange, className: signInError ? 'login__container__input--error' : '' }),
                    signInError),
                signInError && (React.createElement("div", { className: "login__container__error-message" }, NOB.SIGN_IN.ERROR_TEXT)),
                React.createElement("button", { className: "button button--primary", type: "submit" }, NOB.SIGN_IN.SIGN_IN_BUTTON))));
    };
    return SignInForm;
}(Component));
SignInForm.propTypes = {
    signInError: PropTypes.bool.isRequired,
    requestSignIn: PropTypes.func.isRequired,
};
export default SignInForm;
