var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ADDRESS_HISTORY_REQUEST, ADDRESS_HISTORY_SUCCESS, ADDRESS_HISTORY_FAILURE, ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST, } from '../addressHistoryConstants';
var addressHistoryReducerDefaultState = {
    addressHistory: [],
    errorMessage: '',
    isFetching: false,
};
export default (function (state, action) {
    if (state === void 0) { state = addressHistoryReducerDefaultState; }
    var addressHistory = action.addressHistory, errorMessage = action.errorMessage, type = action.type, workOrderId = action.workOrderId;
    switch (type) {
        case ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST:
            return {
                addressHistory: [],
                workOrderId: 0,
                isFetching: true,
            };
        case ADDRESS_HISTORY_REQUEST:
            return {
                addressHistory: [],
                workOrderId: workOrderId,
                isFetching: true,
            };
        case ADDRESS_HISTORY_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, addressHistory: addressHistory });
        case ADDRESS_HISTORY_FAILURE:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: errorMessage });
        default:
            return state;
    }
});
