import { INPUT_TYPES } from '../modules/FollowUpForms/FollowUpConstans';
var isEmpty = function (value) { return value === '' || value === undefined || !value; };
export default (function (values, formInputs) {
    return formInputs.inputs
        .filter(function (input) { return input.type === INPUT_TYPES.TEXT || input.type === INPUT_TYPES.RADIO; })
        .map(function (input) {
        if (input.type === INPUT_TYPES.TEXT)
            return !isEmpty(values[input.headline]);
        return !isEmpty(values[input.title]);
    })
        .every(function (value) { return value === true; });
});
