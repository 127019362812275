import { FIND_SERVICE_TYPES_SUCCESS, FIND_SERVICE_TYPES_ERROR, SELECT_SERVICE_TYPE, UPDATE_DIAGNOSTIC_SERVICE, } from '../createNewWorkOrderConstants';
export var findServiceTypesSuccess = function (_a) {
    var serviceTypes = _a.serviceTypes;
    return ({
        type: FIND_SERVICE_TYPES_SUCCESS,
        serviceTypes: serviceTypes,
    });
};
export var findServiceTypesError = function (_a) {
    var errorMessage = _a.errorMessage;
    return ({
        type: FIND_SERVICE_TYPES_ERROR,
        errorMessage: errorMessage,
    });
};
export var selectServiceType = function (_a) {
    var serviceType = _a.serviceType;
    return ({
        type: SELECT_SERVICE_TYPE,
        serviceType: serviceType,
    });
};
export var updateDiagnosticServices = function (_a) {
    var selectedDiagnosticServices = _a.selectedDiagnosticServices;
    return ({
        type: UPDATE_DIAGNOSTIC_SERVICE,
        selectedDiagnosticServices: selectedDiagnosticServices,
    });
};
