import React from 'react';
import PropTypes from 'prop-types';
import './radio-button.scss';
function RadioButton(_a) {
    var checked = _a.checked, name = _a.name, onClick = _a.onClick, label = _a.label, value = _a.value, isFetching = _a.isFetching;
    return (React.createElement("label", { htmlFor: "".concat(label).concat(name), className: "radio-button" },
        React.createElement("span", { className: checked ? 'label--checked' : 'label' }, label),
        React.createElement("input", { id: "".concat(label).concat(name), checked: checked, value: value, type: "radio", onClick: onClick, name: name, readOnly: true }),
        React.createElement("span", { className: isFetching ? 'circle-loader-black' : 'radio-checkmark' })));
}
RadioButton.propTypes = {
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
    isFetching: PropTypes.bool,
};
RadioButton.defaultProps = {
    isFetching: false,
};
export default RadioButton;
