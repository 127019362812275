var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import './styles/index.scss';
import App from './app/App';
import NOB from './constants/language-strings';
import configureStore from './store';
import { currentVersion } from './version';
import { getEnvironment } from './utils/getEnvironment';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
var history = createHashHistory();
var store = configureStore({ NOB: NOB }, history);
Sentry.init({
    release: currentVersion,
    dsn: 'https://540a5e5fd6b44ee985dbb6a531b99dad@o47373.ingest.sentry.io/2851732',
    integrations: [new BrowserTracing()],
    environment: getEnvironment(),
});
var redirectUrl = window.location.origin + '/sso';
var oidcConfig = {
    authority: 'https://preprod.idpedge.teliacompany.net',
    client_id: 'homesales',
    redirect_uri: redirectUrl,
    post_logout_redirect_uri: redirectUrl,
    onSigninCallback: function () {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
    scope: 'openid profile',
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    response_type: 'code',
    metadata: {
        issuer: 'https://preprod.idpedge.teliacompany.net',
        authorization_endpoint: 'https://preprod.idpedge.teliacompany.net/as/authorization.oauth2',
        token_endpoint: 'https://preprod.idpedge.teliacompany.net/as/token.oauth2',
        revocation_endpoint: 'https://preprod.idpedge.teliacompany.net/as/revoke_token.oauth2',
        userinfo_endpoint: 'https://preprod.idpedge.teliacompany.net/idp/userinfo.openid',
        introspection_endpoint: 'https://preprod.idpedge.teliacompany.net/as/introspect.oauth2',
        jwks_uri: 'https://preprod.idpedge.teliacompany.net/pf/JWKS',
        registration_endpoint: 'https://preprod.idpedge.teliacompany.net/as/clients.oauth2',
        ping_revoked_sris_endpoint: 'https://preprod.idpedge.teliacompany.net/pf-ws/rest/sessionMgmt/revokedSris',
        ping_session_management_sris_endpoint: 'https://preprod.idpedge.teliacompany.net/pf-ws/rest/sessionMgmt/sessions',
        ping_session_management_users_endpoint: 'https://preprod.idpedge.teliacompany.net/pf-ws/rest/sessionMgmt/users',
        ping_end_session_endpoint: 'https://preprod.idpedge.teliacompany.net/idp/startSLO.ping',
        end_session_endpoint: 'https://preprod.idpedge.teliacompany.net/idp/startSLO.ping',
        backchannel_logout_supported: true,
        backchannel_logout_session_supported: true,
        device_authorization_endpoint: 'https://preprod.idpedge.teliacompany.net/as/device_authz.oauth2',
        scopes_supported: ['openid', 'profile', 'email'],
        claims_supported: ['sub'],
        response_types_supported: [
            'code',
            'token',
            'id_token',
            'code token',
            'code id_token',
            'token id_token',
            'code token id_token',
        ],
        response_modes_supported: [
            'fragment',
            'fragment.jwt',
            'query',
            'query.jwt',
            'form_post',
            'form_post.jwt',
            'jwt',
        ],
        grant_types_supported: [
            'implicit',
            'authorization_code',
            'refresh_token',
            'password',
            'client_credentials',
            'urn:pingidentity.com:oauth2:grant_type:validate_bearer',
            'urn:ietf:params:oauth:grant-type:jwt-bearer',
            'urn:ietf:params:oauth:grant-type:saml2-bearer',
            'urn:ietf:params:oauth:grant-type:device_code',
            'urn:ietf:params:oauth:grant-type:token-exchange',
            'urn:openid:params:grant-type:ciba',
        ],
        subject_types_supported: ['public', 'pairwise'],
        id_token_signing_alg_values_supported: [
            'none',
            'HS256',
            'HS384',
            'HS512',
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
        ],
        token_endpoint_auth_methods_supported: [
            'client_secret_basic',
            'client_secret_post',
            'client_secret_jwt',
            'private_key_jwt',
            'tls_client_auth',
        ],
        token_endpoint_auth_signing_alg_values_supported: [
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
            'HS256',
            'HS384',
            'HS512',
        ],
        claim_types_supported: ['normal'],
        claims_parameter_supported: false,
        request_parameter_supported: true,
        request_uri_parameter_supported: false,
        authorization_response_iss_parameter_supported: false,
        request_object_signing_alg_values_supported: [
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
        ],
        request_object_encryption_alg_values_supported: [
            'dir',
            'A128KW',
            'A192KW',
            'A256KW',
            'A128GCMKW',
            'A192GCMKW',
            'A256GCMKW',
            'ECDH-ES',
            'ECDH-ES+A128KW',
            'ECDH-ES+A192KW',
            'ECDH-ES+A256KW',
            'RSA-OAEP',
            'RSA-OAEP-256',
        ],
        request_object_encryption_enc_values_supported: [
            'A128CBC-HS256',
            'A192CBC-HS384',
            'A256CBC-HS512',
            'A128GCM',
            'A192GCM',
            'A256GCM',
        ],
        id_token_encryption_alg_values_supported: [
            'dir',
            'A128KW',
            'A192KW',
            'A256KW',
            'A128GCMKW',
            'A192GCMKW',
            'A256GCMKW',
            'ECDH-ES',
            'ECDH-ES+A128KW',
            'ECDH-ES+A192KW',
            'ECDH-ES+A256KW',
            'RSA-OAEP',
            'RSA-OAEP-256',
        ],
        id_token_encryption_enc_values_supported: [
            'A128CBC-HS256',
            'A192CBC-HS384',
            'A256CBC-HS512',
            'A128GCM',
            'A192GCM',
            'A256GCM',
        ],
        introspection_signing_alg_values_supported: [
            'HS256',
            'HS384',
            'HS512',
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
        ],
        introspection_encryption_alg_values_supported: [
            'dir',
            'A128KW',
            'A192KW',
            'A256KW',
            'A128GCMKW',
            'A192GCMKW',
            'A256GCMKW',
            'ECDH-ES',
            'ECDH-ES+A128KW',
            'ECDH-ES+A192KW',
            'ECDH-ES+A256KW',
            'RSA-OAEP',
            'RSA-OAEP-256',
        ],
        introspection_encryption_enc_values_supported: [
            'A128CBC-HS256',
            'A192CBC-HS384',
            'A256CBC-HS512',
            'A128GCM',
            'A192GCM',
            'A256GCM',
        ],
        authorization_signing_alg_values_supported: [
            'HS256',
            'HS384',
            'HS512',
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
        ],
        authorization_encryption_alg_values_supported: [
            'dir',
            'A128KW',
            'A192KW',
            'A256KW',
            'A128GCMKW',
            'A192GCMKW',
            'A256GCMKW',
            'ECDH-ES',
            'ECDH-ES+A128KW',
            'ECDH-ES+A192KW',
            'ECDH-ES+A256KW',
            'RSA-OAEP',
            'RSA-OAEP-256',
        ],
        authorization_encryption_enc_values_supported: [
            'A128CBC-HS256',
            'A192CBC-HS384',
            'A256CBC-HS512',
            'A128GCM',
            'A192GCM',
            'A256GCM',
        ],
        pushed_authorization_request_endpoint: 'https://preprod.idpedge.teliacompany.net/as/par.oauth2',
        require_pushed_authorization_requests: false,
        authorization_details_types_supported: [],
        backchannel_authentication_endpoint: 'https://preprod.idpedge.teliacompany.net/as/bc-auth.ciba',
        backchannel_token_delivery_modes_supported: ['poll', 'ping'],
        backchannel_authentication_request_signing_alg_values_supported: [
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
        ],
        backchannel_user_code_parameter_supported: false,
        code_challenge_methods_supported: ['plain', 'S256'],
        dpop_signing_alg_values_supported: [
            'RS256',
            'RS384',
            'RS512',
            'ES256',
            'ES384',
            'ES512',
            'PS256',
            'PS384',
            'PS512',
        ],
    },
};
ReactDOM.render(React.createElement(AuthProvider, __assign({}, oidcConfig),
    React.createElement(Provider, { store: store },
        React.createElement(ConnectedRouter, { history: history },
            React.createElement(App, null)))), document.getElementById('app'));
document.getElementById('version').innerText = currentVersion;
