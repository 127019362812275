import React from 'react';
import PropTypes from 'prop-types';
export default function Heading(_a) {
    var icon = _a.icon, mainHeading = _a.mainHeading, subHeading = _a.subHeading;
    return (React.createElement("div", { className: "product__container__heading" },
        React.createElement("div", null,
            icon,
            React.createElement("span", null, mainHeading)),
        React.createElement("div", null, subHeading)));
}
Heading.propTypes = {
    icon: PropTypes.element.isRequired,
    mainHeading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
};
