export default function sortMinToMax(list, sortBy) {
    var sorted = list.sort(function (a, b) {
        if (a[sortBy] > b[sortBy])
            return 1;
        if (a[sortBy] < b[sortBy])
            return -1;
        return 0;
    });
    return sorted;
}
