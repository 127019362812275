var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPullToRefresh from 'rmc-pull-to-refresh';
import { TabContent, TabLink, Tabs } from 'react-tabs-redux';
import { ClipLoader } from '../../../../node_modules/react-spinners';
import { DOWNSTREAM, UPSTREAM } from '../../../constants/samSelector';
import Feedback from '../../../feedbacks';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import Overview from '../components/sam/Overview';
import { requestDevicePollingMac, requestUpdateDevicePollingMac, } from '../actions/devicePollingMacActions';
import samValueCorrector from '../../../utils/samValueCorrector';
import StreamChannel from '../components/sam/StreamChannel';
import '../sam.scss';
var Sam = function (_a) {
    var errorMessage = _a.errorMessage, devicePollingMac = _a.devicePollingMac, isReceiveFetching = _a.isReceiveFetching, isUpdateFetching = _a.isUpdateFetching, requestDevicePollingMacFunc = _a.requestDevicePollingMacFunc, requestUpdateDevicePollingMacFunc = _a.requestUpdateDevicePollingMacFunc, pollIdentifier = _a.pollIdentifier, pollUrl = _a.pollUrl;
    var _b = __read(useState(0), 2), topPosition = _b[0], setTopPosition = _b[1];
    var setTopPositionValue = function () { return setTopPosition(window.pageYOffset); };
    useEffect(function () {
        window.addEventListener('scroll', setTopPositionValue);
        requestDevicePollingMacFunc(pollUrl);
        return function () { return window.removeEventListener('scroll', setTopPositionValue); };
    }, []);
    var refresh = function () {
        return new Promise(function () {
            if (!isReceiveFetching && !errorMessage && pollIdentifier) {
                requestUpdateDevicePollingMacFunc(pollIdentifier);
            }
            else {
                requestDevicePollingMacFunc(pollUrl);
            }
        });
    };
    if (isReceiveFetching) {
        return (React.createElement("div", { className: "downstream__upstream" },
            React.createElement(FetchingSpinner, null)));
    }
    var devicePollingMacCorrected = devicePollingMac
        ? samValueCorrector(devicePollingMac)
        : { Success: false };
    return (React.createElement("div", { className: "downstream__upstream" }, !devicePollingMacCorrected.Success || errorMessage !== '' ? (React.createElement(ReactPullToRefresh, { onRefresh: refresh, className: "info-box-content", refreshing: isUpdateFetching },
        React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, link: false, secondInfoBoxLine: NOB.HEALTH_CHECK.DEVICE_STATUS_ERROR }))) : (React.createElement(Tabs, { className: "downstream__upstream__tabs" },
        React.createElement("div", { className: topPosition === 0
                ? 'downstream__upstream__tabs__navigation'
                : 'downstream__upstream__tabs__navigation--overlay' },
            React.createElement(TabLink, { to: "overview", default: true }, NOB.DOWNSTREAM_UPSTREAM.OVERVIEW),
            React.createElement(TabLink, { to: "ds_channel" }, NOB.DOWNSTREAM_UPSTREAM.DS_CHANNEL),
            React.createElement(TabLink, { to: "us_channel" }, NOB.DOWNSTREAM_UPSTREAM.US_CHANNEL)),
        React.createElement(ReactPullToRefresh, { onRefresh: refresh, className: "content", refreshing: isUpdateFetching },
            React.createElement("div", { className: isUpdateFetching ? 'show-loading' : 'hide-loading' }, isUpdateFetching && React.createElement(ClipLoader, null)),
            React.createElement(TabContent, { className: "tab-content", for: "overview" },
                React.createElement(Overview, { devicePollingMac: devicePollingMacCorrected })),
            React.createElement(TabContent, { className: "tab-content", for: "ds_channel" },
                React.createElement(StreamChannel, { channels: devicePollingMacCorrected.DsChannels, type: DOWNSTREAM, scoreVariables: devicePollingMacCorrected.ScoreVariables })),
            React.createElement(TabContent, { className: "tab-content", for: "us_channel" },
                React.createElement(StreamChannel, { channels: devicePollingMacCorrected.UsChannels, type: UPSTREAM, scoreVariables: devicePollingMacCorrected.ScoreVariables })))))));
};
Sam.propTypes = {
    devicePollingMac: PropTypes.objectOf(PropTypes.any).isRequired,
    errorMessage: PropTypes.string.isRequired,
    isReceiveFetching: PropTypes.bool.isRequired,
    isUpdateFetching: PropTypes.bool,
    requestDevicePollingMacFunc: PropTypes.func.isRequired,
    requestUpdateDevicePollingMacFunc: PropTypes.func.isRequired,
    pollIdentifier: PropTypes.string.isRequired,
    pollUrl: PropTypes.string.isRequired,
};
Sam.defaultProps = {
    isUpdateFetching: false,
};
var mapStateToProps = function (state) { return ({
    devicePollingMac: state.devicePollingMac.devicePollingMac,
    errorMessage: state.devicePollingMac.errorMessage,
    isReceiveFetching: state.devicePollingMac.isFetching,
    isUpdateFetching: state.updateDevicePollingMac.isFetching,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestDevicePollingMacFunc: function (pollUrl) { return dispatch(requestDevicePollingMac(pollUrl)); },
    requestUpdateDevicePollingMacFunc: function (macAddress) {
        return dispatch(requestUpdateDevicePollingMac({ macAddress: macAddress }));
    },
}); };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sam));
