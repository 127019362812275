var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable prefer-const */
import PropTypes from 'prop-types';
import Quagga from 'quagga';
import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import './scanner.scss';
import Icon from '../../icons';
import { CHECK, CROSS, CROSS_LARGE, SWITCH2 } from '../../constants/iconNames';
import SlideInPage from '../SlideInPage/SlideInPage';
import IconButton from '../Buttons/IconButton/IconButton';
import quaggaConfig from './quaggaConfig';
import SimpleButton from '../Buttons/SimpleButton/SimpleButton';
import isiOS from '../../utils/isiOS';
function Scanner(_a) {
    var _this = this;
    var results = _a.results, onHeaderClick = _a.onHeaderClick, totalDevices = _a.totalDevices, onDetected = _a.onDetected;
    var _b = __read(useState(0), 2), currentDeviceId = _b[0], setCurrentDeviceId = _b[1];
    var _c = __read(useState([]), 2), deviceIds = _c[0], setDeviceIds = _c[1];
    var iOS = isiOS();
    var initScanner = function () { return __awaiter(_this, void 0, void 0, function () {
        var devices, cameras, filteredBackDeviceIds, foundCameras, foundCameraDeviceIds, rememberedDeviceId, initDeviceIndex;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!!iOS) return [3 /*break*/, 2];
                    return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
                case 1:
                    devices = _a.sent();
                    cameras = devices.filter(function (device) { return device.kind === 'videoinput'; });
                    filteredBackDeviceIds = cameras.filter(function (camera) { return camera.label.includes('back'); });
                    foundCameras = filteredBackDeviceIds.length ? filteredBackDeviceIds : cameras;
                    foundCameraDeviceIds = foundCameras.map(function (foundCamera) { return foundCamera.deviceId; });
                    setDeviceIds(foundCameraDeviceIds);
                    rememberedDeviceId = localStorage.getItem('deviceId');
                    initDeviceIndex = rememberedDeviceId && foundCameraDeviceIds.includes(rememberedDeviceId)
                        ? foundCameraDeviceIds.indexOf(rememberedDeviceId)
                        : currentDeviceId;
                    setCurrentDeviceId(initDeviceIndex);
                    Quagga.init(quaggaConfig(foundCameraDeviceIds[initDeviceIndex]), function () { return Quagga.start(); });
                    return [3 /*break*/, 3];
                case 2:
                    Quagga.init(quaggaConfig(), function () { return Quagga.start(); });
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        try {
            Quagga.stop();
        }
        catch (error) {
            // Do nothing, this is to ensure prev Quagga instance is stopped
        }
        initScanner();
        if (onDetected)
            Quagga.onDetected(onDetected);
        return function () {
            Quagga.offDetected();
            Quagga.stop();
        };
    }, []);
    var toggleCameraLens = function (deviceId) {
        localStorage.setItem('deviceId', deviceId);
        Quagga.stop();
        Quagga.init(quaggaConfig(deviceId), function () {
            Quagga.start();
        });
    };
    var onCameraChange = function () {
        if (currentDeviceId === deviceIds.length - 1) {
            setCurrentDeviceId(0);
            toggleCameraLens(deviceIds[0]);
        }
        else {
            var deviceIdIndex = currentDeviceId + 1;
            setCurrentDeviceId(deviceIdIndex);
            toggleCameraLens(deviceIds[deviceIdIndex]);
        }
    };
    return ReactDOM.createPortal(React.createElement(SlideInPage, { mainHeading: "Aktiver produkter", rightIcon: React.createElement(IconButton, { onClick: onHeaderClick, icon: CROSS_LARGE }) },
        React.createElement("div", { id: "scanner", className: "scanner-container" },
            React.createElement("div", { className: "scanner-container-overlay scanner-container-overlay--top" },
                React.createElement("div", { className: "scanner-result-content" },
                    React.createElement("span", { className: "result-count" }, totalDevices && (React.createElement("div", null,
                        results.filter(function (result) { return result.isActivated; }).length,
                        " / ",
                        totalDevices))),
                    React.createElement("span", { className: "result-list" }, results.map(function (result) { return (React.createElement("div", { key: result.serialNumber, className: "list-element" },
                        result.isFetching && !result.isActivated && (React.createElement(ClipLoader, { color: "#fff", size: 12 })),
                        result.isActivated && !result.isFetching && React.createElement(Icon, { name: CHECK, fill: "#fff" }),
                        !result.isActivated && !result.isFetching && (React.createElement("span", { className: "x-cross" },
                            React.createElement(Icon, { name: CROSS, fill: "#fff" }))),
                        React.createElement("span", { className: !result.isActivated && !result.isFetching
                                ? 'serialnumber--error'
                                : 'serialnumber' }, result.serialNumber))); })))),
            React.createElement("div", { className: "scanner-container-overlay scanner-container-overlay--bottom" })),
        deviceIds.length > 1 && !iOS && (React.createElement("div", { className: "scanner-container__toggle" },
            React.createElement("div", { className: "scanner-container__toggle__description" }, "Hvis du ikke f\u00E5r scannet, pr\u00F8v \u00E5 endre kameralinse"),
            React.createElement(SimpleButton, { icon: SWITCH2, title: "Endre kameralinse", onClick: onCameraChange })))), document.getElementById('scanner-root'));
}
Scanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
};
Scanner.defaultProps = {
    results: [],
};
export default Scanner;
