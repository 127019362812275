var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ZENDESK_POST_REQUEST, ZENDESK_POST_SUCCESS, ZENDESK_POST_ERROR, SAVE_FILE, SAVE_COMMENT, SET_FOLLOW_UP, ZENDESK_FOLLOW_UP_POST_REQUEST, ZENDESK_FOLLOW_UP_POST_ERROR, ZENDESK_FOLLOW_UP_POST_SUCCESS, SAVE_FOLLOW_UP_FILE, CLEAR_SAVED_FILES, } from '../workLogConstants';
var zendeskReducerDefaultState = {
    commentBody: '',
    files: [],
    isFetching: false,
    errorMessage: '',
    customerNotHome: false,
    allChecked: false,
    followUp: false,
    followUpForm: {
        created: false,
        isFetching: false,
        errorMessage: '',
        files: [],
    },
};
export default (function (state, action) {
    if (state === void 0) { state = zendeskReducerDefaultState; }
    switch (action.type) {
        // Regular zendesk
        case ZENDESK_POST_REQUEST:
            return __assign(__assign({}, state), { isFetching: true });
        case ZENDESK_POST_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false });
        case ZENDESK_POST_ERROR:
            return __assign(__assign({}, state), { isFetching: false, errorMessage: action.errorMessage });
        // Zendesk follow up
        case ZENDESK_FOLLOW_UP_POST_REQUEST:
            return __assign(__assign({}, state), { followUpForm: __assign(__assign({}, state.followUpForm), { isFetching: true }) });
        case ZENDESK_FOLLOW_UP_POST_SUCCESS:
            return __assign(__assign({}, state), { followUpForm: __assign(__assign({}, state.followUpForm), { isFetching: false, created: true, workOrderId: action.workOrderId }) });
        case ZENDESK_FOLLOW_UP_POST_ERROR:
            return __assign(__assign({}, state), { followUpForm: __assign(__assign({}, state.followUpForm), { isFetching: false, errorMessage: action.errorMessage }) });
        case SAVE_FOLLOW_UP_FILE:
            return __assign(__assign({}, state), { followUpForm: __assign(__assign({}, state.followUpForm), { files: action.files }) });
        // Other
        case SAVE_FILE:
            return __assign(__assign({}, state), { files: action.files });
        case SAVE_COMMENT:
            return __assign(__assign({}, state), { commentBody: action.commentBody, file: action.file, fileName: action.fileName });
        case SET_FOLLOW_UP:
            return __assign(__assign({}, state), { customerNotHome: action.customerNotHome, allChecked: action.allChecked, followUp: action.followUp });
        case CLEAR_SAVED_FILES:
            return __assign(__assign({}, state), { files: [] });
        default:
            return state;
    }
});
