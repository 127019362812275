import { create } from 'zustand';
export var useSearchStore = create(function (set) { return ({
    searchInput: '',
    setSearchInput: function (value) { return set({ searchInput: value }); },
    searchQuery: '',
    setSearchQuery: function (value) { return set({ searchQuery: value }); },
    cache: null,
    setCache: function (value) { return set({ cache: value }); },
    cacheKey: '',
    setCacheKey: function (value) { return set({ cacheKey: value }); },
    onlyActive: true,
    setSearchOnlyActive: function (value) { return set({ onlyActive: value }); },
    clearState: function () {
        set({ searchQuery: '', searchInput: '', cache: null, cacheKey: '' });
    },
}); });
