var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest } from 'redux-saga/effects';
import JWT from 'jsonwebtoken';
import { EXTERNAL_INSTALLER, TECHNICIAN, VERIFY_TOKEN_REQUEST, START_DAY_REQUEST, } from '../constants';
import * as action from '../actions/verifyTokenActions';
function verifyToken(_a) {
    var token = _a.token;
    var decodedToken = JWT.decode(token);
    if (decodedToken) {
        var expDate = new Date(0).setUTCSeconds(decodedToken.exp);
        var dateNow = new Date().getTime();
        var role = decodedToken.roles.includes(TECHNICIAN) ? TECHNICIAN : EXTERNAL_INSTALLER;
        return {
            isExpired: dateNow > expDate,
            token: token,
            role: role,
        };
    }
    return null;
}
function callVerifyToken(token) {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(verifyToken, token)];
            case 1:
                result = _a.sent();
                if (!!result.isExpired) return [3 /*break*/, 4];
                return [4 /*yield*/, put(action.successTokenVerification({
                        token: result.token,
                        role: result.role,
                    }))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put({ type: START_DAY_REQUEST })];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, put(action.failureTokenVerification({
                    errorMessage: 'Unauthorized',
                }))];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
export default [takeLatest(VERIFY_TOKEN_REQUEST, callVerifyToken)];
