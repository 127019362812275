var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { buttonRed } from '../../../../styles/variables.scss';
import { TRASH } from '../../../../constants/iconNames';
import ChangeProduct from '../../components/product/changeProduct/ChangeProduct';
import NOB from '../../../../constants/language-strings';
import * as changeAction from '../../actions/changeProductActions';
import './singleProduct.scss';
import IconButton from '../../../../components/Buttons/IconButton/IconButton';
import Popup from '../../../../components/Popup/containers/Popup';
import LinkingContainer from '../Linking/LinkingContainer';
import SlideInModal from '../../../../components/SlideInModal/SlideInModal';
import deviceSerialNumbersToString from '../../../../utils/products';
import { linkProductFailure } from '../../actions/linkProductActions';
function SingleProductContainer(_a) {
    var changeProductState = _a.changeProductState, clearErrorMessage = _a.clearErrorMessage, close = _a.close, cleanUpLinkErrorMessage = _a.cleanUpLinkErrorMessage, display = _a.display, isFetchingInBackground = _a.isFetchingInBackground, isFetchingWorkOrder = _a.isFetchingWorkOrder, requestChangeProduct = _a.requestChangeProduct, product = _a.product, onDelete = _a.onDelete;
    var _b = __read(useState(false), 2), showDeleteModal = _b[0], setShowDeleteModal = _b[1];
    return (React.createElement(SlideInModal, { display: display, onClose: close, mainHeading: product.name, subHeading: deviceSerialNumbersToString(product.devices), isFetching: isFetchingWorkOrder || isFetchingInBackground, cleanUp: cleanUpLinkErrorMessage },
        React.createElement("div", { className: "product__container" },
            showDeleteModal && (React.createElement(Popup, { firstLine: NOB.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT, secondLine: NOB.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT_QUESTION, closePopup: function () { return setShowDeleteModal(false); }, 
                // TODO: dette er copy-pastet ved refaktorering, Popup komponenten bør refaktorers slik at knapper ikke lages her.
                // Eventuelt skifte navn på propsene slik at de gir mer mening
                button: React.createElement("div", { className: "popup__remove-buttons" },
                    React.createElement("button", { onClick: function () { return setShowDeleteModal(false); } }, NOB.REMOVE_PRODUCT_POPUP.CANCEL),
                    React.createElement("button", { onClick: function () {
                            setShowDeleteModal(false);
                            close();
                            onDelete();
                        } }, NOB.REMOVE_PRODUCT_POPUP.REMOVE)) })),
            React.createElement(LinkingContainer, { productId: product.productId }),
            product.devices.length > 0 && (React.createElement(ChangeProduct, { changeProductState: changeProductState, clearErrorMessage: clearErrorMessage, product: product, requestChangeProduct: requestChangeProduct })),
            product.canBeRemoved && (React.createElement(IconButton, { className: "product__container--remove", onClick: function () { return setShowDeleteModal(true); }, icon: TRASH, fill: buttonRed, text: NOB.CHANGE_PRODUCT.REMOVE_UNIT })))));
}
SingleProductContainer.propTypes = {
    changeProductState: PropTypes.objectOf(PropTypes.any).isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    display: PropTypes.bool.isRequired,
    requestChangeProduct: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    isFetchingInBackground: PropTypes.bool.isRequired,
    isFetchingWorkOrder: PropTypes.bool.isRequired,
    cleanUpLinkErrorMessage: PropTypes.func.isRequired,
    product: PropTypes.shape({
        canBeRemoved: PropTypes.bool.isRequired,
        devices: PropTypes.arrayOf(PropTypes.any).isRequired,
        linkOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        productId: PropTypes.number.isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
};
var mapStateToProps = function (state) { return ({
    changeProductState: state.changeProduct,
    customerId: state.workOrder.workOrder.customer.customerId,
    customerProducts: state.customerProducts.customerProducts,
    isFetching: state.customerProducts.isFetching,
    isFetchingInBackground: state.customerProducts.isFetchingInBackground,
    isFetchingWorkOrder: state.workOrder.isFetching,
    isWorkOrderFetching: state.workOrder.isFetching,
    removeProductState: state.removeProduct,
    popupType: state.popup.popupType,
    closePopup: state.popup.close,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    clearErrorMessage: function (errorProducts) { return dispatch(changeAction.clearErrorMessage({ errorProducts: errorProducts })); },
    requestChangeProduct: function (productId, dpadId, serialNumber, reload) {
        return dispatch(changeAction.requestChangeProduct(productId, dpadId, serialNumber, reload));
    },
    cleanUpLinkErrorMessage: function () { return dispatch(linkProductFailure('')); },
}); };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleProductContainer));
