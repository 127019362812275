var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { REMOVE_PRODUCT_FAILURE, REMOVE_PRODUCT_REQUEST, REMOVE_PRODUCT_SUCCESS, TO_BE_REMOVED, CLEAR_REMOVE_ERROR_MESSAGE, } from '../customerProductsConstants';
var removeProductReducerDefaultState = {
    agreementDetailId: 0,
    errorMessage: '',
    isFetching: false,
};
export default (function (state, _a) {
    if (state === void 0) { state = removeProductReducerDefaultState; }
    var agreementDetailId = _a.agreementDetailId, errorMessage = _a.errorMessage, type = _a.type, removedProductId = _a.removedProductId;
    switch (type) {
        case TO_BE_REMOVED:
            return __assign(__assign({}, state), { agreementDetailId: agreementDetailId });
        case REMOVE_PRODUCT_REQUEST:
            return {
                isFetching: true,
                agreementDetailId: agreementDetailId,
            };
        case REMOVE_PRODUCT_SUCCESS:
            return __assign(__assign({}, state), { isFetching: false, removedProductId: removedProductId });
        case REMOVE_PRODUCT_FAILURE:
            return __assign(__assign({}, state), { errorMessage: errorMessage, isFetching: false });
        case CLEAR_REMOVE_ERROR_MESSAGE:
            return __assign(__assign({}, state), { errorMessage: '', isFetching: false });
        default:
            return state;
    }
});
