import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Feedback from '../../feedbacks';
import { FEEDBACK_NOT_FOUND } from '../../constants/feedbackNames';
import { FRONT_PAGE_PATH } from '../../constants/paths';
import NOB from '../../constants/language-strings';
import './infobox.scss';
export default function InfoBox(_a) {
    var feedback = _a.feedback, firstInfoBoxLine = _a.firstInfoBoxLine, link = _a.link, secondInfoBoxLine = _a.secondInfoBoxLine, thirdInfoBoxLine = _a.thirdInfoBoxLine;
    return (React.createElement("div", { className: "info-box" },
        React.createElement("div", { className: "info-box__text" },
            React.createElement("h1", null, firstInfoBoxLine),
            React.createElement("h2", null, secondInfoBoxLine),
            React.createElement("h2", null, thirdInfoBoxLine)),
        link ? (React.createElement("div", { className: "info-box__feedback" },
            feedback || React.createElement(Feedback, { name: FEEDBACK_NOT_FOUND }),
            React.createElement(Link, { to: FRONT_PAGE_PATH }, NOB.NO_WORK_ORDERS.TO_FRONT_PAGE))) : (feedback)));
}
InfoBox.defaultProps = {
    feedback: null,
    firstInfoBoxLine: '',
    link: true,
    secondInfoBoxLine: NOB.INFO_BOX.NOT_EXIST,
    thirdInfoBoxLine: '',
};
InfoBox.propTypes = {
    feedback: PropTypes.element,
    firstInfoBoxLine: PropTypes.string,
    link: PropTypes.bool,
    secondInfoBoxLine: PropTypes.string,
    thirdInfoBoxLine: PropTypes.string,
};
