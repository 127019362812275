var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AddServiceCodeContainer from '../../AddServiceCode/containers/AddServiceCodeContainer';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import { NEARLY_DONE, COMPLETE, INCOMPLETE } from '../../../constants/status-codes';
import ServiceListContainer from '../../ServiceList/ServiceListContainer';
import * as findServicesAction from '../actions/findServiceActions';
import * as updateServiceAction from '../actions/updateServiceActions';
import * as workOrderAction from '../../Workorder/actions/workOrder';
import './workLog.scss';
import WorkOrderDetails from '../../Workorder/components/workOrderDetails/WorkOrderDetails';
import { workorderServicesRequest } from '../actions/workOrderServiceActions';
import WorkOrderCompletionFormContainer from '../../WorkOrderCompletionForm/containers/WorkOrderCompletionFormContainer';
var WorkLogContainer = /** @class */ (function (_super) {
    __extends(WorkLogContainer, _super);
    function WorkLogContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WorkLogContainer.prototype.componentDidMount = function () {
        var _a = this.props, findServicesRequest = _a.findServicesRequest, pathname = _a.pathname, requestStatusChange = _a.requestStatusChange, match = _a.match, requestWorkOrderServices = _a.requestWorkOrderServices, workOrder = _a.workOrder.workOrder;
        requestWorkOrderServices(match.params.id);
        findServicesRequest(pathname.replace(/\D/g, ''));
        if (workOrder.isThreeSixty && workOrder.status !== NEARLY_DONE) {
            requestStatusChange(workOrder.status);
        }
    };
    WorkLogContainer.prototype.render = function () {
        var _a = this.props, isFetchingServices = _a.findServices.isFetchingServices, workOrder = _a.workOrder.workOrder, isFetchingWorkOrderServices = _a.isFetchingWorkOrderServices, orderedItems = _a.orderedItems;
        var done = COMPLETE.includes(workOrder.status) || INCOMPLETE.includes(workOrder.status);
        return (React.createElement("div", { className: "work__log" },
            React.createElement("div", { className: "work__log__content" },
                React.createElement(WorkOrderDetails, { done: done, workOrder: workOrder, totalTimeEstimate: orderedItems.totalTimeEstimate, inWorkLog: true }),
                isFetchingWorkOrderServices || isFetchingServices ? (React.createElement(FetchingSpinner, null)) : (React.createElement(ServiceListContainer, null)),
                !isFetchingServices && (React.createElement(AddServiceCodeContainer, { addServiceCode: true, workOrderServices: orderedItems.itemSummary.map(function (item) { return item.id; }) })),
                React.createElement(WorkOrderCompletionFormContainer, null))));
    };
    return WorkLogContainer;
}(Component));
WorkLogContainer.propTypes = {
    findServices: PropTypes.objectOf(PropTypes.any),
    findServicesRequest: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    requestStatusChange: PropTypes.func.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    isFetchingWorkOrderServices: PropTypes.bool.isRequired,
    orderedItems: PropTypes.objectOf(PropTypes.any).isRequired,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    requestWorkOrderServices: PropTypes.func.isRequired,
};
WorkLogContainer.defaultProps = {
    findServices: { services: [] },
};
var mapStateToProps = function (state) { return ({
    findServices: state.findServices,
    pathname: state.router.location.pathname,
    workOrder: state.workOrder,
    workOrderServices: state.workOrderServices.orderedItems.itemSummary,
    isFetchingWorkOrderServices: state.workOrderServices.isFetchingServices,
    orderedItems: state.workOrderServices.orderedItems,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    findServicesRequest: function (workOrderId) {
        return dispatch(findServicesAction.findServicesRequest({ workOrderId: workOrderId }));
    },
    requestStatusChange: function (currentStatus) {
        return dispatch(workOrderAction.requestStatusChange(currentStatus));
    },
    requestUpdateService: function (workOrderId, serviceId, quantity, added) {
        return dispatch(updateServiceAction.requestUpdateService({
            workOrderId: workOrderId,
            serviceId: serviceId,
            quantity: quantity,
            added: added,
        }));
    },
    requestWorkOrderServices: function (workOrderId) { return dispatch(workorderServicesRequest(workOrderId)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(WorkLogContainer);
