var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-dynamic-swiper/lib/styles.css';
import { EXTERNAL_INSTALLER, TECHNICIAN } from '../../Authentication/constants';
import Feedback from '../../../feedbacks';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import SlideContent from '../components/SlideContent';
import WorkOrderListBottom from '../components/workOrderListBottom/WorkOrderListBottom';
import * as productListAction from '../actions/productList';
import * as popupAction from '../../../components/Popup/actions/popupActions';
import * as workOrderAction from '../actions/workOrderList';
import * as zendeskAction from '../../WorkLog/actions/zendeskActions';
import './workOrderList.scss';
import WorkOrderListSlideContainer from './WorkOrderListSlideContainer';
var WorkOrderListContainer = /** @class */ (function (_super) {
    __extends(WorkOrderListContainer, _super);
    function WorkOrderListContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WorkOrderListContainer.prototype.componentDidMount = function () {
        var _a = this.props, requestProductList = _a.requestProductList, requestWorkOrderList = _a.requestWorkOrderList, saveComment = _a.saveComment, updateFollowUp = _a.updateFollowUp;
        saveComment('');
        updateFollowUp(false, false, false);
        requestWorkOrderList();
        requestProductList();
    };
    WorkOrderListContainer.prototype.componentWillUnmount = function () {
        var cancelWorkOrderListFetching = this.props.cancelWorkOrderListFetching;
        cancelWorkOrderListFetching();
    };
    WorkOrderListContainer.prototype.render = function () {
        var _a = this.props, endDay = _a.endDay, errorMessage = _a.errorMessage, isProductListFetching = _a.isProductListFetching, isWorkOrderListFetching = _a.isWorkOrderListFetching, role = _a.role, togglePopup = _a.togglePopup, workOrders = _a.workOrders, previousWorkOrders = _a.previousWorkOrders;
        var isTechnician = role === TECHNICIAN;
        var isExternalInstaller = role === EXTERNAL_INSTALLER;
        var productsLoading = isTechnician ? false : isProductListFetching;
        var isWorkOrderListEmpty = !workOrders.length;
        var unfinishedPastWorkOrders = previousWorkOrders.filter(function (item) { return item.status === 'SENT'; });
        if (isWorkOrderListFetching || productsLoading) {
            return (React.createElement("div", { className: "work_order_list--fetching" },
                React.createElement(FetchingSpinner, null)));
        }
        if (errorMessage !== '' && !workOrders.length) {
            return (React.createElement("div", { className: "workorder__list--no--workorder" },
                React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, link: false, secondInfoBoxLine: errorMessage }),
                React.createElement(WorkOrderListBottom, { endDayFunction: endDay, role: role, togglePopup: togglePopup })));
        }
        return (React.createElement("div", { className: "workOrder__list", style: { overflowX: 'hidden' } },
            !!unfinishedPastWorkOrders.length && (React.createElement("div", { className: "unfinished_work_orders_message" },
                React.createElement("b", null, "VIKTIG!"),
                " Du har",
                React.createElement("span", { className: "unfinished_work_orders_message__number" }, unfinishedPastWorkOrders.length),
                "WO'er som ikke er meldt inn. Vennligst meld inn disse s\u00E5 fort fort du kan! Jobber skal alltid meldes inn samme dag.")),
            isExternalInstaller && React.createElement(WorkOrderListSlideContainer, null),
            isTechnician && (React.createElement(SlideContent, { isTechnician: true, workOrdersNotDone: workOrders, isWorkOrderListEmpty: isWorkOrderListEmpty })),
            React.createElement(WorkOrderListBottom, { endDayFunction: endDay, role: role, togglePopup: togglePopup })));
    };
    return WorkOrderListContainer;
}(Component));
WorkOrderListContainer.propTypes = {
    endDay: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isProductListFetching: PropTypes.bool.isRequired,
    isWorkOrderListFetching: PropTypes.bool.isRequired,
    requestProductList: PropTypes.func.isRequired,
    requestWorkOrderList: PropTypes.func.isRequired,
    cancelWorkOrderListFetching: PropTypes.func.isRequired,
    role: PropTypes.string,
    saveComment: PropTypes.func.isRequired,
    togglePopup: PropTypes.func.isRequired,
    updateFollowUp: PropTypes.func.isRequired,
    workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
    previousWorkOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
};
WorkOrderListContainer.defaultProps = {
    errorMessage: '',
    role: EXTERNAL_INSTALLER,
};
var mapStateToProps = function (state) { return ({
    errorMessage: state.workOrders.errorMessage,
    isProductListFetching: state.productList.isFetching,
    isWorkOrderListFetching: state.workOrders.isFetching,
    productList: state.productList.productList,
    role: state.authentication.role,
    workOrders: state.workOrders.workOrders,
    numberOfPrevWorkOrders: state.previousWorkOrders.workOrders.length
        ? state.previousWorkOrders.latestPreviousWorkOrderRequest.totalElements
        : 0,
    previousWorkOrders: state.previousWorkOrders.workOrders,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    endDay: function (endDay) { return dispatch(workOrderAction.endDayFunction({ endDay: endDay })); },
    requestWorkOrderList: function () { return dispatch(workOrderAction.requestWorkOrderList()); },
    cancelWorkOrderListFetching: function () { return dispatch(workOrderAction.workOrderListCancel()); },
    requestProductList: function () { return dispatch(productListAction.requestProductList()); },
    saveComment: function (commentBody) { return dispatch(zendeskAction.saveComment(commentBody)); },
    togglePopup: function (close, popupType) { return dispatch(popupAction.togglePopup({ close: close, popupType: popupType })); },
    updateFollowUp: function (followUp, customerNotHome, allChecked) {
        return dispatch(zendeskAction.setFollowUp({ followUp: followUp, customerNotHome: customerNotHome, allChecked: allChecked }));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderListContainer);
