export var SENT = 'SENT';
export var CANCELLED = 'CANCELLED';
var NEW = '00 – NEW';
var ALLOCATE = '10 – ALLOCATE';
export var COMMITED = '30 – COMMIT';
var NETWORKED = '32 – NETWORKED';
var DELIVERED = '35 – DELIVERED';
var ACCEPTED = '40 – ACCEPTED';
export var ON_SITE_STATUS = '60 – ON SITE';
export var NOT_FOUND = 'NOT FOUND';
export var TRAVELING_STATUS = '50 – TRAVELING';
export var NEARLY_DONE = '65 – NEARLY DONE';
export var COMPLETE = '70 – COMPLETE';
export var COMPLETE_WITHOUT_CODE = 'COMPLETE';
var COMPLETE_WITHOUT_CUSTOMER = '71 – COMPLETE_WITHOUT_CUSTOMER';
export var INCOMPLETE = '80 – INCOMPLETE';
export var INCOMPLETE_WITHOUT_CODE = 'INCOMPLETE';
var INCOMPLETE_WITHOUT_CUSTOMER = '81 – INCOMPLETE_WITHOUT_CUSTOMER';
export var INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE = 'INCOMPLETE_WITHOUT_CUSTOMER';
var WAITING = '55 – WAITING';
var Status = {
    ALLOCATED: [SENT, NEW, ALLOCATE, COMMITED, NETWORKED, DELIVERED, ACCEPTED],
    TRAVELING: [TRAVELING_STATUS],
    ON_SITE: [ON_SITE_STATUS, NEARLY_DONE],
    COMPLETE_ARRAY: [COMPLETE, COMPLETE_WITHOUT_CUSTOMER],
    INCOMPLETE_ARRAY: [INCOMPLETE, INCOMPLETE_WITHOUT_CUSTOMER],
};
export var doneStatusCodes = [
    CANCELLED,
    COMPLETE,
    COMPLETE_WITHOUT_CODE,
    COMPLETE_WITHOUT_CUSTOMER,
    INCOMPLETE,
    INCOMPLETE_WITHOUT_CODE,
    INCOMPLETE_WITHOUT_CUSTOMER,
    INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE,
];
export var statusToSkip = [ALLOCATE, COMMITED, NETWORKED, DELIVERED];
export var getNextStatus = function (currentStatus) {
    switch (currentStatus) {
        case NEW:
            return 'COMMITTED';
        case ALLOCATE:
            return 'COMMITTED';
        case NETWORKED:
            return 'DELIVERED';
        case DELIVERED:
            return 'ACCEPTED';
        case COMMITED:
            return 'ACCEPTED';
        case ACCEPTED:
            return 'TRAVELING';
        case TRAVELING_STATUS:
            return 'ON_SITE';
        case WAITING:
            return 'ON_SITE';
        case SENT:
            return 'ICC_STATUS';
        case ON_SITE_STATUS:
            return 'NEARLY_DONE';
        default:
            return NOT_FOUND;
    }
};
export default Object.freeze(Status);
