import PropTypes from 'prop-types';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import Icon from '../../../../icons/index';
import { STATUS_ERROR, STATUS_OK } from '../../../../constants/iconNames';
import DeviceStatus from '../../../../constants/deviceStatus';
import getDeviceStatusColor from '../../../../utils/getDeviceColorStatus';
export default function StatusCheck(_a) {
    var checkTitle = _a.checkTitle, isFetching = _a.isFetching, deviceStatus = _a.deviceStatus;
    return (React.createElement("div", { className: "health__check__status__container--item" },
        isFetching ? (React.createElement(ClipLoader, { size: 20 })) : (React.createElement("div", null, deviceStatus === DeviceStatus.OK || deviceStatus === DeviceStatus.NEUTRAL ? (React.createElement(Icon, { name: STATUS_OK, fill: getDeviceStatusColor(deviceStatus) })) : (React.createElement(Icon, { name: STATUS_ERROR })))),
        checkTitle));
}
StatusCheck.propTypes = {
    checkTitle: PropTypes.string.isRequired,
    deviceStatus: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
};
StatusCheck.defaultProps = {
    deviceStatus: '',
};
