var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SIGN_IN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT_REQUEST, VERIFY_TOKEN_FAILURE, VERIFY_TOKEN_REQUEST, VERIFY_TOKEN_SUCCESS, EXTERNAL_INSTALLER, DEV_SIGN_IN_REQUEST, } from '../constants';
import { TOKEN } from '../../../constants/localStorage';
import { setToken } from '../../../api/apiConfig';
var signInReducerDefaultState = {
    isAuthenticated: false,
    isFetching: false,
    role: EXTERNAL_INSTALLER,
    signInError: false,
};
export default (function (state, _a) {
    if (state === void 0) { state = signInReducerDefaultState; }
    var credentials = _a.credentials, role = _a.role, token = _a.token, type = _a.type;
    switch (type) {
        case SIGN_IN_REQUEST:
            return __assign(__assign({}, state), { isFetching: true, credentials: credentials });
        case DEV_SIGN_IN_REQUEST:
            return __assign(__assign({}, state), { isFetching: true, credentials: credentials });
        case SIGN_IN_SUCCESS:
            localStorage.setItem(TOKEN, token);
            return {
                isFetching: false,
                isAuthenticated: true,
                signInError: false,
            };
        case SIGN_IN_FAILURE:
            return {
                isFetching: false,
                isAuthenticated: false,
                signInError: true,
            };
        case VERIFY_TOKEN_REQUEST:
            return {
                isFetching: true,
                isAuthenticated: false,
            };
        case VERIFY_TOKEN_SUCCESS:
            return {
                token: token,
                role: role,
                isAuthenticated: true,
                isFetching: false,
                signInError: false,
            };
        case VERIFY_TOKEN_FAILURE:
            localStorage.removeItem(TOKEN);
            return {
                isAuthenticated: false,
                isFetching: false,
                signInError: false,
            };
        case SIGN_OUT_REQUEST:
            localStorage.removeItem(TOKEN);
            return {
                isFetching: false,
                isAuthenticated: false,
                signInError: false,
            };
        default:
            return state;
    }
});
