var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import SlideInModal from '../../components/SlideInModal/SlideInModal';
import HealthCheckTabs from './components/HealthCheckTabs';
import FetchingSpinner from '../../utils/FetchingSpinner';
import { getAPI } from '../../api/apiConfig';
import IconButton from '../../components/Buttons/IconButton/IconButton';
import { REFRESH } from '../../constants/iconNames';
import InfoBox from '../../components/InfoBox/InfoBox';
import Feedback from '../../feedbacks';
import { DEVICE_POLL_URL } from '../../constants/endpoints';
function DeviceHealthCheckContainer(_a) {
    var _this = this;
    var pollUrl = _a.pollUrl, onClose = _a.onClose, description = _a.description, display = _a.display, languageStrings = _a.languageStrings;
    var _b = __read(useState(true), 2), isFetching = _b[0], setIsFetching = _b[1];
    var _c = __read(useState({ success: false, errorDescription: '' }), 2), healthCheck = _c[0], setHealthCheck = _c[1];
    var _d = __read(useState(''), 2), errorMessage = _d[0], setErrorMessage = _d[1];
    var callGetHealthCheck = function () { return __awaiter(_this, void 0, void 0, function () {
        var healthCheckResult, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsFetching(true);
                    setErrorMessage('');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getAPI(DEVICE_POLL_URL(pollUrl))];
                case 2:
                    healthCheckResult = _a.sent();
                    setHealthCheck(healthCheckResult);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    Sentry.captureException(error_1);
                    setErrorMessage(languageStrings.HEALTH_CHECK_ERROR);
                    return [3 /*break*/, 4];
                case 4:
                    setIsFetching(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (pollUrl)
            callGetHealthCheck();
    }, [pollUrl]);
    return (React.createElement(SlideInModal, { display: display, onClose: onClose, mainHeading: description, leftIcon: isFetching ? (React.createElement(ClipLoader, { size: 20 })) : (React.createElement(IconButton, { onClick: callGetHealthCheck, icon: REFRESH, className: "sam_refresh-button" })) },
        isFetching && React.createElement(FetchingSpinner, null),
        !isFetching && (errorMessage || !healthCheck.success) && (React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: languageStrings.SORRY, link: false, secondInfoBoxLine: healthCheck.errorDescription || errorMessage })),
        !isFetching && healthCheck.success && (React.createElement(HealthCheckTabs, { categories: healthCheck.categories }))));
}
DeviceHealthCheckContainer.propTypes = {
    pollUrl: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    description: PropTypes.string,
    display: PropTypes.bool.isRequired,
    languageStrings: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
DeviceHealthCheckContainer.defaultProps = {
    pollUrl: '',
    description: '',
};
var mapStateToProps = function (state) { return ({
    languageStrings: state.NOB.FEEDBACKS,
}); };
export default connect(mapStateToProps)(DeviceHealthCheckContainer);
