var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddressHistoryDropdown from '../components/AddressHistoryDropdown';
import capitalize from '../../../utils/capitalizeString';
import Feedback from '../../../feedbacks';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import { requestAddressHistory } from '../actions/addressHistoryActions';
import './addressHistory.scss';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';
var AddressHistoryContainer = /** @class */ (function (_super) {
    __extends(AddressHistoryContainer, _super);
    function AddressHistoryContainer() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = { opened: 0 };
        _this.toggleDropdown = function (workOrderId) {
            _this.setState({ opened: workOrderId });
        };
        return _this;
    }
    AddressHistoryContainer.prototype.componentDidMount = function () {
        var _a = this.props, addressHistory = _a.addressHistory, location = _a.location, requestAddressHistoryFunc = _a.requestAddressHistoryFunc, workOrder = _a.workOrder;
        if (location.pathname.indexOf('customerInfo') !== -1)
            return;
        var customerIds = addressHistory.map(function (item) { return item.customerId; });
        if (addressHistory.length || !customerIds.includes("".concat(workOrder.customer.customerId))) {
            requestAddressHistoryFunc(location.pathname.split('/')[2]);
        }
    };
    AddressHistoryContainer.prototype.render = function () {
        var _a = this.props, addressHistory = _a.addressHistory, isFetching = _a.isFetching, workOrder = _a.workOrder;
        var address = "".concat(capitalize(workOrder.address.street), "\n      ").concat(workOrder.address.houseNumberNumeric, "\n      ").concat(workOrder.address.houseNumberAlpha && " ".concat(workOrder.address.houseNumberAlpha, ","));
        return (React.createElement(SlideInPage, { mainHeading: NOB.ADDRESS_HISTORY.ADDRESS_HISTORY, showLeftIcon: true, showRightIcon: false, subHeading: address, isFetching: isFetching }, !addressHistory.length > 0 ? (React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, link: false, secondInfoBoxLine: NOB.ADDRESS_HISTORY.NO_ADDRESS_HISTORY })) : (React.createElement("div", { className: "address_history_container" },
            React.createElement(AddressHistoryDropdown, { addressHistory: addressHistory, opened: this.state.opened, toggleDropdown: this.toggleDropdown })))));
    };
    return AddressHistoryContainer;
}(Component));
AddressHistoryContainer.propTypes = {
    addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
    isFetching: PropTypes.bool.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    requestAddressHistoryFunc: PropTypes.func.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
};
var mapStateToProps = function (state) { return ({
    addressHistory: state.addressHistory.addressHistory,
    isFetching: state.addressHistory.isFetching,
    workOrder: state.workOrder.workOrder,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    requestAddressHistoryFunc: function (workOrderId) { return dispatch(requestAddressHistory({ workOrderId: workOrderId })); },
}); };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddressHistoryContainer));
