export var HEALTH_CHECK_REQUEST = 'HEALTH_CHECK_REQUEST';
export var HEALTH_CHECK_RECEIVE = 'HEALTH_CHECK_RECEIVE';
export var HEALTH_CHECK_ERROR = 'HEALTH_CHECK_ERROR';
export var UPDATE_HEALTH_CHECK_REQUEST = 'UPDATE_HEALTH_CHECK_REQUEST';
export var ICC_CHECK_REQUEST = 'ICC_CHECK_REQUEST';
export var ICC_CHECK_RECEIVE = 'ICC_CHECK_RECEIVE';
export var ICC_CHECK_ERROR = 'ICC_CHECK_ERROR';
export var DEVICE_POLLING_CUSTOMER_REQUEST = 'DEVICE_POLLING_CUSTOMER_REQUEST';
export var DEVICE_POLLING_CUSTOMER_RECEIVE = 'DEVICE_POLLING_CUSTOMER_RECEIVE';
export var DEVICE_POLLING_CUSTOMER_ERROR = 'DEVICE_POLLING_CUSTOMER_ERROR';
export var SAM = '#healthCheck#sam';
export var DEVICE_POLLING_MAC_REQUEST = 'DEVICE_POLLING_MAC_REQUEST';
export var DEVICE_POLLING_MAC_RECEIVE = 'DEVICE_POLLING_MAC_RECEIVE';
export var DEVICE_POLLING_MAC_ERROR = 'DEVICE_POLLING_MAC_ERROR';
export var PASSED = 'Passed';
export var UPDATE_DEVICE_POLLING_MAC_REQUEST = 'UPDATE_DEVICE_POLLING_MAC_REQUEST';
export var UPDATE_DEVICE_POLLING_MAC_RECEIVE = 'UPDATE_DEVICE_POLLING_MAC_RECEIVE';
export var UPDATE_DEVICE_POLLING_MAC_ERROR = 'UPDATE_DEVICE_POLLING_MAC_ERROR';
