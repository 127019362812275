import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../utils/FetchingSpinner';
import './slideInPage.scss';
/* children container needs to be added to array for slide in pages in routes to work properly */
function SlideInPage(_a) {
    var children = _a.children, isFetching = _a.isFetching, mainHeading = _a.mainHeading, subHeading = _a.subHeading, showLeftIcon = _a.showLeftIcon, showRightIcon = _a.showRightIcon, rightIcon = _a.rightIcon;
    useEffect(function () {
        document.body.classList.add('popup-open');
        return function cleanUp() {
            document.body.classList.remove('popup-open');
        };
    });
    return (React.createElement("div", { className: "slide_in_page-container" },
        React.createElement(ComponentHeader, { mainHeading: mainHeading, subHeading: subHeading, showLeftIcon: showLeftIcon, showRightIcon: showRightIcon, rightIcon: rightIcon }),
        React.createElement("div", { className: "slide_in_page-content" }, isFetching ? React.createElement(FetchingSpinner, null) : children)));
}
SlideInPage.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.any),
        PropTypes.objectOf(PropTypes.any),
    ]).isRequired,
    isFetching: PropTypes.bool,
    mainHeading: PropTypes.string,
    subHeading: PropTypes.string,
    showLeftIcon: PropTypes.bool,
    showRightIcon: PropTypes.bool,
    rightIcon: PropTypes.element,
};
SlideInPage.defaultProps = {
    isFetching: false,
    mainHeading: '',
    subHeading: '',
    showLeftIcon: false,
    showRightIcon: false,
    rightIcon: null,
};
export default SlideInPage;
