var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import AddProductsContainer from '../../containers/addProduct/AddProductsContainer';
import NOB from '../../../../constants/language-strings';
import { NUMBERS_IN_LETTERS_NOB } from '../../../../constants/numbers-in-letters';
import AgreementPrice from './AgreementPrice/AgreementPrice';
import filterCustomerProducts from '../../../../utils/filterCustomerProducts';
import './customerProductList.scss';
import SimpleButton from '../../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../../components/Buttons/Color';
import ListItem from '../../../../components/ListItem/ListItem';
import ProductListElementContainer from '../../containers/CustomerProduct/ProductListElementContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
Modal.setAppElement('#app');
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            cacheTime: 0,
        },
    },
});
export default function CustomerProductList(_a) {
    var addProductErrorMessage = _a.addProductErrorMessage, customerId = _a.customerId, agreements = _a.agreements, customerProducts = _a.customerProducts, errorMessage = _a.errorMessage, isAdding = _a.isAdding;
    var _b = __read(useState(false), 2), IsVisibleAddProductModal = _b[0], setIsVisibleAddProductModal = _b[1];
    var _c = __read(useState(0), 2), selectedAgreement = _c[0], setSelectedAgreement = _c[1];
    var _d = __read(useState(false), 2), showAddProductErrorMessage = _d[0], setShowAddProductErrorMessage = _d[1];
    var allCustomerProducts = filterCustomerProducts(customerProducts);
    // If there is a new product, move this to the bottom of list.
    if (allCustomerProducts.find(function (product) { return product.newProduct; })) {
        var findNewProductIndex = allCustomerProducts.findIndex(function (product) { return product.newProduct; });
        var newProductItem = allCustomerProducts.splice(findNewProductIndex, 1);
        allCustomerProducts.push(newProductItem[0]);
    }
    var showAddProductButton = !errorMessage && customerProducts && agreements.agreements.length > 0;
    /* list out all products */
    var productList = function (products) { return (React.createElement("div", null, products.map(function (product) { return (React.createElement(ProductListElementContainer, { product: product, key: product.productId })); }))); };
    /* show productlist with prices and add products button */
    var agreementProductList = agreements.agreements.map(function (agreement) {
        var productListFilter = allCustomerProducts.filter(function (singleCustomerProduct) { return singleCustomerProduct.agreementId === agreement.agreementId; });
        var productWithDevices = productListFilter.map(function (it) { return it.name; });
        var productsInAgreementWithoutDevice = agreement.productNames.filter(function (it) { return !productWithDevices.includes(it); });
        return (React.createElement("div", { className: "customer__products__list__container", key: agreement.agreementId },
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(AgreementPrice, { customerId: customerId, agreementId: agreement.agreementId, originalPrice: agreement.originalPrice, currentPrice: agreement.currentPrice, isFetchingPrices: agreements.isFetchingPrices })),
            React.createElement("div", { className: "customer__products__container__products-without-devices-list" }, productsInAgreementWithoutDevice.map(function (it) { return (React.createElement("p", { key: it }, it)); })),
            productList(productListFilter),
            isAdding && React.createElement(ListItem, { isFetching: isAdding }),
            showAddProductButton && (React.createElement("div", { className: "customer__products__container__add-button" },
                (addProductErrorMessage || showAddProductErrorMessage) && (React.createElement("div", { className: "customer__products__container__add-button--error" }, showAddProductErrorMessage
                    ? 'Kan ikke legge til produkter, fikk ikke hentet ut kundens agreements'
                    : addProductErrorMessage)),
                React.createElement(SimpleButton, { onClick: function () {
                        if (agreements.errorMessage) {
                            setShowAddProductErrorMessage(true);
                        }
                        else {
                            setIsVisibleAddProductModal(function (state) { return !state; });
                            setSelectedAgreement(agreement.agreementId);
                        }
                    }, deactivated: !!agreements.errorMessage, color: Color.BLACK, title: "Legg til produkt" })))));
    });
    return (React.createElement("div", { className: "customer__products__container" },
        agreements.length > 1 && (React.createElement("div", { className: "customer__products__container--heading" },
            React.createElement("span", null, NOB.CUSTOMER_PRODUCTS.CUSTOMER_HAS),
            "\u00A0",
            NUMBERS_IN_LETTERS_NOB[agreements.length],
            "\u00A0",
            React.createElement("span", null, NOB.CUSTOMER_PRODUCTS.AGREEMENTS))),
        agreementProductList.length > 0 ? agreementProductList : productList(allCustomerProducts),
        React.createElement(AddProductsContainer, { agreementId: selectedAgreement, isVisible: IsVisibleAddProductModal, onClose: function () { return setIsVisibleAddProductModal(function (state) { return !state; }); } })));
}
CustomerProductList.propTypes = {
    agreements: PropTypes.objectOf(PropTypes.any).isRequired,
    customerProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
    errorMessage: PropTypes.string,
    isAdding: PropTypes.bool.isRequired,
    customerId: PropTypes.number.isRequired,
    addProductErrorMessage: PropTypes.string.isRequired,
};
CustomerProductList.defaultProps = {
    errorMessage: '',
};
