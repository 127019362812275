import React from 'react';
import PropTypes from 'prop-types';
import '../../sam.scss';
import NOB from '../../../../constants/language-strings';
import ModemInfo from './ModemInfo';
import Streams from './Streams';
import { UPSTREAM, DOWNSTREAM } from '../../../../constants/samSelector';
var Overview = function (_a) {
    var devicePollingMac = _a.devicePollingMac;
    return (React.createElement("div", { className: "overview" },
        React.createElement("div", { className: "overview__section" },
            React.createElement("div", { className: "overview__section--heading" }, NOB.DOWNSTREAM_UPSTREAM.MODEM_INFO),
            React.createElement(ModemInfo, { devicePollingMac: devicePollingMac })),
        React.createElement("div", { className: "overview__section" },
            React.createElement("div", { className: "overview__section--heading" }, NOB.DOWNSTREAM_UPSTREAM.DOWNSTREAMS),
            React.createElement(Streams, { type: DOWNSTREAM, channels: devicePollingMac.DsChannels, noOfConfigured: devicePollingMac.NoOfDsConfigured, scoreVariables: devicePollingMac.ScoreVariables })),
        React.createElement("div", { className: "overview__section" },
            React.createElement("div", { className: "overview__section--heading" }, NOB.DOWNSTREAM_UPSTREAM.UPSTREAMS),
            React.createElement(Streams, { type: UPSTREAM, channels: devicePollingMac.UsChannels, noOfConfigured: devicePollingMac.NoOfUsConfigured, scoreVariables: devicePollingMac.ScoreVariables }))));
};
Overview.propTypes = {
    devicePollingMac: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Overview;
