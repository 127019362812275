import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
var SVG = function (_a) {
    var stroke = _a.stroke;
    return (React.createElement("svg", { width: "48px", height: "48px", viewBox: "0 0 48 48", version: "1.1" },
        React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
            React.createElement("g", { id: "icon/forst\u00F8rre", stroke: stroke },
                React.createElement("g", { id: "Arrow/Arrow-left_svart", transform: "translate(14.435029, 33.435029) rotate(-45.000000) translate(-14.435029, -33.435029) translate(4.435029, 24.435029)" },
                    React.createElement("g", { id: "Group", transform: "translate(10.000000, 9.000000) scale(-1, 1) translate(-10.000000, -9.000000) translate(1.000000, 3.000000)" },
                        React.createElement("polyline", { id: "Path-2", strokeWidth: "2", transform: "translate(15.000000, 6.000000) scale(-1, 1) rotate(90.000000) translate(-15.000000, -6.000000) ", points: "9 3 15 9 21 3" }),
                        React.createElement("path", { d: "M17,6 L0,6", id: "Line", strokeWidth: "2" }))),
                React.createElement("g", { id: "Arrow/Arrow-left_svart", transform: "translate(33.435029, 14.435029) scale(-1, 1) rotate(45.000000) translate(-33.435029, -14.435029) translate(23.435029, 5.435029)" },
                    React.createElement("g", { id: "Group", transform: "translate(10.000000, 9.000000) scale(-1, 1) translate(-10.000000, -9.000000) translate(1.000000, 3.000000)" },
                        React.createElement("polyline", { id: "Path-2", strokeWidth: "2", transform: "translate(15.000000, 6.000000) scale(-1, 1) rotate(90.000000) translate(-15.000000, -6.000000) ", points: "9 3 15 9 21 3" }),
                        React.createElement("path", { d: "M17,6 L0,6", id: "Line", strokeWidth: "2" })))))));
};
SVG.propTypes = {
    stroke: PropTypes.string,
};
SVG.defaultProps = {
    stroke: Variables.blackPrimary,
};
export default SVG;
