var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import PropTypes from 'prop-types';
import NOB from './../../../constants/language-strings';
import styled from 'styled-components';
var ListItemWrapper = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style-type: none;\n  padding: 5px 0;\n  border-bottom: 1px solid #ddd;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n\n  &:last-child {\n    border-bottom: none;\n  }\n"], ["\n  list-style-type: none;\n  padding: 5px 0;\n  border-bottom: 1px solid #ddd;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n\n  &:last-child {\n    border-bottom: none;\n  }\n"])));
export default function WorkOrderHistoryItem(_a) {
    var workOrderHistoryItem = _a.workOrderHistoryItem;
    return (React.createElement(ListItemWrapper, null,
        React.createElement("div", null, workOrderHistoryItem.time),
        React.createElement("div", null, "".concat(NOB.WORKORDER_HISTORY.USER, ": ").concat(workOrderHistoryItem.userFullName, " (").concat(workOrderHistoryItem.userId, ")")),
        React.createElement("div", null, "".concat(NOB.WORKORDER_HISTORY.STATUS_ID, ": ").concat(workOrderHistoryItem.statusId)),
        React.createElement("div", null, "".concat(NOB.WORKORDER_HISTORY.REASON, ": ").concat(workOrderHistoryItem.reasonDescription, " (").concat(workOrderHistoryItem.reasonKey, ")"))));
}
WorkOrderHistoryItem.propTypes = {
    workOrderHistoryItem: PropTypes.shape({
        reasonDescription: PropTypes.string.isRequired,
        reasonKey: PropTypes.number.isRequired,
        statusId: PropTypes.number.isRequired,
        time: PropTypes.string.isRequired,
        userId: PropTypes.number.isRequired,
        userFullName: PropTypes.string.isRequired,
    }).isRequired,
};
var templateObject_1;
