var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import usePrevious from '../../utils/customHooks/usePrevious';
function ScrollToTop(_a) {
    var location = _a.location, children = _a.children;
    var _b = __read(useState(location), 2), newLocation = _b[0], setNewLocation = _b[1];
    var prevLocation = usePrevious(newLocation);
    useEffect(function () {
        if (prevLocation && location.pathname !== prevLocation.pathname) {
            setNewLocation(location);
            window.scrollTo(0, 0);
        }
    });
    return children;
}
ScrollToTop.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    children: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(ScrollToTop);
