import React from 'react';
import PropTypes from 'prop-types';
import './radio-button-group.scss';
import RadioButton from '../Buttons/RadioButton/RadioButton';
function RadioButtonGroup(_a) {
    var title = _a.title, values = _a.values, onChange = _a.onChange, chosenValue = _a.chosenValue, hasError = _a.hasError;
    var radioButtons = values.map(function (value) { return (React.createElement(RadioButton, { name: title, onClick: onChange, checked: chosenValue === value, label: value, key: value, value: value })); });
    var label = hasError ? "* ".concat(title) : title;
    var className = hasError ? 'radio-group--error' : 'radio-group';
    return (React.createElement("div", { className: className },
        React.createElement("label", { className: "radio-group-label" }, label),
        React.createElement("div", { className: "radio-buttons" }, radioButtons)));
}
RadioButtonGroup.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    chosenValue: PropTypes.string,
    hasError: PropTypes.bool,
};
RadioButtonGroup.defaultProps = {
    chosenValue: '',
    hasError: false,
};
export default RadioButtonGroup;
