var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import capitalize from '../../../../utils/capitalizeString';
import { HOUSE, USER } from '../../../../constants/iconNames';
import Icon from '../../../../icons/index';
import NOB from '../../../../constants/language-strings';
import setStatusTimeValue from '../../../../utils/setStatusTimeValue';
import setStatusClassName from '../../../../utils/setStatusClassName';
import Status, { CANCELLED, INCOMPLETE_WITHOUT_CODE } from '../../../../constants/status-codes';
import Variables from '../../../../styles/variables.scss';
import isPast from '../../../../utils/isPastDue';
export default function WorkOrderList(_a) {
    var workOrders = _a.workOrders;
    var workOrderStatus = function (workOrder) {
        return /\d/.test(workOrder.status)
            ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
            : workOrder.status;
    };
    var startedStatuses = __spreadArray(__spreadArray([], __read(Status.TRAVELING), false), __read(Status.ON_SITE), false);
    var houseColor = function (status, isPastDue) {
        if (status.includes(INCOMPLETE_WITHOUT_CODE) || isPastDue)
            return Variables.buttonRed;
        else if (status === CANCELLED)
            return Variables.blackPrimary;
        return Variables.iconBlue;
    };
    var workOrderList = workOrders.map(function (workOrder) {
        var _a = workOrder.address, city = _a.city, flatOrApartment = _a.flatOrApartment, houseNumberAlpha = _a.houseNumberAlpha, houseNumberNumeric = _a.houseNumberNumeric, postalCode = _a.postalCode, street = _a.street, serviceTypeName = workOrder.serviceTypeName, workOrderId = workOrder.workOrderId, serviceDate = workOrder.serviceDate, _b = workOrder.customer, firstName = _b.firstName, surname = _b.surname;
        var isPastDue = isPast(serviceDate);
        return (React.createElement(Link, { key: workOrderId, to: "/workOrder/".concat(workOrderId), className: "".concat(setStatusClassName(startedStatuses, workOrderStatus(workOrder), workOrder), " ").concat(isPastDue ? 'workorder-warning' : '') },
            React.createElement("div", { className: "workOrder__container--top--left" },
                React.createElement("div", null, serviceTypeName),
                React.createElement("div", { className: "new-text" },
                    React.createElement(Icon, { name: USER, color: houseColor(workOrderStatus(workOrder), isPastDue) }),
                    firstName,
                    " ",
                    surname)),
            React.createElement("div", { className: "workOrder__container--top--right" },
                React.createElement("span", null, "".concat(NOB.WORKORDER_LIST.WORKORDER, ": ")),
                React.createElement("span", null, workOrderId)),
            React.createElement("div", { className: "workOrder__container--bottom--left" },
                React.createElement(Icon, { name: HOUSE, houseColor: houseColor(workOrderStatus(workOrder), isPastDue) }),
                React.createElement("div", { className: "workOrder__container--bottom--left--address" },
                    React.createElement("div", null, "\n                ".concat(capitalize(street), "\n                ").concat(houseNumberNumeric, "\n                ").concat(houseNumberAlpha && " ".concat(houseNumberAlpha, ", "), "\n              ")),
                    React.createElement("div", { className: "workOrder__container--bottom--left--address--postalCode" },
                        React.createElement("span", null, "".concat(postalCode, " ").concat(capitalize(city))),
                        React.createElement("span", null, flatOrApartment)))),
            React.createElement("div", { className: "workOrder__container__bottom--right" }, setStatusTimeValue(workOrder, workOrderStatus(workOrder), isPastDue))));
    });
    return React.createElement("div", { className: "workOrder__list__container" }, workOrderList);
}
WorkOrderList.propTypes = {
    workOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
};
