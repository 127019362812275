import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './slideInModal.scss';
import IconButton from '../Buttons/IconButton/IconButton';
import { CROSS_LARGE } from '../../constants/iconNames';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../utils/FetchingSpinner';
function SlideInModal(_a) {
    var children = _a.children, onClose = _a.onClose, cleanUp = _a.cleanUp, display = _a.display, mainHeading = _a.mainHeading, subHeading = _a.subHeading, leftIcon = _a.leftIcon, isFetching = _a.isFetching, fetchingSpinnerColor = _a.fetchingSpinnerColor, onBackClick = _a.onBackClick;
    useEffect(function () {
        if (display)
            document.body.classList.add('popup-open');
        return function cleanup() {
            document.body.classList.remove('popup-open');
            cleanUp();
        };
    }, [display]);
    var closeIcon = React.createElement(IconButton, { onClick: onClose, icon: CROSS_LARGE, className: "fullscreen_icon" });
    return ReactDOM.createPortal(React.createElement("div", { className: display ? 'overlay' : null },
        React.createElement(CSSTransition, { className: "appear fullscreen_popup_container", timeout: 450, in: display, unmountOnExit: true },
            React.createElement("div", { className: "fullscreen_popup_container" },
                React.createElement(ComponentHeader, { rightIcon: closeIcon, mainHeading: mainHeading, subHeading: subHeading, leftIcon: leftIcon, onClick: onBackClick }),
                React.createElement("div", { className: "fullscreen_popup_content" },
                    isFetching && React.createElement(FetchingSpinner, { color: fetchingSpinnerColor }),
                    display && !isFetching && children)))), document.getElementById('modal-root'));
}
SlideInModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    cleanUp: PropTypes.func,
    display: PropTypes.bool.isRequired,
    mainHeading: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
    subHeading: PropTypes.string,
    leftIcon: PropTypes.objectOf(PropTypes.any),
    isFetching: PropTypes.bool,
    fetchingSpinnerColor: PropTypes.string,
};
SlideInModal.defaultProps = {
    mainHeading: '',
    subHeading: '',
    leftIcon: null,
    isFetching: false,
    cleanUp: function () { },
};
export default SlideInModal;
