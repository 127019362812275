var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import './pageSwiper.scss';
function PageSwiper(_a) {
    var children = _a.children, containerClass = _a.containerClass, getSwiper = _a.getSwiper, initialSlide = _a.initialSlide;
    var params = {
        containerClass: containerClass,
        slideActiveClass: 'swiper-slide-active',
        slideClass: 'swiper-slide',
    };
    return (React.createElement(Swiper, __assign({}, params, { initialSlide: initialSlide, getSwiper: getSwiper, spaceBetween: 40, shortSwipes: false, longSwipesMs: 0, longSwipesRatio: 0.2 }), children));
}
PageSwiper.propTypes = {
    children: PropTypes.node.isRequired,
    containerClass: PropTypes.string.isRequired,
    getSwiper: PropTypes.func.isRequired,
    initialSlide: PropTypes.number.isRequired,
};
export default PageSwiper;
