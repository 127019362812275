import PropTypes from 'prop-types';
import React from 'react';
import DropdownButton from './DropdownButton';
import DropdownContent from './DropdownContent';
import '../containers/addressHistory.scss';
var AddressHistoryDropdown = function (_a) {
    var addressHistory = _a.addressHistory, opened = _a.opened, toggleDropdown = _a.toggleDropdown;
    var spanLightText = function (content) { return (React.createElement("span", { className: "dropdown__content--item--light" }, content)); };
    var dropdown = addressHistory.map(function (singleAddressHistory) {
        var open = opened === 0 ? addressHistory[0].workOrderId : opened;
        var closeDropdown = open !== singleAddressHistory.workOrderId;
        return (React.createElement("div", { className: "address_history__dropdown", key: singleAddressHistory.workOrderId },
            React.createElement(DropdownButton, { closeDropdown: closeDropdown, open: open, singleAddressHistory: singleAddressHistory, toggleDropdown: toggleDropdown }),
            React.createElement(DropdownContent, { closeDropdown: closeDropdown, spanLightText: spanLightText, singleAddressHistory: singleAddressHistory })));
    });
    return React.createElement("div", { className: "dropdown" }, dropdown);
};
AddressHistoryDropdown.propTypes = {
    addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
    opened: PropTypes.number.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
};
export default AddressHistoryDropdown;
