var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import EsriLoaderReact from 'esri-loader-react';
import proj4 from 'proj4';
import React from 'react';
import PropTypes from 'prop-types';
import { EXPAND, CROSS_LARGE } from '../../../constants/iconNames';
import ComponentHeader from '../../../components/ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import Icon from '../../../icons/index';
import NOB from '../../../constants/language-strings';
import * as CONSTANT from '../constants';
import './map.scss';
var EsriLoader = function (_a) {
    var address = _a.address, handleExpand = _a.handleExpand, id = _a.id, isFetching = _a.isFetching, position = _a.position, shouldExpand = _a.shouldExpand, token = _a.token;
    if (isFetching) {
        return (React.createElement("div", { className: "map__container--minimized" },
            React.createElement(FetchingSpinner, null)));
    }
    var customerPosition = true;
    if (Number.isNaN(position.latitude) || Number.isNaN(position.longitude)) {
        customerPosition = false;
    }
    var UTM33;
    if (customerPosition) {
        UTM33 = proj4(CONSTANT.WGS84, CONSTANT.UTM, [position.longitude, position.latitude]);
    }
    return (React.createElement("div", { className: shouldExpand ? 'map__container--fullscreen' : 'map__container--minimized' },
        React.createElement(EsriLoaderReact, { modulesToLoad: [
                'esri/config',
                'esri/identity/IdentityManager',
                'esri/WebMap',
                'esri/views/MapView',
                'esri/tasks/Locator',
                'esri/Graphic',
                'esri/layers/GraphicsLayer',
                'esri/Viewpoint',
                'esri/geometry/Point',
            ], onReady: function (_a) {
                var _b = __read(_a.loadedModules, 9), esriConfig = _b[0], IdentityManager = _b[1], WebMap = _b[2], MapView = _b[3], Locator = _b[4], Graphic = _b[5], GraphicsLayer = _b[6], Viewpoint = _b[7], Point = _b[8], containerNode = _a.containerNode;
                var mapUrl = process.env.MAP_URL
                    ? process.env.MAP_URL
                    : "".concat(document.location.protocol, "//").concat(document.location.host);
                esriConfig.request.interceptors.push({
                    urls: 'https://geosam.get.no',
                    before: function (params) {
                        params.url = params.url.replace('/portal/', '/ias/'); // eslint-disable-line
                        params.url = params.url.replace('https://geosam.get.no/', "".concat(mapUrl, "/"));
                        return null;
                    },
                });
                esriConfig.portalUrl = "https://geosam.get.no/ias"; // eslint-disable-line
                IdentityManager.registerToken({
                    server: "".concat(mapUrl, "/server/rest/services"),
                    token: token,
                });
                IdentityManager.registerToken({
                    server: "".concat(mapUrl, "/ias/sharing/rest"),
                    token: token,
                });
                IdentityManager.registerToken({
                    server: "https://geosam.get.no/portal",
                    token: token,
                });
                var webmap = new WebMap({
                    portalItem: {
                        id: id,
                    },
                });
                var locator = new Locator();
                locator.url = CONSTANT.GEOCODE_URL;
                locator.countryCode = CONSTANT.NO;
                if (customerPosition) {
                    new MapView({
                        container: containerNode,
                        ui: { components: [CONSTANT.ATTRIBUTION] },
                        viewpoint: new Viewpoint({
                            targetGeometry: new Point({
                                x: UTM33[0],
                                y: UTM33[1],
                                spatialReference: { wkid: CONSTANT.WKID },
                            }),
                            scale: CONSTANT.SCALE,
                        }),
                        map: webmap,
                    });
                    var graphicsLayer = new GraphicsLayer();
                    webmap.add(graphicsLayer);
                    var point = {
                        type: 'point',
                        longitude: position.longitude,
                        latitude: position.latitude,
                    };
                    var simpleMarkerSymbol = {
                        type: 'simple-marker',
                        color: [226, 119, 40],
                        outline: {
                            color: [255, 255, 255],
                            width: 1,
                        },
                    };
                    var pointGraphic = new Graphic({
                        geometry: point,
                        symbol: simpleMarkerSymbol,
                    });
                    graphicsLayer.add(pointGraphic);
                }
                else {
                    locator
                        .addressToLocations({ address: { address: address } })
                        .then(function (res) {
                        return new MapView({
                            container: containerNode,
                            ui: { components: [CONSTANT.ATTRIBUTION] },
                            viewpoint: new Viewpoint({
                                targetGeometry: res[0].location,
                                scale: CONSTANT.SCALE,
                            }),
                            map: webmap,
                        });
                    })
                        .catch(function (error) { return error; });
                }
            } }),
        !shouldExpand ? (React.createElement("button", { className: "fullscreen-button--minimized", onClick: handleExpand },
            React.createElement(Icon, { name: EXPAND }))) : (React.createElement(ComponentHeader, { rightIcon: React.createElement("button", { onClick: handleExpand },
                React.createElement(Icon, { name: CROSS_LARGE })), mainHeading: NOB.MAP.MAP }))));
};
EsriLoader.propTypes = {
    address: PropTypes.string.isRequired,
    handleExpand: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    position: PropTypes.objectOf(PropTypes.number).isRequired,
    shouldExpand: PropTypes.bool.isRequired,
    token: PropTypes.string.isRequired,
};
export default EsriLoader;
