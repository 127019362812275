import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import Icon from '../../../icons';
import { ARROW_LEFT } from '../../../constants/iconNames';
import './navigationButton.scss';
import NOB from '../../../constants/language-strings';
import Variables from '../../../styles/variables.scss';
var NavigationButton = function (_a) {
    var URL = _a.URL, leftIcon = _a.leftIcon, title = _a.title, number = _a.number, numberColor = _a.numberColor, rightIcon = _a.rightIcon, disabled = _a.disabled, isFetching = _a.isFetching, customClass = _a.customClass, onClick = _a.onClick;
    var numberClassName = numberColor === NOB.COMPONENT.RED ? 'navigation_button_number-red' : 'navigation_button_number';
    var handleClick = function (e) {
        if (disabled || onClick !== null) {
            e.preventDefault();
            if (onClick !== null) {
                onClick(e);
            }
        }
    };
    return (React.createElement(Link, { className: disabled
            ? 'navigation_button-link-deactivated'
            : "navigation_button ".concat(customClass || 'navigation_button-standard'), to: URL, onClick: handleClick },
        React.createElement("div", { className: "navigation_button_content-left" },
            leftIcon && React.createElement("span", { className: "navigation_button_content-left-icon" }, leftIcon),
            React.createElement("span", { className: "navigation_button_content-text" }, title)),
        React.createElement("div", { className: "navigation_button_content-right" },
            number > 0 && (React.createElement("div", { className: numberClassName },
                React.createElement("span", null, number))),
            React.createElement("span", { className: "navigation_button-icon" }, isFetching ? (React.createElement(ClipLoader, { size: 16, color: Variables.grayButton })) : (React.createElement("span", { className: rightIcon ? '' : 'navigation_button-arrow' },
                React.createElement(Icon, { name: rightIcon || ARROW_LEFT })))))));
};
NavigationButton.propTypes = {
    disabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    leftIcon: PropTypes.objectOf(PropTypes.objectOf),
    number: PropTypes.number,
    title: PropTypes.string.isRequired,
    numberColor: PropTypes.string,
    URL: PropTypes.string.isRequired,
    rightIcon: PropTypes.string,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
};
NavigationButton.defaultProps = {
    disabled: false,
    leftIcon: null,
    rightIcon: null,
    numberColor: null,
    number: null,
    isFetching: false,
    customClass: '',
    onClick: null,
};
export default withRouter(NavigationButton);
