import React from 'react';
import PropTypes from 'prop-types';
import ProductItemContentActive from './ProductItemContentActive';
import ProductItemContentInactive from './ProductItemContentInactive';
import ListItem from '../../components/ListItem/ListItem';
import { ACTIVE } from '../../constants/productStatuses';
import SerialNumberList from '../../components/SerialNumber/SerialNumberList';
import './productListElement.scss';
function ProductListItem(_a) {
    var onDelete = _a.onDelete, onActivate = _a.onActivate, errorMessage = _a.errorMessage, isFetching = _a.isFetching, shouldActivateActiveAnimation = _a.shouldActivateActiveAnimation, serialNumberInputs = _a.serialNumberInputs, onClick = _a.onClick, clickable = _a.clickable, showDeleteButton = _a.showDeleteButton, showLinkIcon = _a.showLinkIcon, title = _a.title, status = _a.status, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, canBeActivated = _a.canBeActivated, onScannerClick = _a.onScannerClick, serialNumbers = _a.serialNumbers, onReset = _a.onReset;
    return (React.createElement("div", { clasname: "product-list-element" },
        React.createElement(ListItem, { activated: shouldActivateActiveAnimation, activeBorder: shouldActivateActiveAnimation, isFetching: isFetching, clickable: clickable, onClick: clickable ? onClick : null, errorMessage: errorMessage }, status === ACTIVE ? (React.createElement(ProductItemContentActive, { hasLinkOptions: showLinkIcon, name: title, showDeleteButton: showDeleteButton, removeProduct: onDelete, leftText: status, serialNumbers: serialNumbers })) : (React.createElement(ProductItemContentInactive, { onActivate: canBeActivated ? onActivate : null, name: title, showDeleteButton: showDeleteButton, removeProduct: onDelete, leftText: canBeActivated ? 'Trykk for å aktivere' : status }))),
        serialNumberInputs.length > 0 && (React.createElement(SerialNumberList, { devices: serialNumberInputs, handleChange: handleChange, handleSubmit: handleSubmit, onScannerClick: onScannerClick, onReset: onReset, isFetching: isFetching }))));
}
ProductListItem.propTypes = {
    onDelete: PropTypes.func,
    onActivate: PropTypes.func,
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool,
    shouldActivateActiveAnimation: PropTypes.bool,
    serialNumberInputs: PropTypes.arrayOf(PropTypes.shape({
        dpadId: PropTypes.number.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        errorMessage: PropTypes.string,
    })),
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    showLinkIcon: PropTypes.bool,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    canBeActivated: PropTypes.bool,
    onScannerClick: PropTypes.func,
    serialNumbers: PropTypes.arrayOf(PropTypes.string),
    onReset: PropTypes.func,
};
ProductListItem.defaultProps = {
    onDelete: function () { },
    onActivate: function () { },
    errorMessage: '',
    isFetching: false,
    shouldActivateActiveAnimation: false,
    serialNumberInputs: [],
    onClick: function () { },
    clickable: false,
    showDeleteButton: false,
    showLinkIcon: false,
    handleChange: function () { },
    handleSubmit: function () { },
    canBeActivated: false,
    onScannerClick: function () { },
    serialNumbers: [],
    onReset: function () { },
};
export default ProductListItem;
