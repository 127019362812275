import React from 'react';
import PropTypes from 'prop-types';
var Item = function (_a) {
    var header = _a.header, value = _a.value, statusColor = _a.statusColor, secondValue = _a.secondValue, secondColor = _a.secondColor, unit = _a.unit, type = _a.type;
    return (React.createElement("div", { className: "overview__section__items" },
        React.createElement("div", null, header),
        React.createElement("div", { className: type.includes('Modem')
                ? 'overview__section__items--value--modem'
                : 'overview__section__items--value' },
            React.createElement("span", { className: statusColor }, " ".concat(value, " ")),
            secondValue && (React.createElement("span", null,
                "/",
                React.createElement("span", { className: secondColor }, " ".concat(secondValue, " ")))),
            unit)));
};
Item.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    header: PropTypes.string,
    statusColor: PropTypes.string,
    secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    secondColor: PropTypes.string,
    type: PropTypes.string,
};
Item.defaultProps = {
    value: 0,
    header: '',
    statusColor: '',
    secondValue: '',
    unit: '',
    secondColor: 'status--good',
    type: '',
};
export default Item;
