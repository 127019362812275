import { LINK_PRODUCT_REQUEST, LINK_PRODUCT_SUCCESS, LINK_PRODUCT_FAILURE, LINK_SINGLE_PRODUCT_REQUEST, } from '../customerProductsConstants';
export var linkProductRequest = function (customerId, productId, dpadId) { return ({
    type: LINK_PRODUCT_REQUEST,
    customerId: customerId,
    productId: productId,
    dpadId: dpadId,
}); };
export var linkProductSuccess = function () { return ({
    type: LINK_PRODUCT_SUCCESS,
}); };
export var linkProductFailure = function (error) { return ({
    type: LINK_PRODUCT_FAILURE,
    error: error,
}); };
export var linkSingleDpadRequest = function (customerId, productId, dpadId) { return ({
    type: LINK_SINGLE_PRODUCT_REQUEST,
    customerId: customerId,
    productId: productId,
    dpadId: dpadId,
}); };
