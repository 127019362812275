import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../components/TextInput/TextInput';
import { COMMENT, FOLLOW_UP, CUSTOMER_NOT_HOME, CONFIRMED_RIGHT_ADDRESS, RUNG_KNOCKED_DOOR, CALLED_CUSTOMER, AZIMUTH, } from '../../WorkLog/workLogConstants';
import NOB from '../../../constants/language-strings';
import Checkbox from '../../../components/Checkbox/Checkbox';
import NumberInput from '../../../components/NumberInput/NumberInput';
import DatafieldRow from '../../../components/DatafieldRow/DatafieldRow';
function WorkOrderCompletionFields(_a) {
    var handleChange = _a.handleChange, values = _a.values, technician = _a.technician, azimuthErrorMessage = _a.azimuthErrorMessage, azimuthProposed = _a.azimuthProposed, isFWA = _a.isFWA;
    return (React.createElement("div", { className: "content" },
        React.createElement(TextInput, { onChange: handleChange, maxLength: "500", name: COMMENT, value: values[COMMENT] || '', label: NOB.FOLLOW_UP.COMMENT_LABEL }),
        azimuthProposed && isFWA && (React.createElement(DatafieldRow, { title: NOB.WORK_LOG.AZIMUTH_PROPOSED, value: azimuthProposed, symbol: "\u00B0" })),
        isFWA && (React.createElement(NumberInput, { onChange: handleChange, value: values[AZIMUTH] || '', name: AZIMUTH, placeholder: "0 - 359", label: NOB.WORK_LOG.AZIMUTH_INSTALLED, icon: "\u00B0", errorMessage: azimuthErrorMessage })),
        !technician && (React.createElement(Checkbox, { onClick: handleChange, name: FOLLOW_UP, label: NOB.FOLLOW_UP.FOLLOW_UP, checked: values[FOLLOW_UP] })),
        React.createElement("div", { className: "not__home__checkbox__group" },
            React.createElement(Checkbox, { checked: values[CUSTOMER_NOT_HOME], onClick: handleChange, label: NOB.FOLLOW_UP.NOT_HOME, name: CUSTOMER_NOT_HOME }),
            values[CUSTOMER_NOT_HOME] && (React.createElement("div", { className: "not-home-checkboxes--sub" },
                React.createElement(Checkbox, { checked: values[CONFIRMED_RIGHT_ADDRESS], onClick: handleChange, label: NOB.FOLLOW_UP.CONFIRMED_RIGHT_ADDRESS, name: CONFIRMED_RIGHT_ADDRESS }),
                React.createElement(Checkbox, { checked: values[RUNG_KNOCKED_DOOR], onClick: handleChange, label: NOB.FOLLOW_UP.RUNG_KNOCKED_DOOR, name: RUNG_KNOCKED_DOOR }),
                React.createElement(Checkbox, { checked: values[CALLED_CUSTOMER], onClick: handleChange, label: NOB.FOLLOW_UP.CALLED_CUSTOMER, name: CALLED_CUSTOMER }))))));
}
WorkOrderCompletionFields.propTypes = {
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    technician: PropTypes.bool.isRequired,
    azimuthErrorMessage: PropTypes.string,
    azimuthProposed: PropTypes.string,
    isFWA: PropTypes.bool,
};
WorkOrderCompletionFields.defaultProps = {
    azimuthErrorMessage: '',
    azimuthProposed: '',
    isFWA: false,
};
export default WorkOrderCompletionFields;
