import React from 'react';
import PropTypes from 'prop-types';
import ProductList from './productList/ProductList';
import IconButton from '../../../../components/Buttons/IconButton/IconButton';
import { ARROW_DOWN } from '../../../../constants/iconNames';
import './workOrderListTop.scss';
function WorkOrderListTop(_a) {
    var hideProductList = _a.hideProductList, productList = _a.productList, onNextClick = _a.onNextClick, onPrevClick = _a.onPrevClick, title = _a.title, numberOfElementsPrevPage = _a.numberOfElementsPrevPage;
    var prevButton = (React.createElement("div", { className: onPrevClick ? 'navigation-arrow' : 'navigation-arrow--disabled' },
        React.createElement(IconButton, { onClick: onPrevClick || (function () { }), className: "prev", icon: ARROW_DOWN }),
        numberOfElementsPrevPage > 0 && React.createElement("span", { className: "number" }, numberOfElementsPrevPage)));
    var nextButton = (React.createElement("div", { className: onNextClick ? 'navigation-arrow' : 'navigation-arrow--disabled' },
        React.createElement(IconButton, { onClick: onNextClick || (function () { }), className: "next", icon: ARROW_DOWN })));
    return (React.createElement("div", { className: "work-order-list__top" },
        React.createElement("div", { className: "navigation" },
            prevButton,
            React.createElement("span", { className: "title" }, title),
            nextButton),
        !hideProductList && React.createElement(ProductList, { productList: productList })));
}
WorkOrderListTop.propTypes = {
    hideProductList: PropTypes.bool,
    productList: PropTypes.objectOf(PropTypes.any),
    onNextClick: PropTypes.func,
    onPrevClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    numberOfElementsPrevPage: PropTypes.number,
};
WorkOrderListTop.defaultProps = {
    productList: {},
    onNextClick: null,
    numberOfElementsPrevPage: null,
    onPrevClick: null,
    hideProductList: false,
};
export default WorkOrderListTop;
