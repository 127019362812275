var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CAMERA, DELETE, PDF } from '../../constants/iconNames';
import ExtendedImage from '../ImageViewer/ImageViewer';
import Icon from '../../icons';
import NOB from '../../constants/language-strings';
import './addFiles.scss';
import resizeImage from '../../utils/resizeImage';
import { acceptTypes, imageTypes } from './addFilesConstants';
function AddFiles(_a) {
    var saveFiles = _a.saveFiles, files = _a.files, type = _a.type;
    var _b = __read(useState(false), 2), expand = _b[0], setExpand = _b[1];
    var _c = __read(useState(0), 2), expandedIndex = _c[0], setExpandedIndex = _c[1];
    var uploadFile = function (event) {
        event.preventDefault();
        if (event.target.files.length > 50) {
            alert(NOB.WORK_LOG.IMAGE_ERROR); // eslint-disable-line
        }
        else {
            var _loop_1 = function (i) {
                var file = event.target.files[i];
                var pushFile = function (convertedFile) {
                    saveFiles(function (prev) { return __spreadArray(__spreadArray([], __read(prev), false), [
                        {
                            file: convertedFile,
                            fileName: file.name,
                            type: file.type,
                        },
                    ], false); });
                };
                if (imageTypes.includes(event.target.files[i].type)) {
                    resizeImage(file, 400, 400, function (resizedImage) {
                        pushFile(resizedImage);
                    });
                }
                else {
                    var reader_1 = new FileReader();
                    reader_1.readAsDataURL(file);
                    reader_1.onload = function () {
                        pushFile(reader_1.result);
                    };
                }
            };
            for (var i = 0; i < event.target.files.length; i++) {
                _loop_1(i);
            }
        }
    };
    var deleteFile = function (index) {
        var newFileArray = __spreadArray([], __read(files), false);
        newFileArray.splice(index, 1);
        saveFiles(newFileArray);
    };
    var toggleExpandImage = function (item) {
        var index = files.findIndex(function (image) { return image === item; });
        setExpand(!expand);
        setExpandedIndex(index);
    };
    return (React.createElement("div", { className: "image_file" },
        React.createElement("form", null,
            React.createElement("input", { id: "image_file-".concat(type), onChange: uploadFile, onClick: function (event) {
                    // eslint-disable-next-line no-param-reassign
                    event.target.value = null;
                }, type: "file", accept: acceptTypes, multiple: true }),
            React.createElement("label", { htmlFor: "image_file-".concat(type), id: "image_file_button" },
                React.createElement(Icon, { name: CAMERA }))),
        React.createElement("div", { className: "image_file__images" }, files &&
            files.map(function (item, index) {
                if (imageTypes.includes(item.type)) {
                    return (React.createElement("div", { className: "image_file__images--image", key: item.fileName },
                        React.createElement("button", { className: "image_file__images--image--cross", onClick: function () { return deleteFile(index); } },
                            React.createElement(Icon, { name: DELETE })),
                        React.createElement("button", { onClick: function () { return toggleExpandImage(item); } },
                            React.createElement("img", { alt: "added item", src: item.file }))));
                }
                return (React.createElement("div", { className: "image_file__images--image", key: item.fileName },
                    React.createElement("button", { className: "image_file__images--image--cross", onClick: function () { return deleteFile(index); } },
                        React.createElement(Icon, { name: DELETE })),
                    React.createElement(Icon, { name: PDF, title: item.type.replace('application/', '.') })));
            })),
        expand && (React.createElement(ExtendedImage, { expandedIndex: expandedIndex, imagesFiles: files, toggleExpandImage: toggleExpandImage }))));
}
AddFiles.propTypes = {
    files: PropTypes.arrayOf(PropTypes.any).isRequired,
    saveFiles: PropTypes.func.isRequired,
    type: PropTypes.string,
};
AddFiles.defaultProps = {
    type: '',
};
export default AddFiles;
